import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import SmartForm from '../form/base.jsx';

class PMOProjectForm extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    this.props.userList();
    if (this.props.pmoProjects.itemId) {
      this.props.pmoProjectsItem(this.props.pmoProjects.itemId);
    }

    this.props.userList();
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.pmoProjects.addSuccess && nextProps.pmoProjects.addSuccess !== this.props.pmoProjects.addSuccess) || (nextProps.pmoProjects.editSuccess && nextProps.pmoProjects.editSuccess !== this.props.pmoProjects.editSuccess)) {
      this.props.pmoProjectsShowList();
    }
  }

  submit(data) {
    const dataToSend = Object.assign({}, data);

    if (!dataToSend.end_date) {
      dataToSend.end_date = null;
    }

    if (this.props.pmoProjects.itemId) {
      this.props.pmoProjectsUpdateItem(this.props.pmoProjects.itemId, data, this.props.listStatus);
    } else {
      this.props.pmoProjectsAddItem(data, this.props.listStatus);
    }
  }

  render() {
    let itemData;
    if (this.props.pmoProjects.itemId) {
      itemData = this.props.pmoProjects.itemData;
    }

    const users = [];

    if (this.props.user.data) {
      this.props.user.data.forEach((user) => {
        if (user.role && user.role.capabilities && user.role.capabilities.can_be_officer_in_charge) {
          users.push({
            name: user.name,
            value: user.id
          });
        }
      });
    }

    const schema = [{
      type: 'default',
      fields: [
        {
          type: 'text',
          name: 'project',
          label: 'Project Name:',
          value: itemData ? itemData.project : '',
        },
        {
          type: 'select',
          name: 'officer_in_charge_id',
          label: 'OIC:',
          disabled: this.props.user.inProgress,
          value: itemData && itemData.officer_in_charge ? itemData.officer_in_charge.id : '',
          options: users,
        },
        {
          type: 'select',
          name: 'status',
          label: 'Status:',
          value: itemData ? itemData.status : 1,
          options: [
            { name: 'Active', value: 1 },
            { name: 'Inactive', value: 0 },
            { name: 'Archived', value: 2 },
          ],
        },
        {
          type: 'calendar',
          name: 'start_date',
          label: 'Start Date:',
          value: itemData && itemData.start_date ? itemData.start_date : '',
        },
        {
          type: 'calendar',
          name: 'end_date',
          label: 'End Date:',
          value: itemData && itemData.end_date ? itemData.end_date : '',
          fieldReadOnly: false,
        },
        {
          type: 'textarea',
          name: 'note',
          label: 'Notes:',
          value: itemData ? itemData.note : '',
        },
      ],
    }];

    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this.submit}
              showError={this.props.pmoProjects.error && this.props.pmoProjects.errorText}
              errorMessage={this.props.pmoProjects.errorText}
              onReset={this.props.pmoProjectsShowList.bind(this)}
              loading={this.props.pmoProjects.inProgress}
            />
          </Col>
        </Row>
      </section>
    );
  }
}

export default PMOProjectForm;
