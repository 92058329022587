import makeActionCreator from '../../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API       from '../../utils/config-api'
import * as endpoints from './endpoints'
import { logout } from '../../auth/list';

export const SETTINGS_LEVELS_PENDING = 'SETTINGS_LEVELS_PENDING'
export const settingsLevelsPending = makeActionCreator(SETTINGS_LEVELS_PENDING)

export const SETTINGS_LEVELS_REJECTED = 'SETTINGS_LEVELS_REJECTED'
export const settingsLevelsRejected = makeActionCreator(SETTINGS_LEVELS_REJECTED)

export const SETTINGS_LEVELS_SHOW_LIST= 'SETTINGS_LEVELS_SHOW_LIST'
export const settingsLevelsShowList = makeActionCreator(SETTINGS_LEVELS_SHOW_LIST)

export const SETTINGS_LEVELS_HIDE_LIST = 'SETTINGS_LEVELS_HIDE_LIST'
export const settingsLevelsHideList = makeActionCreator(SETTINGS_LEVELS_HIDE_LIST)

export const SETTINGS_LEVELS_SHOW_FORM= 'SETTINGS_LEVELS_SHOW_FORM'
export const settingsLevelsShowForm = makeActionCreator(SETTINGS_LEVELS_SHOW_FORM, 'itemId')

export const SETTINGS_LEVELS_HIDE_FORM = 'SETTINGS_LEVELS_HIDE_FORM'
export const settingsLevelsHideForm = makeActionCreator(SETTINGS_LEVELS_HIDE_FORM)

export const SETTINGS_LEVELS_SHOW_ITEM = 'SETTINGS_LEVELS_SHOW_ITEM'
export const settingsLevelsShowItem = makeActionCreator(SETTINGS_LEVELS_SHOW_ITEM, 'itemId')


//Show Index

export const SETTINGS_LEVELS_LIST_FULFILLED = 'SETTINGS_LEVELS_LIST_FULFILLED'
export const settingsLevelsListFulfilled = makeActionCreator(SETTINGS_LEVELS_LIST_FULFILLED, 'data')

export const SETTINGS_LEVELS_LIST_ERROR = 'SETTINGS_LEVELS_LIST_ERROR'
export const settingsLevelsListError = makeActionCreator(SETTINGS_LEVELS_LIST_ERROR, 'message')

export function settingsLevelsList() {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsLevelsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}`, {
              method  : endpoints.index.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(settingsLevelsListFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsLevelsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}


//Show Item

export const SETTINGS_LEVELS_ITEM_FULFILLED = 'SETTINGS_LEVELS_ITEM_FULFILLED'
export const settingsLevelsItemFulfilled = makeActionCreator(SETTINGS_LEVELS_ITEM_FULFILLED, 'data')

export const SETTINGS_LEVELS_ITEM_ERROR = 'SETTINGS_LEVELS_ITEM_ERROR'
export const settingsLevelsItemError = makeActionCreator(SETTINGS_LEVELS_ITEM_ERROR, 'message')

export function settingsLevelsItem(itemId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsLevelsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getItem.version}${endpoints.getItem.path.replace(":user_roles_id", itemId)}`, {
              method  : endpoints.getItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(settingsLevelsItemFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsLevelsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}


//Add Item

export const SETTINGS_LEVELS_ITEM_ADD_FULFILLED = 'SETTINGS_LEVELS_ITEM_ADD_FULFILLED'
export const settingsLevelsItemAddFulfilled = makeActionCreator(SETTINGS_LEVELS_ITEM_ADD_FULFILLED, 'data')

export const SETTINGS_LEVELS_ITEM_ADD_ERROR = 'SETTINGS_LEVELS_ITEM_ADD_ERROR'
export const settingsLevelsItemAddError = makeActionCreator(SETTINGS_LEVELS_ITEM_ADD_ERROR, 'message')

export function settingsLevelsAddItem(data) {
  let formData = new FormData()

  Object.keys(data).map((key)=> {
    formData.append(key, data[key]);
    return key;
  });

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsLevelsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addItem.version}${endpoints.addItem.path}`, {
              method  : endpoints.addItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(settingsLevelsItemAddFulfilled(json.data))
              }else{
                dispatch(settingsLevelsItemAddError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsLevelsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}

//Update Item

export const SETTINGS_LEVELS_ITEM_UPDATE_FULFILLED = 'SETTINGS_LEVELS_ITEM_UPDATE_FULFILLED'
export const settingsLevelsItemUpdateFulfilled = makeActionCreator(SETTINGS_LEVELS_ITEM_UPDATE_FULFILLED, 'data')

export const SETTINGS_LEVELS_ITEM_UPDATE_ERROR = 'SETTINGS_LEVELS_ITEM_UPDATE_ERROR'
export const settingsLevelsItemUpdateError = makeActionCreator(SETTINGS_LEVELS_ITEM_UPDATE_ERROR, 'message')

export function settingsLevelsUpdateItem(itemId, data) {
  let formData = new FormData()
  formData.append('_method', 'PUT')

  Object.keys(data).map((key)=> {
    formData.append(key, data[key]);
    return key;
  });


  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsLevelsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateItem.version}${endpoints.updateItem.path.replace(":user_roles_id", itemId)}`, {
              method  : endpoints.updateItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(settingsLevelsItemUpdateFulfilled(json.data))
              }else{
                dispatch(settingsLevelsItemUpdateError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsLevelsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}

//Delete Item

export const SETTINGS_LEVELS_ITEM_DELETE_FULFILLED = 'SETTINGS_LEVELS_ITEM_DELETE_FULFILLED'
export const settingsLevelsItemDeleteFulfilled = makeActionCreator(SETTINGS_LEVELS_ITEM_DELETE_FULFILLED, 'data')

export const SETTINGS_LEVELS_ITEM_DELETE_ERROR = 'SETTINGS_LEVELS_ITEM_DELETE_ERROR'
export const settingsLevelsItemDeleteError = makeActionCreator(SETTINGS_LEVELS_ITEM_DELETE_ERROR, 'message')

export function settingsLevelsDeleteItem(itemId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsLevelsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteItem.version}${endpoints.deleteItem.path.replace(":user_roles_id", itemId)}`, {
              method  : endpoints.deleteItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
            if(json.status === "ok"){
              dispatch(settingsLevelsItemDeleteFulfilled(json.data))
              dispatch(settingsLevelsList())
            }else{
              dispatch(settingsLevelsItemDeleteError(json.message))
            }

          })
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsLevelsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })





  }
}
