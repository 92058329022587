import React, { Component } from 'react';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import cookie from 'react-cookies';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

import 'assets/css/table.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

class PMOIssueManagementList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      itemId: null,
      sortName: '',
      sortOrder: '',
    };
  }

  componentWillMount() {
    this.setState(
      {
        sortName: cookie.load('sort_column_pmo_issues') || 'serial_number',
        sortOrder: cookie.load('sortOrder_pmo_issues') || 'asc',
      },
    );
  }

  onSortChange(sortName, sortOrder) {
    cookie.save('sort_column_pmo_issues', sortName, { path: '/' });
    cookie.save('sortOrder_pmo_issues', sortOrder, { path: '/' });

    this.setState({
      sortName,
      sortOrder,
    });
  }

  trClassFormat(row, rowIndex) {
    return rowIndex % 2 === 0 ? "tr-odd" : "tr-even";  // return class name.
  }

  moneyFormatter(cell, row){
    return "$" + Number(cell).toLocaleString('en-US')
  }

  dateFormatter(cell, row){
    return moment(cell).format("YYYY-MM-DD")
  }

  sortDateFunc(a, b, order, sortField) {   // order is desc or asc
    if (order === 'asc') {
      if (moment(a[sortField]).isBefore(b[sortField])) {
        return -1;
      } else if (moment(b[sortField]).isBefore(a[sortField])) {
        return 1;
      }
      return 0;
    }


    if (moment(b[sortField]).isBefore(a[sortField])) {
      return -1;
    } else if (moment(a[sortField]).isBefore(b[sortField])) {
      return 1;
    }
    return 0;

  }

  boolFormatter(cell, row){
    return cell?"Yes":"No"
  }

  percentajeFormatter(cell, row){
    return cell + "%"
  }

  objectFormatter(field, cell, row){
    return cell[field]
  }

  objectPercentajeFormatter(field, cell, row){
    return cell[field] + "%"
  }

  sortObjectFunc(a, b, order, sortField, extraData) {   // order is desc or asc
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return a[sortField][fieldName].localeCompare(b[sortField][fieldName])
    } else {
      return b[sortField][fieldName].localeCompare(a[sortField][fieldName]);
    }

  }

  sortNumberObjectFunc(a, b, order, sortField, extraData) {
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return parseFloat(a[sortField][fieldName]) - parseFloat(b[sortField][fieldName])
    } else {
      return parseFloat(b[sortField][fieldName]) - parseFloat(a[sortField][fieldName]);
    }
  }

  sortNumberFunc(a, b, order, sortField) {
    if (order === 'asc') {
      return parseFloat(a[sortField]) - parseFloat(b[sortField])
    } else {
      return parseFloat(b[sortField]) - parseFloat(a[sortField]);
    }
  }

  closeDeleteModal() {
    this.setState({ showModal: false, itemId: null });
  }

  openDeleteModal(itemId) {
    this.setState({ showModal: true, itemId });
  }

  confirmDelete(itemId) {
    this.props.pmoIssueManagementDeleteItem(this.props.projectId, itemId);
    this.closeDeleteModal();
  }


  renderButtons(cell, row) {
    return (
      <div className="actions">
        <Button
          bsStyle="success"
          onClick={(e) => {
            e.stopPropagation();
            this.props.pmoIssueManagementShowForm(row.id);
          }}
        >
          <i className="fa fa-pencil" aria-hidden="true" />
        </Button>

        <Button
          bsStyle="danger"
          onClick={(e) => {
            e.stopPropagation();
            this.openDeleteModal(row.id);
          }}
        >
          <i className="fa fa-trash" aria-hidden="true" />
        </Button>
      </div>
    );
  }

  renderModal() {
    let name = '';
    if (this.state.itemId && this.props.pmoIssueManagement.data.length > 0) {
      for (let i=0; i<this.props.pmoIssueManagement.data.length;i++) {
        if(this.props.pmoIssueManagement.data[i].id === this.state.itemId){
            name = this.props.pmoIssueManagement.data[i].name
        }
      }
    }

    return (
      <Modal show={this.state.showModal} onHide={this.closeDeleteModal.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm Delete {name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete this element?
          </p>
          <p>
            All the infomation related to this Issue will be deleted.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.confirmDelete.bind(this, this.state.itemId)} bsStyle="danger">
            Delete
          </Button>
          <Button onClick={this.closeDeleteModal.bind(this)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const list = this.props.pmoIssueManagement.data;

    const {
      sortName,
      sortOrder,
    } = this.state;

    const options = {
      expandRowBgColor: 'rgb(242, 255, 163)',
      defaultSortName: sortName,
      defaultSortOrder: sortOrder,
      onSortChange: this.onSortChange.bind(this),
    };

    return (
      <section className="section-list-content issues">
        <BootstrapTable
          data={list}
          hover
          options={options}
          ref={(table) => { this.table = table; }}
          trClassName={this.trClassFormat}
          headerContainerClass="fixed-header"
        >
          <TableHeaderColumn
            dataField="serial_number"
            columnTitle
            isKey
            dataSort
            width="50px"
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="area"
            columnTitle
            filter={{ type: 'TextFilter' }}
            dataSort
            width="110px"
          >
            Area
          </TableHeaderColumn>
          <TableHeaderColumn dataField="description" columnTitle>
            Description
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="manually_created_at"
            columnTitle
            filter={{ type: 'TextFilter' }}
            dataSort
            sortFunc={this.sortDateFunc}
            width="105px"
          >
            Created On
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="assigned_to"
            columnTitle
            filter={{ type: 'TextFilter' }}
            dataSort
            width="105px"
          >
            Assigned To
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="due_date"
            columnTitle
            filter={{ type: 'TextFilter' }}
            dataSort
            sortFunc={this.sortDateFunc}
            width="100px"
          >
            Due Date
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="is_closed"
            columnTitle
            filter={{ type: 'SelectFilter', options: { 1: 'Yes', 0: 'No' } }}
            dataSort
            width="80px"
            dataFormat={this.boolFormatter}
          >
            Closed
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="closed_at"
            columnTitle
            filter={{ type: 'TextFilter' }}
            dataSort
            width="100px"
            sortFunc={this.sortDateFunc}
          >
            Closed On
          </TableHeaderColumn>
          <TableHeaderColumn dataField="comments" columnTitle>
            Comments
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="buttons"
            dataFormat={this.renderButtons.bind(this)}
            dataAlign="center"
            headerAlign="left"
            className="col_buttons"
            columnClassName="col_buttons"
            width="95px"
            hidden={!this.props.permissions.can_update && !this.props.permissions.can_delete}
          />
        </BootstrapTable>


        {this.renderModal()}
      </section>
    );
  }
}


export default PMOIssueManagementList;
