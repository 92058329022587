import React, { Component } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import _ from 'lodash';
import cookie from 'react-cookies';
import { browserHistory } from 'react-router';

import ActionItemsList from './list.jsx';
import ActionItemsForm from './form.jsx';
import SystemMsg from '../../common/system-msg.jsx';
import 'assets/css/pmo.css';
import SmartForm from '../../form/base.jsx';

class ActionItems extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'pmo') {
          permissions = area;
        }
      });
    }

    this.state = {
      permissions,
      status: '9999',
      projectId: this.props.params && this.props.params.projectId ? this.props.params.projectId : '',
      projectData: null,
    };
  }

  componentWillMount() {
    if (this.state.projectId) {
      this.props.pmoProjectsItem(this.state.projectId);
    }
    const filtersCookie = cookie.load('pmo_action_items_filters') || {};

    this.setState(
      {
        status: filtersCookie && filtersCookie.status ? filtersCookie.status : this.state.status,
      },
    );
  }

  componentDidMount() {
    this.props.pmoActionItemsList(this.state.projectId, this.state.status);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'pmo') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }

    if (!_.isEqual(this.props.pmoProjects.itemData, nextProps.pmoProjects.itemData) || !_.isEqual(nextProps.pmoProjects.itemData, this.state.projectData)) {
      this.setState({
        projectData: nextProps.pmoProjects.itemData,
      });
    }
  }

  componentWillUnmount() {
    this.props.pmoActionItemsHideForm();
    this.props.pmoActionItemsShowList();
  }

  getTitle() {
    if (!this.state.projectData) {
      return null;
    }

    if (this.props.pmoActionItems.showList) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={() => { browserHistory.push(`/pmo/project/${this.state.projectId}`); }}>
                <span className="fa fa-arrow-circle-o-left" />
                &nbsp;Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={4} className="title-section">
              <div className="title-box">
                <h1 className="box-title">
                  Action Items
                </h1>
                <h2>
                  {this.state.projectData.project}
                </h2>
              </div>
              {this.state.permissions.can_create ? (
                <Button bsSize="small" bsStyle="success" onClick={this.props.pmoActionItemsShowForm.bind(this, "newItem")}>
                  Add New
                </Button>
              ) : null
            }
            </Col>
            <Col xs={8} className="action-section">
              <SmartForm
                schema={[
                  {
                    type: 'default',
                    fields: [
                      {
                        type: 'select',
                        name: 'completed',
                        label: 'Completed:',
                        value: this.state.status,
                        options: [
                          {
                            name: 'Both',
                            value: '9999'
                          },
                          {
                            name: 'Yes',
                            value: '1'
                          },
                          {
                            name: 'No',
                            value: '0'
                          },
                        ],
                        onChange: this.changeStatus.bind(this),
                      },
                    ],
                  },
                ]}
                inline
                buttons={[]}
              />
            </Col>
          </Row>
        </div>
      );
    }

    if (this.props.pmoActionItems.showForm) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.pmoActionItemsShowList.bind(this)}>
                <span className="fa fa-arrow-circle-o-left" />
                &nbsp;Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <div className="title-box">
                <h1 className="box-title">
                  {this.props.pmoActionItems.itemId ? 'Edit Action Item' : 'Add New Action Item'}
                </h1>
                <h2>
                  {this.state.projectData.project}
                </h2>
              </div>
            </Col>
          </Row>
        </div>
      );
    }

    return null;
  }

  changeStatus(value) {
    this.setState({ status: value });
    this.props.pmoActionItemsList(this.state.projectId, value);

    cookie.save('pmo_action_items_filters', JSON.stringify({ status: value }), { path: '/' });
  }

  render() {
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className={`pmo-content ${this.props.pmoActionItems.showList ? 'minWidth' : ''}`}>
        { this.props.pmoActionItems.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this.getTitle()}
          </div>
          <div className="box-content">
            {this.props.pmoActionItems.showList ? <ActionItemsList permissions={this.state.permissions} projectId={this.state.projectId} listStatus={this.state.status} {...this.props} /> : ''}
            {this.props.pmoActionItems.showForm ? <ActionItemsForm permissions={this.state.permissions} projectId={this.state.projectId} listStatus={this.state.status} {...this.props} /> : ''}
          </div>
        </div>
      </section>
    )
  }
}

export default ActionItems
