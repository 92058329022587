import React, { Component } from 'react';
import _ from 'lodash';
import AnnouncementsList from './list.jsx';
import SystemMsg from '../common/system-msg.jsx';
import Aux from '../../hoc/Aux';
import './Announcements.css';



class Announcements extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'announcements') {
          permissions = area;
        }
      });
    }

    this.state = {
      permissions     
    };

  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'announcements') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

 

  getTitle() {
    return <h1 className="box-title">Announcements</h1>;
  }

  render() {
    if ( !this.state.permissions || ( !this.state.permissions.can_read )) {
        if (this.state.permissions) {
            this.props.router.push('/404');
        }
        return null;
    }

    return (
        <Aux>
            { this.props.settingsAnnouncements.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
                <div className="box-header with-border"> {this.getTitle()} </div>
             
                <AnnouncementsList
                    permissions={this.state.permissions}
                    data={this.props.settingsAnnouncements.data}
                    {...this.props}
                    
                />
         
        </Aux>
    );
  }
}

export default Announcements;
