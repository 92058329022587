import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './_endpoints';
import { logout } from '../auth/list';

export const MENTORSHIP_PENDING = 'MENTORSHIP_PENDING';
export const mentorshipPending = makeActionCreator(MENTORSHIP_PENDING);

export const MENTORSHIP_REJECTED = 'MENTORSHIP_REJECTED';
export const mentorshipRejected = makeActionCreator(MENTORSHIP_REJECTED, 'error');

// Show Index
export const MENTORSHIP_FULFILLED = 'MENTORSHIP_FULFILLED';
export const mentorshipDataFulfilled = makeActionCreator(MENTORSHIP_FULFILLED, 'data');

export const MENTORSHIP_ERROR = 'MENTORSHIP_ERROR';
export const mentorshipDataError = makeActionCreator(MENTORSHIP_ERROR, 'message');

export function getMentorshipData() {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(mentorshipPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}`, {
        method: endpoints.index.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(mentorshipDataFulfilled(json.data));
          } else {
            dispatch(mentorshipDataError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(mentorshipRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Get Mentors Catalog
export const MENTORSHIP_MENTORS_CATALOG_FULFILLED = 'MENTORSHIP_MENTORS_CATALOG_FULFILLED';
export const mentorsCatalogFulfilled = makeActionCreator(MENTORSHIP_MENTORS_CATALOG_FULFILLED, 'data');

export const MENTORSHIP_MENTORS_CATALOG_ERROR = 'MENTORSHIP_MENTORS_CATALOG_ERROR';
export const mentorsCatalogError = makeActionCreator(MENTORSHIP_MENTORS_CATALOG_ERROR, 'message');

export function getMentorsCatalog() {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(mentorshipPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.mentorsCatalog.version}${endpoints.mentorsCatalog.path}`, {
        method: endpoints.mentorsCatalog.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(mentorsCatalogFulfilled(json.data));
          } else {
            dispatch(mentorsCatalogError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(mentorshipRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
