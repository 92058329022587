import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../../utils/makeActionCreator';
import * as API from '../../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../../auth/list';

export const SETTINGS_RESOURCES_METHODOLOGIES_PENDING = 'SETTINGS_RESOURCES_METHODOLOGIES_PENDING';
export const settingsMethodologiesResourcesPending = makeActionCreator(
  SETTINGS_RESOURCES_METHODOLOGIES_PENDING,
);

export const SETTINGS_RESOURCES_METHODOLOGIES_REJECTED = 'SETTINGS_RESOURCES_METHODOLOGIES_REJECTED';
export const settingsMethodologiesResourcesRejected = makeActionCreator(
  SETTINGS_RESOURCES_METHODOLOGIES_REJECTED,
);


export const SETTINGS_RESOURCES_METHODOLOGIES_SHOW_ITEM = 'SETTINGS_RESOURCES_METHODOLOGIES_SHOW_ITEM';
export const settingsMethodologiesResourcesShowItem = makeActionCreator(
  SETTINGS_RESOURCES_METHODOLOGIES_SHOW_ITEM,
  'itemId',
);

// Show Index

export const SETTINGS_RESOURCES_METHODOLOGIES_LIST_FULFILLED = 'SETTINGS_RESOURCES_METHODOLOGIES_LIST_FULFILLED';
export const settingsMethodologiesResourcesListFulfilled = makeActionCreator(SETTINGS_RESOURCES_METHODOLOGIES_LIST_FULFILLED, 'data', 'pagination', 'currentPage');

export const SETTINGS_RESOURCES_METHODOLOGIES_LIST_ERROR = 'SETTINGS_RESOURCES_METHODOLOGIES_LIST_ERROR';
export const settingsMethodologiesResourcesListError = makeActionCreator(SETTINGS_RESOURCES_METHODOLOGIES_LIST_ERROR, 'message');

export function resourcesMethodologiesList(page = 1) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(settingsMethodologiesResourcesPending());
      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.MethodologiesResourcesList.version}${endpoints.MethodologiesResourcesList.path}?page=${page}`, {
        method: endpoints.MethodologiesResourcesList.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          const paginationData = {
            first_page_url: json.first_page_url,
            from: json.from,
            last_page: json.last_page,
            last_page_url: json.last_page_url,
            next_page_url: json.next_page_url,
            per_page: json.per_page,
            prev_page_url: json.prev_page_url,
            to: json.to,
            total: json.total,
          };
          dispatch(settingsMethodologiesResourcesListFulfilled(json.data, paginationData, page));
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(settingsMethodologiesResourcesRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Add new

export const SETTINGS_RESOURCES_METHODOLOGIES_ITEM_ADD_FULFILLED = 'SETTINGS_RESOURCES_METHODOLOGIES_ITEM_ADD_FULFILLED';
export const settingsMethodologiesResourcesAddFulfilled = makeActionCreator(SETTINGS_RESOURCES_METHODOLOGIES_ITEM_ADD_FULFILLED, 'data');

export const SETTINGS_RESOURCES_METHODOLOGIES_ITEM_ADD_ERROR = 'SETTINGS_RESOURCES_METHODOLOGIES_ITEM_ADD_ERROR';
export const settingsMethodologiesResourcesAddError = makeActionCreator(SETTINGS_RESOURCES_METHODOLOGIES_ITEM_ADD_ERROR, 'message');

export function settingsMethodologiesResourcesAddItem(data) {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('version', data.version);

  if (data.resource_1 || data.resource_1 === null) {
    formData.append('resource_1', data.resource_1 || '');
  }

  if (data.resource_2 || data.resource_2 === null) {
    formData.append('resource_2', data.resource_2 || '');
  }

  if (data.resource_3 || data.resource_3 === null) {
    formData.append('resource_3', data.resource_3 || '');
  }

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(settingsMethodologiesResourcesPending());
      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.MethodologiesResourcesSave.version}${endpoints.MethodologiesResourcesSave.path}`, {
        method: endpoints.MethodologiesResourcesSave.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
          // 'Content-Type': 'multipart/form-data',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(settingsMethodologiesResourcesAddFulfilled(json.data));
          } else {
            dispatch(settingsMethodologiesResourcesAddError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(settingsMethodologiesResourcesRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Edit Item

export const SETTINGS_RESOURCES_METHODOLOGIES_ITEM_UPDATE_FULFILLED = 'SETTINGS_RESOURCES_METHODOLOGIES_ITEM_UPDATE_FULFILLED';
export const settingsMethodologiesResourcesEditFulfilled = makeActionCreator(SETTINGS_RESOURCES_METHODOLOGIES_ITEM_UPDATE_FULFILLED, 'data');

export const SETTINGS_RESOURCES_METHODOLOGIES_ITEM_UPDATE_ERROR = 'SETTINGS_RESOURCES_METHODOLOGIES_ITEM_UPDATE_ERROR';
export const settingsMethodologiesResourcesEditError = makeActionCreator(SETTINGS_RESOURCES_METHODOLOGIES_ITEM_UPDATE_ERROR, 'message');

export function settingsMethodologiesResourcesUpdateItem(itemId, data) {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('version', data.version);

  if (data.resource_1 || data.resource_1 === null) {
    formData.append('resource_1', data.resource_1 || '');
  }

  if (data.resource_2 || data.resource_2 === null) {
    formData.append('resource_2', data.resource_2 || '');
  }

  if (data.resource_3 || data.resource_3 === null) {
    formData.append('resource_3', data.resource_3 || '');
  }

  formData.append('_method', 'PUT');

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(settingsMethodologiesResourcesPending());
      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.MethodologiesResourcesEdit.version}${endpoints.MethodologiesResourcesEdit.path.replace(':methodologies_resource_id', itemId)}`, {
        method: endpoints.MethodologiesResourcesEdit.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
          // 'Content-Type': 'multipart/form-data',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(settingsMethodologiesResourcesEditFulfilled(json.data));
          } else {
            dispatch(settingsMethodologiesResourcesEditError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(settingsMethodologiesResourcesRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Delete Item

export const SETTINGS_RESOURCES_METHODOLOGIES_DELETE_FULFILLED = 'SETTINGS_RESOURCES_METHODOLOGIES_DELETE_FULFILLED';
export const settingsMethodologiesResourcesDeleteFulfilled = makeActionCreator(SETTINGS_RESOURCES_METHODOLOGIES_DELETE_FULFILLED, 'data');

export const SETTINGS_RESOURCES_METHODOLOGIES_DELETE_ERROR = 'SETTINGS_RESOURCES_METHODOLOGIES_DELETE_ERROR';
export const settingsMethodologiesResourcesDeleteError = makeActionCreator(SETTINGS_RESOURCES_METHODOLOGIES_DELETE_ERROR, 'message');

export function settingsMethodologiesResourcesRemoveItem(itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(settingsMethodologiesResourcesPending());
      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.MethodologiesResourcesDelete.version}${endpoints.MethodologiesResourcesDelete.path.replace(':methodologies_resource_id', itemId)}`, {
        method: endpoints.MethodologiesResourcesDelete.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          dispatch(settingsMethodologiesResourcesDeleteFulfilled(json.data));
          dispatch(dispatch(resourcesMethodologiesList()));
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(settingsMethodologiesResourcesRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
