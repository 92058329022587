import React, { Component } from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import UserLevelsList from './list.jsx';
import UserLevelsForm from './form.jsx';

class UserLevels extends Component {
  constructor(props) {
    super(props);
    let userData = null;

    props.user.data.forEach((user) => {
      if (user.id === props.userId) {
        userData = user;
      }
    })

    this.state = {
      date: userData && userData.staffing_end_date ? moment(userData.staffing_end_date) : '',
    };
    this.handleValueChange = this.handleValueChange.bind(this);
    this.validDay = this.validDay.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  componentWillUnmount() {
    this.props.userLevelHideForm();
    this.props.userLevelShowList();
  }

  handleFieldChange(value) {
    this.setState({ date: value });
  }

  handleValueChange() {
    if (moment(this.state.date, 'YYYY-MM-DD', true).isValid() || this.state.date === '' || this.state.date === null) {
      if (moment(this.state.date, 'YYYY-MM-DD', true).isValid()) {
        this.props.editUserStaffingDate(this.props.userId, moment(this.state.date).format('YYYY-MM-DD'));
      } else {
        this.props.editUserStaffingDate(this.props.userId, '');
      }
    }
  }

  validDay(currentDate) {
    return currentDate.isSameOrAfter(moment(), 'day');
  }

  render() {
    return (
      <div className="levels-content">
        {this.props.userLevel.showList && this.props.userId ?
          (
            <div className="form-group field calendar-field">
              <label
                htmlFor="staffing_end_date"
              >
                Staffing Not Available From:
              </label>
              <div className="field-calendar">
                <Datetime
                  dateFormat="YYYY-MM-DD"
                  timeFormat={false}
                  value={this.state.date}
                  onChange={this.handleFieldChange}
                  inputProps={{
                    id: 'staffing_end_date',
                    name: 'staffing_end_date',
                    required: true,
                    autoComplete: 'off',
                    placeholder: 'Select a date',
                    style: { backgroundColor: '#FFF' },
                  }}
                  isValidDate={this.validDay}
                  name="staffing_end_date"
                  closeOnSelect
                  viewMode="days"
                />
                <div className="icon">
                  <span className="fa fa-calendar" />
                </div>
              </div>
              <div className="save">
                <Button
                  bsSize="small"
                  bsStyle="primary"
                  onClick={this.handleValueChange}
                  disabled={(!moment(this.state.date, 'YYYY-MM-DD', true).isValid() &&  this.state.date !== '' && this.state.date !== null) || (this.state.date !== '' && this.state.date !== null && !this.validDay(moment(this.state.date))) }
                >
                  Update
                </Button>
              </div>
          </div>
          )
          : null }
        {this.props.userLevel.showList && this.props.userId ?<UserLevelsList userId={this.props.userId} {...this.props} /> : null}
        {this.props.userLevel.showForm?<UserLevelsForm userId={this.props.userId} {...this.props} /> : null}
      </div>
    );
  }
}

export default UserLevels
