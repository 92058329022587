import React, {Component} from 'react';
import SettingsLevelsList from './list.jsx'
import SettingsLevelsForm from './form.jsx'
import SystemMsg from '../../common/system-msg.jsx'
import { Col, Row, Button} from 'react-bootstrap';
import "assets/css/business-development.css";

class SettingsLevels extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'settings') {
          permissions = area;
        }
      });
    }
    this.state = {
      permissions,
    };
  }

  componentWillUnmount() {
    this.props.settingsLevelsHideForm()
    this.props.settingsLevelsShowList()
  }

  render(){
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className="levels-content">
        { this.props.settingsLevels.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this._getTitle()}
          </div>
          <div className="box-content">
            {this.props.settingsLevels.showList?<SettingsLevelsList {...this.props} />:""}
            {this.props.settingsLevels.showForm?<SettingsLevelsForm {...this.props} />:""}
          </div>
        </div>



      </section>
    )
  }

  _getTitle(){
    if(this.props.settingsLevels.showList){
      return (
        <Row>
          <Col xs={12} className="title-section">
            <h1 className="box-title">Levels</h1>
            {
              //<Button bsStyle="link">No Grouping <span className="fa fa-caret-down"></span></Button>
            }
            <Button bsSize="small" bsStyle="success" onClick={this.props.settingsLevelsShowForm.bind(this, "newItem")}>Add New</Button>
          </Col>
        </Row>



      )
    }else if(this.props.settingsLevels.showForm){
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.settingsLevelsShowList.bind(this)}><span className="fa fa-arrow-circle-o-left"></span> Back</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">{this.props.settingsLevels.itemId?"Edit Level":"Add New Level"}</h1>
            </Col>
          </Row>
        </div>


      )
    }
  }
}

export default SettingsLevels
