import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';

export const REALIZATION_SUMMARY_PENDING = 'REALIZATION_SUMMARY_PENDING';
export const realizationSummaryPending = makeActionCreator(REALIZATION_SUMMARY_PENDING);

export const REALIZATION_SUMMARY_REJECTED = 'REALIZATION_SUMMARY_REJECTED';
export const realizationSummaryRejected = makeActionCreator(REALIZATION_SUMMARY_REJECTED);

// Show Index

export const REALIZATION_SUMMARY_FULFILLED = 'REALIZATION_SUMMARY_FULFILLED';
export const realizationSummaryListFulfilled = makeActionCreator(REALIZATION_SUMMARY_FULFILLED, 'data');

export function realizationSummaryList(summaryStatus, summaryBillable, summaryBillableBy) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(realizationSummaryPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.summary.version}${endpoints.summary.path}?status=${summaryStatus}&billable=${summaryBillable}&billable_by=${summaryBillableBy}`, {
        method: endpoints.summary.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then(json => dispatch(realizationSummaryListFulfilled(json.data)))
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(realizationSummaryRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
