import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Button } from 'react-bootstrap';
import 'assets/css/table.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

class ConsultantSkillsList extends Component {
  constructor(props) {
    super(props);
    const { session, permissions } = this.props;
    const { user } = session;

    // Set State
    this.state = {
      permissions,
      loggedUser: user,
    };

    this.renderButtons = this.renderButtons.bind(this);
  }

  objectFormatter(field, cell, row) {
    if (cell && cell[field]) {
      return cell[field];
    }

    return '';
  }

  sortObjectFunc(a, b, order, sortField, extraData) {
    const fieldName = extraData["objectField"];
    if (order === 'asc') {
      return (a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'').localeCompare(b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'');
    } else {
      return (b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'').localeCompare(a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'');
    } 
  }

  columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {
    let className = '';
    if (!row.harvest_is_active) {
      className += ' inactive';
    } else if (!row.role) {
      className += ' no-role';
    }
    return className;
  }

  renderButtons(cell, row) {
    const { permissions } = this.props;
    const { loggedUser } = this.state;
    const canUpdate = (
      permissions && 
      permissions.can_update &&
      (!permissions.limit_to_own_user || loggedUser.id === row.id)
    );

    // build destination url
    const url = '/consultant-skills/'+row.id;

    return (
      <div className="actions">
        { /* If not limited to own user and can be edited by other users */ }
        {(canUpdate) ? (
          <Button bsStyle="success" onClick={() => { browserHistory.push(url); }}>
            <i className="fa fa-pencil" aria-hidden="true" />
          </Button>
        ) : null}
      </div>
    );
  }

  render() {
    const { consultantSkills, permissions } = this.props;

    // Calculate if user is able to EDIT
    const editEnabled = (permissions && permissions.can_update);

    const options = {
      defaultSortName: 'name',
      defaultSortOrder: 'asc',
    };

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={consultantSkills.indexData}
          striped
          hover
          options={options}
          headerContainerClass="fixed-header"
        >
          <TableHeaderColumn
            dataField="name"
            row="0"
            rowSpan="2"
            filter={{ type: 'TextFilter', delay: 500 }}
            dataSort
            isKey
            filterFormatted
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="role"
            row="0"
            rowSpan="2"
            columnTitle
            dataSort
            dataFormat={this.objectFormatter.bind(this, 'name')}
            columnClassName={this.columnClassNameFormat}
            filterFormatted
            sortFunc={this.sortObjectFunc}
            sortFuncExtraData={
              { objectField: 'name' }
            }
          >
            Level
          </TableHeaderColumn>

          <TableHeaderColumn className="col_skill_metric_header" row="0" colSpan="2" dataAlign="center">Frontend</TableHeaderColumn>
          <TableHeaderColumn
            dataField="frontend_d"
            columnClassName="col_skill_metric"
            dataSort
            dataAlign="center"
            row="1"
          >
            D
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="frontend_b"
            columnClassName="col_skill_metric"
            dataSort
            dataAlign="center"
            row="1"
          >
            B
          </TableHeaderColumn>

          <TableHeaderColumn className="col_skill_metric_header" row="0" colSpan="2" dataAlign="center">Backend</TableHeaderColumn>
          <TableHeaderColumn
            dataField="backend_d"
            columnClassName="col_skill_metric"
            dataSort
            dataAlign="center"
            row="1"
          >
            D
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="backend_b"
            columnClassName="col_skill_metric"
            dataSort
            dataAlign="center"
            row="1"
          >
            B
          </TableHeaderColumn>

          <TableHeaderColumn className="col_skill_metric_header" row="0" colSpan="2" dataAlign="center">Management</TableHeaderColumn>
          <TableHeaderColumn
            dataField="management_d"
            columnClassName="col_skill_metric"
            dataSort
            dataAlign="center"
            row="1"
          >
            D
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="management_b"
            columnClassName="col_skill_metric"
            dataSort
            dataAlign="center"
            row="1"
          >
            B
          </TableHeaderColumn>

          <TableHeaderColumn className="col_skill_metric_header" row="0" colSpan="2" dataAlign="center">Cloud</TableHeaderColumn>
          <TableHeaderColumn
            dataField="cloud_d"
            columnClassName="col_skill_metric"
            dataSort
            dataAlign="center"
            row="1"
          >
            D
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="cloud_b"
            columnClassName="col_skill_metric"
            dataSort
            dataAlign="center"
            row="1"
          >
            B
          </TableHeaderColumn>

          <TableHeaderColumn className="col_skill_metric_header" row="0" colSpan="2" dataAlign="center">UI/UX</TableHeaderColumn>
          <TableHeaderColumn
            dataField="ui_ux_d"
            columnClassName="col_skill_metric"
            dataSort
            dataAlign="center"
            row="1"
          >
            D
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="ui_ux_b"
            columnClassName="col_skill_metric"
            dataSort
            dataAlign="center"
            row="1"
          >
            B
          </TableHeaderColumn>

          <TableHeaderColumn className="col_skill_metric_header" row="0" colSpan="2" dataAlign="center">Analytics</TableHeaderColumn>
          <TableHeaderColumn
            dataField="analytics_d"
            columnClassName="col_skill_metric"
            dataSort
            dataAlign="center"
            row="1"
          >
            D
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="analytics_b"
            columnClassName="col_skill_metric"
            dataSort
            dataAlign="center"
            row="1"
          >
            B
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="buttons"
            dataFormat={this.renderButtons}
            dataAlign="center"
            headerAlign="left"
            className="col_buttons"
            columnClassName="col_buttons"
            width="95px"
            hidden={!permissions || !editEnabled }
            row="0"
            rowSpan="2"
          />
        </BootstrapTable>
      </section>
    );
  }
}

export default ConsultantSkillsList;
