import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';

export const PMO_CHANGE_CONTROL_PENDING = 'PMO_CHANGE_CONTROL_PENDING';
export const pmoChangeControlPending = makeActionCreator(PMO_CHANGE_CONTROL_PENDING);

export const PMO_CHANGE_CONTROL_REJECTED = 'PMO_CHANGE_CONTROL_REJECTED';
export const pmoChangeControlRejected = makeActionCreator(PMO_CHANGE_CONTROL_REJECTED);

export const PMO_CHANGE_CONTROL_SHOW_LIST = 'PMO_CHANGE_CONTROL_SHOW_LIST';
export const pmoChangeControlShowList = makeActionCreator(PMO_CHANGE_CONTROL_SHOW_LIST);

export const PMO_CHANGE_CONTROL_HIDE_LIST = 'PMO_CHANGE_CONTROL_HIDE_LIST';
export const pmoChangeControlHideList = makeActionCreator(PMO_CHANGE_CONTROL_HIDE_LIST);

export const PMO_CHANGE_CONTROL_SHOW_FORM = 'PMO_CHANGE_CONTROL_SHOW_FORM';
export const pmoChangeControlShowForm = makeActionCreator(PMO_CHANGE_CONTROL_SHOW_FORM, 'itemId');

export const PMO_CHANGE_CONTROL_HIDE_FORM = 'PMO_CHANGE_CONTROL_HIDE_FORM';
export const pmoChangeControlHideForm = makeActionCreator(PMO_CHANGE_CONTROL_HIDE_FORM);

export const PMO_CHANGE_CONTROL_SHOW_ITEM = 'PMO_CHANGE_CONTROL_SHOW_ITEM';
export const pmoChangeControlShowItem = makeActionCreator(PMO_CHANGE_CONTROL_SHOW_ITEM, 'itemId');


// Show Index

export const PMO_CHANGE_CONTROL_LIST_FULFILLED = 'PMO_CHANGE_CONTROL_LIST_FULFILLED';
export const pmoChangeControlListFulfilled = makeActionCreator(PMO_CHANGE_CONTROL_LIST_FULFILLED, 'data');

export const PMO_CHANGE_CONTROL_LIST_ERROR = 'PMO_CHANGE_CONTROL_LIST_ERROR';
export const pmoChangeControlListError = makeActionCreator(PMO_CHANGE_CONTROL_LIST_ERROR, 'message');

export function pmoChangeControlList(projectId, status = 9999) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoChangeControlPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.changesIndex.version}${endpoints.changesIndex.path.replace(':pmo_project_id', projectId)}?is_approved=${status}`, {
        method: endpoints.changesIndex.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoChangeControlListFulfilled(json.data));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoChangeControlRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Show Item

export const PMO_CHANGE_CONTROL_ITEM_FULFILLED = 'PMO_CHANGE_CONTROL_ITEM_FULFILLED';
export const pmoChangeControlItemFulfilled = makeActionCreator(PMO_CHANGE_CONTROL_ITEM_FULFILLED, 'data');

export const PMO_CHANGE_CONTROL_ITEM_ERROR = 'PMO_CHANGE_CONTROL_ITEM_ERROR';
export const pmoChangeControlItemError = makeActionCreator(PMO_CHANGE_CONTROL_ITEM_ERROR, 'message');

export function pmoChangeControlItem(projectId, itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoChangeControlPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getChange.version}${endpoints.getChange.path.replace(':pmo_project_id', projectId).replace(':change_id', itemId)}`, {
        method: endpoints.getChange.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoChangeControlItemFulfilled(json.data));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoChangeControlRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Add Item
export const PMO_CHANGE_CONTROL_ITEM_ADD_FULFILLED = 'PMO_CHANGE_CONTROL_ITEM_ADD_FULFILLED';
export const pmoChangeControlItemAddFulfilled = makeActionCreator(PMO_CHANGE_CONTROL_ITEM_ADD_FULFILLED, 'data');

export const PMO_CHANGE_CONTROL_ITEM_ADD_ERROR = 'PMO_CHANGE_CONTROL_ITEM_ADD_ERROR';
export const pmoChangeControlItemAddError = makeActionCreator(PMO_CHANGE_CONTROL_ITEM_ADD_ERROR, 'message');

export function pmoChangeControlAddItem(projectId, data, status) {
  const formData = new FormData();

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoChangeControlPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addChange.version}${endpoints.addChange.path.replace(':pmo_project_id', projectId)}`, {
        method: endpoints.addChange.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoChangeControlItemAddFulfilled(json.data));
            dispatch(pmoChangeControlList(projectId, status));
          } else {
            dispatch(pmoChangeControlItemAddError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoChangeControlRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Update Item

export const PMO_CHANGE_CONTROL_ITEM_UPDATE_FULFILLED = 'PMO_CHANGE_CONTROL_ITEM_UPDATE_FULFILLED';
export const pmoChangeControlItemUpdateFulfilled = makeActionCreator(PMO_CHANGE_CONTROL_ITEM_UPDATE_FULFILLED, 'data');

export const PMO_CHANGE_CONTROL_ITEM_UPDATE_ERROR = 'PMO_CHANGE_CONTROL_ITEM_UPDATE_ERROR';
export const pmoChangeControlItemUpdateError = makeActionCreator(PMO_CHANGE_CONTROL_ITEM_UPDATE_ERROR, 'message');

export function pmoChangeControlUpdateItem(projectId, itemId, data, status) {
  const formData = new FormData();
  formData.append('_method', 'PUT');

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoChangeControlPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateChange.version}${endpoints.updateChange.path.replace(':pmo_project_id', projectId).replace(':change_id', itemId)}`, {
        method: endpoints.updateChange.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoChangeControlItemUpdateFulfilled(json.data));
            dispatch(pmoChangeControlList(projectId, status));
          } else {
            dispatch(pmoChangeControlItemUpdateError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoChangeControlRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Delete Item

export const PMO_CHANGE_CONTROL_ITEM_DELETE_FULFILLED = 'PMO_CHANGE_CONTROL_ITEM_DELETE_FULFILLED';
export const pmoChangeControlItemDeleteFulfilled = makeActionCreator(PMO_CHANGE_CONTROL_ITEM_DELETE_FULFILLED, 'data');

export const PMO_CHANGE_CONTROL_ITEM_DELETE_ERROR = 'PMO_CHANGE_CONTROL_ITEM_DELETE_ERROR';
export const pmoChangeControlItemDeleteError = makeActionCreator(PMO_CHANGE_CONTROL_ITEM_DELETE_ERROR, 'message');

export function pmoChangeControlDeleteItem(projectId, itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoChangeControlPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteChange.version}${endpoints.deleteChange.path.replace(':pmo_project_id', projectId).replace(':change_id', itemId)}`, {
        method: endpoints.deleteChange.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoChangeControlItemDeleteFulfilled(json.data));
            dispatch(pmoChangeControlList(projectId));
          } else {
            dispatch(pmoChangeControlItemDeleteError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoChangeControlRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
