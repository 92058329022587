import makeActionCreator from '../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API       from '../utils/config-api'
import * as endpoints from './endpoints'
import { logout } from '../auth/list';

export const REALIZATION_PENDING = 'REALIZATION_PENDING'
export const realizationPending = makeActionCreator(REALIZATION_PENDING)

export const REALIZATION_REJECTED = 'REALIZATION_REJECTED'
export const realizationRejected = makeActionCreator(REALIZATION_REJECTED)

export const REALIZATION_SHOW_LIST= 'REALIZATION_SHOW_LIST'
export const realizationShowList = makeActionCreator(REALIZATION_SHOW_LIST)

export const REALIZATION_HIDE_LIST = 'REALIZATION_HIDE_LIST'
export const realizationHideList = makeActionCreator(REALIZATION_HIDE_LIST)

export const REALIZATION_SHOW_FORM= 'REALIZATION_SHOW_FORM'
export const realizationShowForm = makeActionCreator(REALIZATION_SHOW_FORM, 'itemId')

export const REALIZATION_HIDE_FORM = 'REALIZATION_HIDE_FORM'
export const realizationHideForm = makeActionCreator(REALIZATION_HIDE_FORM)

export const REALIZATION_SHOW_ITEM = 'REALIZATION_SHOW_ITEM'
export const realizationShowItem = makeActionCreator(REALIZATION_SHOW_ITEM, 'itemId')


//Show Index

export const REALIZATION_LIST_FULFILLED = 'REALIZATION_LIST_FULFILLED'
export const realizationListFulfilled = makeActionCreator(REALIZATION_LIST_FULFILLED, 'data')

export const REALIZATION_LIST_ERROR = 'REALIZATION_LIST_ERROR'
export const realizationListError = makeActionCreator(REALIZATION_LIST_ERROR, 'message')

export function realizationList(status, start_date, end_date, billable, billable_by) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(realizationPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.detailedIndex.version}${endpoints.detailedIndex.path}?status=${status}&start_date=${start_date}&end_date=${end_date}&billable=${billable}&billable_by=${billable_by}`, {
              method  : endpoints.detailedIndex.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(realizationListFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(realizationRejected(err))
          })
    })
    .catch(err => {
    dispatch(logout(() => browserHistory.push('/login')));

    })




  }
}
