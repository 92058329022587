import makeActionCreator from '../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API from '../utils/config-api'
import * as endpoints from './endpoints'
import { logout } from '../auth/list';

export const UTILIZATION_PENDING = 'UTILIZATION_PENDING'
export const utilizationPending = makeActionCreator(UTILIZATION_PENDING)

export const UTILIZATION_REJECTED = 'UTILIZATION_REJECTED'
export const utilizationRejected = makeActionCreator(UTILIZATION_REJECTED)

export const UTILIZATION_SHOW_LIST= 'UTILIZATION_SHOW_LIST'
export const utilizationShowList = makeActionCreator(UTILIZATION_SHOW_LIST)

export const UTILIZATION_HIDE_LIST = 'UTILIZATION_HIDE_LIST'
export const utilizationHideList = makeActionCreator(UTILIZATION_HIDE_LIST)

export const UTILIZATION_SHOW_FORM= 'UTILIZATION_SHOW_FORM'
export const utilizationShowForm = makeActionCreator(UTILIZATION_SHOW_FORM, 'itemId')

export const UTILIZATION_HIDE_FORM = 'UTILIZATION_HIDE_FORM'
export const utilizationHideForm = makeActionCreator(UTILIZATION_HIDE_FORM)

export const UTILIZATION_SHOW_ITEM = 'UTILIZATION_SHOW_ITEM'
export const utilizationShowItem = makeActionCreator(UTILIZATION_SHOW_ITEM, 'itemId')


//Show Index
export const UTILIZATION_LIST_FULFILLED = 'UTILIZATION_LIST_FULFILLED'
export const utilizationListFulfilled = makeActionCreator(UTILIZATION_LIST_FULFILLED, 'data')

export const UTILIZATION_LIST_ERROR = 'UTILIZATION_LIST_ERROR'
export const utilizationListError = makeActionCreator(UTILIZATION_LIST_ERROR, 'message')

export function utilizationList(status, start_date, end_date) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(utilizationPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}?status=${status}&start_date=${start_date}&end_date=${end_date}`, {
        method  : endpoints.index.method,
        headers : {
          'Authorization' : `Bearer ${currentSession.token}`,
          'Accept': 'application/json'
        }
      })
        .then( API.checkStatus )
        .then( API.parseJSON )
        .then( json => dispatch(utilizationListFulfilled(json.data)) )
        .catch( (err) => {
          // console.log(err);
            // Dispatch the error action with error information
            dispatch(utilizationRejected(err))
        })
    })
      .catch((err) => {
        dispatch(logout(() => browserHistory.push('/login')));
      })
  }
}
