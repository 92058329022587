import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';

export const CONSULTANT_NETWORKING_PENDING = 'CONSULTANT_NETWORKING_PENDING';
export const consultantNetworkingPending = makeActionCreator(CONSULTANT_NETWORKING_PENDING);

export const CONSULTANT_NETWORKING_REJECTED = 'CONSULTANT_NETWORKING_REJECTED';
export const consultantNetworkingRejected = makeActionCreator(CONSULTANT_NETWORKING_REJECTED, 'error');

// Show Index
export const CONSULTANT_NETWORKING_FULFILLED = 'CONSULTANT_NETWORKING_FULFILLED';
export const consultantNetworkingDataFulfilled = makeActionCreator(CONSULTANT_NETWORKING_FULFILLED, 'data');

export const CONSULTANT_NETWORKING_ERROR = 'CONSULTANT_NETWORKING_ERROR';
export const consultantNetworkingDataError = makeActionCreator(CONSULTANT_NETWORKING_ERROR, 'message');

export function getConsultantNetworkingData() {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(consultantNetworkingPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}`, {
        method: endpoints.index.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(consultantNetworkingDataFulfilled(json.data));
          } else {
            dispatch(consultantNetworkingDataError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(consultantNetworkingRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
