import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { Col, Row, Button } from 'react-bootstrap';
import SystemMsg from '../common/system-msg.jsx';
import SmartForm from '../form/base.jsx';

class MentorshipForm extends Component {
  constructor(props) {
    super(props);
    const { session, params } = this.props;
    const { user } = session;
    let permissions = null;

    // Load Permissions for this area
    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'consultant_skills') {
          permissions = area;
        }
      });
    }

    // Set State
    this.state = {
      permissions,
      userId: params && params.userId ? parseInt(params.userId, 10) : '',
      //userData: [],
      consultantSkillsData: [],
      loggedUser: user,
    };

    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    const { permissions, userId, loggedUser } = this.state;
    const { router, getConsultantSkillsGetShow } = this.props;

    // Validate Permissions
    if (
      !permissions
      || !permissions.can_update
      || (
        permissions.limit_to_own_user
        && loggedUser.id !== userId
      )
    ) {
      router.push('/404');
    }

    // Get User Information
    // userItem(userId);

    // Get Skills Information
    getConsultantSkillsGetShow(userId);
  }

  componentWillReceiveProps(nextProps) {
    // Set User
    if (nextProps.consultantSkills && nextProps.consultantSkills.itemData) {
      this.setState({ consultantSkillsData: nextProps.consultantSkills.itemData });
    }

    // After update navigate to index
    if (
      nextProps.consultantSkills.editSuccess &&
      nextProps.consultantSkills.editSuccess !== this.props.consultantSkills.editSuccess
    ) {
      browserHistory.push('/consultant-skills');
    }
  }

  submit(data) {
    const { consultantSkillsPutUpdate } = this.props;
    const { userId } = this.state;
    consultantSkillsPutUpdate(userId, data);
  }

  render() {
    const { consultantSkillsData } = this.state;
    const { consultantSkills } = this.props;

    const fieldOptions = Array.from(Array(6), (x, index) => ({ name: index, value: index }));
    function optionField(name, label) {
      return {
        type: 'select',
        name,
        label,
        value: consultantSkillsData && consultantSkillsData[name] ? consultantSkillsData[name] : 0,
        options: fieldOptions,
        disabled: consultantSkills.inProgress,
      };
    }

    const formSchema = [{
      type: 'update-consultant-skills-fieldset',
      fields: [
        optionField('frontend_d', 'Frontend Depth'),
        optionField('frontend_b', 'Frontend Breadth'),
        optionField('backend_d', 'Backend Depth'),
        optionField('backend_b', 'Backend Breadth'),
        optionField('management_d', 'Management Depth'),
        optionField('management_b', 'Management Breadth'),
        optionField('cloud_d', 'Cloud Depth'),
        optionField('cloud_b', 'Cloud Breadth'),
        optionField('ui_ux_d', 'UI/UX Depth'),
        optionField('ui_ux_b', 'UI/UX Breadth'),
        optionField('analytics_d', 'Analytics Depth'),
        optionField('analytics_b', 'Analytics Breadth'),
      ],
    }];

    return (
      <section className="consultant-skills-content">
        { consultantSkills.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            <Row>
              <Col xs={12}>
                <Button bsSize="xsmall" onClick={() => { browserHistory.push('/consultant-skills/'); }}>
                  <span className="fa fa-arrow-circle-o-left" />
                  &nbsp;Back
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={4} className="title-section">
                <div className="title-box">
                  <h1 className="box-title">Consultant Skills</h1><br/>
                  <h2>{ consultantSkillsData.name }</h2>
                </div>
              </Col>
            </Row>
          </div>
          <div className="box-content">
            <section className="section-content">
              <Row>
                <Col xs={12} md={12} lg={12} className="consultant-skills-content-form">
                  <SmartForm
                    schema={formSchema}
                    style = {{ width: '200px' }}
                    onSave={this.submit}
                    showError={
                      consultantSkills.error
                      && consultantSkills.errorText
                    }
                    errorMessage={consultantSkills.errorText}
                    loading={consultantSkills.inProgress}
                  />
                </Col>
              </Row>
            </section>
          </div>
        </div>
      </section>
    );
  }
}

export default MentorshipForm;
