import React, { Component } from 'react';
import moment from 'moment';
import cookie from 'react-cookies';
import Announcement from './Announcement';
import Aux from '../../hoc/Aux';

class AnnouncementsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sort_name: '',
            sort_order: '',
        };

        this.dateFormatter = this.dateFormatter.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
    }



    componentWillMount() {
        this.props.settingsAnnouncementsList();  //grab announcements
        this.setState(
            {
                sortName: cookie.load('sort_column_settings_announcements') || 'content',
                sortOrder: cookie.load('sort_order_settings_announcements') || 'desc'
            }
        )
    }

    onSortChange(sortName, sortOrder) {
        cookie.save('sort_column_announcements', sortName, { path: '/' });
        cookie.save('sort_order_announcements', sortOrder, { path: '/' });

        this.setState({
            sort_name: sortName,
            sort_order: sortOrder,
        });
    }

    dateFormatter(cell, row) {
        return moment(cell).format('MMM D YYYY');
    }

    sortObjectFunc(a, b, order, sortField, extraData) {   // order is desc or asc
        const fieldName = extraData["objectField"]

        if (order === 'asc') {
            return a[sortField][fieldName].localeCompare(b[sortField][fieldName])
        } else {
            return b[sortField][fieldName].localeCompare(a[sortField][fieldName]);
        }

    }

    onPageChange(page) {
        this.props.getPageList(page);
        this.setState({ currentPage: page });
    }


    remote() {
        const remoteObj = {};
        // Only cell editing, insert and delete row will be handled by remote store
        remoteObj.pagination = true;
        return remoteObj;
    }


    render() {
        const list = this.props.data;

        // sort announcements by publication date
        list.sort(function(a, b){
            return new Date(b.publication_date) - new Date(a.publication_date);
        });

        let announcements = Object.keys(list)
            .map(key => {

                    return [...Array(list[key])].map((_, i) => {

                        const a = list[key].publication_date;
                        const b = moment(a).format('MMM D, YYYY');

                        return(

                            <Aux key={key + i}>
                               <Announcement
                                    isPublic={list[key].is_public}
                                    content={list[key].content}
                                    highlight={list[key].is_highlighted}
                                    date={b}/>
                            </Aux>
                        );

                })
                .reduce((arr, el) => {
                    return arr.concat(el)
                }, []);


            })

        if (announcements.length === 0){
            announcements = <p>No announcements today!</p>
        }



        return <div className="Announcement-Container">{announcements}</div>;

    }
}

export default AnnouncementsList;
