export const UniversityResourcesList = {
  path: '/university_resources/',
  method: 'GET',
  version: '/v1',
};

export const UniversityResourcesSave = {
  path: '/university_resources/',
  method: 'POST',
  version: '/v1',
};

export const UniversityResourcesEdit = {
  path: '/university_resources/:university_resource_id',
  method: 'POST',
  version: '/v1',
};

export const UniversityResourcesShow = {
  path: '/university_resources/',
  method: 'GET',
  version: '/v1',
};

export const UniversityResourcesDelete = {
  path: '/university_resources/:university_resource_id',
  method: 'DELETE',
  version: '/v1',
};

export const InsightsResourcesList = {
  path: '/insight_resources/',
  method: 'GET',
  version: '/v1',
};

export const InsightsResourcesSave = {
  path: '/insight_resources/',
  method: 'POST',
  version: '/v1',
};

export const InsightsResourcesEdit = {
  path: '/insight_resources/:insight_resource_id',
  method: 'POST',
  version: '/v1',
};

export const InsightsResourcesShow = {
  path: '/insight_resources/',
  method: 'GET',
  version: '/v1',
};

export const InsightsResourcesDelete = {
  path: '/insight_resources/:insight_resource_id',
  method: 'DELETE',
  version: '/v1',
};

export const MethodologiesResourcesList = {
  path: '/methodology_resources/',
  method: 'GET',
  version: '/v1',
};

export const MethodologiesResourcesSave = {
  path: '/methodology_resources/',
  method: 'POST',
  version: '/v1',
};

export const MethodologiesResourcesEdit = {
  path: '/methodology_resources/:methodologies_resource_id',
  method: 'POST',
  version: '/v1',
};

export const MethodologiesResourcesShow = {
  path: '/methodology_resources/',
  method: 'GET',
  version: '/v1',
};

export const MethodologiesResourcesDelete = {
  path: '/methodology_resources/:methodologies_resource_id',
  method: 'DELETE',
  version: '/v1',
};
