const initialState = {
    inProgress: false,
    error: false,
    errorText: null,
    showList: true,
  };
  
  function RealizationLast3MonthsReducer(state = initialState, action) {
    const actionName = 'REALIZATION_LAST_3_MONTHS';
    switch (action.type) {
      case `${actionName}_PENDING`:
        return {
          ...state,
          inProgress: true,
          error: false,
          errorText: null,
        };
      case `${actionName}_REJECTED`:
        return {
          ...state,
          inProgress: false,
          error: action.error,
        };
      case `${actionName}_FULFILLED`:
        return {
          ...state,
          inProgress: false,
          error: false,
          errorText: null,
          data: action.data,
        };
      default:
        return state;
    }
  }
  
  export default RealizationLast3MonthsReducer;
  