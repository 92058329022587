import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { Col, Row, Button } from 'react-bootstrap';
import SystemMsg from '../common/system-msg.jsx';
import SmartForm from '../form/base.jsx';

class MentorshipForm extends Component {
  constructor(props) {
    super(props);
    const { session, params } = this.props;
    const { user } = session;
    let permissions = null;

    // Load Permissions for this area
    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'mentorship') {
          permissions = area;
        }
      });
    }

    // Set State
    this.state = {
      permissions,
      userId: params && params.userId ? parseInt(params.userId, 10) : '',
      userData: [],
      mentorsCatalog: [],
    };

    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    const { permissions, userId } = this.state;

    // Validate Permissions
    if (!permissions || !permissions.can_update) {
      this.props.router.push('/404');
    }

    // Get User Information
    this.props.userItem(userId);

    // Get Mentors Catalog
    this.props.getMentorsCatalog();
  }

  componentWillReceiveProps(nextProps) {
    // Set User
    if (nextProps.user && nextProps.user.itemData) {
      this.setState({ userData: nextProps.user.itemData });
    }

    // Set Mentors Catalog
    if (nextProps.mentorship.mentorsCatalog && nextProps.mentorship.mentorsCatalog) {
      this.setState({ mentorsCatalog: nextProps.mentorship.mentorsCatalog });
    }

    // After update navigate to index
    if (
      nextProps.user.editSuccess &&
      nextProps.user.editSuccess !== this.props.user.editSuccess
    ) {
      browserHistory.push('/mentorship');
    }
  }

  submit(data) {
    const { editUserMentor } = this.props;
    const { userId } = this.state;
    editUserMentor(userId, data.mentor_user_id);
  }

  render() {
    const { userData, mentorsCatalog } = this.state;
    const { user } = this.props;

    // Mentors
    let mentorsList = [];

    if (mentorsCatalog){
      for (let i=0; i<mentorsCatalog.length; i++){
        mentorsList.push({
          name: mentorsCatalog[i].name,
          value: mentorsCatalog[i].id
        })
      }
    }

    const schema = [{
      type: 'default',
      fields: [
        {
          type: "select",
          name: "mentor_user_id",
          label: "OIC:",
          value: userData && userData.mentor_user_id ? userData.mentor_user_id : '' ,
          options: mentorsList,
          disabled: this.props.mentorship.inProgress
        },
      ],
    }];

    return (
      <section className={"mentorship-content"}>
        { user.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            <Row>
              <Col xs={12}>
                <Button bsSize="xsmall" onClick={() => { browserHistory.push('/mentorship/'); }}>
                  <span className="fa fa-arrow-circle-o-left" />
                  &nbsp;Back
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={4} className="title-section">
                <div className="title-box">
                  <h1 className="box-title">Mentorship</h1>
                  <h2>{ userData.name }</h2>
                </div>
              </Col>
            </Row>
          </div>
          <div className="box-content">
            <section className="section-content">
              <Row>
                <Col xs={12} md={8} lg={6}>
                  <SmartForm
                    schema={schema}
                    onSave={this.submit}
                    showError={
                      user.error
                      && user.errorText
                    }
                    errorMessage={user.errorText}
                    loading={user.inProgress}
                  />
                </Col>
              </Row>
            </section>
          </div>
        </div>
      </section>
    );
  }
}

export default MentorshipForm;
