import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';

export const PMO_ISSUE_MANAGEMENT_PENDING = 'PMO_ISSUE_MANAGEMENT_PENDING';
export const pmoIssueManagementPending = makeActionCreator(PMO_ISSUE_MANAGEMENT_PENDING);

export const PMO_ISSUE_MANAGEMENT_REJECTED = 'PMO_ISSUE_MANAGEMENT_REJECTED';
export const pmoIssueManagementRejected = makeActionCreator(PMO_ISSUE_MANAGEMENT_REJECTED);

export const PMO_ISSUE_MANAGEMENT_SHOW_LIST = 'PMO_ISSUE_MANAGEMENT_SHOW_LIST';
export const pmoIssueManagementShowList = makeActionCreator(PMO_ISSUE_MANAGEMENT_SHOW_LIST);

export const PMO_ISSUE_MANAGEMENT_HIDE_LIST = 'PMO_ISSUE_MANAGEMENT_HIDE_LIST';
export const pmoIssueManagementHideList = makeActionCreator(PMO_ISSUE_MANAGEMENT_HIDE_LIST);

export const PMO_ISSUE_MANAGEMENT_SHOW_FORM = 'PMO_ISSUE_MANAGEMENT_SHOW_FORM';
export const pmoIssueManagementShowForm = makeActionCreator(PMO_ISSUE_MANAGEMENT_SHOW_FORM, 'itemId');

export const PMO_ISSUE_MANAGEMENT_HIDE_FORM = 'PMO_ISSUE_MANAGEMENT_HIDE_FORM';
export const pmoIssueManagementHideForm = makeActionCreator(PMO_ISSUE_MANAGEMENT_HIDE_FORM);

export const PMO_ISSUE_MANAGEMENT_SHOW_ITEM = 'PMO_ISSUE_MANAGEMENT_SHOW_ITEM';
export const pmoIssueManagementShowItem = makeActionCreator(PMO_ISSUE_MANAGEMENT_SHOW_ITEM, 'itemId');


// Show Index

export const PMO_ISSUE_MANAGEMENT_LIST_FULFILLED = 'PMO_ISSUE_MANAGEMENT_LIST_FULFILLED';
export const pmoIssueManagementListFulfilled = makeActionCreator(PMO_ISSUE_MANAGEMENT_LIST_FULFILLED, 'data');

export const PMO_ISSUE_MANAGEMENT_LIST_ERROR = 'PMO_ISSUE_MANAGEMENT_LIST_ERROR';
export const pmoIssueManagementListError = makeActionCreator(PMO_ISSUE_MANAGEMENT_LIST_ERROR, 'message');

export function pmoIssueManagementList(projectId, status = 9999) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoIssueManagementPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.issuesIndex.version}${endpoints.issuesIndex.path.replace(':pmo_project_id', projectId)}?is_closed=${status}`, {
        method: endpoints.issuesIndex.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoIssueManagementListFulfilled(json.data));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoIssueManagementRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Show Item

export const PMO_ISSUE_MANAGEMENT_ITEM_FULFILLED = 'PMO_ISSUE_MANAGEMENT_ITEM_FULFILLED';
export const pmoIssueManagementItemFulfilled = makeActionCreator(PMO_ISSUE_MANAGEMENT_ITEM_FULFILLED, 'data');

export const PMO_ISSUE_MANAGEMENT_ITEM_ERROR = 'PMO_ISSUE_MANAGEMENT_ITEM_ERROR';
export const pmoIssueManagementItemError = makeActionCreator(PMO_ISSUE_MANAGEMENT_ITEM_ERROR, 'message');

export function pmoIssueManagementItem(projectId, itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoIssueManagementPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getIssue.version}${endpoints.getIssue.path.replace(':pmo_project_id', projectId).replace(':issue_id', itemId)}`, {
        method: endpoints.getIssue.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoIssueManagementItemFulfilled(json.data));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoIssueManagementRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Add Item
export const PMO_ISSUE_MANAGEMENT_ITEM_ADD_FULFILLED = 'PMO_ISSUE_MANAGEMENT_ITEM_ADD_FULFILLED';
export const pmoIssueManagementItemAddFulfilled = makeActionCreator(PMO_ISSUE_MANAGEMENT_ITEM_ADD_FULFILLED, 'data');

export const PMO_ISSUE_MANAGEMENT_ITEM_ADD_ERROR = 'PMO_ISSUE_MANAGEMENT_ITEM_ADD_ERROR';
export const pmoIssueManagementItemAddError = makeActionCreator(PMO_ISSUE_MANAGEMENT_ITEM_ADD_ERROR, 'message');

export function pmoIssueManagementAddItem(projectId, data, status) {
  const formData = new FormData();

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoIssueManagementPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addIssue.version}${endpoints.addIssue.path.replace(':pmo_project_id', projectId)}`, {
        method: endpoints.addIssue.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoIssueManagementItemAddFulfilled(json.data));
            dispatch(pmoIssueManagementList(projectId, status));
          } else {
            dispatch(pmoIssueManagementItemAddError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoIssueManagementRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Update Item

export const PMO_ISSUE_MANAGEMENT_ITEM_UPDATE_FULFILLED = 'PMO_ISSUE_MANAGEMENT_ITEM_UPDATE_FULFILLED';
export const pmoIssueManagementItemUpdateFulfilled = makeActionCreator(PMO_ISSUE_MANAGEMENT_ITEM_UPDATE_FULFILLED, 'data');

export const PMO_ISSUE_MANAGEMENT_ITEM_UPDATE_ERROR = 'PMO_ISSUE_MANAGEMENT_ITEM_UPDATE_ERROR';
export const pmoIssueManagementItemUpdateError = makeActionCreator(PMO_ISSUE_MANAGEMENT_ITEM_UPDATE_ERROR, 'message');

export function pmoIssueManagementUpdateItem(projectId, itemId, data, status) {
  const formData = new FormData();
  formData.append('_method', 'PUT');

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoIssueManagementPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateIssue.version}${endpoints.updateIssue.path.replace(':pmo_project_id', projectId).replace(':issue_id', itemId)}`, {
        method: endpoints.updateIssue.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoIssueManagementItemUpdateFulfilled(json.data));
            dispatch(pmoIssueManagementList(projectId, status));
          } else {
            dispatch(pmoIssueManagementItemUpdateError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoIssueManagementRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Delete Item

export const PMO_ISSUE_MANAGEMENT_ITEM_DELETE_FULFILLED = 'PMO_ISSUE_MANAGEMENT_ITEM_DELETE_FULFILLED';
export const pmoIssueManagementItemDeleteFulfilled = makeActionCreator(PMO_ISSUE_MANAGEMENT_ITEM_DELETE_FULFILLED, 'data');

export const PMO_ISSUE_MANAGEMENT_ITEM_DELETE_ERROR = 'PMO_ISSUE_MANAGEMENT_ITEM_DELETE_ERROR';
export const pmoIssueManagementItemDeleteError = makeActionCreator(PMO_ISSUE_MANAGEMENT_ITEM_DELETE_ERROR, 'message');

export function pmoIssueManagementDeleteItem(projectId, itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoIssueManagementPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteIssue.version}${endpoints.deleteIssue.path.replace(':pmo_project_id', projectId).replace(':issue_id', itemId)}`, {
        method: endpoints.deleteIssue.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoIssueManagementItemDeleteFulfilled(json.data));
            dispatch(pmoIssueManagementList(projectId));
          } else {
            dispatch(pmoIssueManagementItemDeleteError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoIssueManagementRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
