import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './_endpoints';
import { logout } from '../auth/list';

export const CONSULTANT_SKILLS_PENDING = 'CONSULTANT_SKILLS_PENDING';
export const consultantSkillsPending = makeActionCreator(CONSULTANT_SKILLS_PENDING);

export const CONSULTANT_SKILLS_REJECTED = 'CONSULTANT_SKILLS_REJECTED';
export const consultantSkillsRejected = makeActionCreator(CONSULTANT_SKILLS_REJECTED, 'error');

// GET Index
export const CONSULTANT_SKILLS_INDEX_FULFILLED = 'CONSULTANT_SKILLS_INDEX_FULFILLED';
export const consultantSkillsGetIndexDataFulfilled = makeActionCreator(CONSULTANT_SKILLS_INDEX_FULFILLED, 'data');

export const CONSULTANT_SKILLS_INDEX_ERROR = 'CONSULTANT_SKILLS_INDEX_ERROR';
export const consultantSkillsGetIndexDataError = makeActionCreator(CONSULTANT_SKILLS_INDEX_ERROR, 'message');

export function getConsultantSkillsGetIndex() {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(consultantSkillsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}`, {
        method: endpoints.index.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(consultantSkillsGetIndexDataFulfilled(json.data));
          } else {
            dispatch(consultantSkillsGetIndexDataError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(consultantSkillsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// GET Show - Get Item
export const CONSULTANT_SKILLS_GET_SHOW_FULFILLED = 'CONSULTANT_SKILLS_GET_SHOW_FULFILLED';
export const consultantSkillsGetShowFulfilled = makeActionCreator(CONSULTANT_SKILLS_GET_SHOW_FULFILLED, 'data');

export const CONSULTANT_SKILLS_GET_SHOW_ERROR = 'CONSULTANT_SKILLS_GET_SHOW_ERROR';
export const consultantSkillsGetShowError = makeActionCreator(CONSULTANT_SKILLS_GET_SHOW_ERROR, 'message');

export function getConsultantSkillsGetShow(userId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(consultantSkillsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.show.version}${endpoints.show.path.replace(':user_id', userId)}`, {
        method: endpoints.index.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(consultantSkillsGetShowFulfilled(json.data));
          } else {
            dispatch(consultantSkillsGetShowError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(consultantSkillsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// PUT update - Update Item
export const CONSULTANT_SKILLS_PUT_UPDATE_FULFILLED = 'CONSULTANT_SKILLS_PUT_UPDATE_FULFILLED';
export const consultantSkillsPutUpdateFulfilled = makeActionCreator(CONSULTANT_SKILLS_PUT_UPDATE_FULFILLED, 'data');

export const CONSULTANT_SKILLS_PUT_UPDATE_ERROR = 'CONSULTANT_SKILLS_PUT_UPDATE_ERROR';
export const consultantSkillsPutUpdateError = makeActionCreator(CONSULTANT_SKILLS_PUT_UPDATE_ERROR, 'message');

export function consultantSkillsPutUpdate(userId, data) {
  const formData = new FormData();
  formData.append('_method', 'PUT');

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(consultantSkillsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.update.version}${endpoints.update.path.replace(':user_id', userId)}`, {
        method: endpoints.update.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(consultantSkillsPutUpdateFulfilled(json.data));
          } else {
            dispatch(consultantSkillsPutUpdateError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(consultantSkillsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}