import React, {Component} from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'

class Details extends Component {

  render() {

    if (this.props.data && this.props.data.months) {
      return (
        <BootstrapTable data={ this.props.data.months }>
          <TableHeaderColumn dataField='name' isKey={ true }>Month</TableHeaderColumn>
          <TableHeaderColumn dataField='billable'>Billable</TableHeaderColumn>
          <TableHeaderColumn dataField='available'>Available</TableHeaderColumn>
          <TableHeaderColumn dataField='used' dataFormat={this._percentajeFormatter}>Utilization</TableHeaderColumn>
        </BootstrapTable>);
    } else {
      return (<p>No Data</p>);
    }
  }

  _percentajeFormatter(cell, row){
    return cell + "%"
  }
}

export default Details
