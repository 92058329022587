import React, {Component} from 'react';
import SettingsAnnouncementsList from './list.jsx'
import SettingsAnnouncementsForm from './form.jsx'
import SystemMsg from '../../common/system-msg.jsx'
import { Col, Row, Button} from 'react-bootstrap';
import "assets/css/announcements.css";
import Aux from '../../../hoc/Aux';

class SettingsAnnouncements extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'settings') {
          permissions = area;
        }
      });
    }
    this.state = {
      permissions,
    };
  }

  componentWillUnmount() {
    this.props.settingsAnnouncementsHideForm()
    this.props.settingsAnnouncementsShowList()
  }

  render(){
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <Aux>
        <section className="project-likelihood-content">
          { this.props.settingsAnnouncements.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
          <div className="box">
            <div className="box-header with-border">
              {this._getTitle()}
            </div>
            <div className="box-content">
              {this.props.settingsAnnouncements.showList?<SettingsAnnouncementsList {...this.props} />:""}
              {this.props.settingsAnnouncements.showForm?<SettingsAnnouncementsForm {...this.props} />:""}
            </div>
          </div>

        </section>
       
        </Aux>
    )
  }

  _getTitle(){
    if(this.props.settingsAnnouncements.showList){
      return (
        <Row>
          <Col xs={12} className="title-section">
            <h1 className="box-title">Announcements</h1>
           
            <Button bsSize="small" bsStyle="success" onClick={this.props.settingsAnnouncementsShowForm.bind(this, "newItem")}>Add New</Button>
          </Col>
        </Row>



      )
    }else if(this.props.settingsAnnouncements.showForm){
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.settingsAnnouncementsShowList.bind(this)}><span className="fa fa-arrow-circle-o-left"></span> Back</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">{this.props.settingsAnnouncements.itemId?"Edit Announcement":"Add New Announcement"}</h1>
            </Col>
          </Row>
        </div>


      )
    }
  }
}

export default SettingsAnnouncements
