import React, {Component} from 'react';
import "assets/css/table.css";
// import { Button, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'

class RecruitingList extends Component {
  constructor(props) {
  	super(props);
  	this.state = {
      itemId: null,
    };
  }

  componentWillMount() {

  }

  _boolFormatter(cell, row){
    return cell ? "Yes" : "No"
  }

  _statusFormatter(cell, row){
    switch(cell) {
      case 1: return "Active";
      case 2: return "Hired";
      default: return "Rejected";
    }
  }

  _objectFormatter(field, cell, row){
    if(cell && cell[field]){
      return cell[field]
    }

    return ''
  }

  _sortObjectFunc(a, b, order, sortField, extraData) {   // order is desc or asc
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return a[sortField][fieldName].localeCompare(b[sortField][fieldName])
    } else {
      return b[sortField][fieldName].localeCompare(a[sortField][fieldName]);
    }

  }

  _filterRecruitName(field, cell, row) {
     return cell[field];
  }

  render() {
    const list = this.props.recruits.data

    const options = {
      defaultSortName: 'current_stage_name',
      defaultSortOrder: 'desc',
    }

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={list}
          striped
          hover
          options={options}
          headerContainerClass="fixed-header"
        >
          <TableHeaderColumn
            dataField="candidate_name"
            filter={{ type: 'TextFilter', delay: 500 }}
            dataSort
            isKey
            filterFormatted
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="source_public_name"
            columnTitle
            dataSort
            filter={{ type: 'TextFilter', delay: 500 }}
          >
            Source
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="job_name"
            columnTitle
            dataSort
            filter={{ type: 'TextFilter', delay: 500 }}
            className='col_user_role'
            columnClassName='col_user_role'
          >
            Level
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="current_stage_name"
            columnTitle
            dataSort
            filter={{ type: 'TextFilter', delay: 500 }}
          >
            Stage in Process
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="status"
            columnTitle
            dataSort
            dataFormat={this._statusFormatter}
            dataAlign='center'
            headerAlign='left'
            className='col_is_active'
            columnClassName='col_is_active'
          >
            Status
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="candidate_company"
            columnTitle
          >
            Last Employer
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="candidate_school_name"
            columnTitle
          >
            School
          </TableHeaderColumn>
        </BootstrapTable>
      </section>
    )
  }
}

export default RecruitingList;
