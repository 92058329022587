import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
} from 'react-bootstrap';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import Download from '@axetroy/react-download';
import moment from 'moment';

import SystemMsg from '../common/system-msg.jsx';
import 'assets/css/pmo-project.css';
import 'chartist/dist/chartist.min.css';
import 'chartist/dist/chartist.min.js';

class ProjectDashboard extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'pmo') {
          permissions = area;
        }
      });
    }
    this.state = {
      permissions,
      projectData: {},
      projectId: this.props.params && this.props.params.projectId ? this.props.params.projectId : '',
      showExportModal: false,
      contentDownload: '',
    };

    this.openExportModal = this.openExportModal.bind(this);
    this.closeExportModal = this.closeExportModal.bind(this);
  }

  componentDidMount() {
    if (this.state.projectId) {
      this.props.pmoProjectsItem(this.state.projectId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.pmoProjects.itemData && !_.isEqual(this.props.pmoProjects.itemData, nextProps.pmoProjects.itemData)) ||
      (nextProps.pmoProjects.itemData && !_.isEqual(this.state.projectData, nextProps.pmoProjects.itemData))
    ) {
      this.setState({
        projectData: nextProps.pmoProjects.itemData,
      });
    }

    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'pmo') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }

    if (!_.isEqual(this.props.pmoProjects.exportData, nextProps.pmoProjects.exportData)) {
      this.setState({ contentDownload: nextProps.pmoProjects.exportData });
    }
  }


  openExportModal() {
    this.props.pmoProjectsExportPPTX(this.state.projectId);
    this.setState({ showExportModal: true });
  }

  closeExportModal() {
    this.setState({ showExportModal: false });
  }

  renderModalExport() {
    if (!this.state.projectId) {
      return null;
    }

    let content = '';
    if (this.state.contentDownload && !this.props.pmoProjects.inProgress ) {
      content = (
        <div>
          <p>The file is ready to be downloaded.</p>
          <Download file={`${this.state.projectData.project.toLowerCase().replace(' ', '-')}-${moment().format('x')}.pptx`} content={this.state.contentDownload}>
            <button type="button" className="btn btn-success download-button">Download file</button>
          </Download>
        </div>
      )
    } else if (this.props.pmoProjects.errorExport  && !this.props.pmoProjects.inProgress) {
      content = <p className="msg error">{this.props.pmoProjects.errorText || 'Unknow Error. Please try again'}</p>;
    } else {
      content = <p>Wait while we prepare the download...</p>;
    }

    return (
      <Modal
        show={this.state.showExportModal}
        onHide={this.closeExportModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Export Project
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {content}
        </Modal.Body>
      </Modal>
    );
  }


  render() {
    const {
      projectData,
      permissions,
      projectId,
    } = this.state;

    if (
      !projectId
      || !permissions
      || (
        !permissions.can_read
        && !permissions.can_create
        && !permissions.can_delete
        && !permissions.can_update
        && !permissions.limit_to_own_user
      )
    ) {
      if (permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    if (_.isEmpty(projectData)) {
      return (
        <section className="pmoProjects-container">
          {this.props.pmoProjects.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        </section>
      );
    }

    return (
      <section className="pmoProjects-container" ref={(c) => { this.sectionContainer = c; }}>
        {this.props.pmoProjects.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="content">
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={() => { browserHistory.push('/pmo'); }}>
                <span className="fa fa-arrow-circle-o-left" />
                &nbsp;Back
              </Button>
            </Col>

            <Col xs={8}>
              <div className="title-box">
                <h1 className="box-title">
                  PMO Overview
                </h1>
                <h2>
                  {this.state.projectData.project}
                </h2>
              </div>
            </Col>
            <Col xs={4}>
              <div className="title-box download-button">
                <button className="btn download-ppt" type="button" onClick={this.openExportModal}>
                  Download Project
                  &nbsp;
                  <i className="fa fa-download" aria-hidden="true" />
                </button>
              </div>
            </Col>
          </Row>
          <Row className="row-eq-height">
            {
              projectData.status_summary && projectData.status_summary.issues ? (
                <Col md={6} xs={12}>
                  <div className="small-box">
                    <div className="inner">
                      <h3>
                        Issue Management
                      </h3>
                      <Row>
                        <Col xs={4}>

                          <div className="secondary-title">
                            Open
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.issues.open}
                          </div>
                        </Col>
                        <Col xs={4}>

                          <div className="secondary-title">
                            Closed
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.issues.closed}
                          </div>
                        </Col>
                        <Col xs={4}>

                          <div className="secondary-title">
                            Total
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.issues.total}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <button
                      onClick={() => { browserHistory.push(`/pmo/project/${projectId}/issue-management`); }}
                      className="small-box-footer"
                      type="button"
                    >
                      More info&nbsp;
                      <i className="fa fa-arrow-circle-right" />
                    </button>
                  </div>
                </Col>
              ) : null
            }

            {
              projectData.status_summary && projectData.status_summary.changes ? (
                <Col md={6} xs={12}>
                  <div className="small-box">
                    <div className="inner">
                      <h3>
                        Change Control
                      </h3>
                      <Row>
                        <Col xs={4}>

                          <div className="secondary-title">
                            Approved
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.changes.approved}
                          </div>
                        </Col>
                        <Col xs={4}>

                          <div className="secondary-title">
                            Not Approved
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.changes.not_approved}
                          </div>
                        </Col>
                        <Col xs={4}>

                          <div className="secondary-title">
                            Total
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.changes.total}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <button
                      onClick={() => { browserHistory.push(`/pmo/project/${projectId}/change-control`); }}
                      className="small-box-footer"
                      type="button"
                    >
                      More info&nbsp;
                      <i className="fa fa-arrow-circle-right" />
                    </button>
                  </div>
                </Col>
              ) : null
            }

            {
              projectData.status_summary && projectData.status_summary.risks ? (
                <Col md={6} xs={12}>
                  <div className="small-box">
                    <div className="inner">
                      <h3>
                        Risk Management
                      </h3>

                      <Row>
                        <Col xs={4}>

                          <div className="secondary-title">
                            Active
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.risks.active}
                          </div>
                        </Col>
                        <Col xs={4}>

                          <div className="secondary-title">
                            Inactive
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.risks.inactive}
                          </div>
                        </Col>
                        <Col xs={4}>

                          <div className="secondary-title">
                            Total
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.risks.total}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <button
                      onClick={() => { browserHistory.push(`/pmo/project/${projectId}/risk-management`); }}
                      className="small-box-footer"
                      type="button"
                    >
                      More info&nbsp;
                      <i className="fa fa-arrow-circle-right" />
                    </button>
                  </div>
                </Col>
              ) : null
            }
            {
              projectData.status_summary && projectData.status_summary.milestones ? (
                <Col md={6} xs={12}>
                  <div className="small-box">
                    <div className="inner">
                      <h3>
                        Milestones
                      </h3>
                      <Row>
                        <Col xs={4}>
                          <div className="secondary-title">
                            Open
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.milestones.incomplete}
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="secondary-title">
                            Completed
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.milestones.completed}
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="secondary-title">
                            Total
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.milestones.total}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <button
                      onClick={() => { browserHistory.push(`/pmo/project/${projectId}/milestone`); }}
                      className="small-box-footer"
                      type="button"
                    >
                      More info&nbsp;
                      <i className="fa fa-arrow-circle-right" />
                    </button>
                  </div>
                </Col>
              ) : null
            }
            {
              projectData.status_summary && projectData.status_summary.action_items ? (
                <Col md={6} xs={12}>
                  <div className="small-box">
                    <div className="inner">
                      <h3>
                        Action Items
                      </h3>
                      <Row>
                        <Col xs={4}>
                          <div className="secondary-title">
                            Open
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.action_items.incomplete}
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="secondary-title">
                            Completed
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.action_items.completed}
                          </div>
                        </Col>
                        <Col xs={4}>
                          <div className="secondary-title">
                            Total
                          </div>
                          <div className="big-number">
                            {projectData.status_summary.action_items.total}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <button
                      onClick={() => { browserHistory.push(`/pmo/project/${projectId}/action-item`); }}
                      className="small-box-footer"
                      type="button"
                    >
                      More info&nbsp;
                      <i className="fa fa-arrow-circle-right" />
                    </button>
                  </div>
                </Col>
              ) : null
          }
          </Row>
        </div>
        {this.renderModalExport()}
      </section>
    );
  }
}

export default ProjectDashboard;
