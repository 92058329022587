import makeActionCreator from '../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'

import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API       from '../utils/config-api'
import * as endpoints from './endpoints'
import { logout } from '../auth/list';


export const BUSINESS_DEVELOPMENT_PENDING = 'BUSINESS_DEVELOPMENT_PENDING'
export const businessDevelopmentPending = makeActionCreator(BUSINESS_DEVELOPMENT_PENDING)

export const BUSINESS_DEVELOPMENT_REJECTED = 'BUSINESS_DEVELOPMENT_REJECTED'
export const businessDevelopmentRejected = makeActionCreator(BUSINESS_DEVELOPMENT_REJECTED)

export const BUSINESS_DEVELOPMENT_SHOW_LIST= 'BUSINESS_DEVELOPMENT_SHOW_LIST'
export const businessDevelopmentShowList = makeActionCreator(BUSINESS_DEVELOPMENT_SHOW_LIST)

export const BUSINESS_DEVELOPMENT_HIDE_LIST = 'BUSINESS_DEVELOPMENT_HIDE_LIST'
export const businessDevelopmentHideList = makeActionCreator(BUSINESS_DEVELOPMENT_HIDE_LIST)

export const BUSINESS_DEVELOPMENT_SHOW_FORM= 'BUSINESS_DEVELOPMENT_SHOW_FORM'
export const businessDevelopmentShowForm = makeActionCreator(BUSINESS_DEVELOPMENT_SHOW_FORM, 'itemId')

export const BUSINESS_DEVELOPMENT_HIDE_FORM = 'BUSINESS_DEVELOPMENT_HIDE_FORM'
export const businessDevelopmentHideForm = makeActionCreator(BUSINESS_DEVELOPMENT_HIDE_FORM)

export const BUSINESS_DEVELOPMENT_SHOW_ITEM = 'BUSINESS_DEVELOPMENT_SHOW_ITEM'
export const businessDevelopmentShowItem = makeActionCreator(BUSINESS_DEVELOPMENT_SHOW_ITEM, 'itemId')


//Show Index

export const BUSINESS_DEVELOPMENT_LIST_FULFILLED = 'BUSINESS_DEVELOPMENT_LIST_FULFILLED'
export const businessDevelopmentListFulfilled = makeActionCreator(BUSINESS_DEVELOPMENT_LIST_FULFILLED, 'data')

export const BUSINESS_DEVELOPMENT_LIST_ERROR = 'BUSINESS_DEVELOPMENT_LIST_ERROR'
export const businessDevelopmentListError = makeActionCreator(BUSINESS_DEVELOPMENT_LIST_ERROR, 'message')

export function businessDevelopmentList() {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(businessDevelopmentPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}`, {
              method  : endpoints.index.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(businessDevelopmentListFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(businessDevelopmentRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}


//Show Item

export const BUSINESS_DEVELOPMENT_ITEM_FULFILLED = 'BUSINESS_DEVELOPMENT_ITEM_FULFILLED'
export const businessDevelopmentItemFulfilled = makeActionCreator(BUSINESS_DEVELOPMENT_ITEM_FULFILLED, 'data')

export const BUSINESS_DEVELOPMENT_ITEM_ERROR = 'BUSINESS_DEVELOPMENT_ITEM_ERROR'
export const businessDevelopmentItemError = makeActionCreator(BUSINESS_DEVELOPMENT_ITEM_ERROR, 'message')

export function businessDevelopmentItem(itemId) {

  return dispatch => {
      sessionService.loadSession().then(currentSession => {
        dispatch(businessDevelopmentPending())

            return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getItem.version}${endpoints.getItem.path.replace(":business_development_id", itemId)}`, {
                method  : endpoints.getItem.method,
                headers : {
                    'Authorization' : `Bearer ${currentSession.token}`,
                    'Accept': 'application/json'
                }
            })
            .then( API.checkStatus )
            .then( API.parseJSON )
            .then( json => dispatch(businessDevelopmentItemFulfilled(json.data)) )
            .catch( err => {
                // Dispatch the error action with error information
                dispatch(businessDevelopmentRejected(err))
            })
      })
      .catch(err => {
        dispatch(logout(() => browserHistory.push('/login')));

      })





  }
}


//Add Item

export const BUSINESS_DEVELOPMENT_ITEM_ADD_FULFILLED = 'BUSINESS_DEVELOPMENT_ITEM_ADD_FULFILLED'
export const businessDevelopmentItemAddFulfilled = makeActionCreator(BUSINESS_DEVELOPMENT_ITEM_ADD_FULFILLED, 'data')

export const BUSINESS_DEVELOPMENT_ITEM_ADD_ERROR = 'BUSINESS_DEVELOPMENT_ITEM_ADD_ERROR'
export const businessDevelopmentItemAddError = makeActionCreator(BUSINESS_DEVELOPMENT_ITEM_ADD_ERROR, 'message')

export function businessDevelopmentAddItem(data) {
  let formData = new FormData()

  Object.keys(data).map((key)=> {
    formData.append(key, data[key]);
    return key;
  });

  return dispatch => {
      sessionService.loadSession().then(currentSession => {
        dispatch(businessDevelopmentPending())

            return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addItem.version}${endpoints.addItem.path}`, {
                method  : endpoints.addItem.method,
                headers : {
                    'Authorization' : `Bearer ${currentSession.token}`,
                    'Accept': 'application/json'
                },
                body: formData
            })
            .then( API.checkStatus )
            .then( API.parseJSON )
            .then( json => {
                if(json.status === "ok"){
                  dispatch(businessDevelopmentItemAddFulfilled(json.data))
                }else{
                  dispatch(businessDevelopmentItemAddError(json.message))
                }
            } )
            .catch( err => {
                // Dispatch the error action with error information
                dispatch(businessDevelopmentRejected(err))
            })
      })
      .catch(err => {
        dispatch(logout(() => browserHistory.push('/login')));

      })



  }
}

//Update Item

export const BUSINESS_DEVELOPMENT_ITEM_UPDATE_FULFILLED = 'BUSINESS_DEVELOPMENT_ITEM_UPDATE_FULFILLED'
export const businessDevelopmentItemUpdateFulfilled = makeActionCreator(BUSINESS_DEVELOPMENT_ITEM_UPDATE_FULFILLED, 'data')

export const BUSINESS_DEVELOPMENT_ITEM_UPDATE_ERROR = 'BUSINESS_DEVELOPMENT_ITEM_UPDATE_ERROR'
export const businessDevelopmentItemUpdateError = makeActionCreator(BUSINESS_DEVELOPMENT_ITEM_UPDATE_ERROR, 'message')

export function businessDevelopmentUpdateItem(itemId, data) {
  let formData = new FormData()
  formData.append('_method', 'PUT')

  Object.keys(data).map((key)=> {
    formData.append(key, data[key]);
    return key;
  });


  return dispatch => {
      sessionService.loadSession().then(currentSession => {
        dispatch(businessDevelopmentPending())

            return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateItem.version}${endpoints.updateItem.path.replace(":business_development_id", itemId)}`, {
                method  : endpoints.updateItem.method,
                headers : {
                    'Authorization' : `Bearer ${currentSession.token}`,
                    'Accept': 'application/json'
                },
                body: formData
            })
            .then( API.checkStatus )
            .then( API.parseJSON )
            .then( json => {
                if(json.status === "ok"){
                  dispatch(businessDevelopmentItemUpdateFulfilled(json.data))
                }else{
                  dispatch(businessDevelopmentItemUpdateError(json.message))
                }
            } )
            .catch( err => {
                // Dispatch the error action with error information
                dispatch(businessDevelopmentRejected(err))
            })
      })
      .catch(err => {
        dispatch(logout(() => browserHistory.push('/login')));

      })



  }
}

//Delete Item

export const BUSINESS_DEVELOPMENT_ITEM_DELETE_FULFILLED = 'BUSINESS_DEVELOPMENT_ITEM_DELETE_FULFILLED'
export const businessDevelopmentItemDeleteFulfilled = makeActionCreator(BUSINESS_DEVELOPMENT_ITEM_DELETE_FULFILLED, 'data')

export const BUSINESS_DEVELOPMENT_ITEM_DELETE_ERROR = 'BUSINESS_DEVELOPMENT_ITEM_DELETE_ERROR'
export const businessDevelopmentItemDeleteError = makeActionCreator(BUSINESS_DEVELOPMENT_ITEM_DELETE_ERROR, 'message')

export function businessDevelopmentDeleteItem(itemId) {

  return dispatch => {
      sessionService.loadSession().then(currentSession => {
        dispatch(businessDevelopmentPending())

            return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteItem.version}${endpoints.deleteItem.path.replace(":business_development_id", itemId)}`, {
                method  : endpoints.deleteItem.method,
                headers : {
                    'Authorization' : `Bearer ${currentSession.token}`,
                    'Accept': 'application/json'
                }
            })
            .then( API.checkStatus )
            .then( API.parseJSON )
            .then( json => {
              if(json.status === "ok"){
                dispatch(businessDevelopmentItemDeleteFulfilled(json.data))
                dispatch(businessDevelopmentList())
              }else{
                dispatch(businessDevelopmentItemDeleteError(json.message))
              }

            })
            .catch( err => {
                // Dispatch the error action with error information
                dispatch(businessDevelopmentRejected(err))
            })
      })
      .catch(err => {
        dispatch(logout(() => browserHistory.push('/login')));

      })





  }
}
