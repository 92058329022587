import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';

class Select extends React.Component {
  constructor(props) {
    super(props);
    this.state = ({ value: this.props.schema.value, modified: false })
    this.focus = this.focus.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.schema.value !== this.state.value && !this.state.modified) {
      this.setState({ value: nextProps.schema.value });
    }
  }

  onChange(e, data) {
    const { value } = data;

    this.setState({ value, modified: true });
    if (this.props.schema.onChange) {
      this.props.schema.onChange(value);
    }
  }

  getValue() {
    return this.state.value
  }

  focus() {
    this.input.focus();
  }

  changeClass(schema) {
    if (schema.disabled) {
      return "form-group field disabled " + (schema.className ? schema.className : '')
    }

    return "form-group field " + (schema.className ? schema.className : '');
  }

  render() {
    const {
      schema,
    } = this.props;

    return (
      <div
        className={this.changeClass(schema)}
        style={schema.style ? schema.style : null}
        data-tip={schema.tooltip ? schema.tooltip : ''}
      >
        {schema.label ? (
          <label
            htmlFor={ schema.name }
          >
            { schema.label }
          </label>
        ) : null}

        <div className="field-select">
          <Dropdown
            placeholder={schema.placeholder || 'Select Option'}
            disabled={schema.disabled}
            fluid
            selection
            search={schema.searchable}
            options={schema.options.map((opt) => {
              const item = { key: opt.value, text: opt.name, value: opt.value.toString() };

              if (opt.image) {
                item.image = opt.image;
              }

              if (opt.description) {
                item.description = opt.description;
              }

              return item;
            })}
            value={this.state.value || this.state.value === 0 ? this.state.value.toString() : ''}
            onChange={this.onChange}
            name={schema.name}
            id={schema.id ? schema.id : schema.name}
            ref={(input) => { this.input = input; }}
            icon={schema.icon ? schema.icon : 'dropdown'}
            required={schema.isRequired ? 'required' : null}
          />
        </div>
      </div>
    );
  }
}


export default Select;
