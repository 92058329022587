import makeActionCreator from '../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'

import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API       from '../utils/config-api'
import * as endpoints from './endpoints'
import { logout } from '../auth/list';

export const STOCK_OPTIONS_PENDING = 'STOCK_OPTIONS_PENDING'
export const stockOptionsPending = makeActionCreator(STOCK_OPTIONS_PENDING)

export const STOCK_OPTIONS_REJECTED = 'STOCK_OPTIONS_REJECTED'
export const stockOptionsRejected = makeActionCreator(STOCK_OPTIONS_REJECTED)

export const STOCK_OPTIONS_SHOW_LIST= 'STOCK_OPTIONS_SHOW_LIST'
export const stockOptionsShowList = makeActionCreator(STOCK_OPTIONS_SHOW_LIST)

export const STOCK_OPTIONS_HIDE_LIST = 'STOCK_OPTIONS_HIDE_LIST'
export const stockOptionsHideList = makeActionCreator(STOCK_OPTIONS_HIDE_LIST)

export const STOCK_OPTIONS_SHOW_FORM= 'STOCK_OPTIONS_SHOW_FORM'
export const stockOptionsShowForm = makeActionCreator(STOCK_OPTIONS_SHOW_FORM, 'itemId')

export const STOCK_OPTIONS_HIDE_FORM = 'STOCK_OPTIONS_HIDE_FORM'
export const stockOptionsHideForm = makeActionCreator(STOCK_OPTIONS_HIDE_FORM)

export const STOCK_OPTIONS_SHOW_ITEM = 'STOCK_OPTIONS_SHOW_ITEM'
export const stockOptionsShowItem = makeActionCreator(STOCK_OPTIONS_SHOW_ITEM, 'itemId')


//Show Index

export const STOCK_OPTIONS_LIST_FULFILLED = 'STOCK_OPTIONS_LIST_FULFILLED'
export const stockOptionsListFulfilled = makeActionCreator(STOCK_OPTIONS_LIST_FULFILLED, 'data')

export const STOCK_OPTIONS_LIST_ERROR = 'STOCK_OPTIONS_LIST_ERROR'
export const stockOptionsListError = makeActionCreator(STOCK_OPTIONS_LIST_ERROR, 'message')

export function stockOptionsList(status) {
  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(stockOptionsPending())
      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}?user_status=${status}`, {
          method  : endpoints.index.method,
          headers : {
              'Authorization' : `Bearer ${currentSession.token}`,
              'Accept': 'application/json'
          }
      })
      .then( API.checkStatus )
      .then( API.parseJSON )
      .then( json => dispatch(stockOptionsListFulfilled(json.data)) )
      .catch( err => {
          // Dispatch the error action with error information
          dispatch(stockOptionsRejected(err))
      })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));
    })
  }
}

//Show Item

export const STOCK_OPTIONS_ITEM_FULFILLED = 'STOCK_OPTIONS_ITEM_FULFILLED'
export const stockOptionsItemFulfilled = makeActionCreator(STOCK_OPTIONS_ITEM_FULFILLED, 'data')

export const STOCK_OPTIONS_ITEM_ERROR = 'STOCK_OPTIONS_ITEM_ERROR'
export const stockOptionsItemError = makeActionCreator(STOCK_OPTIONS_ITEM_ERROR, 'message')

export function stockOptionsItem(itemId) {
  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(stockOptionsPending())
      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getItem.version}${endpoints.getItem.path.replace(":stock_options_id", itemId)}`, {
        method  : endpoints.getItem.method,
        headers : {
            'Authorization' : `Bearer ${currentSession.token}`,
            'Accept': 'application/json'
        }
      })
      .then( API.checkStatus )
      .then( API.parseJSON )
      .then( json => dispatch(stockOptionsItemFulfilled(json.data)) )
      .catch( err => {
          // Dispatch the error action with error information
          dispatch(stockOptionsRejected(err))
      })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));
    })
  }
}


//Add Item

export const STOCK_OPTIONS_ITEM_ADD_FULFILLED = 'STOCK_OPTIONS_ITEM_ADD_FULFILLED'
export const stockOptionsItemAddFulfilled = makeActionCreator(STOCK_OPTIONS_ITEM_ADD_FULFILLED, 'data')

export const STOCK_OPTIONS_ITEM_ADD_ERROR = 'STOCK_OPTIONS_ITEM_ADD_ERROR'
export const stockOptionsItemAddError = makeActionCreator(STOCK_OPTIONS_ITEM_ADD_ERROR, 'message')

export function stockOptionsAddItem(data) {
  let formData = new FormData()

  Object.keys(data).map((key)=> {
    formData.append(key, data[key]);
    return key;
  });

  return dispatch => {
      sessionService.loadSession().then(currentSession => {
        dispatch(stockOptionsPending())

            return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addItem.version}${endpoints.addItem.path}`, {
                method  : endpoints.addItem.method,
                headers : {
                    'Authorization' : `Bearer ${currentSession.token}`,
                    'Accept': 'application/json'
                },
                body: formData
            })
            .then( API.checkStatus )
            .then( API.parseJSON )
            .then( json => {
                if(json.status === "ok"){
                  dispatch(stockOptionsItemAddFulfilled(json.data))
                }else{
                  dispatch(stockOptionsItemAddError(json.message))
                }
            } )
            .catch( err => {
                // Dispatch the error action with error information
                dispatch(stockOptionsRejected(err))
            })
      })
      .catch(err => {
        dispatch(logout(() => browserHistory.push('/login')));

      })



  }
}

//Update Item

export const STOCK_OPTIONS_ITEM_UPDATE_FULFILLED = 'STOCK_OPTIONS_ITEM_UPDATE_FULFILLED'
export const stockOptionsItemUpdateFulfilled = makeActionCreator(STOCK_OPTIONS_ITEM_UPDATE_FULFILLED, 'data')

export const STOCK_OPTIONS_ITEM_UPDATE_ERROR = 'STOCK_OPTIONS_ITEM_UPDATE_ERROR'
export const stockOptionsItemUpdateError = makeActionCreator(STOCK_OPTIONS_ITEM_UPDATE_ERROR, 'message')

export function stockOptionsUpdateItem(itemId, data) {
  let formData = new FormData()
  formData.append('_method', 'PUT')

  Object.keys(data).map((key)=> {
    formData.append(key, data[key]);
    return key;
  });


  return dispatch => {
      sessionService.loadSession().then(currentSession => {
        dispatch(stockOptionsPending())

            return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateItem.version}${endpoints.updateItem.path.replace(":stock_options_id", itemId)}`, {
                method  : endpoints.updateItem.method,
                headers : {
                    'Authorization' : `Bearer ${currentSession.token}`,
                    'Accept': 'application/json'
                },
                body: formData
            })
            .then( API.checkStatus )
            .then( API.parseJSON )
            .then( json => {
                if(json.status === "ok"){
                  dispatch(stockOptionsItemUpdateFulfilled(json.data))
                }else{
                  dispatch(stockOptionsItemUpdateError(json.message))
                }
            } )
            .catch( err => {
                // Dispatch the error action with error information
                dispatch(stockOptionsRejected(err))
            })
      })
      .catch(err => {
        dispatch(logout(() => browserHistory.push('/login')));

      })
  }
}

//Delete Item

export const STOCK_OPTIONS_ITEM_DELETE_FULFILLED = 'STOCK_OPTIONS_ITEM_DELETE_FULFILLED'
export const stockOptionsItemDeleteFulfilled = makeActionCreator(STOCK_OPTIONS_ITEM_DELETE_FULFILLED, 'data')

export const STOCK_OPTIONS_ITEM_DELETE_ERROR = 'STOCK_OPTIONS_ITEM_DELETE_ERROR'
export const stockOptionsItemDeleteError = makeActionCreator(STOCK_OPTIONS_ITEM_DELETE_ERROR, 'message')

export function stockOptionsDeleteItem(itemId, status) {

  return dispatch => {
      sessionService.loadSession().then(currentSession => {
        dispatch(stockOptionsPending())

            return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteItem.version}${endpoints.deleteItem.path.replace(":stock_options_id", itemId)}`, {
                method  : endpoints.deleteItem.method,
                headers : {
                    'Authorization' : `Bearer ${currentSession.token}`,
                    'Accept': 'application/json'
                }
            })
            .then( API.checkStatus )
            .then( API.parseJSON )
            .then( json => {
              if (json.status === "ok") {
                dispatch(stockOptionsItemDeleteFulfilled(json.data))
                dispatch(stockOptionsList(status))
              }else{
                dispatch(stockOptionsItemDeleteError(json.message))
              }

            })
            .catch( err => {
                // Dispatch the error action with error information
                dispatch(stockOptionsRejected(err))
            })
      })
      .catch(err => {
        dispatch(logout(() => browserHistory.push('/login')));
      })





  }
}
