import React, {Component} from 'react';
import "assets/css/table.css";
import { Button, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import cookie from 'react-cookies'
import moment from 'moment'

class SettingsProfitSharingValueList extends Component{
  constructor(props){
  	super(props);
  	this.state = {
      showModal: false,
      itemId: null,
      sort_name: "",
      sort_order: ""
    };
  }
  componentWillMount() {

     this.setState(
       {
         sort_name: cookie.load('sort_column_settings_rates') || 'date',
         sort_order: cookie.load('sort_order_settings_rates') || 'asc'
       }
     )
  }

  render(){
    const list = this.props.settingProfitSharingValues.data

    const options = {
      defaultSortName: this.state.sort_name,
      defaultSortOrder: this.state.sort_order,
      onSortChange: this._onSortChange.bind(this),
    }

    return (
      <section className="section-list-content">
        <BootstrapTable
            data={list}
            striped={true}
            hover={true}
            options={options}
            headerContainerClass='fixed-header'>
            <TableHeaderColumn dataField="date"  isKey={true} dataSort={true} dataFormat={this._yearMonthFormat}>Start date</TableHeaderColumn>
            <TableHeaderColumn dataField="profit_value"  dataSort={true} dataFormat={this._moneyFormatter}>Profit Sharing Value Total</TableHeaderColumn>
            <TableHeaderColumn dataField="sharing_points"  dataSort={true}>Total Points</TableHeaderColumn>
            <TableHeaderColumn dataField="profit_sharing_per_point"  dataSort={true} dataFormat={this._moneyFormatter}>Profit Sharing per Point</TableHeaderColumn>
            <TableHeaderColumn dataField="buttons" dataFormat={this._renderButtons.bind(this)} dataAlign='center'></TableHeaderColumn>
        </BootstrapTable>


        {this._renderModal()}
      </section>
    )
  }

  _onSortChange(sortName, sortOrder){

    cookie.save('sort_column_settings_rates', sortName, { path: '/' });
    cookie.save('sort_order_settings_rates', sortOrder, { path: '/' });

    this.setState({
      sort_name: sortName,
      sort_order: sortOrder
    });

  }

  _yearMonthFormat(cell, row){
    return moment(cell).format("Y-M")
  }

  _moneyFormatter(cell, row){
    return Number(cell).toLocaleString('en-US', {style: 'currency',currency: 'USD'})
  }

  _renderButtons(cell, row){
    return(
      <div className="actions">
        <Button bsStyle="success" onClick={this.props.settingProfitSharingValuesShowForm.bind(this,row.id)}><i className="fa fa-pencil" aria-hidden="true"></i></Button>
        <Button bsStyle="danger" onClick={this._openDeleteModal.bind(this,row.id)}><i className="fa fa-trash" aria-hidden="true"></i></Button>
      </div>
    )
  }

  _closeDeleteModal() {
    this.setState({ showModal: false, itemId: null });
  }

  _openDeleteModal(itemId) {
    this.setState({ showModal: true, itemId:itemId });
  }

  _renderModal(){
    let name = ""
    if(this.state.itemId && this.props.settingProfitSharingValues.data.length > 0){
      for(let i=0; i<this.props.settingProfitSharingValues.data.length;i++){
        if(this.props.settingProfitSharingValues.data[i].id === this.state.itemId){
            name = this.props.settingProfitSharingValues.data[i].name
        }
      }
    }

    return (
      <Modal show={this.state.showModal} onHide={this._closeDeleteModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete {name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this element?</p>
            <p>All the infomation related to this Profit Sharing Value will be deleted.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this._confirmDelete.bind(this, this.state.itemId)} bsStyle="danger">Delete</Button>
            <Button onClick={this._closeDeleteModal.bind(this)}>Close</Button>
          </Modal.Footer>
        </Modal>
    )
  }
  _confirmDelete(itemId){
    this.props.settingProfitSharingValuesDeleteItem(itemId, this.props.currentYear.format("YYYY"))
    this._closeDeleteModal()
  }

}



export default SettingsProfitSharingValueList
