import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import SmartForm from '../form/base.jsx';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import 'assets/css/staffing.css';
import 'assets/css/table.css';

class StaffingListAvailability extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortName: 'user_name',
      sortOrder: 'asc',
    };

    this.availabilityColumnClassName = this.availabilityColumnClassName.bind(this);
    this.formatAvailabilityValue = this.formatAvailabilityValue.bind(this);

    // Retrieve Information
    const { avFilterWithTargetReached } = this.props;
    this.props.staffingGetIndexAvailability(avFilterWithTargetReached);
  }

  availabilityColumnClassName(fieldValue) {
    const className = ['col-availability'];

    // empty className
    if (!fieldValue && fieldValue !== 0) className.push('empty');

    // empty value grade className
    if (fieldValue || fieldValue === 0) {
      className.push(`availability-${Math.round(fieldValue / 10)}`);
    }

    return className.join(' ');
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder,
    });
  }

  trClassFormat = (row, rowIndex) => (rowIndex % 2 === 0 ? 'tr-odd' : 'tr-even') // return class name.


  formatAvailabilityValue = (value) => {
    if (value || value === 0) {
      const val = Math.max(0, value);
      return `${val}%`;
    }
    return 'N/A';
  }

  changeReportFilter = (withTargetReached) => {
    this.props.updateAvFilterWithTargetReached(withTargetReached);
    this.props.staffingGetIndexAvailability(withTargetReached);
  }

  onAfterSaveCell = (row, cellName, cellValue) => {
    row.user.staffing_availability_note = cellValue;
    this.props.putUpdateUserStaffingAvailabilityNote(row.user.id, cellValue);
  }

  render() {
    const { permissions, staffing, avFilterWithTargetReached } = this.props;
    const staffingData = [];
    if (staffing.indexAvailabilityData) {
      const { indexAvailabilityData } = staffing;
      for (let i = 0; i < indexAvailabilityData.length; i += 1) {
        const data = Object.assign({}, indexAvailabilityData[i]);

        // Plain user role
        data.user_role = (data.user.role && data.user.role.name) ? data.user.role.name : '';

        // Plain user name
        data.user_name = data.user.name;

        // Plain user Staffing Availability Note
        data.staffing_availability_note = data.user.staffing_availability_note;

        // Transform utilization to availability
        if (data.total_week_1 || data.total_week_1 === 0) {
          data.total_week_1 = 100 - data.total_week_1;
        }
        if (data.total_week_2 || data.total_week_2 === 0) {
          data.total_week_2 = 100 - data.total_week_2;
        }
        if (data.total_week_3 || data.total_week_3 === 0) {
          data.total_week_3 = 100 - data.total_week_3;
        }
        if (data.total_week_4 || data.total_week_4 === 0) {
          data.total_week_4 = 100 - data.total_week_4;
        }
        if (data.total_week_5 || data.total_week_5 === 0) {
          data.total_week_5 = 100 - data.total_week_5;
        }
        if (data.total_week_6 || data.total_week_6 === 0) {
          data.total_week_6 = 100 - data.total_week_6;
        }
        if (data.total_week_7 || data.total_week_7 === 0) {
          data.total_week_7 = 100 - data.total_week_7;
        }
        if (data.total_week_8 || data.total_week_8 === 0) {
          data.total_week_8 = 100 - data.total_week_8;
        }
        staffingData.push(data);
      }
    }

    const { sortName, sortOrder } = this.state;

    const options = {
      defaultSortName: sortName,
      defaultSortOrder: sortOrder,
      onSortChange: this.onSortChange.bind(this),
    };

    const week1 = moment().startOf('isoWeek');
    const week2 = moment(week1).add(1, 'week');
    const week3 = moment(week2).add(1, 'week');
    const week4 = moment(week3).add(1, 'week');
    const week5 = moment(week4).add(1, 'week');
    const week6 = moment(week5).add(1, 'week');
    const week7 = moment(week6).add(1, 'week');
    const week8 = moment(week7).add(1, 'week');

    const cellEdit = {
      mode: 'click',
      blurToSave: true,
      afterSaveCell: this.onAfterSaveCell,
    };

    return (
      <div>
        <div className="box-header with-border">
          <Row>
            <Col xs={5} className="title-section">
              <h1 className="box-title">
                Staffing &raquo; Availability
              </h1>
            </Col>
            <Col xs={7} className="action-section">
              <SmartForm
                inline
                buttons={[]}
                schema={[
                  {
                    type: 'default',
                    fields: [
                      {
                        type: 'select',
                        name: 'withTargetReached',
                        label: 'Include Consultants w/ Target reached',
                        value: avFilterWithTargetReached,
                        onChange: this.changeReportFilter,
                        options: [
                          { name: 'Yes', value: 1 },
                          { name: 'No', value: 0 },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
        <div className="box-content">
          <section className="section-list-content staffing-availability-list-content">
            <BootstrapTable
              data={staffingData}
              options={options}
              headerContainerClass="fixed-header"
              trClassName={this.trClassFormat}
              cellEdit={cellEdit}
            >
              <TableHeaderColumn
                dataField="user_name"
                columnTitle
                isKey
                filter={{ type: 'TextFilter', delay: 500 }}
                filterFormatted
                dataSort
              >
                  Name
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="user_role"
                columnTitle
                filter={{ type: 'TextFilter', delay: 500 }}
                filterFormatted
                className="col-level"
                columnClassName="col-level"
                dataSort
                editable={false}
              >
                  Level
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="total_week_1"
                dataFormat={this.formatAvailabilityValue}
                headerAlign="left"
                dataAlign="center"
                className="col-availability-header"
                columnClassName={this.availabilityColumnClassName}
                dataSort
                editable={false}
              >
                {week1.format('MMM')}
                <br />
                {week1.format('Do')}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="total_week_2"
                dataFormat={this.formatAvailabilityValue}
                headerAlign="left"
                dataAlign="center"
                className="col-availability-header"
                columnClassName={this.availabilityColumnClassName}
                dataSort
                editable={false}
              >
                {week2.format('MMM')}
                <br />
                {week2.format('Do')}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="total_week_3"
                dataFormat={this.formatAvailabilityValue}
                headerAlign="left"
                dataAlign="center"
                className="col-availability-header"
                columnClassName={this.availabilityColumnClassName}
                dataSort
                editable={false}
              >
                {week3.format('MMM')}
                <br />
                {week3.format('Do')}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="total_week_4"
                dataFormat={this.formatAvailabilityValue}
                headerAlign="left"
                dataAlign="center"
                className="col-availability-header"
                columnClassName={this.availabilityColumnClassName}
                dataSort
                editable={false}
              >
                {week4.format('MMM')}
                <br />
                {week4.format('Do')}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="total_week_5"
                dataFormat={this.formatAvailabilityValue}
                headerAlign="left"
                dataAlign="center"
                className="col-availability-header"
                columnClassName={this.availabilityColumnClassName}
                dataSort
                editable={false}
              >
                {week5.format('MMM')}
                <br />
                {week5.format('Do')}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="total_week_6"
                dataFormat={this.formatAvailabilityValue}
                headerAlign="left"
                dataAlign="center"
                className="col-availability-header"
                columnClassName={this.availabilityColumnClassName}
                dataSort
                editable={false}
              >
                {week6.format('MMM')}
                <br />
                {week6.format('Do')}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="total_week_7"
                dataFormat={this.formatAvailabilityValue}
                headerAlign="left"
                dataAlign="center"
                className="col-availability-header"
                columnClassName={this.availabilityColumnClassName}
                dataSort
                editable={false}
              >
                {week7.format('MMM')}
                <br />
                {week7.format('Do')}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="total_week_8"
                dataFormat={this.formatAvailabilityValue}
                headerAlign="left"
                dataAlign="center"
                className="col-availability-header"
                columnClassName={this.availabilityColumnClassName}
                dataSort
                editable={false}
              >
                {week8.format('MMM')}
                <br />
                {week8.format('Do')}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="staffing_availability_note"
                headerAlign="left"
                className="col-notes"
                columnClassName="col-notes"
                dataSort
                hidden={!permissions.can_update && !permissions.can_create}
              >
                Notes
              </TableHeaderColumn>
            </BootstrapTable>
          </section>
        </div>
      </div>
    );
  }
}

export default StaffingListAvailability;
