import React, { Component } from 'react';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import cookie from 'react-cookies';
import { browserHistory } from 'react-router';

import 'assets/css/table.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

class PMOProjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      itemId: null,
      sortName: '',
      sortOrder: '',
    };
    this.onRowClick = this.onRowClick.bind(this);
  }

  componentWillMount() {
    this.setState(
      {
        sortName: cookie.load('sort_column_pmo_projects') || 'project',
        sortOrder: cookie.load('sortOrder_pmo_projects') || 'asc',
      },
    );
  }

  onSortChange(sortName, sortOrder) {
    cookie.save('sort_column_pmo_projects', sortName, { path: '/' });
    cookie.save('sortOrder_pmo_projects', sortOrder, { path: '/' });

    this.setState({
      sortName,
      sortOrder,
    });
  }

  trClassFormat(row, rowIndex) {
    return rowIndex % 2 === 0 ? "tr-odd" : "tr-even";  // return class name.
  }

  moneyFormatter(cell, row){
    return "$" + Number(cell).toLocaleString('en-US')
  }

  dateFormatter(cell, row){
    return moment(cell).format("YYYY-MM-DD")
  }

  boolFormatter(cell, row){
    return cell?"Yes":"No"
  }

  percentajeFormatter(cell, row){
    return cell + "%"
  }

  objectFormatter(field, cell, row){
    return cell[field]
  }

  objectPercentajeFormatter(field, cell, row){
    return cell[field] + "%"
  }

  sortObjectFunc(a, b, order, sortField, extraData) {   // order is desc or asc
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return a[sortField][fieldName].localeCompare(b[sortField][fieldName])
    } else {
      return b[sortField][fieldName].localeCompare(a[sortField][fieldName]);
    }

  }

  sortNumberObjectFunc(a, b, order, sortField, extraData) {
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return parseFloat(a[sortField][fieldName]) - parseFloat(b[sortField][fieldName])
    } else {
      return parseFloat(b[sortField][fieldName]) - parseFloat(a[sortField][fieldName]);
    }
  }

  sortNumberFunc(a, b, order, sortField) {
    if (order === 'asc') {
      return parseFloat(a[sortField]) - parseFloat(b[sortField])
    } else {
      return parseFloat(b[sortField]) - parseFloat(a[sortField]);
    }
  }



  closeDeleteModal() {
    this.setState({ showModal: false, itemId: null });
  }

  openDeleteModal(itemId) {
    this.setState({ showModal: true, itemId });
  }

  confirmDelete(itemId) {
    this.props.pmoProjectsDeleteItem(itemId);
    this.closeDeleteModal();
  }

  onRowClick(row) {
    browserHistory.push(`/pmo/project/${row.id}`);
  }

  renderButtons(cell, row) {
    return (
      <div className="actions">
        {this.props.permissions.can_update ? (
          <Button
            bsStyle="success"
            onClick={(e) => {
              e.stopPropagation();
              this.props.pmoProjectsShowForm(row.id);
            }}
          >
            <i className="fa fa-pencil" aria-hidden="true" />
          </Button>
        ) : null}
        {this.props.permissions.can_delete ? (
          <Button
            bsStyle="danger"
            onClick={(e) => {
              e.stopPropagation();
              this.openDeleteModal(row.id);
            }}
          >
            <i className="fa fa-trash" aria-hidden="true" />
          </Button>
        ) : null}
      </div>
    );
  }

  renderModal() {
    let name = '';
    if (this.state.itemId && this.props.pmoProjects.data.length > 0) {
      for (let i=0; i<this.props.pmoProjects.data.length;i++) {
        if(this.props.pmoProjects.data[i].id === this.state.itemId){
            name = this.props.pmoProjects.data[i].name
        }
      }
    }

    return (
      <Modal show={this.state.showModal} onHide={this.closeDeleteModal.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete this element?
          </p>
          <p>
            All the infomation related to this Project will be deleted.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.confirmDelete.bind(this, this.state.itemId)} bsStyle="danger">Delete</Button>
          <Button onClick={this.closeDeleteModal.bind(this)}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    const list = this.props.pmoProjects.data;

    const {
      sortName,
      sortOrder,
    } = this.state;

    const options = {
      expandRowBgColor: 'rgb(242, 255, 163)',
      defaultSortName: sortName,
      defaultSortOrder: sortOrder,
      onSortChange: this.onSortChange.bind(this),
      onRowClick: this.onRowClick,
    };

    return (
      <section className="section-list-content projects">
        <BootstrapTable
          data={list}
          hover
          options={options}
          ref={(table) => { this.table = table; }}
          trClassName={this.trClassFormat}
          headerContainerClass="fixed-header"
        >
          <TableHeaderColumn
            dataField="project"
            columnTitle
            filter={{ type: 'TextFilter', delay: 500 }}
            isKey
            dataSort
            width="300px"
          >
            Project Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="officer_in_charge"
            columnTitle
            filter={{ type: 'TextFilter', delay: 500 }}
            dataSort
            dataFormat={this.objectFormatter.bind(this, 'name')}
            filterFormatted
            sortFunc={this.sortObjectFunc}
            sortFuncExtraData={{ objectField: 'name' }}
            width="105px"
          >
            OIC
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="status"
            columnTitle
            filter={{ type: 'TextFilter', delay: 500 }}
            filterFormatted
            dataSort
            dataFormat={(cell) => {
              if (cell === 2) {
                return 'Archived';
              }
              return cell === 1 ? 'Active' : 'Inactive';
            }}
            width="95px"
          >
            Status
          </TableHeaderColumn>
          <TableHeaderColumn dataField="note" columnTitle>
            Notes
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="buttons"
            dataFormat={this.renderButtons.bind(this)}
            dataAlign="center"
            headerAlign="left"
            className="col_buttons"
            columnClassName="col_buttons"
            width="95px"
            hidden={!this.props.permissions.can_update && !this.props.permissions.can_delete}
          />
        </BootstrapTable>


        {this.renderModal()}
      </section>
    );
  }
}


export default PMOProjectList;
