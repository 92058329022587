const initialState = {
  inProgress: true,
  editSuccess: false,
  error: false,
  errorText: null,
  indexData: [],
  itemData: null,
};

function ConsultantSkillsReducer(state = initialState, action) {
  const actionName = 'CONSULTANT_SKILLS';
  switch (action.type) {
    case `${actionName}_PENDING`:
      return {
        ...state,
        inProgress: true,
        error: false,
        errorText: null,
      };
    case `${actionName}_REJECTED`:
      return {
        ...state,
        inProgress: false,
        error: action.error,
      };
    case `${actionName}_INDEX_FULFILLED`:
      return {
        ...state,
        inProgress: false,
        editSuccess: false,
        error: false,
        errorText: null,
        indexData: action.data,
      };
    case `${actionName}_INDEX_ERROR`:
      return {
        ...state,
        inProgress: false,
        error: true,
        errorText: action.message,
      };
    case `${actionName}_GET_SHOW_FULFILLED`:
      return {
        ...state,
        inProgress: false,
        editSuccess: false,
        error: false,
        errorText: null,
        itemData: action.data,
      };
    case `${actionName}_GET_SHOW_ERROR`:
      return {
        ...state,
        inProgress: false,
        error: true,
        errorText: action.message,
      };
    case `${actionName}_PUT_UPDATE_FULFILLED`:
      return {
        ...state,
        inProgress: false,
        editSuccess: true,
      };
    case `${actionName}_PUT_UPDATE_ERROR`:
      return {
        ...state,
        inProgress: false,
        error: true,
        errorText: action.message,
      };
    default:
      return state;
  }
}

export default ConsultantSkillsReducer;
