import React, {Component} from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'

class Details extends Component {

  render() {

    if (this.props.data && this.props.data.months) {
      return (
        <BootstrapTable data={ this.props.data.months }>
          <TableHeaderColumn
            dataField='month'
            isKey={ true }
          >
            Month
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='points'
            dataAlign='center'
            headerAlign='center'
          >
            Points
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='value_per_point'
            dataFormat={this._moneyFormatter}
            dataAlign='right'
            headerAlign='right'
          >
            Value
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='profit_sharing'
            dataFormat={this._moneyFormatter}
            dataAlign='right'
            headerAlign='right'
          >
            Profit Sharing
          </TableHeaderColumn>
        </BootstrapTable>);
    } else {
      return (<p>No Data</p>);
    }
  }

  _moneyFormatter(cell, row){
    return Number(cell).toLocaleString('en-US', {style: 'currency',currency: 'USD'})
  }
}

export default Details
