import React, { Component } from 'react';
import "assets/css/table.css";
import { Button, Modal, Table, Row, Col } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import Details from './details.jsx'

class utilizationList extends Component{
  constructor(props){
  	super(props);
  	this.state = {
      showModal: false,
      itemId: null,
      total_billable: 0,
      total_available: 0,
      ytd: 0
    };
  }

  componentWillMount() {
  }

  render(){
    let utilization = [];

    if (this.props.utilization.data) {
      for(let i=0; i<this.props.utilization.data.length; i++){
        let data = Object.assign({}, this.props.utilization.data[i])

        data.role_name = ""
        data.role_target = ""

        if(data.role){
          data.role_name = data.role.name
          data.role_target = data.role.target
        }


        utilization.push(data)
      }
    }



    const options = {
      afterColumnFilter: this._afterColumnFilter.bind(this),
      expandRowBgColor: 'rgb(242, 255, 163)',
      defaultSortName: "name",
      defaultSortOrder: "asc"
    }

    return (
      <section className="section-list-content">
        <BootstrapTable
            data={utilization}
            hover={true}
            options={ options }
            expandableRow={ this._isExpandableRow.bind(this) }
            expandComponent={ this._expandComponent.bind(this) }
            autoCollapse={ { sort: true, search: true, filter: true } }
            expandColumnOptions={ {
              expandColumnVisible: true,
              expandColumnComponent: this.expandColumnComponent,
              columnWidth: 50
            } }
            ref={(table) => { this.table = table; }}
            trClassName={this._trClassFormat}
            headerContainerClass='fixed-header'>
            <TableHeaderColumn
              dataField="name"
              columnTitle
              isKey={true}
              filter={ { type: 'TextFilter', delay: 500 } }
              dataSort={true}
            >
              Name
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="role_name"
              columnTitle
              dataSort={true}
              filter={ { type: 'TextFilter', delay: 500 } }
              filterFormatted
            >
              Level
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="billable_total"
              filterFormatted
              dataSort={true}
              dataAlign='center'
              headerAlign='left'
              className='col_billable_total'
              columnClassName='col_billable_total'
            >
              Total Billable
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="role_target"
              filterFormatted
              dataSort={true}
              dataFormat={this._percentajeFormatter}
              dataAlign='center'
              headerAlign='left'
              className='col_role_target'
              columnClassName='col_role_target'
            >
              Target
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="available_total"
              dataSort={true}
              dataAlign='center'
              headerAlign='left'
              className='col_available_total'
              columnClassName='col_available_total'
            >
              Total Available
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="used_total"
              dataSort={true}
              dataFormat={this._percentajeFormatter}
              sortFunc={this._sortNumberFunc}
              dataAlign='center'
              headerAlign='left'
              className='col_used_total'
              columnClassName='col_used_total'
            >
              Utilization
            </TableHeaderColumn>
        </BootstrapTable>
        <div className="totals">
            <Row>
              <Col xs={12} md={5} mdOffset={7}>
                <Table striped bordered hover responsive>
                  <thead>
                    <tr>
                      <th>Total Billable:</th>
                      <th>Total Available:</th>
                      <th>Utilization:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{Number(this.state.total_billable).toLocaleString('en-US')}</td>
                      <td>{Number(this.state.total_available).toLocaleString('en-US')}</td>
                      <td>{Number(this.state.ytd).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </div>

        {this._renderModal()}
      </section>
    )
  }

  _trClassFormat(row, rowIndex) {
    return rowIndex % 2 === 0 ? "tr-odd" : "tr-even";  // return class name.
  }

  _sortNumberFunc(a, b, order, sortField){

    if (order === 'asc') {
      return parseFloat(a[sortField]) - parseFloat(b[sortField])
    } else {
      return parseFloat(b[sortField]) - parseFloat(a[sortField]);
    }
  }

  _afterColumnFilter(filterConds, result) {
    let total_billable = 0, total_available = 0, ytd = 0

    for (let i = 0; i < result.length; i++) {
      total_billable += parseFloat(result[i].billable_total)
      total_available += parseFloat(result[i].available_total)
    }

    if (total_billable > 0 && total_available > 0) {
        ytd = (total_billable * 100) / total_available
    }

    if (total_billable !== this.state.total_billable || total_available !== this.state.total_available || ytd !== this.state.ytd){
        this.setState({total_billable: total_billable, total_available: total_available, ytd: ytd})
    }

  }


expandColumnComponent({ isExpandableRow, isExpanded }) {
    let content = '';

    if (isExpandableRow) {
      content = (isExpanded ? '(-)' : '(+)' );
    } else {
      content = ' ';
    }
    return (
      <div> { content } </div>
    );
  }

  _isExpandableRow(row) {

    if (row.months && row.months.length > 0) return true;
    else return false;
  }

  _expandComponent(row) {

    return (
      <Details data={ row } {...this.props} />
    );
  }

  _percentajeFormatter(cell, row){
    //return Number(cell).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) + "%"
    if(cell || cell === 0){
      return cell + "%"
    }

    return ""

  }

  _objectPercentajeFormatter(field, cell, row){
    if(cell && cell[field]){
      return Math.round(cell[field]) + "%"
    }

    return ''
  }

  _objectFormatter(field, cell, row){
    if(cell && cell[field]){
      return cell[field]
    }

    return ''

  }

  _objectNumberFormatter(field, cell, row){
    if(cell && cell[field]){
      return Number(cell[field]).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
    }

    return ''

  }

  _sortObjectFunc(a, b, order, sortField, extraData) {   // order is desc or asc
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return (a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'').localeCompare(b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'')
    } else {
      return (b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'').localeCompare(a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'');
    }

  }

  _sortObjectNumberFunc(a, b, order, sortField, extraData) {   // order is desc or asc
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return (a[sortField] && a[sortField][fieldName]?parseFloat(a[sortField][fieldName]):0) - (b[sortField] && b[sortField][fieldName]?parseFloat(b[sortField][fieldName]):0)
    } else {
      return (b[sortField] && b[sortField][fieldName]?parseFloat(b[sortField][fieldName]):0) - (a[sortField] && a[sortField][fieldName]?parseFloat(a[sortField][fieldName]):0);
    }

  }

  _renderButtons(cell, row){
    return(
      <div className="actions">
        <Button bsStyle="success" onClick={this.props.utilizationShowForm.bind(this,row.id)}><i className="fa fa-pencil" aria-hidden="true"></i></Button>
        <Button bsStyle="danger" onClick={this._openDeleteModal.bind(this,row.id)}><i className="fa fa-trash" aria-hidden="true"></i></Button>
      </div>
    )
  }



  _closeDeleteModal() {
    this.setState({ showModal: false, itemId: null });
  }

  _openDeleteModal(itemId) {
    this.setState({ showModal: true, itemId:itemId });
  }

  _renderModal(){
    let name = ""
    if(this.state.itemId && this.props.utilization.data.length > 0){
      for(let i=0; i<this.props.utilization.data.length;i++){
        if(this.props.utilization.data[i].id === this.state.itemId){
            name = this.props.utilization.data[i].name
        }
      }
    }

    return (
      <Modal show={this.state.showModal} onHide={this._closeDeleteModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete {name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this element?</p>
            <p>All the infomation related to this Realization will be deleted.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this._confirmDelete.bind(this, this.state.itemId)} bsStyle="danger">Delete</Button>
            <Button onClick={this._closeDeleteModal.bind(this)}>Close</Button>
          </Modal.Footer>
        </Modal>
    )
  }
  _confirmDelete(itemId){
    this.props.utilizationDeleteItem(itemId)
    this._closeDeleteModal()
  }

}



export default utilizationList
