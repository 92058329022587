import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';

export const DASHBOARD_PENDING = 'DASHBOARD_PENDING';
export const dashboardPending = makeActionCreator(DASHBOARD_PENDING);

export const DASHBOARD_REJECTED = 'DASHBOARD_REJECTED';
export const dashboardRejected = makeActionCreator(DASHBOARD_REJECTED);

// Show Index

export const DASHBOARD_FULFILLED = 'DASHBOARD_FULFILLED';
export const dashboardDataFulfilled = makeActionCreator(DASHBOARD_FULFILLED, 'data');

export function getDashboard() {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(dashboardPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}`, {
        method: endpoints.index.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') { dispatch(dashboardDataFulfilled(json.data)); }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(dashboardRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
