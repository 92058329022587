import React, {Component} from 'react';
import "assets/css/table.css";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import cookie from 'react-cookies'
import Details from './details.jsx'

class StockOptionsList extends Component{
  constructor(props){
  	super(props);
  	this.state = {
      itemId: null,
      sort_name: "",
      sort_order: ""
    };
  }

  componentWillMount() {
    this.props.stockOptionsList(this.props.statusFilter)
    this.setState(
      {
        sort_name: cookie.load('sort_column_stock_options') || 'name',
        sort_order: cookie.load('sort_order_stock_options') || 'asc'
      }
    )
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.statusFilter !== nextProps.statusFilter) {
      this.props.stockOptionsList(nextProps.statusFilter)
    }
  }

  render(){
    const list = this.props.stockOptions.data

    const options = {
      expandRowBgColor: 'rgb(242, 255, 163)',
      defaultSortName: this.state.sort_name,
      defaultSortOrder: this.state.sort_order,
      // onSortChange: this._onSortChange.bind(this),
    }

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={list}
          hover={true}
          options={ options }
          expandableRow={ this._isExpandableRow.bind(this) }
          expandComponent={ this._expandComponent.bind(this) }
          autoCollapse={ { sort: true, search: true, filter: true } }
          expandColumnOptions={ {
            expandColumnVisible: true,
            expandColumnComponent: this.expandColumnComponent,
            columnWidth: 50
          } }
          ref={(table) => { this.table = table; }}
          trClassName={this._trClassFormat}
          headerContainerClass='fixed-header'
        >
          <TableHeaderColumn
            dataField="name"
            columnTitle
            filter={ { type: 'TextFilter', delay: 500 } }
            isKey={true}
            dataSort={true}
            columnClassName={ this._columnClassNameFormat }
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="grant_amount"
            dataFormat={this._numberFormatter.bind(this)}
            dataAlign='right'
            headerAlign='right'
            dataSort={true}
            filterFormatted
            columnClassName={ this._columnClassNameFormat }
            width="150px"
          >
            Options Granted
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="value"
            dataFormat={this._moneyFormatter.bind(this)}
            dataAlign='right'
            headerAlign='right'
            dataSort={true}
            columnClassName={ this._columnClassNameFormat }
            width="140px"
          >
              Value
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="vested"
            dataFormat={this._numberFormatter.bind(this)}
            dataAlign='right'
            headerAlign='right'
            dataSort={true}
            columnClassName={ this._columnClassNameFormat }
            width="140px"
          >
            Total Vested
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="vested_value"
            dataFormat={this._moneyFormatter.bind(this)}
            dataAlign='right'
            headerAlign='right'
            dataSort={true}
            columnClassName={ this._columnClassNameFormat }
            width="155px"
          >
            Total Vested Value
          </TableHeaderColumn>
        </BootstrapTable>
      </section>
    )
  }

  _onSortChange(sortName, sortOrder){
    cookie.save('sort_column_stock_options', sortName, { path: '/' });
    cookie.save('sort_order_stock_options', sortOrder, { path: '/' });
    this.setState({
      sort_name: sortName,
      sort_order: sortOrder
    });
  }

  _columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {
    return !row.is_active ? 'inactive' : '';
  }

  expandColumnComponent({ isExpandableRow, isExpanded }) {
      let content = '';

      if (isExpandableRow) {
        content = (isExpanded ? '(-)' : '(+)' );
      } else {
        content = ' ';
      }
      return (
        <div> { content } </div>
      );
    }

  _isExpandableRow(row) {
    if (row.entries && row.entries.length > 0) return true;
    else return false;
  }

  _expandComponent(row) {
    return (
      <Details data={ row } {...this.props} />
    );
  }

  _trClassFormat(row, rowIndex) {
    return rowIndex % 2 === 0 ? "tr-odd" : "tr-even";  // return class name.
  }

  _moneyFormatter(cell, row){
    return "$" + Number(cell).toLocaleString('en-US', {minimumFractionDigits: 2})
  }

  _numberFormatter(cell, row){
    return Number(cell).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
  }


  _sortObjectFunc(a, b, order, sortField, extraData) {   // order is desc or asc
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return a[sortField][fieldName].localeCompare(b[sortField][fieldName])
    } else {
      return b[sortField][fieldName].localeCompare(a[sortField][fieldName]);
    }
  }

  _sortNumberObjectFunc(a, b, order, sortField, extraData){
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return parseFloat(a[sortField][fieldName]) - parseFloat(b[sortField][fieldName])
    } else {
      return parseFloat(b[sortField][fieldName]) - parseFloat(a[sortField][fieldName]);
    }
  }

  _sortNumberFunc(a, b, order, sortField){

    if (order === 'asc') {
      return parseFloat(a[sortField]) - parseFloat(b[sortField])
    } else {
      return parseFloat(b[sortField]) - parseFloat(a[sortField]);
    }
  }
}

export default StockOptionsList
