import authReducer from './auth/list';
import BusinessDevelopmentReducer from './business-development/list';
import RecruitsReducer from './recruits/list';
import RealizationReducer from './realization/list';
import RealizationSummaryReducer from './realization/realizationSummary';
import RealizationLast3MonthsReducer from './realization/realizationLast3Months';
import UtilizationReducer from './utilization/list';
import InvoicesIssuedReducer from './invoices-issued/list';
import UserReducer from './user/list';
import UserLevelReducer from './user-level/list';
import StaffingReducer from './staffing/list';
import ProjectsReducer from './projects/list';
import InternalProjectsReducer from './internal-projects/list';
import ProfitSharingReducer from './profit-sharing/list';
import StockOptionsReducer from './stock-options/list';
import ConsultantLocationsReducer from './consultant-locations/list';
import ConsultantNetworkingReducer from './consultant-networking/list';
import ConsultantNetworkingSessionsReducer from './consultant-networking/session';
import ConsultantSkillsReducer from './consultant-skills/consultantSkills';
import MentorshipReducer from './mentorship/mentorship';
import DashboardReducer from './dashboard/list';
import pmoProjectsReducer from './pmo/projects';
import pmoIssueManagementReducer from './pmo/issueManagement';
import pmoRiskManagementReducer from './pmo/riskManagement';
import pmoChangeControlReducer from './pmo/changeControl';
import pmoActionItemsReducer from './pmo/actionItems';
import pmoMilestoneReducer from './pmo/milestone';
import BackgroundProcessReducer from './background-process/backgroundProcess';

import SettingsRecruitingStagesReducer from './settings/recruiting-stages/list';
import SettingsRecruitingSourcesReducer from './settings/recruiting-sources/list';
import SettingsLevelsReducer from './settings/levels/list';
import SettingsRatesReducer from './settings/rates/list';
import SettingsResourcesReducer from './settings/resources/list';
import SettingsUniversityResourcesReducer from './settings/resources/university';
import SettingsInsightsResourcesReducer from './settings/resources/insights';
import SettingsMethodologiesResourcesReducer from './settings/resources/methodologies';
import ImportReducer from './settings/import/list';
import UserPermissionReducer from './settings/user-permissions/list';
import StockValueReducer from './settings/stock-value/list';
import SettingsProfitSharingPointsReducer from './settings/profit-sharing-points/list';
import SettingsProfitSharingValuesReducer from './settings/profit-sharing-values/list';
import SettingsAnnouncementsReducer from './settings/announcements/list';

const reducers = {
  authReducer,
  BusinessDevelopmentReducer,
  RecruitsReducer,
  RealizationReducer,
  RealizationSummaryReducer,
  RealizationLast3MonthsReducer,
  UtilizationReducer,
  InvoicesIssuedReducer,
  UserReducer,
  UserLevelReducer,
  StaffingReducer,
  ProjectsReducer,
  InternalProjectsReducer,
  ProfitSharingReducer,
  StockOptionsReducer,
  ConsultantLocationsReducer,
  ConsultantNetworkingReducer,
  ConsultantNetworkingSessionsReducer,
  ConsultantSkillsReducer,
  MentorshipReducer,
  DashboardReducer,
  pmoProjectsReducer,
  pmoIssueManagementReducer,
  pmoRiskManagementReducer,
  pmoChangeControlReducer,
  pmoActionItemsReducer,
  pmoMilestoneReducer,
  BackgroundProcessReducer,

  SettingsRecruitingStagesReducer,
  SettingsRecruitingSourcesReducer,
  SettingsLevelsReducer,
  SettingsRatesReducer,
  SettingsProfitSharingPointsReducer,
  SettingsProfitSharingValuesReducer,
  SettingsResourcesReducer,
  SettingsUniversityResourcesReducer,
  ImportReducer,
  UserPermissionReducer,
  StockValueReducer,
  SettingsInsightsResourcesReducer,
  SettingsMethodologiesResourcesReducer,
  SettingsAnnouncementsReducer,
};

export default reducers;
