function _getTooltips(pathname, children = null, locale = null){
  let tooltips = locale && locale.tooltips?locale.tooltips:null
  let tooltips_section = []
  let pathnameStandarized = ""

  if(pathname){
    let pathnameCamelCase = pathname.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase(); });

    const pathnameParts = pathnameCamelCase.split("/")

    if(pathnameParts.length > 2){
      pathnameStandarized = pathnameParts[2]
    }else{
      pathnameStandarized = pathnameParts[1]
    }

    if(!pathnameStandarized){
      pathnameStandarized = "dashboard"
    }
  }

  if(tooltips && localStorage.tooltips_enabled){
    tooltips_section = tooltips[pathnameStandarized]
  }

  return tooltips_section
}


function _getTooltipText(tooltips, section, pos){
  let text = ''

  if(tooltips && tooltips[section] && tooltips[section][pos]){
    text = tooltips[section][pos]
  }

  return text

}

function _findAncestor (el, cls) {
  while(el && !el.classList.contains(cls)){
    el = el.parentElement
  }

  return el;
}

function _compareArrays(arr1, arr2){
  let theSame = true

  if(arr1.length !== arr2.length)
    return false


  for(let i=0; i<arr1.length; i++){
    if(theSame){
      theSame = _isEquivalent(arr1[i], arr2[i])
    }
  }

  return theSame
}

function _isEquivalent(a, b) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
        return false;
    }

    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

function scrollToLinear(element, to, duration) {
  if (duration <= 0) return;
  var difference = to - element.scrollTop;
  var perTick = difference / duration * 10;

  setTimeout((()=> {
      element.scrollTop += perTick;
      if (element.scrollTop === to) return;
      scrollToLinear(element, to, duration - 10);
  }), 10);
}

function scrollToQuadEase(element, to, duration) {
    let start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;

    let animateScroll = function(){
        currentTime += increment;
        let val = Math.easeInOutQuad(currentTime, start, change, duration);
        element.scrollTop = val;
        if(currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
	t /= d/2;
	if (t < 1) return c/2*t*t + b;
	t--;
	return -c/2 * (t*(t-2) - 1) + b;
};


export {_getTooltipText, _getTooltips, _findAncestor, _compareArrays, _isEquivalent, scrollToLinear, scrollToQuadEase}
