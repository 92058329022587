import React, {Component} from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'

class Details extends Component {

  render() {

    if (this.props.data && this.props.data.time_and_materials) {
      return (
        <BootstrapTable data={ this.props.data.time_and_materials }>
          <TableHeaderColumn
            dataField='name'
            columnTitle
            isKey={ true }
            className='col_name'
            columnClassName='col_name'
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='role'
            columnTitle
            className='col_role'
            columnClassName='col_role'
          >
            Level
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='hours'
            className='col_hours'
            columnClassName='col_hours'
          >
            Hours
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='rate'
            dataFormat={this._moneyFormatter}
            className='col_rate'
            columnClassName='col_rate'
          >
            Rate
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='expenses'
            className='col_expenses'
            columnClassName='col_expenses'
          >
            Expenses
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField='total'
            dataFormat={this._moneyFormatter}
            className='col_total'
            columnClassName='col_total'
          >
            Total
          </TableHeaderColumn>
        </BootstrapTable>);
    } else {
      return (<p>No Data</p>);
    }
  }

  _moneyFormatter(cell, row){
    return Number(cell).toLocaleString('en-US', {style: 'currency',currency: 'USD'})
  }
}

export default Details
