import React    from 'react'
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap'

class TextArea extends React.Component {

    constructor(props) {
        super(props)
        this.state = ({ value: this.props.schema.value?this.props.schema.value:"" })
        this.focus = this.focus.bind(this);
    }

    componentWillReceiveProps(nexProps) {
        if ( nexProps.schema.value !== this.props.schema.value || nexProps.schema.empty === true) {
            this.setState({ value: nexProps.schema.value?nexProps.schema.value:"" })
        }
    }

    render() {
        const schema = this.props.schema

        return (
          <FormGroup  className={this._changeClass(schema)} controlId={ schema.id? schema.id  :schema.name } >
            <ControlLabel>{ schema.label }</ControlLabel>
            <FormControl
              componentClass="textarea"
              name={ schema.name }
              placeholder={ schema.placeholder }
              ref={(input) => { this.input = input; }}
              disabled={ schema.disabled }
              readOnly={ schema.readOnly }
              value={this.state.value}
              onChange={ this._onChange.bind(this, schema) }
              />
          </FormGroup>
        )
    }

    focus() {
      this.input.focus();
    }

    getValue(){
      return this.state.value
    }

    _changeClass(schema){
      if(schema.disabled){
        return "field disabled"
      }



      return "field"
    }

    _onChange(schema, e) {
        this.setState({ value : e.target.value});

        if(schema.onChange){
          schema.onChange( e.target.value)
        }
    }

}

export default TextArea;
