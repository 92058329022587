import React, { Component } from 'react';
import "assets/css/table.css";
import { Button, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import cookie from 'react-cookies';
import "../../../assets/css/settings-levels.css";
class SettingsLevelsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      itemId: null,
      sort_name: '',
      sort_order: '',
    };
    this.renderButtons = this.renderButtons.bind(this);
  }

  componentWillMount() {
    this.props.settingsLevelsList();

    this.setState({
      sort_name: cookie.load('sort_column_settings_levels') || 'name',
      sort_order: cookie.load('sort_order_settings_levels') || 'asc',
    });
  }

  percentajeFormatter(cell, row) {
    return cell + "%"
  }

  boolFormatter(cell) {
    return cell ? 'Yes' : 'No';
  }

  onSortChange(sortName, sortOrder) {
    cookie.save('sort_column_settings_levels', sortName, { path: '/' });
    cookie.save('sort_order_settings_levels', sortOrder, { path: '/' });

    this.setState({
      sort_name: sortName,
      sort_order: sortOrder,
    });
  }

  renderButtons(cell, row) {
    return (
      <div className="actions">
        <Button bsStyle="success" onClick={this.props.settingsLevelsShowForm.bind(this,row.id)}><i className="fa fa-pencil" aria-hidden="true"></i></Button>
        <Button bsStyle="danger" onClick={this.openDeleteModal.bind(this,row.id)}><i className="fa fa-trash" aria-hidden="true"></i></Button>
      </div>
    );
  }

  sortNumberFunc(a, b, order, sortField) {
    if (order === 'asc') {
      return parseFloat(a[sortField]) - parseFloat(b[sortField])
    } else {
      return parseFloat(b[sortField]) - parseFloat(a[sortField]);
    }
  }

  closeDeleteModal() {
    this.setState({ showModal: false, itemId: null });
  }

  openDeleteModal(itemId) {
    this.setState({ showModal: true, itemId });
  }

  renderModal() {
    let name = '';
    if (this.state.itemId && this.props.settingsLevels.data.length > 0) {
      for (let i = 0; i < this.props.settingsLevels.data.length;i++) {
        if (this.props.settingsLevels.data[i].id === this.state.itemId) {
            name = this.props.settingsLevels.data[i].name;
        }
      }
    }

    return (
      <Modal show={this.state.showModal} onHide={this.closeDeleteModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete {name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this element?</p>
            <p>All the infomation related to this Level will be deleted.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.confirmDelete.bind(this, this.state.itemId)} bsStyle="danger">Delete</Button>
            <Button onClick={this.closeDeleteModal.bind(this)}>Close</Button>
          </Modal.Footer>
        </Modal>
    )
  }
  confirmDelete(itemId) {
    this.props.settingsLevelsDeleteItem(itemId);
    this.closeDeleteModal();
  }

  render() {
    const list = this.props.settingsLevels.data;

    const options = {
      defaultSortName: this.state.sort_name,
      defaultSortOrder: this.state.sort_order,
      onSortChange: this.onSortChange.bind(this),
    };

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={list}
          striped
          hover
          ref={(table) => { this.table = table; }}
          options={options}
          headerContainerClass="fixed-header"
        >
          <TableHeaderColumn
            isKey
            dataField="name"
            filter={{ type: 'TextFilter', delay: 500 }}
            dataSort
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="target"
            dataSort
            dataFormat={this.percentajeFormatter}
            sortFunc={this.sortNumberFunc}
          >
            Target
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="include_in_staffing" 
            dataSort
            dataFormat={this.boolFormatter}
            sortFunc={this.sortNumberFunc}
          >
            Include in Staffing
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="include_in_utilization"
            dataSort
            dataFormat={this.boolFormatter}
            sortFunc={this.sortNumberFunc}
          >
            Include in Utilization
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="include_in_consultant_location" 
            dataSort
            dataFormat={this.boolFormatter}
            sortFunc={this.sortNumberFunc}
          >
            Include in C. Location
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="include_in_consultant_networking" 
            dataSort
            dataFormat={this.boolFormatter}
            sortFunc={this.sortNumberFunc}
          >
            Include in C. Networking
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="include_in_consultant_skills" 
            dataSort
            dataFormat={this.boolFormatter}
            sortFunc={this.sortNumberFunc}
          >
            Include in C. Skills
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="include_in_mentorship"
            dataSort
            dataFormat={this.boolFormatter}
            sortFunc={this.sortNumberFunc}
          >
            Include in Mentorship
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="can_mentor"
            dataSort
            dataFormat={this.boolFormatter}
            sortFunc={this.sortNumberFunc}
          >
            Can Mentor
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="buttons"
            dataFormat={this.renderButtons}
            dataAlign="center"
          />
        </BootstrapTable>
        {this.renderModal()}
      </section>
    );
  }
}

export default SettingsLevelsList;
