import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';

export const PROJECTS_PENDING = 'PROJECTS_PENDING';
export const projectsPending = makeActionCreator(PROJECTS_PENDING);

export const PROJECTS_REJECTED = 'PROJECTS_REJECTED';
export const projectsRejected = makeActionCreator(PROJECTS_REJECTED);

export const PROJECTS_SHOW_LIST = 'PROJECTS_SHOW_LIST';
export const projectsShowList = makeActionCreator(PROJECTS_SHOW_LIST);

export const PROJECTS_HIDE_LIST = 'PROJECTS_HIDE_LIST';
export const projectsHideList = makeActionCreator(PROJECTS_HIDE_LIST);

export const PROJECTS_SHOW_FORM = 'PROJECTS_SHOW_FORM';
export const projectsShowForm = makeActionCreator(PROJECTS_SHOW_FORM, 'itemId');

export const PROJECTS_HIDE_FORM = 'PROJECTS_HIDE_FORM';
export const projectsHideForm = makeActionCreator(PROJECTS_HIDE_FORM);

export const PROJECTS_SHOW_ITEM = 'PROJECTS_SHOW_ITEM';
export const projectsShowItem = makeActionCreator(PROJECTS_SHOW_ITEM, 'itemId');


// Show Index

export const PROJECTS_LIST_FULFILLED = 'PROJECTS_LIST_FULFILLED';
export const projectsListFulfilled = makeActionCreator(PROJECTS_LIST_FULFILLED, 'data');

export const PROJECTS_LIST_ERROR = 'PROJECTS_LIST_ERROR';
export const projectsListError = makeActionCreator(PROJECTS_LIST_ERROR, 'message');

export function projectsList() {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(projectsPending());
      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}`, {
        method: endpoints.index.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then(json => dispatch(projectsListFulfilled(json.data)))
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(projectsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


//Show Item

export const PROJECTS_ITEM_FULFILLED = 'PROJECTS_ITEM_FULFILLED'
export const projectsItemFulfilled = makeActionCreator(PROJECTS_ITEM_FULFILLED, 'data')

export const PROJECTS_ITEM_ERROR = 'PROJECTS_ITEM_ERROR'
export const projectsItemError = makeActionCreator(PROJECTS_ITEM_ERROR, 'message')

export function projectsItem(itemId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(projectsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getItem.version}${endpoints.getItem.path.replace(":projects_id", itemId)}`, {
              method  : endpoints.getItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(projectsItemFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(projectsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}
