//
// Login:
//

export const index = {
    path   : '/invoices/',
    method : 'GET',
    version: '/v1'
}

// export const getItem = {
//   path   : '/business_developments/:business_development_id/',
//   method : 'GET',
//   version: '/v1'
// }
//
// export const addItem = {
//   path   : '/business_developments/',
//   method : 'POST',
//   version: '/v1'
// }
//
// export const updateItem = {
//   path   : '/business_developments/:business_development_id/',
//   method : 'POST',
//   version: '/v1'
// }
//
// export const deleteItem = {
//   path   : '/business_developments/:business_development_id/',
//   method : 'DELETE',
//   version: '/v1'
// }
