import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';

export const PMO_RISK_MANAGEMENT_PENDING = 'PMO_RISK_MANAGEMENT_PENDING';
export const pmoRiskManagementPending = makeActionCreator(PMO_RISK_MANAGEMENT_PENDING);

export const PMO_RISK_MANAGEMENT_REJECTED = 'PMO_RISK_MANAGEMENT_REJECTED';
export const pmoRiskManagementRejected = makeActionCreator(PMO_RISK_MANAGEMENT_REJECTED);

export const PMO_RISK_MANAGEMENT_SHOW_LIST = 'PMO_RISK_MANAGEMENT_SHOW_LIST';
export const pmoRiskManagementShowList = makeActionCreator(PMO_RISK_MANAGEMENT_SHOW_LIST);

export const PMO_RISK_MANAGEMENT_HIDE_LIST = 'PMO_RISK_MANAGEMENT_HIDE_LIST';
export const pmoRiskManagementHideList = makeActionCreator(PMO_RISK_MANAGEMENT_HIDE_LIST);

export const PMO_RISK_MANAGEMENT_SHOW_FORM = 'PMO_RISK_MANAGEMENT_SHOW_FORM';
export const pmoRiskManagementShowForm = makeActionCreator(PMO_RISK_MANAGEMENT_SHOW_FORM, 'itemId');

export const PMO_RISK_MANAGEMENT_HIDE_FORM = 'PMO_RISK_MANAGEMENT_HIDE_FORM';
export const pmoRiskManagementHideForm = makeActionCreator(PMO_RISK_MANAGEMENT_HIDE_FORM);

export const PMO_RISK_MANAGEMENT_SHOW_ITEM = 'PMO_RISK_MANAGEMENT_SHOW_ITEM';
export const pmoRiskManagementShowItem = makeActionCreator(PMO_RISK_MANAGEMENT_SHOW_ITEM, 'itemId');


// Show Index

export const PMO_RISK_MANAGEMENT_LIST_FULFILLED = 'PMO_RISK_MANAGEMENT_LIST_FULFILLED';
export const pmoRiskManagementListFulfilled = makeActionCreator(PMO_RISK_MANAGEMENT_LIST_FULFILLED, 'data');

export const PMO_RISK_MANAGEMENT_LIST_ERROR = 'PMO_RISK_MANAGEMENT_LIST_ERROR';
export const pmoRiskManagementListError = makeActionCreator(PMO_RISK_MANAGEMENT_LIST_ERROR, 'message');

export function pmoRiskManagementList(projectId, status = 9999) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoRiskManagementPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.riskIndex.version}${endpoints.riskIndex.path.replace(':pmo_project_id', projectId)}?is_active=${status}`, {
        method: endpoints.riskIndex.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoRiskManagementListFulfilled(json.data));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoRiskManagementRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Show Item

export const PMO_RISK_MANAGEMENT_ITEM_FULFILLED = 'PMO_RISK_MANAGEMENT_ITEM_FULFILLED';
export const pmoRiskManagementItemFulfilled = makeActionCreator(PMO_RISK_MANAGEMENT_ITEM_FULFILLED, 'data');

export const PMO_RISK_MANAGEMENT_ITEM_ERROR = 'PMO_RISK_MANAGEMENT_ITEM_ERROR';
export const pmoRiskManagementItemError = makeActionCreator(PMO_RISK_MANAGEMENT_ITEM_ERROR, 'message');

export function pmoRiskManagementItem(projectId, itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoRiskManagementPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getRisk.version}${endpoints.getRisk.path.replace(':pmo_project_id', projectId).replace(':risk_id', itemId)}`, {
        method: endpoints.getRisk.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoRiskManagementItemFulfilled(json.data));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoRiskManagementRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Add Item
export const PMO_RISK_MANAGEMENT_ITEM_ADD_FULFILLED = 'PMO_RISK_MANAGEMENT_ITEM_ADD_FULFILLED';
export const pmoRiskManagementItemAddFulfilled = makeActionCreator(PMO_RISK_MANAGEMENT_ITEM_ADD_FULFILLED, 'data');

export const PMO_RISK_MANAGEMENT_ITEM_ADD_ERROR = 'PMO_RISK_MANAGEMENT_ITEM_ADD_ERROR';
export const pmoRiskManagementItemAddError = makeActionCreator(PMO_RISK_MANAGEMENT_ITEM_ADD_ERROR, 'message');

export function pmoRiskManagementAddItem(projectId, data, status) {
  const formData = new FormData();

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoRiskManagementPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addRisk.version}${endpoints.addRisk.path.replace(':pmo_project_id', projectId)}`, {
        method: endpoints.addRisk.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoRiskManagementItemAddFulfilled(json.data));
            dispatch(pmoRiskManagementList(projectId, status));
          } else {
            dispatch(pmoRiskManagementItemAddError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoRiskManagementRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Update Item

export const PMO_RISK_MANAGEMENT_ITEM_UPDATE_FULFILLED = 'PMO_RISK_MANAGEMENT_ITEM_UPDATE_FULFILLED';
export const pmoRiskManagementItemUpdateFulfilled = makeActionCreator(PMO_RISK_MANAGEMENT_ITEM_UPDATE_FULFILLED, 'data');

export const PMO_RISK_MANAGEMENT_ITEM_UPDATE_ERROR = 'PMO_RISK_MANAGEMENT_ITEM_UPDATE_ERROR';
export const pmoRiskManagementItemUpdateError = makeActionCreator(PMO_RISK_MANAGEMENT_ITEM_UPDATE_ERROR, 'message');

export function pmoRiskManagementUpdateItem(projectId, itemId, data, status) {
  const formData = new FormData();
  formData.append('_method', 'PUT');

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoRiskManagementPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateRisk.version}${endpoints.updateRisk.path.replace(':pmo_project_id', projectId).replace(':risk_id', itemId)}`, {
        method: endpoints.updateRisk.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoRiskManagementItemUpdateFulfilled(json.data));
            dispatch(pmoRiskManagementList(projectId, status));
          } else {
            dispatch(pmoRiskManagementItemUpdateError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoRiskManagementRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Delete Item

export const PMO_RISK_MANAGEMENT_ITEM_DELETE_FULFILLED = 'PMO_RISK_MANAGEMENT_ITEM_DELETE_FULFILLED';
export const pmoRiskManagementItemDeleteFulfilled = makeActionCreator(PMO_RISK_MANAGEMENT_ITEM_DELETE_FULFILLED, 'data');

export const PMO_RISK_MANAGEMENT_ITEM_DELETE_ERROR = 'PMO_RISK_MANAGEMENT_ITEM_DELETE_ERROR';
export const pmoRiskManagementItemDeleteError = makeActionCreator(PMO_RISK_MANAGEMENT_ITEM_DELETE_ERROR, 'message');

export function pmoRiskManagementDeleteItem(projectId, itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoRiskManagementPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteRisk.version}${endpoints.deleteRisk.path.replace(':pmo_project_id', projectId).replace(':risk_id', itemId)}`, {
        method: endpoints.deleteRisk.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoRiskManagementItemDeleteFulfilled(json.data));
            dispatch(pmoRiskManagementList(projectId));
          } else {
            dispatch(pmoRiskManagementItemDeleteError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoRiskManagementRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
