const initialState = {
    filterBy        : [''],
    inProgress      : false,
    error           : false,
    errorText       : null,
    data            : [],
    showList        : true,
    showForm        : false,
    showItem        : null,
    addSuccess      : false,
    itemId          : null,
    itemData        : null,
    editSuccess     : false
}

function BusinessDevelopmentReducer(state = initialState, action) {
  const action_name = "BUSINESS_DEVELOPMENT"
    switch (action.type) {
      case `${action_name}_PENDING`:
          return {
              ...state,
              inProgress : true,
              error      : false,
              errorText  : null,
              addSuccess : false,
              editSuccess: false
          }
      case `${action_name}_LIST_FULFILLED`:
        return {
          ...state,
          inProgress: false,
          error: false,
          data: action.data
        }
      case `${action_name}_REJECTED`:
        return {
          ...state,
          inProgress: false,
          error: action.error
        }
      case `${action_name}_LIST_FILTER_BY`:
        let filters = state.filterBy
        filters[action.index] = action.value
        return {
          ...state,
          filterBy: filters
        }
      case `${action_name}_SHOW_LIST`:
        return {
          ...state,
          showList: true,
          showForm: false,
          itemId: null,
          itemData: null
        }
      case `${action_name}_HIDE_LIST`:
        return {
          ...state,
          showList: false
        }
      case `${action_name}_SHOW_FORM`:
        return {
          ...state,
          showForm: true,
          showList: false,
          itemId: action.itemId !== "newItem"?action.itemId:""
        }
      case `${action_name}_HIDE_FORM`:
        return {
          ...state,
          showForm: false
        }
      case `${action_name}_SHOW_ITEM`:
        return {
          ...state,
          showItem: action.itemId
        }
      case `${action_name}_ITEM_ADD_FULFILLED`:
        return {
          ...state,
          inProgress: false,
          addSuccess: true
        }
      case `${action_name}_ITEM_ADD_ERROR`:
        return {
          ...state,
          inProgress: false,
          error: true,
          errorText: action.message
        }
      case `${action_name}_ITEM_UPDATE_FULFILLED`:
        return {
          ...state,
          inProgress: false,
          editSuccess: true
        }
      case `${action_name}_ITEM_UPDATE_ERROR`:
        return {
          ...state,
          inProgress: false,
          error: true,
          errorText: action.message
        }
      case `${action_name}_ITEM_FULFILLED`:
        return {
          ...state,
          inProgress: false,
          error: false,
          itemData: action.data
        }
      default:
        return state
    }
}

export default BusinessDevelopmentReducer
