import React, { Component } from 'react';
import TextTruncate from 'react-text-truncate';

class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lineNum: 3,
      shown: false,
    };
  }

  textExpandedHandler = () => {
    if (this.state.shown === false) {
      this.setState({
        lineNum: 7,
        shown: true,
      });
    } else {
      this.setState({
        lineNum: 3,
        shown: false,
      });
    }
  };

  render() {
    return (
      <div
        className={`Announcement ${this.props.highlight ? 'highlight' : ''}`}
        onClick={() => this.textExpandedHandler()}
        style={{
	        display: this.props.isPublic ? 'block' : 'none',
	      }}
        role="button"
      >
        <div className="date">
          {this.props.date}
        </div>
        <TextTruncate
          line={this.state.lineNum}
          truncateText="..."
          text={this.props.content}
          containerClassName="content"
        />
      </div>
    );
  }
}
export default Announcement;
