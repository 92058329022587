import makeActionCreator from '../../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API       from '../../utils/config-api'
import * as endpoints from './endpoints'
import { logout } from '../../auth/list';

export const SETTINGS_RECRUITING_SOURCES_PENDING = 'SETTINGS_RECRUITING_SOURCES_PENDING'
export const settingsRecruitingSourcesPending = makeActionCreator(SETTINGS_RECRUITING_SOURCES_PENDING)

export const SETTINGS_RECRUITING_SOURCES_REJECTED = 'SETTINGS_RECRUITING_SOURCES_REJECTED'
export const settingsRecruitingSourcesRejected = makeActionCreator(SETTINGS_RECRUITING_SOURCES_REJECTED)

export const SETTINGS_RECRUITING_SOURCES_SHOW_LIST= 'SETTINGS_RECRUITING_SOURCES_SHOW_LIST'
export const settingsRecruitingSourcesShowList = makeActionCreator(SETTINGS_RECRUITING_SOURCES_SHOW_LIST)

export const SETTINGS_RECRUITING_SOURCES_HIDE_LIST = 'SETTINGS_RECRUITING_SOURCES_HIDE_LIST'
export const settingsRecruitingSourcesHideList = makeActionCreator(SETTINGS_RECRUITING_SOURCES_HIDE_LIST)

export const SETTINGS_RECRUITING_SOURCES_SHOW_FORM= 'SETTINGS_RECRUITING_SOURCES_SHOW_FORM'
export const settingsRecruitingSourcesShowForm = makeActionCreator(SETTINGS_RECRUITING_SOURCES_SHOW_FORM, 'itemId')

export const SETTINGS_RECRUITING_SOURCES_HIDE_FORM = 'SETTINGS_RECRUITING_SOURCES_HIDE_FORM'
export const settingsRecruitingSourcesHideForm = makeActionCreator(SETTINGS_RECRUITING_SOURCES_HIDE_FORM)

export const SETTINGS_RECRUITING_SOURCES_SHOW_ITEM = 'SETTINGS_RECRUITING_SOURCES_SHOW_ITEM'
export const settingsRecruitingSourcesShowItem = makeActionCreator(SETTINGS_RECRUITING_SOURCES_SHOW_ITEM, 'itemId')


//Show Index

export const SETTINGS_RECRUITING_SOURCES_LIST_FULFILLED = 'SETTINGS_RECRUITING_SOURCES_LIST_FULFILLED'
export const settingsRecruitingSourcesListFulfilled = makeActionCreator(SETTINGS_RECRUITING_SOURCES_LIST_FULFILLED, 'data')

export const SETTINGS_RECRUITING_SOURCES_LIST_ERROR = 'SETTINGS_RECRUITING_SOURCES_LIST_ERROR'
export const settingsRecruitingSourcesListError = makeActionCreator(SETTINGS_RECRUITING_SOURCES_LIST_ERROR, 'message')

export function settingsRecruitingSourcesList() {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsRecruitingSourcesPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}`, {
              method  : endpoints.index.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(settingsRecruitingSourcesListFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsRecruitingSourcesRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}


//Show Item

export const SETTINGS_RECRUITING_SOURCES_ITEM_FULFILLED = 'SETTINGS_RECRUITING_SOURCES_ITEM_FULFILLED'
export const settingsRecruitingSourcesItemFulfilled = makeActionCreator(SETTINGS_RECRUITING_SOURCES_ITEM_FULFILLED, 'data')

export const SETTINGS_RECRUITING_SOURCES_ITEM_ERROR = 'SETTINGS_RECRUITING_SOURCES_ITEM_ERROR'
export const settingsRecruitingSourcesItemError = makeActionCreator(SETTINGS_RECRUITING_SOURCES_ITEM_ERROR, 'message')

export function settingsRecruitingSourcesItem(itemId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsRecruitingSourcesPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getItem.version}${endpoints.getItem.path.replace(":recruit_sources_id", itemId)}`, {
              method  : endpoints.getItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(settingsRecruitingSourcesItemFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsRecruitingSourcesRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));
    })



  }
}


//Add Item

export const SETTINGS_RECRUITING_SOURCES_ITEM_ADD_FULFILLED = 'SETTINGS_RECRUITING_SOURCES_ITEM_ADD_FULFILLED'
export const settingsRecruitingSourcesItemAddFulfilled = makeActionCreator(SETTINGS_RECRUITING_SOURCES_ITEM_ADD_FULFILLED, 'data')

export const SETTINGS_RECRUITING_SOURCES_ITEM_ADD_ERROR = 'SETTINGS_RECRUITING_SOURCES_ITEM_ADD_ERROR'
export const settingsRecruitingSourcesItemAddError = makeActionCreator(SETTINGS_RECRUITING_SOURCES_ITEM_ADD_ERROR, 'message')

export function settingsRecruitingSourcesAddItem(data) {
  let formData = new FormData()

  Object.keys(data).map((key)=> {
    formData.append(key, data[key]);
    return key;
  });

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsRecruitingSourcesPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addItem.version}${endpoints.addItem.path}`, {
              method  : endpoints.addItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(settingsRecruitingSourcesItemAddFulfilled(json.data))
              }else{
                dispatch(settingsRecruitingSourcesItemAddError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsRecruitingSourcesRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}

//Update Item

export const SETTINGS_RECRUITING_SOURCES_ITEM_UPDATE_FULFILLED = 'SETTINGS_RECRUITING_SOURCES_ITEM_UPDATE_FULFILLED'
export const settingsRecruitingSourcesItemUpdateFulfilled = makeActionCreator(SETTINGS_RECRUITING_SOURCES_ITEM_UPDATE_FULFILLED, 'data')

export const SETTINGS_RECRUITING_SOURCES_ITEM_UPDATE_ERROR = 'SETTINGS_RECRUITING_SOURCES_ITEM_UPDATE_ERROR'
export const settingsRecruitingSourcesItemUpdateError = makeActionCreator(SETTINGS_RECRUITING_SOURCES_ITEM_UPDATE_ERROR, 'message')

export function settingsRecruitingSourcesUpdateItem(itemId, data) {
  let formData = new FormData()
  formData.append('_method', 'PUT')

  Object.keys(data).map((key)=> {
    formData.append(key, data[key]);
    return key;
  });


  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsRecruitingSourcesPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateItem.version}${endpoints.updateItem.path.replace(":recruit_sources_id", itemId)}`, {
              method  : endpoints.updateItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(settingsRecruitingSourcesItemUpdateFulfilled(json.data))
              }else{
                dispatch(settingsRecruitingSourcesItemUpdateError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsRecruitingSourcesRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}

//Delete Item

export const SETTINGS_RECRUITING_SOURCES_ITEM_DELETE_FULFILLED = 'SETTINGS_RECRUITING_SOURCES_ITEM_DELETE_FULFILLED'
export const settingsRecruitingSourcesItemDeleteFulfilled = makeActionCreator(SETTINGS_RECRUITING_SOURCES_ITEM_DELETE_FULFILLED, 'data')

export const SETTINGS_RECRUITING_SOURCES_ITEM_DELETE_ERROR = 'SETTINGS_RECRUITING_SOURCES_ITEM_DELETE_ERROR'
export const settingsRecruitingSourcesItemDeleteError = makeActionCreator(SETTINGS_RECRUITING_SOURCES_ITEM_DELETE_ERROR, 'message')

export function settingsRecruitingSourcesDeleteItem(itemId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsRecruitingSourcesPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteItem.version}${endpoints.deleteItem.path.replace(":recruit_sources_id", itemId)}`, {
              method  : endpoints.deleteItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
            if(json.status === "ok"){
              dispatch(settingsRecruitingSourcesItemDeleteFulfilled(json.data))
              dispatch(settingsRecruitingSourcesList())
            }else{
              dispatch(settingsRecruitingSourcesItemDeleteError(json.message))
            }

          })
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsRecruitingSourcesRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })





  }
}
