import makeActionCreator from '../../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API       from '../../utils/config-api'
import * as endpoints from './endpoints'
import { logout } from '../../auth/list';

export const PROFIT_SHARING_VALUES_PENDING = 'PROFIT_SHARING_VALUES_PENDING'
export const settingProfitSharingValuesPending = makeActionCreator(PROFIT_SHARING_VALUES_PENDING)

export const PROFIT_SHARING_VALUES_REJECTED = 'PROFIT_SHARING_VALUES_REJECTED'
export const settingProfitSharingValuesRejected = makeActionCreator(PROFIT_SHARING_VALUES_REJECTED)

export const PROFIT_SHARING_VALUES_SHOW_LIST= 'PROFIT_SHARING_VALUES_SHOW_LIST'
export const settingProfitSharingValuesShowList = makeActionCreator(PROFIT_SHARING_VALUES_SHOW_LIST)

export const PROFIT_SHARING_VALUES_HIDE_LIST = 'PROFIT_SHARING_VALUES_HIDE_LIST'
export const settingProfitSharingValuesHideList = makeActionCreator(PROFIT_SHARING_VALUES_HIDE_LIST)

export const PROFIT_SHARING_VALUES_SHOW_FORM= 'PROFIT_SHARING_VALUES_SHOW_FORM'
export const settingProfitSharingValuesShowForm = makeActionCreator(PROFIT_SHARING_VALUES_SHOW_FORM, 'itemId')

export const PROFIT_SHARING_VALUES_HIDE_FORM = 'PROFIT_SHARING_VALUES_HIDE_FORM'
export const settingProfitSharingValuesHideForm = makeActionCreator(PROFIT_SHARING_VALUES_HIDE_FORM)

export const PROFIT_SHARING_VALUES_SHOW_ITEM = 'PROFIT_SHARING_VALUES_SHOW_ITEM'
export const settingProfitSharingValuesShowItem = makeActionCreator(PROFIT_SHARING_VALUES_SHOW_ITEM, 'itemId')


//Show Index

export const PROFIT_SHARING_VALUES_LIST_FULFILLED = 'PROFIT_SHARING_VALUES_LIST_FULFILLED'
export const settingProfitSharingValuesListFulfilled = makeActionCreator(PROFIT_SHARING_VALUES_LIST_FULFILLED, 'data')

export const PROFIT_SHARING_VALUES_LIST_ERROR = 'PROFIT_SHARING_VALUES_LIST_ERROR'
export const settingProfitSharingValuesListError = makeActionCreator(PROFIT_SHARING_VALUES_LIST_ERROR, 'message')

export function settingProfitSharingValuesList(year) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingProfitSharingValuesPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}?year=${year}`, {
              method  : endpoints.index.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(settingProfitSharingValuesListFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingProfitSharingValuesRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}


//Show Item

export const PROFIT_SHARING_VALUES_ITEM_FULFILLED = 'PROFIT_SHARING_VALUES_ITEM_FULFILLED'
export const settingProfitSharingValuesItemFulfilled = makeActionCreator(PROFIT_SHARING_VALUES_ITEM_FULFILLED, 'data')

export const PROFIT_SHARING_VALUES_ITEM_ERROR = 'PROFIT_SHARING_VALUES_ITEM_ERROR'
export const settingProfitSharingValuesItemError = makeActionCreator(PROFIT_SHARING_VALUES_ITEM_ERROR, 'message')

export function settingProfitSharingValuesItem(profitValueId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingProfitSharingValuesPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getItem.version}${endpoints.getItem.path.replace(":profit_sharing_id", profitValueId)}`, {
              method  : endpoints.getItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(settingProfitSharingValuesItemFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingProfitSharingValuesRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}


//Add Item

export const PROFIT_SHARING_VALUES_ITEM_ADD_FULFILLED = 'PROFIT_SHARING_VALUES_ITEM_ADD_FULFILLED'
export const settingProfitSharingValuesItemAddFulfilled = makeActionCreator(PROFIT_SHARING_VALUES_ITEM_ADD_FULFILLED, 'data')

export const PROFIT_SHARING_VALUES_ITEM_ADD_ERROR = 'PROFIT_SHARING_VALUES_ITEM_ADD_ERROR'
export const settingProfitSharingValuesItemAddError = makeActionCreator(PROFIT_SHARING_VALUES_ITEM_ADD_ERROR, 'message')

export function settingProfitSharingValuesAddItem(data) {
  let formData = new FormData()

  formData.append("date", data["date"]);
  formData.append("value", data["value"]);
  formData.append("is_public", data["is_public"]);

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingProfitSharingValuesPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addItem.version}${endpoints.addItem.path}`, {
              method  : endpoints.addItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(settingProfitSharingValuesItemAddFulfilled(json.data))
              }else{
                dispatch(settingProfitSharingValuesItemAddError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingProfitSharingValuesRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}

//Update Item

export const PROFIT_SHARING_VALUES_ITEM_UPDATE_FULFILLED = 'PROFIT_SHARING_VALUES_ITEM_UPDATE_FULFILLED'
export const settingProfitSharingValuesItemUpdateFulfilled = makeActionCreator(PROFIT_SHARING_VALUES_ITEM_UPDATE_FULFILLED, 'data')

export const PROFIT_SHARING_VALUES_ITEM_UPDATE_ERROR = 'PROFIT_SHARING_VALUES_ITEM_UPDATE_ERROR'
export const settingProfitSharingValuesItemUpdateError = makeActionCreator(PROFIT_SHARING_VALUES_ITEM_UPDATE_ERROR, 'message')

export function settingProfitSharingValuesUpdateItem(itemId, data) {
  let formData = new FormData()

  formData.append('_method', 'PUT')
  formData.append("date", data["date"]);
  formData.append("value", data["value"]);
  formData.append("is_public", data["is_public"]);


  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingProfitSharingValuesPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateItem.version}${endpoints.updateItem.path.replace(":profit_sharing_id", itemId)}`, {
              method  : endpoints.updateItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(settingProfitSharingValuesItemUpdateFulfilled(json.data))
              }else{
                dispatch(settingProfitSharingValuesItemUpdateError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingProfitSharingValuesRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}

//Delete Item

export const PROFIT_SHARING_VALUES_ITEM_DELETE_FULFILLED = 'PROFIT_SHARING_VALUES_ITEM_DELETE_FULFILLED'
export const settingProfitSharingValuesItemDeleteFulfilled = makeActionCreator(PROFIT_SHARING_VALUES_ITEM_DELETE_FULFILLED, 'data')

export const PROFIT_SHARING_VALUES_ITEM_DELETE_ERROR = 'PROFIT_SHARING_VALUES_ITEM_DELETE_ERROR'
export const settingProfitSharingValuesItemDeleteError = makeActionCreator(PROFIT_SHARING_VALUES_ITEM_DELETE_ERROR, 'message')

export function settingProfitSharingValuesDeleteItem(profitValueId, year) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingProfitSharingValuesPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteItem.version}${endpoints.deleteItem.path.replace(":profit_sharing_id", profitValueId)}`, {
              method  : endpoints.deleteItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
            if(json.status === "ok"){
              dispatch(settingProfitSharingValuesItemDeleteFulfilled(json.data))
              dispatch(settingProfitSharingValuesList(year))
            }else{
              dispatch(settingProfitSharingValuesItemDeleteError(json.message))
            }

          })
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingProfitSharingValuesRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })





  }
}
