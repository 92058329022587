import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';
import _ from 'lodash';
import Datetime from 'react-datetime';
import '../../assets/css/staffing.css';
import 'assets/react-datetime/react-datetime.css';

class StaffingForm extends Component {
  constructor(props) {
    super(props);

    const week1 = moment().startOf('isoWeek');
    const week2 = moment(week1).add(1, 'week');
    const week3 = moment(week2).add(1, 'week');
    const week4 = moment(week3).add(1, 'week');
    const week5 = moment(week4).add(1, 'week');
    const week6 = moment(week5).add(1, 'week');
    const week7 = moment(week6).add(1, 'week');
    const week8 = moment(week7).add(1, 'week');

    this.state = {
      projectsReported: [],
      showModal: false,
      week1,
      week2,
      week3,
      week4,
      week5,
      week6,
      week7,
      week8,
      canEditUsersStaffing: !!!this.props.permissions.limit_to_own_user,
      currentUser: null,
    };
    this.fields = [];
    this.forecastColumnClassName = this.forecastColumnClassName.bind(this);
    this.forecastColumnClassNameEdit = this.forecastColumnClassNameEdit.bind(this);
    this.validDay = this.validDay.bind(this);
    this.createDateEditor = this.createDateEditor.bind(this);
    this.createUtilizationEditor = this.createUtilizationEditor.bind(this);
    this.onAfterSaveCell = this.onAfterSaveCell.bind(this);
  }

  componentWillMount() {
    let currentUser = this.props.currentUserData

    if (this.state.canEditUsersStaffing) {
      for (let i = 0; i < this.props.user.data.length; i++) {
        if (this.props.user.data[i].id === parseInt(this.props.userId, 10)) {
          currentUser = this.props.user.data[i];
        }
      }
    }

    this.props.projectsList();
    let projectsReported = [];

    if (this.props.staffing.data.length > 0) {
      for (let i = 0; i < this.props.staffing.data.length; i++) {

        if (
          this.props.staffing.data[i].user &&
          this.props.user && this.props.staffing.data[i].user.id === currentUser.id
        ) {
          for (let a = 0; a < this.props.staffing.data[i].projects.length; a++) {
            if (this.props.staffing.data[i].projects[a].project) {
              projectsReported.push(this.props.staffing.data[i].projects[a].project.id);
            }
          }
        }
      }
    }

    this.setState({ projectsReported, currentUser });

    this.utilizationValues = [
      { text: '0%', value: 0 },
      { text: '10%', value: 10 },
      { text: '20%', value: 20 },
      { text: '30%', value: 30 },
      { text: '40%', value: 40 },
      { text: '50%', value: 50 },
      { text: '60%', value: 60 },
      { text: '70%', value: 70 },
      { text: '80%', value: 80 },
      { text: '90%', value: 90 },
      { text: '100%', value: 100 },
    ];
  }

  componentWillReceiveProps(nextProps) {
    // Recompute ProjectsReported
    const projectsReported = [];
    if (!_.isEqual(this.props.staffing.data, nextProps.staffing.data)) {
      for (let i = 0; i < nextProps.staffing.data.length; i++) {
        if (
          nextProps.staffing.data[i].user && nextProps.user &&
          nextProps.staffing.data[i].user.id === this.state.currentUser.id
        ) {
          if (nextProps.staffing.data[i].projects) {
            for (let a = 0; a < nextProps.staffing.data[i].projects.length; a++) {
              if (nextProps.staffing.data[i].projects[a].project) {
                projectsReported.push(nextProps.staffing.data[i].projects[a].project.id);
              }
            }
          }
        }
      }

      this.setState({ projectsReported });
    }

    // UseId Changed
    if (this.props.userId !== nextProps.userId) {
      let currentUser = nextProps.currentUserData;
      if (this.table) {
        this.table.reset();
      }

      if (this.state.canEditUsersStaffing) {
        for (let i = 0; i < nextProps.user.data.length; i++) {
          if (nextProps.user.data[i].id === parseInt(nextProps.userId, 10)) {
            currentUser = nextProps.user.data[i];
          }
        }
      }

      this.setState({ currentUser, projectsReported: [] });
      this.props.staffingList();
    }

    if (
      (
        nextProps.staffing.addSuccess &&
        nextProps.staffing.addSuccess !== this.props.staffing.addSuccess
      ) ||
      (
        nextProps.staffing.editSuccess &&
        nextProps.staffing.editSuccess !== this.props.staffing.editSuccess
      )
    ) {
      // this.props.staffingShowList()
      const { avFilterWithTargetReached } = this.props;
      this.props.staffingGetIndexAvailability(avFilterWithTargetReached);
      this.props.staffingList();
    }
  }

  forecastColumnClassName(fieldValue, row, rowIdx, colIdx) {
    let className = 'col_availability_edit';
    if (!fieldValue && fieldValue !== 0) {
      className += ' empty';
    }

    return className;
  }

  forecastColumnClassNameEdit(fieldValue, row, rowIdx, colIdx) {
    let className = 'col_availability_edit';
    if (!fieldValue && fieldValue !== 0) {
      className += ' empty';
    }

    return className;
  }

  RepeatUntilColumnClassName(fieldValue, row, rowIdx, colIdx) {
    let className = 'dateParent';
    if (fieldValue === 'N/A') {
      className += ' empty';
    }

    return className;
  }

  RepeatUntilColumnClassNameEdit(fieldValue, row, rowIdx, colIdx) {
    let className = 'dateParent';
    if (fieldValue === 'N/A') {
      className += ' empty';
    }

    return className;
  }

  validDay(currentDate, selectedDate) {
    return moment(currentDate).isoWeekday() === 1 && moment(selectedDate).isValid() && currentDate.isSameOrAfter(moment().day(57), 'day');
  }

  createDateEditor(onUpdate, props) {
    if (props.defaultValue === 'N/A') {
      return (
        <div
          style={{
            textAlign: 'center',
          }}
        >
          N/A
        </div>
      );
    }

    let defaultDate = '';
    if (
      props.defaultValue !== 'NONE' &&
      props.defaultValue !== null
    ) {
      defaultDate = props.defaultValue;
    }

    return (
      <span>
        <Datetime
          dateFormat="YYYY-MM-DD"
          timeFormat={false}
          inputProps={{
            id: 'date',
            name: 'date',
            required: true,
            autoComplete: 'off',
            placeholder: 'Select a date',
            autoFocus: true,
            readOnly: false,
            style: { backgroundColor: '#FFF' },
          }}
          isValidDate={this.validDay}
          name="date"
          closeOnSelect
          viewMode="days"
          defaultValue={defaultDate}
          // onChange={(date) => {}}
          onBlur={(date) => {
            if (moment.isMoment(date)) {
              onUpdate(date.format('YYYY-MM-DD'));
            } else {
              onUpdate('');
            }
          }}
        />
      </span>
    );
  }

  createUtilizationEditor(onUpdate, props) {
    let hasSelect = true;

    if (props.defaultValue === '') {
      hasSelect = false;
    }

    if (hasSelect) {
      const options = this.utilizationValues.map((option, i) => (
        <option
          key={`option${i}`}
          value={option.value}
        >
          {option.text}
        </option>
      ));

      return (
        <select className="form-control editor edit-select" {...props}>
          {options}
        </select>
      )
    }

    return (
      <div
        style={{
          textAlign: 'center',
        }}
      >
        N/A
      </div>
    );
  }

  createCustomModal(onModalClose, onSave, columns, validateState, ignoreEditable) {
    return (
      <div className="modal-content react-bs-table-insert-modal">
        <form ref={(form) => { this.form = form; }} onSubmit={this.handleSave.bind(this, columns, onSave) }>
          <div className="modal-header react-bs-table-inser-modal-header">
            <span>
              <button type="button" className="close" onClick={ (e)=>{e.preventDefault(); onModalClose()} }>
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
              <h4 className="modal-title">Add Project Billability</h4>
            </span>
          </div>

          <div className="modal-body">
            <div className="copy-billability">
              <button className="btn btn-info btn-sm " onClick={this.copyBilliability.bind(this, columns)}>Copy Billability</button>
            </div>

            {
              columns.map((column, i) => {

                const {
                  editable,
                  format,
                  field,
                  name,
                  hiddenOnInsert
                } = column;

                if (hiddenOnInsert) {
                  return null;
                }

                if (editable.type === 'select') {
                  const error = validateState[field]
                  ?
                    (<span className='help-block bg-danger'>{ validateState[field] }</span>)
                  :
                    null;

                  let text;
                  let value;
                  const { textKey, valueKey } = editable.options;

                  return (
                    <div className='form-group' key={ field }>
                      <label>{ name } : </label>
                        <select placeholder={ name } name={ field } className=" form-control editor edit-select">
                          {editable.type === "select" && editable.options && editable.options.values?
                            editable.options.values.map((option, i)=>{
                              if (typeof option === 'object') {
                                text = textKey ? option[textKey] : option.text;
                                value = valueKey ? option[valueKey] : option.value;
                              } else {
                                text = format ? format(option) : option;
                                value = option;
                              }

                              return (
                                <option key={ 'option' + i } value={ value }>{ text }</option>
                              )
                            }): ''}
                        </select>
                      { error }
                    </div>
                  );
                }

                if (field.indexOf('projectUtilizationWeek') > -1) {
                  const error = validateState[field]
                    ?
                    (<span className='help-block bg-danger'>{ validateState[field] }</span>)
                    :
                    null;

                  let text;
                  let value;

                  return (
                    <div className='form-group' key={ field }>
                      <label>{ name } : </label>
                        <select placeholder={ name } name={ field } className=" form-control editor edit-select">
                          {
                            this.utilizationValues.map((option, index) => {
                              ({ text } = option);
                              ({ value } = option);
                              return (
                                <option key={ 'option' + index } value={ value }>{ text }</option>
                              );
                            })
                          }
                        </select>
                      { error }
                    </div>
                  );
                }

                if (field === 'projectUtilizationRepeatUntil') {
                  const error = validateState[field]
                  ?
                    (<span className='help-block bg-danger'>{ validateState[field] }</span>)
                  :
                    null;
                  return (
                    <div className="form-group" key={field}>
                      <label>{name}: </label>
                      <br />
                      <Datetime
                        dateFormat="YYYY-MM-DD"
                        timeFormat={false}
                        inputProps={{ id: field, name: field, required: true, autoComplete: 'off', readOnly: true, placeholder: 'Select a date', style:{ backgroundColor: '#FFF' }}}
                        isValidDate={this.validDay}
                        name={field}
                        closeOnSelect
                        viewMode="days"
                      />
                      { error }
                    </div>
                  );
                }

                const error = validateState[field]
                ?
                  (<span className='help-block bg-danger'>{ validateState[field] }</span>)
                :
                  null;
                return (
                  <div className='form-group' key={ field }>
                    <label>{ name } : </label><br/>
                    <input type="text" name={ field }  />
                    { error }
                  </div>
                );
              })
            }

          </div>
          <div className="modal-footer react-bs-table-inser-modal-footer">
            <button type="button" className="btn btn-default btn-secondary " onClick={ (e)=>{e.preventDefault(); onModalClose()} }>Close</button>
            <button type="submit" className="btn btn-primary ">Save</button>
          </div>
        </form>
      </div>
    );
  }

  copyBilliability(columns, e) {
    e.preventDefault()

    if(this.form && columns[1] && columns[1].field && this.form[columns[1].field] && this.form[columns[1].field].value){
      const valueToCopy =  this.form[columns[1].field].value
      // Moved to fixed number (8) on the columns FOR iteration
      // for(let i=2; i<columns.length; i++){
      for(let i=2; i<=8; i++){
        this.form[columns[i].field].value = valueToCopy
      }
    }

  }


  handleSave(columns, onSave, e) {
    e.preventDefault()
    const form = e.target.elements
    const newRow = {};

    for (let i = 0; i < columns.length; i++){
      newRow[columns[i].field] = form[columns[i].field].value;
    }

    onSave(newRow);
  }

  objectProject(field, cell, row) {
    if (parseInt(cell, 10)) {
      if(this.props.projects.data.length > 0){
        for (let i=0; i<this.props.projects.data.length; i++){
          if (this.props.projects.data[i].id === parseInt(cell, 10)){
              return this.props.projects.data[i].name
          }
        }
      }
    } else {
      if (cell[field]) {
        return cell[field]
      } else {
        return ''
      }
    }
  }

  intValidator(fieldName, value, row ) {
    const nan = isNaN(parseInt(value, 10));
    if (value && nan) {
      return fieldName + ' must be a integer!';
    }else if(!nan){
      if (parseInt(value, 10) > 100) {
        return fieldName + ' must be lower than 100';
      }

      if (parseInt(value, 10) < 0) {
        return fieldName + ' must be 0 or greater';
      }
    }

    return true;
  }

  _dateValidator(fieldName, value, row) {
    return true;
  }

  onAfterSaveCell(row, cellName, cellValue) {
    let data = {};

    if (cellName === "projectUtilizationWeek1") {
      data["week1[date]"] = this.state.week1.format("YYYY-MM-DD");
      data["week1[utilization]"] = cellValue;
    }

    if (cellName === "projectUtilizationWeek2") {
      data["week2[date]"] = this.state.week2.format("YYYY-MM-DD");
      data["week2[utilization]"] = cellValue;
    }

    if (cellName === "projectUtilizationWeek3") {
      data["week3[date]"] = this.state.week3.format("YYYY-MM-DD");
      data["week3[utilization]"] = cellValue;
    }


    if (cellName === "projectUtilizationWeek4") {
      data["week4[date]"] =  this.state.week4.format("YYYY-MM-DD");
      data["week4[utilization]"] = cellValue;
    }


    if (cellName === "projectUtilizationWeek5") {
      data["week5[date]"] = this.state.week5.format("YYYY-MM-DD");
      data["week5[utilization]"] = cellValue;
    }


    if (cellName === "projectUtilizationWeek6") {
      data["week6[date]"] = this.state.week6.format("YYYY-MM-DD");
      data["week6[utilization]"] = cellValue;
    }


    if (cellName === "projectUtilizationWeek7") {
      data["week7[date]"] = this.state.week7.format("YYYY-MM-DD");
      data["week7[utilization]"] = cellValue;
    }


    if (cellName === "projectUtilizationWeek8") {
      data["week8[date]"] = this.state.week8.format("YYYY-MM-DD");
      data["week8[utilization]"] = cellValue;
    }

    if (cellName === "projectUtilizationRepeatUntil"){
      if (cellValue !== 'NONE') {
        data["repeat_until[date]"] = cellValue;
        data["repeat_until[utilization]"] = row.projectUtilizationWeek8;
      }
    }

    this.props.staffingUpdateItem(row.project.id, this.state.currentUser.id, data)
  }

  _onAfterInsertRow(row) {
    let data = {};
    data.project_id = row.project;
    data.user_id = this.state.currentUser.id;
    data.week1 = row.projectUtilizationWeek1 ? row.projectUtilizationWeek1 : 0;
    data.week2 = row.projectUtilizationWeek2 ? row.projectUtilizationWeek2 : 0;
    data.week3 = row.projectUtilizationWeek3 ? row.projectUtilizationWeek3 : 0;
    data.week4 = row.projectUtilizationWeek4 ? row.projectUtilizationWeek4 : 0;
    data.week5 = row.projectUtilizationWeek5 ? row.projectUtilizationWeek5 : 0;
    data.week6 = row.projectUtilizationWeek6 ? row.projectUtilizationWeek6 : 0;
    data.week7 = row.projectUtilizationWeek7 ? row.projectUtilizationWeek7 : 0;
    data.week8 = row.projectUtilizationWeek8 ? row.projectUtilizationWeek8 : 0;

    if (row.projectUtilizationRepeatUntil) {
      data.repeat_until_date = row.projectUtilizationRepeatUntil;
      data.repeat_until_value = row.projectUtilizationWeek8;
    }

    this.props.staffingAddItem(data);
  }

  _onAfterDeleteRow(rowKeys){
    for (let i = 0; i < rowKeys.length; i++){
      this.props.staffingDeleteItem(rowKeys[i].id, this.state.currentUser.id);
    }

  }

  percentajeFormatter(cell) {
    if (cell || cell === 0) {
      return `${cell} %`;
    }

    return 'N/A';
  }


  _closeDeleteModal() {
    this.setState({ showModal: false, itemId: null });
  }

  _openDeleteModal(onConfirm) {
    this.setState({ showModal: true, onConfirm });
  }

  _renderModal() {
    return (
      <Modal show={this.state.showModal} onHide={this._closeDeleteModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this element?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this._confirmDelete.bind(this)} bsStyle="danger">Delete</Button>
            <Button onClick={this._closeDeleteModal.bind(this)}>Close</Button>
          </Modal.Footer>
        </Modal>
    )
  }

  _confirmDelete() {
    this._closeDeleteModal()
    this.state.onConfirm()
  }

  _customConfirm(next, dropRowKeys) {
    // const dropRowKeysStr = dropRowKeys.join(',');
    // //  Here, you can use a custom modal instead confirm at this demo
    // if (confirm(`(It's a custom confirm)Are you sure you want to delete ${dropRowKeysStr}?`)) {
    //   // If the confirmation is true, call the function that
    //   // continues the deletion of the record.
    //   next();
    // }
    this._openDeleteModal(next)
  }

  render() {
    let userData = [], projects = []

    if (this.state.canEditUsersStaffing && !this.props.userId){
      return null
    }

    if (
      this.props.projects && this.props.projects.data &&
      this.props.projects.data.length > 0
    ) {
      for (let i=0; i<this.props.projects.data.length; i++) {
        if (
          this.props.projects.data[i].is_active &&
          this.state.projectsReported.indexOf(this.props.projects.data[i].id) < 0
        ) {
          let client = "";
          if (this.props.projects.data[i].client && this.props.projects.data[i].client.name) {
            client = this.props.projects.data[i].client.name
          }
          projects.push({text: "[" + client + "] " + this.props.projects.data[i].name, value: this.props.projects.data[i].id})
        }
      }
    }

    if (this.props.staffing.data.length > 0 && this.state.currentUser) {
      for (let i=0; i<this.props.staffing.data.length; i++) {
        if (
          this.props.staffing.data[i].user &&
          this.props.staffing.data[i].user.id === this.state.currentUser.id
        ) {
          if (this.props.staffing.data[i].projects) {
            userData = this.props.staffing.data[i].projects
          }

        }

      }
    }

    for (let i=0; i<userData.length; i++) {
      userData[i].projectUtilizationWeek1 = userData[i].forecast[0].utilization;
      userData[i].projectUtilizationWeek2 = userData[i].forecast[1].utilization;
      userData[i].projectUtilizationWeek3 = userData[i].forecast[2].utilization;
      userData[i].projectUtilizationWeek4 = userData[i].forecast[3].utilization;
      userData[i].projectUtilizationWeek5 = userData[i].forecast[4].utilization;
      userData[i].projectUtilizationWeek6 = userData[i].forecast[5].utilization;
      userData[i].projectUtilizationWeek7 = userData[i].forecast[6].utilization;
      userData[i].projectUtilizationWeek8 = userData[i].forecast[7].utilization;
      userData[i].projectUtilizationRepeatUntil = (userData[i].repeat_until.date === null) ? 'N/A' : userData[i].repeat_until.date;
    }

    const options = {
      // afterColumnFilter: this._afterColumnFilter.bind(this),
      afterInsertRow: this._onAfterInsertRow.bind(this),
      afterDeleteRow: this._onAfterDeleteRow.bind(this),
      ignoreEditable: true,
      handleConfirmDeleteRow: this._customConfirm.bind(this),
      insertModal: this.createCustomModal.bind(this)
    }

    const cellEditProp = {
      mode: 'click',
      blurToSave: true,
      afterSaveCell: this.onAfterSaveCell,  // a hook for after saving cell
    };

    const projectEditable = {
      type: 'select',
      options: {
        values: projects,
      },
    };

    const selectRow = {
      mode: 'checkbox', // radio or checkbox
    };

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={userData}
          cellEdit={cellEditProp}
          insertRow={!!this.props.permissions.can_create}
          deleteRow={!!this.props.permissions.can_delete}
          options={options}
          selectRow={selectRow}
          ref={(c) => { this.table = c; }}
        >
          <TableHeaderColumn
            dataField="project"
            isKey
            editable={projectEditable}
            dataFormat={this.objectProject.bind(this, "name")}
          >
            Project
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="projectUtilizationWeek1"
            filterFormatted
            dataSort
            dataFormat={this.percentajeFormatter}
            customEditor={{ getElement: this.createUtilizationEditor }}
            headerAlign="left"
            dataAlign="center"
            columnClassName={this.forecastColumnClassName}
            editColumnClassName={this.forecastColumnClassNameEdit}
          >
            {this.state.week1.format('YYYY-MM-DD')}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="projectUtilizationWeek2"
            filterFormatted
            dataSort
            dataFormat={this.percentajeFormatter}
            customEditor={{ getElement: this.createUtilizationEditor }}
            headerAlign="left"
            dataAlign="center"
            columnClassName={this.forecastColumnClassName}
            editColumnClassName={this.forecastColumnClassNameEdit}
          >
            {this.state.week2.format('YYYY-MM-DD')}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="projectUtilizationWeek3"
            filterFormatted
            dataSort
            dataFormat={this.percentajeFormatter}
            customEditor={{ getElement: this.createUtilizationEditor }}
            headerAlign="left"
            dataAlign="center"
            columnClassName={this.forecastColumnClassName}
            editColumnClassName={this.forecastColumnClassNameEdit}
          >
            {this.state.week3.format('YYYY-MM-DD')}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="projectUtilizationWeek4"
            filterFormatted
            dataSort
            dataFormat={this.percentajeFormatter}
            customEditor={{ getElement: this.createUtilizationEditor }}
            headerAlign="left"
            dataAlign="center"
            columnClassName={this.forecastColumnClassName}
            editColumnClassName={this.forecastColumnClassNameEdit}
          >
            {this.state.week4.format('YYYY-MM-DD')}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="projectUtilizationWeek5"
            filterFormatted
            dataSort
            dataFormat={this.percentajeFormatter}
            customEditor={{ getElement: this.createUtilizationEditor }}
            headerAlign="left"
            dataAlign="center"
            columnClassName={this.forecastColumnClassName}
            editColumnClassName={this.forecastColumnClassNameEdit}
          >
            {this.state.week5.format('YYYY-MM-DD')}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="projectUtilizationWeek6"
            filterFormatted
            dataSort
            dataFormat={this.percentajeFormatter}
            customEditor={{ getElement: this.createUtilizationEditor }}
            headerAlign="left"
            dataAlign="center"
            columnClassName={this.forecastColumnClassName}
            editColumnClassName={this.forecastColumnClassNameEdit}
          >
            {this.state.week6.format('YYYY-MM-DD')}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="projectUtilizationWeek7"
            filterFormatted
            dataSort
            dataFormat={this.percentajeFormatter}
            customEditor={{ getElement: this.createUtilizationEditor }}
            headerAlign="left"
            dataAlign="center"
            columnClassName={this.forecastColumnClassName}
            editColumnClassName={this.forecastColumnClassNameEdit}
          >
            {this.state.week7.format('YYYY-MM-DD')}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="projectUtilizationWeek8"
            filterFormatted
            dataSort
            dataFormat={this.percentajeFormatter}
            customEditor={{ getElement: this.createUtilizationEditor }}
            headerAlign="left"
            dataAlign="center"
            columnClassName={this.forecastColumnClassName}
            editColumnClassName={this.forecastColumnClassNameEdit}
          >
            {this.state.week8.format('YYYY-MM-DD')}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="projectUtilizationRepeatUntil"
            filterFormatted
            dataSort
            customEditor={{ getElement: this.createDateEditor }}
            headerAlign="left"
            dataAlign="center"
            columnClassName={this.RepeatUntilColumnClassName}
            editColumnClassName={this.RepeatUntilColumnClassNameEdit}
          >
            Repeat Until
          </TableHeaderColumn>
      </BootstrapTable>

      {this._renderModal()}
      </section>
    )
  }
}

export default StaffingForm
