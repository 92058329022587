import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';
import * as constants from '../../constants';

// let ReactGA = require('react-ga');
// ReactGA.initialize(constants.GACode);

//
// Config the paratemers API:
//

let domain;
const env = process.env.NODE_ENV;

switch (env) {
  case 'production':
    domain = 'https://api.mtm.headstorm.com/';
    break;
  case 'qa':
    domain = 'http://ec2-54-185-8-238.us-west-2.compute.amazonaws.com';
    break;
  default:
    domain = 'http://dev.api.mtm.headstorm.com/';
}

export function checkStatus(response) {
  if ((response.status >= 200 && response.status < 300) || response.status === 400) {
    return response;
  }

  if (response.status === 401) {
    sessionService.deleteSession().then(() => {
      sessionService.deleteUser();
      browserHistory.push('/login');
    }).catch((e) => {
      console.log(e, 'error');
    });
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;

  // if(env === 'production"){
  //   ReactGA.exception({
  //      description: error.response,
  //      fatal: true
  //    });
  // }

  throw error;
}

export function parseJSON(response) {
  return response.json();
}

export const config = {
  domain,
  apiPreUrl: 'api',
  client_id: constants.client_id,
  client_secret: constants.client_secret,
};
