import React, {Component} from 'react';
import SmartForm from '../../form/base.jsx'
import { Col, Row} from 'react-bootstrap';
import moment from 'moment'

class UsersLevelForm extends Component{

  componentWillMount() {
    if(this.props.userLevel.itemId){
      this.props.userLevelItem(this.props.userId, this.props.userLevel.itemId)
    }
    this.props.settingsLevelsList()
  }

  componentWillReceiveProps(nextProps) {
    if((nextProps.userLevel.addSuccess && nextProps.userLevel.addSuccess !== this.props.userLevel.addSuccess) || (nextProps.userLevel.editSuccess && nextProps.userLevel.editSuccess !== this.props.userLevel.editSuccess)){
      this.props.userLevelShowList()
    }
  }

  render(){
    let itemData, levels = [], users = []

    if(this.props.userLevel.itemId){
      itemData = this.props.userLevel.itemData
    }

    for(let i = 0; i < this.props.settingsLevels.data.length; i++){
      levels.push(
        {
            name: this.props.settingsLevels.data[i].name,
            value: this.props.settingsLevels.data[i].id
        }
      )
    }

    for(let i = 0; i < this.props.user.data.length; i++){
      users.push(
        {
            name: this.props.user.data[i].name,
            value: this.props.user.data[i].id
        }
      )
    }

    const schema = [
      {
        type: "default",
        fields: [
          {
            type: "select",
            name: "user_id",
            label: "User:",
            value: this.props.userId,
            disabled: true,
            options: users
          },
          {
            type: "select",
            name: "user_role_id",
            label: "Level:",
            value: itemData?itemData.user_role_id:'',
            options: levels
          },
          {
            type: "calendar",
            name: "start_date",
            label: "Start Date:",
            value: itemData?itemData.start_date:'',
            dateFormat: "YYYY-MM-DD",
          },

        ]
      }
    ]
    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this._submit.bind(this)}
              showError={this.props.userLevel.error && this.props.userLevel.errorText}
              errorMessage={ this.props.userLevel.errorText }
              onReset={this.props.userLevelShowList.bind(this)}
              loading={this.props.settingsLevels.inProgress} />
          </Col>
        </Row>


      </section>
    )
  }

  _submit(data){
    let dataToSend = data
    dataToSend.start_date = moment(dataToSend.start_date).format("YYYY-MM-DD");

    if(this.props.userLevel.itemId){
      this.props.userLevelUpdateItem(this.props.userLevel.itemId, dataToSend)
    }else{
      this.props.userLevelAddItem(dataToSend)
    }
  }


}

export default UsersLevelForm
