import React, {Component} from 'react';
import moment from 'moment'
import "assets/css/table.css";
import { Table, Row, Col } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import cookie from 'react-cookies'

class RealizationSummaryList extends Component{
  constructor(props){
  	super(props);
  	this.state = {
      itemId: null,
      total_amout: 0,
      total_invoiced: 0,
      total_realization: 0,
      sort_name: "",
      sort_order: ""
    };
  }

  componentWillMount() {
    this.setState(
      {
        sort_name: cookie.load('sort_column_realization') || 'id',
        sort_order: cookie.load('sort_order_realization') || 'asc'
      }
    )
  }

  render(){
    const options = {
      expandRowBgColor: 'rgb(242, 255, 163)',
      afterColumnFilter: this._afterColumnFilter.bind(this),
      defaultSortName: this.state.sort_name,
      defaultSortOrder: this.state.sort_order,
      onSortChange: this._onSortChange.bind(this),
    }
    const summaryList = this.props.realizationSummary.data;

    return (
      <section className="section-list-content">
        <BootstrapTable
            data={summaryList}
            hover={true}
            options={ options }
            autoCollapse={ { sort: true, search: true, filter: true } }
            ref={(table) => { this.table = table; }}
            trClassName={this._trClassFormat}
            headerContainerClass='fixed-header'>
            <TableHeaderColumn dataField="id" isKey={true} dataSort={true} width="90px">No.</TableHeaderColumn>
            <TableHeaderColumn dataField="client"  filter={ { type: 'TextFilter', delay: 500 } }  dataSort={true}>Client</TableHeaderColumn>
            <TableHeaderColumn dataField="name"  filter={ { type: 'TextFilter', delay: 500 } } dataSort={true}>Project</TableHeaderColumn>
            <TableHeaderColumn dataField="officer_in_charge"  filter={ { type: 'TextFilter', delay: 500 } } dataSort={true}>OIC</TableHeaderColumn>
            <TableHeaderColumn
              dataField="time"
              dataSort={true}
              sortFunc={this._sortNumberFunc}
              dataAlign='right'
              headerAlign='left'
              className='col_time'
              columnClassName='col_time'
            >
              Time
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="expenses"
              dataSort={true}
              dataFormat={this._moneyFormatter}
              sortFunc={this._sortNumberFunc}
              dataAlign='right'
              headerAlign='left'
              className='col_expenses'
              columnClassName='col_expenses'
            >
              Expenses
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="total"
              dataSort={true}
              dataFormat={this._moneyFormatter}
              sortFunc={this._sortNumberFunc}
              dataAlign='right'
              headerAlign='left'
              className='col_total'
              columnClassName='col_total'
            >
              Total
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="invoiced"
              dataSort={true}
              dataFormat={this._moneyFormatter}
              sortFunc={this._sortNumberFunc}
              dataAlign='right'
              headerAlign='left'
              className='col_invoiced'
              columnClassName='col_invoiced'
            >
              Invoiced
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="realization"
              dataSort={true} dataFormat={this._percentajeFormatter}
              sortFunc={this._sortNumberFunc}
              dataAlign='center'
              headerAlign='left'
              className='col_realization'
              columnClassName='col_realization'
            >
              Realization
            </TableHeaderColumn>
        </BootstrapTable>

        <div className="totals">
          <Row>
            <Col xs={12} md={5} mdOffset={7}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Total Amount:</th>
                    <th>Total Invoiced:</th>
                    <th>Total Realization:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{Number(this.state.total_amout).toLocaleString('en-US', {style: 'currency',currency: 'USD'})}</td>
                    <td>{Number(this.state.total_invoiced).toLocaleString('en-US', {style: 'currency',currency: 'USD'})}</td>
                    <td>{Number(this.state.total_realization).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}%</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>

      </section>
    )
  }

  _onSortChange(sortName, sortOrder){
    cookie.save('sort_column_realization', sortName, { path: '/' });
    cookie.save('sort_order_realization', sortOrder, { path: '/' });

    this.setState({
      sort_name: sortName,
      sort_order: sortOrder
    });

  }

  _trClassFormat(row, rowIndex) {
    return rowIndex % 2 === 0 ? "tr-odd" : "tr-even";  // return class name.
  }

  _moneyFormatter(cell, row){
    return Number(cell).toLocaleString('en-US', {style: 'currency',currency: 'USD'})
  }

  _dateFormatter(cell, row){
    return moment(cell).format("YYYY-MM-DD")
  }

  _percentajeFormatter(cell, row){
    return Number(cell).toLocaleString('en-US') + "%"
  }

  _sortNumberFunc(a, b, order, sortField){
    if (order === 'asc') {
      return parseFloat(a[sortField]) - parseFloat(b[sortField])
    } else {
      return parseFloat(b[sortField]) - parseFloat(a[sortField]);
    }
  }

  _afterColumnFilter(filterConds, result) {
    let total_amout = 0, total_invoiced = 0, total_realization = 0

    for (let i = 0; i < result.length; i++) {
      total_amout += parseFloat(result[i].total)
      total_invoiced += parseFloat(result[i].invoiced)
    }

    if(total_amout > 0){
        total_realization = (total_invoiced * 100) / total_amout
    }

    if(total_amout !== this.state.total_amout || total_invoiced !== this.state.total_invoiced || total_realization !== this.state.total_realization){
        this.setState({total_amout: total_amout, total_invoiced: total_invoiced, total_realization: total_realization})
    }
  }
}

export default RealizationSummaryList
