import React, {Component} from 'react';
import "assets/css/table.css";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import 'assets/css/staffing.css'
import 'assets/css/table.css'
import Details from './details.jsx'
import moment from 'moment'
import cookie from 'react-cookies'

class StaffingListAll extends Component {
  constructor(props){
  	super(props);
  	this.state = {
      sort_name: "",
      sort_order: ""
    };
    this.forecastColumnClassName = this.forecastColumnClassName.bind(this);
  }

  componentWillMount() {
     this.props.staffingList()

     this.setState(
       {
         sort_name: cookie.load('sort_column_staffing') || 'user_name',
         sort_order: cookie.load('sort_order_staffing') || 'asc'
       }
     )
  }

  forecastColumnClassName(fieldValue, row, rowIdx, colIdx) {
    let className = 'col_availability';
    
    if (colIdx - 2 >= 0) {
      const data = fieldValue[colIdx - 2];
      if (!data.utilization && data.utilization !== 0) {
        className += ' empty';
      }
    }

    return className;
  }


  render() {
    let staffing = []

    if (this.props.staffing.data) {
      for(let i=0; i<this.props.staffing.data.length; i++) {
        let data = Object.assign({}, this.props.staffing.data[i])

        // Plain user role
        data.user_role = ""
        if (data.user.role && data.user.role.name){
          data.user_role = data.user.role.name
        }

        // Plain user name
        data.user_name = data.user.name

        // Plain utilizaiton totals
        data.total_utilization_raw = [];
        if (data.total_utilization_raw && data.user.role.name){
          data.user_role = data.user.role.name
        }
        for (let j=0; j<data.total_utilization.length; j++) {
          data.total_utilization_raw[j] = data.total_utilization[j].utilization;
        }

        staffing.push(data)
      }
    }

    const options = {
      expandRowBgColor: 'rgb(242, 255, 163)',
      expandBodyClass: 'content-details',
      defaultSortName: this.state.sort_name,
      defaultSortOrder: this.state.sort_order,
      onSortChange: this._onSortChange.bind(this),
    }

    let week_1 = moment().startOf("isoWeek")
    let week_2 = moment(week_1).add(1, "week")
    let week_3 = moment(week_2).add(1, "week")
    let week_4 = moment(week_3).add(1, "week")
    let week_5 = moment(week_4).add(1, "week")
    let week_6 = moment(week_5).add(1, "week")
    let week_7 = moment(week_6).add(1, "week")
    let week_8 = moment(week_7).add(1, "week")


    return (
      <section className="section-list-content">
        <BootstrapTable
            data={staffing}
            hover={true}
            options={ options }
            expandableRow={ this._isExpandableRow.bind(this) }
            expandComponent={ this._expandComponent.bind(this) }
            autoCollapse={ { sort: true, search: true, filter: true } }
            expandColumnOptions={ {
              expandColumnVisible: true,
              expandColumnComponent: this._expandColumnComponent.bind(this),
              columnWidth: 50
            } }
             trClassName={this._trClassFormat}
             headerContainerClass='fixed-header'>
            <TableHeaderColumn
              dataField="user_name"
              columnTitle
              isKey={true}
              dataSort={true}
              filter={ { type: 'TextFilter', delay: 500 } }
              filterFormatted
            >
              Name
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="user_role"
              columnTitle
              dataSort={true}
              filter={ { type: 'TextFilter', delay: 500 } }
              filterFormatted
            >
              Level
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="total_utilization"
              dataFormat={this._totalUtilization.bind(this, 0, "utilization")}
              headerAlign='left'
              dataAlign='center'
              className='col_utilization'
              columnClassName={ this.forecastColumnClassName }
            >
              {week_1.format('MMM')}
              <br />
              {week_1.format('Do')}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="total_utilization"
              dataFormat={this._totalUtilization.bind(this, 1, "utilization")}
              headerAlign='left'
              dataAlign='center'
              className='col_utilization'
              columnClassName={ this.forecastColumnClassName }
            >
              {week_2.format('MMM')}
              <br />
              {week_2.format('Do')}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="total_utilization"
              dataFormat={this._totalUtilization.bind(this, 2, "utilization")}
              headerAlign='left'
              dataAlign='center'
              className='col_utilization'
              columnClassName={ this.forecastColumnClassName }
            >
              {week_3.format('MMM')}
              <br />
              {week_3.format('Do')}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="total_utilization"
              dataFormat={this._totalUtilization.bind(this, 3, "utilization")}
              headerAlign='left'
              dataAlign='center'
              className='col_utilization'
              columnClassName={ this.forecastColumnClassName }
            >
              {week_4.format('MMM')}
              <br />
              {week_4.format('Do')}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="total_utilization"
              dataFormat={this._totalUtilization.bind(this, 4, "utilization")}
              headerAlign='left'
              dataAlign='center'
              className='col_utilization'
              columnClassName={ this.forecastColumnClassName }
            >
              {week_5.format('MMM')}
              <br />
              {week_5.format('Do')}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="total_utilization"
              dataFormat={this._totalUtilization.bind(this, 5, "utilization")}
              headerAlign='left'
              dataAlign='center'
              className='col_utilization'
              columnClassName={ this.forecastColumnClassName }
            >
              {week_6.format('MMM')}
              <br />
              {week_6.format('Do')}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="total_utilization"
              dataFormat={this._totalUtilization.bind(this, 6, "utilization")}
              headerAlign='left'
              dataAlign='center'
              className='col_utilization'
              columnClassName={ this.forecastColumnClassName }
            >
              {week_7.format('MMM')}
              <br />
              {week_7.format('Do')}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="total_utilization"
              dataFormat={this._totalUtilization.bind(this, 7, "utilization")}
              headerAlign='left'
              dataAlign='center'
              className='col_utilization'
              columnClassName={ this.forecastColumnClassName }
              //dataSort={true}
              //sortFunc={this._sortObjectFunc}
              //sortFuncExtraData={{objectField: "client", secondLevelObjectField: "name"}}
            >
              {week_8.format('MMM')}
              <br />
              {week_8.format('Do')}
            </TableHeaderColumn>
        </BootstrapTable>
      </section>
      )
  }

  _onSortChange(sortName, sortOrder){

    cookie.save('sort_column_staffing', sortName, { path: '/' });
    cookie.save('sort_order_staffing', sortOrder, { path: '/' });

    this.setState({
      sort_name: sortName,
      sort_order: sortOrder
    });

  }

  _trClassFormat(row, rowIndex) {
    return rowIndex % 2 === 0 ? "tr-odd" : "tr-even";  // return class name.
  }

  _expandColumnComponent({ isExpandableRow, isExpanded }) {
      let content = '';

      if (isExpandableRow) {
        content = (isExpanded ? '(-)' : '(+)' );
      } else {
        content = ' ';
      }
      return (
        <div> { content } </div>
      );
    }

  _isExpandableRow(row) {

    if (row.projects && row.projects.length > 0) return true;
    else return false;
  }

  _expandComponent(row) {

    return (
      <Details data={ row }  {...this.props} />
    );
  }

  _objectFormatter(field, field2, cell, row){

    if (field2) {
      if(cell && cell[field] && cell[field][field2]){
        return cell[field][field2]
      }
    } else {
      if (cell && cell[field]) {
        return cell[field]
      }
    }


    return ''

  }

  _objectFormatterPosField(pos, field, cell, row){
    if((cell && cell[pos] && cell[pos][field]) || (cell && cell[pos] && cell[pos][field] === 0)){
      return cell[pos][field]
    }

    return ''

  }

  _sortObjectFunc(a, b, order, sortField, extraData) {   // order is desc or asc


    if(extraData["secondLevelObjectField"]){
      let fieldName = extraData["objectField"]
      let fieldSecondLevelFieldName = extraData["secondLevelObjectField"]

      if (order === 'asc') {
        return (a[sortField] && a[sortField][fieldName] && a[sortField][fieldName][fieldSecondLevelFieldName]?a[sortField][fieldName][fieldSecondLevelFieldName]:'').localeCompare(b[sortField] && b[sortField][fieldName]  && b[sortField][fieldName][fieldSecondLevelFieldName]?b[sortField][fieldName][fieldSecondLevelFieldName]:'')
      } else {
        return (b[sortField] && b[sortField][fieldName] && b[sortField][fieldName][fieldSecondLevelFieldName]?b[sortField][fieldName][fieldSecondLevelFieldName]:'').localeCompare(a[sortField] && a[sortField][fieldName] && a[sortField][fieldName][fieldSecondLevelFieldName]?a[sortField][fieldName][fieldSecondLevelFieldName]:'');
      }
    }else{
      let fieldName = extraData["objectField"]

      if (order === 'asc') {
        return (a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'').localeCompare(b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'')
      } else {
        return (b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'').localeCompare(a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'');
      }
    }



  }

  _totalUtilization(pos, field, cell, row) {
    const data = this._objectFormatterPosField(pos, field, cell, row);
    let availability = data;

    if (availability || availability === 0) {
      if (this.props.reportType === 'availability') {
        availability = 100 - data;

        if (availability < 0) {
          availability = 0;
        }
      }
      return `${availability}%`;
    }

    return 'N/A';
  }
}

export default StaffingListAll;
