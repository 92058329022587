//
// Login:
//

export const login = {
  path: '/oauth/token/',
  method: 'POST',
  version: '/v1',
};

export const updatePassword = {
  path: '/users/update_password/:user_id/',
  method: 'POST',
  version: '/v1',
};

export const resetPasswordRequest = {
  path: '/users/password_reset_request/',
  method: 'POST',
  version: '/v1',
};

export const resetPassword = {
  path: '/users/password_reset_change/',
  method: 'POST',
  version: '/v1',
};
