import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

import SmartForm from '../../form/base.jsx';

class pmoRiskManagementForm extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    if (this.props.pmoRiskManagement.itemId) {
      this.props.pmoRiskManagementItem(this.props.projectId, this.props.pmoRiskManagement.itemId);
    }

    this.props.userList();
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.pmoRiskManagement.addSuccess && nextProps.pmoRiskManagement.addSuccess !== this.props.pmoRiskManagement.addSuccess) || (nextProps.pmoRiskManagement.editSuccess && nextProps.pmoRiskManagement.editSuccess !== this.props.pmoRiskManagement.editSuccess)) {
      this.props.pmoRiskManagementShowList();
    }
  }

  submit(data) {
    if (this.props.pmoRiskManagement.itemId) {
      this.props.pmoRiskManagementUpdateItem(this.props.projectId, this.props.pmoRiskManagement.itemId, data, this.props.listStatus);
    } else {
      this.props.pmoRiskManagementAddItem(this.props.projectId, data, this.props.listStatus);
    }
  }

  render() {
    let itemData;
    if (this.props.pmoRiskManagement.itemId) {
      itemData = this.props.pmoRiskManagement.itemData;
    }

    const schema = [{
      type: 'default',
      fields: [
        {
          type: 'text',
          name: 'area',
          label: 'Area:',
          value: itemData ? itemData.area : '',
        },
        {
          type: 'textarea',
          name: 'description',
          label: 'Description:',
          value: itemData && itemData.description ? itemData.description : '',
        },
        {
          type: 'calendar',
          name: 'manually_created_at',
          label: 'Created On:',
          value: itemData && itemData.manually_created_at ? itemData.manually_created_at : moment().format('YYYY-MM-DD'),
        },
        {
          type: 'text',
          name: 'created_by',
          label: 'Created By:',
          value: itemData && itemData.created_by ? itemData.created_by : '',
        },
        {
          type: 'text',
          name: 'monitored_by',
          label: 'Monitored By:',
          value: itemData && itemData.monitored_by ? itemData.monitored_by : '',
        },
        {
          type: 'radios',
          name: 'is_active',
          label: 'Active:',
          value: itemData && (itemData.is_active || itemData.is_active === 0) ? itemData.is_active.toString() : '1',
          options: [
            { value: '1', text: 'Yes' },
            { value: '0', text: 'No' },
          ],
        },
        {
          type: 'textarea',
          name: 'mitigation_strategy',
          label: 'Mitigation Strategy:',
          value: itemData ? itemData.mitigation_strategy : '',
        },
        {
          type: 'textarea',
          name: 'comments',
          label: 'Comments:',
          value: itemData ? itemData.comments : '',
        },
      ],
    }];

    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this.submit}
              showError={this.props.pmoRiskManagement.error && this.props.pmoRiskManagement.errorText}
              errorMessage={this.props.pmoRiskManagement.errorText}
              onReset={this.props.pmoRiskManagementShowList.bind(this)}
              loading={this.props.pmoRiskManagement.inProgress}
            />
          </Col>
        </Row>
      </section>
    );
  }
}

export default pmoRiskManagementForm;
