import React, { Component } from 'react';
// import CheckBox from '../form/checkbox.jsx';
import HSPortalLogo from 'assets/img/HS_portal_logo.svg';
import "assets/css/login.css";
import {
  Row,
  Col,
  Button,
  Alert,
  ButtonToolbar,
} from 'react-bootstrap';
import Modal from 'react-modal';
import _ from 'lodash';

import SystemMsg from '../common/system-msg.jsx';
import Input from '../form/input.jsx';

Modal.setAppElement('body');

class Login extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user: {
        email: '',
        password: '',
        changePasswordModalIsOpen: false,
        newPassword: '',
        confirmNewPassword: '',
        checkNewPasswordContent: false,

      },
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeField = this.onChangeField.bind(this);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changeNewPassword = this.changeNewPassword.bind(this);
    this.forgotPasswordClick = this.forgotPasswordClick.bind(this);
  }

  componentWillMount() {
    if (this.props.session.authenticated) {
      this.props.router.push('/');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.session.authenticated && nextProps.session.authenticated !== this.props.session.authenticated && !_.isEmpty(nextProps.session.user))
      || (nextProps.session.authenticated && !_.isEmpty(nextProps.session.user) && !_.isEqual(nextProps.session.user, this.props.session.user))
    ) {
      if (!_.isEmpty(nextProps.session.user) && nextProps.session.user.force_password_reset) {
        this.setState({ changePasswordModalIsOpen: true });
      } else {
        nextProps.router.push('/');
      }
    }

    if (nextProps.auth.error && nextProps.auth.error !== this.props.auth.error && nextProps.auth.errorMessage) {
      this.setState({ user: { email: this.state.user.email, password: '' } });
    }

    if (nextProps.auth.passwordChanged && nextProps.auth.passwordChanged !== this.props.auth.passwordChanged) {
      if (nextProps.auth.data && nextProps.auth.data.force_password_reset) {
        this.setState({ changePasswordModalIsOpen: true });
        this.props.updatePasswordReset();
      } else {
        setTimeout(() => {
          nextProps.router.push('/');
        }, 1500);
      }
    }
  }

  onSubmit(e) {
    e.preventDefault();
    const { user } = this.state;

    this.props.login(user);
  }

  onChangeField(field, value) {
    const {
      user,
    } = this.state;

    user[field] = value;
    this.setState({ user });
  }

  forgotPasswordClick() {
    this.props.router.push('/forgot-password');
  }

  openModal() {
    this.setState({ changePasswordModalIsOpen: true });
  }

  closeModal() {
    this.setState({ changePasswordModalIsOpen: false, checkNewPasswordContent: false });
    this.onChangeField('password', '');
    this.props.logout();
  }

  changePassword() {
    const {
      user,
    } = this.state;

    const data = {
      password: user.newPassword,
      passwordConfirmation: user.newPassword,
    };

    this.props.updatePassword(this.props.session.user.id, data);
  }

  changeNewPassword(value) {
    clearInterval(this.newPasswordTimer);

    this.newPasswordTimer = setTimeout(() => {
      this.setState({ checkNewPasswordContent: true });
    }, 250);

    this.onChangeField('newPassword', value);
  }

  renderChangePasswordModal() {
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        height: 305,
      },
    };
    const {
      changePasswordModalIsOpen,
      user,
      checkNewPasswordContent,
    } = this.state;

    const match = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-])(?=.{8,})/.test(user.newPassword);

    return (
      <Modal
        isOpen={changePasswordModalIsOpen}
        contentLabel="Change Password"
        onRequestClose={this.closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
      >
        <header>
          <h3>
            <span className="title">
              Change password:
            </span>
          </h3>
        </header>
        <div
          className="modal-content-wrapper"
        >
          {this.props.auth.errorPasswordUpdate && this.props.auth.errorMessagePasswordUpdate ? (
            <Alert bsStyle="danger">
              {this.props.auth.errorMessagePasswordUpdate}
            </Alert>
          ) : ''}

          {this.props.auth.passwordChanged ? (
            <Alert bsStyle="success">
              Password successfully updated. Redirecting...
            </Alert>
          ) : ''}
          <p>
            Please update your password:
          </p>
          <div>
            <Input
              schema={{
                type: 'password',
                placeholder: 'New Password',
                className: 'has-feedback',
                value: user.newPassword,
                icon: {
                  name: 'lock',
                  className: 'form-control-feedback',
                },
                onChange: this.changeNewPassword,
                empty: user.newPassword === '',
                errorText: !match && checkNewPasswordContent ?
                  'Password has to be of at least 8 characters long and have at least one uppercase character, one lower case, one numeric value and one symbol.'
                  : '',
                validationState: !match && checkNewPasswordContent ? 'error' : '',
              }}

            />

            <Input
              schema={{
                type: 'password',
                placeholder: 'Confirm New Password',
                className: 'has-feedback',
                value: user.confirmNewPassword,
                icon: {
                  name: 'lock',
                  className: 'form-control-feedback',
                },
                onChange: this.onChangeField.bind(this, 'confirmNewPassword'),
                empty: user.confirmNewPassword === '',
                validationState: checkNewPasswordContent && user.newPassword !== user.confirmNewPassword ? 'error' : '',
                errorText: checkNewPasswordContent && user.newPassword !== user.confirmNewPassword ? 'Both fields have to match' : '',
              }}
            />
          </div>

          <ButtonToolbar className="pull-right">
            {/* Standard button */}
            <Button onClick={this.closeModal}>
              Cancel
            </Button>
            <Button
              onClick={this.changePassword}
              disabled={!match || user.newPassword !== user.confirmNewPassword}
              bsStyle="primary"
            >
              Confirm
            </Button>

          </ButtonToolbar>
        </div>
      </Modal>
    );
  }

  render() {
    const {
      user,
    } = this.state;

    return (
      <div className="hold-transition login-page">
        { this.props.auth.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="login-box">
          <div className="login-logo">
            <a href="/">
              <img alt="Headstorm MTM" src={HSPortalLogo}/>
            </a>
          </div>

          <div className="login-box-body">
            {this.props.auth.error && this.props.auth.errorMessage ? (
              <Alert bsStyle="danger">
                {this.props.auth.errorMessage}
              </Alert>
            ) : ''}

            {
              // <p>systemadmin@cshound.com</p>
              // <p>c$H0und$y5t3m!A!</p>
            }


            <form onSubmit={this.onSubmit} method="post">
              <Input
                schema={{
                  type: 'email',
                  placeholder: 'Email',
                  className: 'has-feedback',
                  value: user.email,
                  icon: {
                    name: 'envelope',
                    className: 'form-control-feedback',
                  },
                  onChange: this.onChangeField.bind(this, 'email'),
                }}
              />

              <Input
                schema={{
                  type: 'password',
                  placeholder: 'Password',
                  className: 'has-feedback',
                  value: user.password,
                  icon: {
                    name: 'lock',
                    className: 'form-control-feedback',
                  },
                  onChange: this.onChangeField.bind(this, 'password'),
                  empty: user.password === '',
                }}
              />

              <Row>
                <Col xs={8}>
                  {
                    // <div className="checkbox icheck">
                    //   <CheckBox label="Remember Me" checked={false}/>
                    //
                    // </div>
                    <Button bsStyle="link" onClick={this.forgotPasswordClick}>
                      I forgot my password
                    </Button>
                  }
                </Col>

                <Col xs={4}>
                  <Button className="btn-hs-orange" type="submit" bsClass="btn"  block>
                    Sign In
                  </Button>
                </Col>
              </Row>
            </form>

            { // <div className="social-auth-links text-center">
            //   <p>- OR -</p>
            //   <a href="#" className="btn btn-block btn-social btn-facebook btn-flat"><i className="fa fa-facebook"></i> Sign in using
            //     Facebook</a>
            //   <a href="#" className="btn btn-block btn-social btn-google btn-flat"><i className="fa fa-google-plus"></i> Sign in using
            //     Google+</a>
            // </div>

          }

          </div>
        </div>
        {this.renderChangePasswordModal()}
      </div>
    );
  }
}


export default Login;
