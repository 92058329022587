import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';

export const PMO_MILESTONE_PENDING = 'PMO_MILESTONE_PENDING';
export const pmoMilestonePending = makeActionCreator(PMO_MILESTONE_PENDING);

export const PMO_MILESTONE_REJECTED = 'PMO_MILESTONE_REJECTED';
export const pmoMilestoneRejected = makeActionCreator(PMO_MILESTONE_REJECTED);

export const PMO_MILESTONE_SHOW_LIST = 'PMO_MILESTONE_SHOW_LIST';
export const pmoMilestoneShowList = makeActionCreator(PMO_MILESTONE_SHOW_LIST);

export const PMO_MILESTONE_HIDE_LIST = 'PMO_MILESTONE_HIDE_LIST';
export const pmoMilestoneHideList = makeActionCreator(PMO_MILESTONE_HIDE_LIST);

export const PMO_MILESTONE_SHOW_FORM = 'PMO_MILESTONE_SHOW_FORM';
export const pmoMilestoneShowForm = makeActionCreator(PMO_MILESTONE_SHOW_FORM, 'itemId');

export const PMO_MILESTONE_HIDE_FORM = 'PMO_MILESTONE_HIDE_FORM';
export const pmoMilestoneHideForm = makeActionCreator(PMO_MILESTONE_HIDE_FORM);

export const PMO_MILESTONE_SHOW_ITEM = 'PMO_MILESTONE_SHOW_ITEM';
export const pmoMilestoneShowItem = makeActionCreator(PMO_MILESTONE_SHOW_ITEM, 'itemId');


// Show Index

export const PMO_MILESTONE_LIST_FULFILLED = 'PMO_MILESTONE_LIST_FULFILLED';
export const pmoMilestoneListFulfilled = makeActionCreator(PMO_MILESTONE_LIST_FULFILLED, 'data');

export const PMO_MILESTONE_LIST_ERROR = 'PMO_MILESTONE_LIST_ERROR';
export const pmoMilestoneListError = makeActionCreator(PMO_MILESTONE_LIST_ERROR, 'message');

export function pmoMilestoneList(projectId, status = 9999) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoMilestonePending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.milestonesIndex.version}${endpoints.milestonesIndex.path.replace(':pmo_project_id', projectId)}?is_completed=${status}`, {
        method: endpoints.milestonesIndex.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoMilestoneListFulfilled(json.data));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoMilestoneRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Show Item

export const PMO_MILESTONE_ITEM_FULFILLED = 'PMO_MILESTONE_ITEM_FULFILLED';
export const pmoMilestoneFulfilled = makeActionCreator(PMO_MILESTONE_ITEM_FULFILLED, 'data');

export const PMO_MILESTONE_ITEM_ERROR = 'PMO_MILESTONE_ITEM_ERROR';
export const pmoMilestoneError = makeActionCreator(PMO_MILESTONE_ITEM_ERROR, 'message');

export function pmoMilestones(projectId, itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoMilestonePending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getMilestone.version}${endpoints.getMilestone.path.replace(':pmo_project_id', projectId).replace(':milestone_id', itemId)}`, {
        method: endpoints.getMilestone.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoMilestoneFulfilled(json.data));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoMilestoneRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Add Item
export const PMO_MILESTONE_ITEM_ADD_FULFILLED = 'PMO_MILESTONE_ITEM_ADD_FULFILLED';
export const pmoMilestoneAddFulfilled = makeActionCreator(PMO_MILESTONE_ITEM_ADD_FULFILLED, 'data');

export const PMO_MILESTONE_ITEM_ADD_ERROR = 'PMO_MILESTONE_ITEM_ADD_ERROR';
export const pmoMilestoneAddError = makeActionCreator(PMO_MILESTONE_ITEM_ADD_ERROR, 'message');

export function pmoMilestoneAddItem(projectId, data, status) {
  const formData = new FormData();

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoMilestonePending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addMilestone.version}${endpoints.addMilestone.path.replace(':pmo_project_id', projectId)}`, {
        method: endpoints.addMilestone.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoMilestoneAddFulfilled(json.data));
            dispatch(pmoMilestoneList(projectId, status));
          } else {
            dispatch(pmoMilestoneAddError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoMilestoneRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Update Item

export const PMO_MILESTONE_ITEM_UPDATE_FULFILLED = 'PMO_MILESTONE_ITEM_UPDATE_FULFILLED';
export const pmoMilestoneUpdateFulfilled = makeActionCreator(PMO_MILESTONE_ITEM_UPDATE_FULFILLED, 'data');

export const PMO_MILESTONE_ITEM_UPDATE_ERROR = 'PMO_MILESTONE_ITEM_UPDATE_ERROR';
export const pmoMilestoneUpdateError = makeActionCreator(PMO_MILESTONE_ITEM_UPDATE_ERROR, 'message');

export function pmoMilestoneUpdateItem(projectId, itemId, data, status) {
  const formData = new FormData();
  formData.append('_method', 'PUT');

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoMilestonePending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateMilestone.version}${endpoints.updateMilestone.path.replace(':pmo_project_id', projectId).replace(':milestone_id', itemId)}`, {
        method: endpoints.updateMilestone.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoMilestoneUpdateFulfilled(json.data));
            dispatch(pmoMilestoneList(projectId, status));
          } else {
            dispatch(pmoMilestoneUpdateError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoMilestoneRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Delete Item

export const PMO_MILESTONE_ITEM_DELETE_FULFILLED = 'PMO_MILESTONE_ITEM_DELETE_FULFILLED';
export const pmoMilestoneDeleteFulfilled = makeActionCreator(PMO_MILESTONE_ITEM_DELETE_FULFILLED, 'data');

export const PMO_MILESTONE_ITEM_DELETE_ERROR = 'PMO_MILESTONE_ITEM_DELETE_ERROR';
export const pmoMilestoneDeleteError = makeActionCreator(PMO_MILESTONE_ITEM_DELETE_ERROR, 'message');

export function pmoMilestoneDeleteItem(projectId, itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoMilestonePending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteMilestone.version}${endpoints.deleteMilestone.path.replace(':pmo_project_id', projectId).replace(':milestone_id', itemId)}`, {
        method: endpoints.deleteMilestone.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoMilestoneDeleteFulfilled(json.data));
            dispatch(pmoMilestoneList(projectId));
          } else {
            dispatch(pmoMilestoneDeleteError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoMilestoneRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
