import React, { Component } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import _ from 'lodash';
import { browserHistory } from 'react-router';

import ConsultantNetworkingSessionList from './list.jsx';
import ConsultantNetworkingSessionForm from './form.jsx';
import SystemMsg from '../../common/system-msg.jsx';
import 'assets/css/consultant-networking.css';
// import SmartForm from '../../form/base.jsx';

class ConsultantNetworkingSessions extends Component {
  constructor(props) {
    super(props);
    const { session, params } = this.props;
    const { user } = session;
    let permissions = null;

    // Load Permissions for this area
    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'consultant_networking_sessions') {
          permissions = area;
        }
      });
    }

    // Set State
    this.state = {
      permissions,
      userId: params && params.userId ? parseInt(params.userId, 10) : '',
      userData: [],
      loggedUser: user,
    };
  }

  componentWillMount() {
    const { permissions, userId } = this.state;

    // Validate Permissions
    if (
      !permissions
      || (
        !permissions.can_read
        && !permissions.can_create
        && !permissions.can_delete
        && !permissions.can_update
        && !permissions.limit_to_own_user
      )
    ) {
      this.props.router.push('/404');
    }

    // Get User Information
    this.props.userItem(userId);

    // Request consultant Session List
    this.props.consultantNetworkingSessionList(userId);
  }

  componentWillReceiveProps(nextProps) {
    // Deconstruct
    const { session } = this.state;

    // User Data update
    if (nextProps.user && nextProps.user.itemData) {
      this.setState({ userData: nextProps.user.itemData });
    }

    // Sessions update
    if (!_.isEqual(session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'consultant_networking_sessions') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  componentWillUnmount() {
    this.props.consultantNetworkingSessionHideForm();
    this.props.consultantNetworkingSessionShowList();
  }

  getTitle(permissions, consultantNetworkingSessions) {
    const { userData, loggedUser } = this.state;

    if (consultantNetworkingSessions.showList) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={() => { browserHistory.push('/consultant-networking/'); }}>
                <span className="fa fa-arrow-circle-o-left" />
                &nbsp;Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={4} className="title-section">
              <div className="title-box">
                <h1 className="box-title">
                  Networking Sessions
                </h1>
                <h2>
                  { userData.name }
                </h2>
              </div>
              {(
                permissions && 
                permissions.can_create && 
                (!permissions.limit_to_own_user || loggedUser.id === userData.id)
              ) ? (
                <Button bsSize="small" bsStyle="success" onClick={this.props.consultantNetworkingSessionShowForm.bind(this, "newItem")}>
                  Add New
                </Button>
              ) : null
            }
            </Col>
          </Row>
        </div>
      );
    }

    if (consultantNetworkingSessions.showForm) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.consultantNetworkingSessionShowList.bind(this)}>
                <span className="fa fa-arrow-circle-o-left" />
                &nbsp;Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <div className="title-box">
                <h1 className="box-title">
                  {consultantNetworkingSessions.itemId ? 'Edit Session' : 'Add New Session'}
                </h1>
                <h2>
                  { userData.name }
                </h2>
              </div>
            </Col>
          </Row>
        </div>
      );
    }

    return null;
  }

  render() {
    // destructuring
    const { permissions, userId, userData, loggedUser } = this.state;
    const { consultantNetworkingSessions } = this.props;

    // Calculate if user is able to EDIT
    const editEnabled =  (
      permissions &&
      permissions.can_update && 
      (!permissions.limit_to_own_user || loggedUser.id === userData.id)
    );

    // Calculate if user is able to DELETE
    const deleteEnabled = (permissions.can_delete && userId === loggedUser.id);

    // render
    return (
      <section className={`cn-sessions-content ${consultantNetworkingSessions.showList ? 'minWidth' : ''}`}>
        { consultantNetworkingSessions.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this.getTitle(permissions, consultantNetworkingSessions)}
          </div>
          <div className="box-content">
            {consultantNetworkingSessions.showList ? <ConsultantNetworkingSessionList permissions={permissions} userId={userId} editEnabled={editEnabled} deleteEnabled={deleteEnabled} {...this.props} /> : ''}
            {consultantNetworkingSessions.showForm ? <ConsultantNetworkingSessionForm permissions={permissions} userId={userId} {...this.props} /> : ''}
          </div>
        </div>
      </section>
    )
  }
}

export default ConsultantNetworkingSessions
