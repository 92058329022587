import React, { Component } from 'react';
import _ from 'lodash';
import { Col, Row, Button, Tabs, Tab } from 'react-bootstrap';
import cookie from 'react-cookies';
import SystemMsg from '../common/system-msg.jsx';
import Select from '../form/select.jsx';
import SmartForm from '../form/base.jsx';
import StaffingListAvailability from './listAvailability.jsx';
import StaffingListAll from './listAll.jsx';
import StaffingForm from './form.jsx';
import 'assets/css/staffing.css';

class Staffing extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'staffing') {
          permissions = area;
        }
      });
    }

    this.state = {
      userId: null,
      user,
      report_type: 'availability',
      permissions,
      avFilterWithTargetReached: 0,
    };

    this.updateAvFilterWithTargetReached = this.updateAvFilterWithTargetReached.bind(this);
  }

  componentWillMount() {
    this.props.userList();
    const filtersCookie = cookie.load('staffing_filters') || {}
    this.setState(
      {
        report_type: filtersCookie && filtersCookie.report_type?filtersCookie.report_type:this.state.report_type
      }
    )
  }

  updateAvFilterWithTargetReached(avFilterWithTargetReached) {
    this.setState( { avFilterWithTargetReached });
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'staffing') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  componentWillUnmount() {
    this.props.staffingHideForm()
    this.props.staffingShowList()
  }

  getIndexAllTitle() {
    if (this.props.staffing.showList) {
      return (
        <Row>
          <Col xs={5} className="title-section">
            <h1 className="box-title">
              Staffing &raquo; All
            </h1>
            {
              this.state.permissions.can_create ? (
                <Button
                  bsSize="small"
                  bsStyle="success"
                  disabled={this.props.staffing.inProgress}
                  onClick={this.props.staffingShowForm.bind(this, 'newItem')}
                >
                  Edit Utilization for the next 8 weeks
                </Button>
              )
                : null
            }
          </Col>

          <Col xs={7} className="action-section">
            <SmartForm
              schema={[{
                type: 'default',
                fields: [
                  {
                    type: 'select',
                    name: 'report_type',
                    label: 'Report Type:',
                    value: this.state.report_type,
                    options: [
                      {
                        name: 'Availability Report',
                        value: 'availability'
                      },
                      {
                        name: 'Usability Report',
                        value: 'usability',
                      },
                    ],
                    onChange: this._changeReport.bind(this)
                  },
                ],
              },
              ]}
              inline
              buttons={[]}
            />
          </Col>
        </Row>
)
    }

    if (this.props.staffing.showForm) {
      const canEditOtherUsersStaffing = this.state.permissions.limit_to_own_user;

      // Prepare user list
      const users = [];
      this.props.user.data.forEach((user) => {
        if (
          user.harvest_is_active
          && user.role
          && user.role.include_in_staffing
        ) {
          users.push({ name: user.name, value: user.id });
        }
      });

      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button
                bsSize="xsmall"
                onClick={this.props.staffingShowList.bind(this)}
              >
                <span className="fa fa-arrow-circle-o-left" />
                Back
              </Button>
            </Col>
          </Row>

          <Row>
            <Col xs={3} sm={3} className="title-section">
              <h1 className="box-title">Edit Utilization for the next 8 weeks</h1>
            </Col>
            <Col xs={9} sm={6} md={4} lg={3}>
              <Select schema={{
                value: this.state.permissions.limit_to_own_user ? this.state.user.id : this.state.userId,
                name: 'user',
                label: '',
                disabled: this.props.staffing.inProgress || !!canEditOtherUsersStaffing,
                placeholder: 'Select a user to continue',
                options: users,
                onChange: this.setUser.bind(this),
                searchable: true,
              }}
              />
            </Col>
          </Row>
        </div>


      )
    }

    return null;
  }


  _changeReport(report_type){
    this.setState({report_type: report_type})

    cookie.save('staffing_filters', JSON.stringify({report_type: report_type}), { path: '/' });
  }

  setUser(user){
    this.setState({"userId": user})
  }

  render() {
    const { avFilterWithTargetReached } = this.state;

    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className="staffing-content minWidth">
        { this.props.staffing.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <Tabs defaultActiveKey={1} id="staffing-tabs">
            <Tab eventKey={1} title="All">
              <div className="box-header with-border">
                {this.getIndexAllTitle()}
              </div>
              <div className="box-content">
                { this.props.staffing.showList ?
                  (
                    <StaffingListAll
                      permissions={this.state.permissions} 
                      reportType={this.state.report_type} 
                      {...this.props}
                    />
                  ) : null
                }
                { this.props.staffing.showForm ? 
                  (
                    <StaffingForm
                      permissions={this.state.permissions}
                      currentUserData={this.state.user}
                      userId={this.state.userId}
                      avFilterWithTargetReached={avFilterWithTargetReached}
                      {...this.props}
                    />
                  ) : null
                }
              </div>
            </Tab>
            <Tab eventKey={2} title="Availability">
              <StaffingListAvailability
                permissions={this.state.permissions}
                updateAvFilterWithTargetReached={this.updateAvFilterWithTargetReached}
                avFilterWithTargetReached={avFilterWithTargetReached}
                {...this.props}
              />
            </Tab>
          </Tabs>
        </div>
      </section>
    )
  }
}

export default Staffing
