import React, { Component } from 'react';
import { RadioGroup, Radio } from 'react-icheck';

import '../../assets/icheck/skins/all.css';
import '../../assets/css/input.css';

class Radios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.schema.value || this.props.schema.value === 0 ? this.props.schema.value : '',
    };

    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.schema.value !== this.props.schema.value) {
      this.setState({ value: nextProps.schema.value || nextProps.schema.value === 0 ? nextProps.schema.value : '' });
    }
  }

  onChange(e, value) {
    this.setState({
      value,
    });

    if (this.props.schema.onChange) {
      this.props.schema.onChange(value);
    }
  }

  getValue() {
    return this.state.value;
  }

  changeClass(schema) {
    if (schema.disabled) {
      return "form-group field disabled " + (schema.className ? schema.className : '')
    }

    return "form-group field " + (schema.className ? schema.className : '');
  }

  render() {
    const {
      schema,
    } = this.props;

    return (

      <div
        className={this.changeClass(schema)}
        style={schema.style ? schema.style : null}
        data-tip={schema.tooltip ? schema.tooltip : ''}
      >
        <label htmlFor={schema.name}>
          {schema.label}
        </label>
        <RadioGroup
          name={schema.name}
          value={this.state.value}
          onChange={this.onChange}
        >
          {schema.options.map((option, index) => (
            <Radio
              value={option.value}
              radioClass="iradio_square-blue"
              increaseArea="20%"
              label={<span className="radioLabel">{option.text}</span>}
              disabled={option.disabled || schema.disabled}
              key={index}
              cursor="true"
            />
          ))}
        </RadioGroup>
      </div>
    );
  }
}

export default Radios;
