import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';

import SmartForm from '../../form/base.jsx';

class InternalProjectsForm extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    const {
      itemData,
    } = this.props.pmoActionItems;

    this.state = {
      isCompleted: itemData ? itemData.is_completed.toString() : '0',
      completedAt: itemData && itemData.completed_at ? itemData.completed_at : moment(),
      completedBy: itemData && itemData.completed_by ? itemData.completed_by : '',
    };
  }

  componentWillMount() {
    if (this.props.pmoActionItems.itemId) {
      this.props.pmoActionItemsItem(this.props.projectId, this.props.pmoActionItems.itemId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.pmoActionItems.addSuccess && nextProps.pmoActionItems.addSuccess !== this.props.pmoActionItems.addSuccess) || (nextProps.pmoActionItems.editSuccess && nextProps.pmoActionItems.editSuccess !== this.props.pmoActionItems.editSuccess)) {
      this.props.pmoActionItemsShowList();
    }

    if (!_.isEqual(this.props.pmoActionItems.itemData, nextProps.pmoActionItems.itemData)) {
      const {
        itemData,
      } = nextProps.pmoActionItems;

      this.setState({
        isCompleted: itemData ? itemData.is_completed.toString() : '0',
        completedAt: itemData && itemData.completed_at ? itemData.completed_at : moment(),
        completedBy: itemData && itemData.completed_by ? itemData.completed_by : '',
      });
    }
  }

  submit(data) {
    if (this.props.pmoActionItems.itemId) {
      this.props.pmoActionItemsUpdateItem(this.props.projectId, this.props.pmoActionItems.itemId, data, this.props.listStatus);
    } else {
      this.props.pmoActionItemsAddItem(this.props.projectId, data, this.props.listStatus);
    }
  }

  render() {
    let itemData;
    if (this.props.pmoActionItems.itemId) {
      itemData = this.props.pmoActionItems.itemData;
    }

    const schema = [{
      type: 'default',
      fields: [
        {
          type: 'textarea',
          name: 'area',
          label: 'Area:',
          value: itemData && itemData.area ? itemData.area : '',
        },
        {
          type: 'textarea',
          name: 'description',
          label: 'Description:',
          value: itemData && itemData.description ? itemData.description : '',
        },
        {
          type: 'calendar',
          name: 'manually_created_at',
          label: 'Created On:',
          value: itemData && itemData.manually_created_at ? itemData.manually_created_at : moment().format('YYYY-MM-DD'),
        },
        {
          type: 'text',
          name: 'created_by',
          label: 'Created By:',
          value: itemData && itemData.created_by ? itemData.created_by : '',
        },
        {
          type: 'text',
          name: 'assigned_to',
          label: 'Assigned To:',
          value: itemData && itemData.assigned_to ? itemData.assigned_to : '',
        },
        {
          type: 'calendar',
          name: 'due_date',
          label: 'Due Date:',
          value: itemData && itemData.due_date ? itemData.due_date : '',
        },
        {
          type: 'radios',
          name: 'is_completed',
          label: 'Completed:',
          value: this.state.isCompleted,//itemData && itemData.is_completed ? itemData.is_completed.toString() : '0',
          options: [
            { value: '1', text: 'Yes' },
            { value: '0', text: 'No' },
          ],
          onChange: (isCompleted) => {
            if (isCompleted) {
              this.setState({ isCompleted: isCompleted.toString(), completedAt: moment().format('YYYY-MM-DD'), completedBy: '' });
            } else {
              this.setState({ isCompleted: isCompleted.toString(), completedAt: '', completedBy: '' });
            }
          },
        },
        this.state.isCompleted === '1' ? {
          type: 'calendar',
          name: 'completed_at',
          label: 'Completed On:',
          value: this.state.completedAt,
          onChange: (date) => {
            this.setState({ completedAt: date });
          },
          maxDate: moment(),
        } : null,
        this.state.isCompleted === '1' ? {
          type: 'text',
          name: 'completed_by',
          label: 'Completed By:',
          value: this.state.completedBy, //itemData && itemData.completed_by ? itemData.completed_by : '',
          onChange: (name) => {
            this.setState({ completedBy: name });
          },
        } : null,
        {
          type: 'textarea',
          name: 'comments',
          label: 'Comments:',
          value: itemData ? itemData.comments : '',
        },
      ],
    }];

    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this.submit}
              showError={this.props.pmoActionItems.error && this.props.pmoActionItems.errorText}
              errorMessage={this.props.pmoActionItems.errorText}
              onReset={this.props.pmoActionItemsShowList.bind(this)}
              loading={this.props.pmoActionItems.inProgress}
            />
          </Col>
        </Row>
      </section>
    );
  }
}

export default InternalProjectsForm;
