import React, { Component } from 'react';
import _ from 'lodash';
import SystemMsg from '../common/system-msg.jsx';
import '../../assets/css/consultant-skills.css';
import ConsultantSkillsList from './list.jsx';

class ConsultantSkills extends Component {
  constructor(props) {
    super(props);
    const { session } = this.props;
    const { user } = session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'consultant_skills') {
          permissions = area;
        }
      });
    }

    this.state = {
      permissions
    };
  }

  componentDidMount() {
    this.props.getConsultantSkillsGetIndex();
  }

  componentWillReceiveProps(nextProps) {
    // Session + Permissions
    const { session } = this.props;
    if (!_.isEqual(session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'consultant_skills') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  render() {
    const { permissions } = this.state;
    const { consultantSkills } = this.props;

    if (
      !permissions
      || (
        !permissions.can_read
        && !permissions.can_create
        && !permissions.can_delete
        && !permissions.can_update
        && !permissions.limit_to_own_user
      )
    ) {
      const { router } = this.props;
      router.push('/404');
      return null;
    }

    // Index Ready
    return (
      <section className="consultant-skills-content">
        { consultantSkills.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        { consultantSkills.errorList ? <SystemMsg msg={consultantSkills.errorText } /> : ''}
        <div className="box">
          <div className="box-header with-border">
            <h1>
              Consultant Skills
            </h1>
          </div>
          <div className="box-content">
            <ConsultantSkillsList
              permissions={permissions}
              {...this.props}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default ConsultantSkills;
