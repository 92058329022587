import React, {Component} from 'react';
import SettingsImportList from './list.jsx'
import SettingsImportForm from './form.jsx'
import SystemMsg from '../../common/system-msg.jsx'
import { Col, Row, Button, Modal} from 'react-bootstrap';
import "assets/css/import-process.css";

class SettingsImport extends Component {
  constructor(props) {
  	super(props);

    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'settings') {
          permissions = area;
        }
      });
    }

  	this.state = {
      showModal: false,
      alreadyScheduled: false,
      permissions,
    };
  }

  componentWillUnmount() {
    this.props.settingsImportHideForm()
    this.props.settingsImportHideSuccess()
    this.props.settingsImportShowList()
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.settingsImport.processStart && this.props.settingsImport.processStart !== nextProps.settingsImport.processStart){
      this.setState({alreadyScheduled: true})
      this._showModal()
    }
  }

  getTitle() {
    if(this.props.settingsImport.showList){
      return (
        <Row>
          <Col xs={12} className="title-section">
            <h1 className="box-title">Import Process</h1>
            {
              //<Button bsStyle="link">No Grouping <span className="fa fa-caret-down"></span></Button>
            }
            <Button bsSize="small" bsStyle="success" onClick={this.props.settingsImportProcess.bind(this)} disabled={this.state.alreadyScheduled}>Import Data</Button>
          </Col>
        </Row>



      )
    }else if(this.props.settingsImport.showForm){
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.settingsImportShowList.bind(this)}><span className="fa fa-arrow-circle-o-left"></span> Back</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">{this.props.settingsImport.itemId?"Edit Project Likelihood":"Add New Project Likelihood"}</h1>
            </Col>
          </Row>
        </div>


      )
    }
  }

  _showModal() {
    this.setState({showModal: true})
  }

  _hideModal() {
    this.setState({showModal: false})
  }

  renderModal() {
    return (
      <Modal show={this.state.showModal} onHide={this._hideModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Process Scheduled</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>The import process was scheduled and will finish in the next 10 minutes.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this._hideModal.bind(this)}>Close</Button>
          </Modal.Footer>
        </Modal>
    )
  }

  render() {
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className="import-process-content">
        { this.props.settingsImport.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this.getTitle()}
          </div>
          <div className="box-content">
            {false && this.props.settingsImport.showList?<SettingsImportList {...this.props} />:""}
            {this.props.settingsImport.showForm?<SettingsImportForm {...this.props} />:""}
          </div>
        </div>

        {this.renderModal()}



      </section>
    )
  }
}

export default SettingsImport
