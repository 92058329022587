import React, {Component} from 'react';
import SmartForm from '../form/base.jsx'
import { Col, Row} from 'react-bootstrap';

class RecruitingForm extends Component{

  componentWillMount() {
    if(this.props.recruits.itemId){
      this.props.recruitsItem(this.props.recruits.itemId)
    }
    this.props.settingsRecruitingStagesList()
    this.props.settingsRecruitingSourcesList()
    this.props.settingsLevelsList()
  }

  componentWillReceiveProps(nextProps) {
    if((nextProps.recruits.addSuccess && nextProps.recruits.addSuccess !== this.props.recruits.addSuccess) || (nextProps.recruits.editSuccess && nextProps.recruits.editSuccess !== this.props.recruits.editSuccess)){
      this.props.recruitsShowList()
    }
  }

  render(){
    let itemData

    if(this.props.recruits.itemId){
      itemData = this.props.recruits.itemData
    }

    let recruitingSteps = [], recruitingSources = [], levels = []

    for(let i = 0; i < this.props.settingsRecruitingStages.data.length; i++){
      recruitingSteps.push(
        {
            name: this.props.settingsRecruitingStages.data[i].name,
            value: this.props.settingsRecruitingStages.data[i].id
        }
      )
    }

    for(let i = 0; i < this.props.settingsRecruitingSources.data.length; i++){
      recruitingSources.push(
        {
            name: this.props.settingsRecruitingSources.data[i].name,
            value: this.props.settingsRecruitingSources.data[i].id
        }
      )
    }

    for(let i = 0; i < this.props.settingsLevels.data.length; i++){
      levels.push(
        {
            name: this.props.settingsLevels.data[i].name,
            value: this.props.settingsLevels.data[i].id
        }
      )
    }

    const schema = [
      {
        type: "default",
        fields: [
          {
            type: "text",
            name: "name",
            label: "Name:",
            value: itemData?itemData.name:''
          },
          {
            type: "select",
            name: "recruit_source_id",
            label: "Source:",
            value: itemData?itemData.recruit_source_id:'',
            options: recruitingSources
          },
          {
            type: "select",
            name: "user_role_id",
            label: "Level:",
            value: itemData?itemData.user_role_id:'',
            options: levels

          },
          {
            type: "select",
            name: "recruit_stage_id",
            label: "Stage in Process:",
            value: itemData?itemData.recruit_stage_id:'',
            options: recruitingSteps
          },
          {
            type: "radios",
            name: "is_offered",
            label: "Offered?",
            value: itemData?itemData.is_offered.toString():'',
            options: [
              {
                text: "Yes",
                value: "1"
              },
              {
                text: "No",
                value: "0"
              }
            ]
          },
          {
            type: "radios",
            name: "is_active",
            label: "Status:",
            value: itemData?itemData.is_active.toString():'',
            options: [
              {
                text: "Active",
                value: "1"
              },
              {
                text: "Inactive",
                value: "0"
              }
            ]
          },
          {
            type: "text",
            name: "last_employer",
            label: "Last Employer:",
            value: itemData?itemData.last_employer:''

          },
          {
            type: "text",
            name: "school",
            label: "School:",
            value: itemData?itemData.school:''

          },
          {
            type: "textarea",
            name: "note",
            label: "Notes:",
            value: itemData?itemData.note:''

          }
        ]
      }
    ]
    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this._submit.bind(this)}
              showError={this.props.recruits.error && this.props.recruits.errorText}
              errorMessage={ this.props.recruits.errorText }
              onReset={this.props.recruitsShowList.bind(this)}
              loading= { this.props.recruits.inProgress || this.props.settingsLevels.inProgress || this.props.settingsRecruitingSources.inProgress || this.props.settingsRecruitingStages.inProgress } />
          </Col>
        </Row>


      </section>
    )
  }

  _submit(data){
    if(this.props.recruits.itemId){
      this.props.recruitsUpdateItem(this.props.recruits.itemId, data)
    }else{
      this.props.recruitsAddItem(data)
    }
  }


}

export default RecruitingForm
