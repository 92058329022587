import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';

export const REALIZATION_LAST_3_MONTHS_PENDING = 'REALIZATION_LAST_3_MONTHS_PENDING';
export const realizationLast3MonthsPending = makeActionCreator(REALIZATION_LAST_3_MONTHS_PENDING);

export const REALIZATION_LAST_3_MONTHS_REJECTED = 'REALIZATION_LAST_3_MONTHS_REJECTED';
export const realizationLast3MonthsRejected = makeActionCreator(REALIZATION_LAST_3_MONTHS_REJECTED);

// Show Index

export const REALIZATION_LAST_3_MONTHS_FULFILLED = 'REALIZATION_LAST_3_MONTHS_FULFILLED';
export const realizationLast3MonthsListFulfilled = makeActionCreator(REALIZATION_LAST_3_MONTHS_FULFILLED, 'data');

export function realizationLast3MonthsList(summaryStatus, summaryBillable, summaryBillableBy) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(realizationLast3MonthsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.last3Months.version}${endpoints.last3Months.path}?status=${summaryStatus}&billable=${summaryBillable}&billable_by=${summaryBillableBy}`, {
        method: endpoints.last3Months.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then(json => dispatch(realizationLast3MonthsListFulfilled(json.data)))
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(realizationLast3MonthsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
