const initialState = {
  inProgress: true,
  error: false,
  errorText: null,
};

function ConsultantNetworkingReducer(state = initialState, action) {
  const actionName = 'CONSULTANT_NETWORKING';
  switch (action.type) {
    case `${actionName}_PENDING`:
      return {
        ...state,
        inProgress: true,
        error: false,
        errorText: null,
      };
    case `${actionName}_FULFILLED`:
      return {
        ...state,
        inProgress: false,
        error: false,
        data: action.data,
      };
    case `${actionName}_REJECTED`:
      return {
        ...state,
        inProgress: false,
        error: action.error,
      };
    case `${actionName}_ERROR`:
      return {
        ...state,
        inProgress: false,
        errorList: true,
        errorText: action.message,
      };
    default:
      return state;
  }
}

export default ConsultantNetworkingReducer;
