import React, {Component} from 'react';
//import {Checkbox} from 'react-icheck';
import { FormGroup, ControlLabel, Checkbox } from 'react-bootstrap'
import '../../assets/icheck/skins/all.css';
import '../../assets/css/input.css'

class CheckBox extends Component {
  render() {
    const schema = this.props.schema
    const { label, checked,onChange } = this.props

    return (
      <FormGroup  className={this._changeClass(schema)} controlId={ schema.id? schema.id  :schema.name } >
        <ControlLabel>
          { schema.label }
        </ControlLabel>
          <Checkbox
            checkboxClass="icheckbox_square-blue"
            label={label}
            onChange={onChange}
            checked={checked}
          />
      </FormGroup>
    )
  }

  _changeClass(schema){
    if(schema.disabled){
      return "field disabled"
    }
    return "field"
  }
}


export default CheckBox;
