import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { Router, Route, IndexRoute } from 'react-router';
import { sessionService } from 'redux-react-session';
import { store, history } from './store';
// import { registerServiceWorker, unregister} from './registerServiceWorker';

import App from './components/app';
import ConsultingHub from './components/consulting-hub/base.jsx';
import Login from './components/login/login.jsx';
import RecoverPasswordRequest from './components/login/recover-password.jsx';
import RecoverPassword from './components/login/change-password.jsx';
import Dashboard from './components/dashboard/base.jsx';
import Home from './components/dashboard/home.jsx';
import BusinessDeveploment from './components/business-development/base.jsx';
import Recruiting from './components/recruiting/base.jsx';
import Staffing from './components/staffing/base.jsx';
import Realization from './components/realization/base.jsx';
import Utilization from './components/utilization/base.jsx';
import Invoices from './components/invoices-issued/base.jsx';
// import SettingsRecruitStages from './components/settings/recruit-stages/base.jsx';
// import SettingsRecruitSources from './components/settings/recruit-sources/base.jsx';
// import SettingsProjectLikelihood from './components/settings/project-likelihood/base.jsx';
import SettingsLevels from './components/settings/levels/base.jsx';
import SettingsRates from './components/settings/rates/base.jsx';
import SettingsAnnouncements from './components/settings/announcements/base.jsx';
import Users from './components/users/base.jsx';
import Import from './components/settings/import/base.jsx';
import InternalProjects from './components/internal-projects/base.jsx';
import ProfitSharingPoints from './components/settings/profit-sharing-points/base.jsx';
import ProfitSharingValues from './components/settings/profit-sharing-values/base.jsx';
import ProfitSharing from './components/profit-sharing/base.jsx';
import StockOptions from './components/stock-options/base.jsx';
import StockValue from './components/settings/stock-value/base.jsx';
import EditUser from './components/users/edit-user.jsx';
import UserPermissions from './components/settings/permissions/base.jsx';
import Resources from './components/settings/resources/base.jsx';
import University from './components/resources/university.jsx';
import Insights from './components/resources/insights.jsx';
import Methodologies from './components/resources/methodologies.jsx';
import ConsultantLocations from './components/consultant-locations/base.jsx';
import ConsultantNetworking from './components/consultant-networking/base.jsx';
import ConsultantNetworkingSessions from './components/consultant-networking/sessions/base.jsx';
import ConsultantSkills from './components/consultant-skills/base.jsx';
import ConsultantSkillsForm from './components/consultant-skills/form.jsx';
import Mentorship from './components/mentorship/base.jsx';
import MentorshipForm from './components/mentorship/form.jsx';
import PMO from './components/pmo/base.jsx';
import PMOProject from './components/pmo/project.jsx';
import PMOIssueManagement from './components/pmo/issue-management/base.jsx';
import PMOActionItem from './components/pmo/action-item/base.jsx';
import PMOChangeControl from './components/pmo/change-control/base.jsx';
import PMOMilestone from './components/pmo/milestone/base.jsx';
import PMORiskManagement from './components/pmo/risk-management/base.jsx';

import Navigation from './components/common/nav.jsx';
import Header from './components/common/header.jsx';
import Error404 from './components/common/404.jsx';

ReactDOM.render(
  <Provider store={store}>
    { /* ConnectedRouter will use the store from Provider automatically */ }
    <Router history={history}>
      <Route path="/" component={App}>
        <IndexRoute
          onEnter={sessionService.checkAuth}
          components={{ main: Home, nav: Navigation, header: Header }}
        />
        <Route onEnter={sessionService.checkAuth} path="/dashboard" components={{ main: Dashboard, nav: Navigation, header: Header }} />

        {/* Operations Section */}
        <Route onEnter={sessionService.checkAuth} path="/business-development" components={{ main: BusinessDeveploment, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/recruiting" components={{ main: Recruiting, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/realization" components={{ main: Realization, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/staffing" components={{ main: Staffing, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/utilization" components={{ main: Utilization, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/invoices" components={{ main: Invoices, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/consulting-hub" components={{ main: ConsultingHub, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/university" components={{ main: University, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/insights" components={{ main: Insights, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/methodologies" components={{ main: Methodologies, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/consultant-locations" components={{ main: ConsultantLocations, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/consultant-networking" components={{ main: ConsultantNetworking, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/consultant-networking/:userId" components={{ main: ConsultantNetworkingSessions, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/consultant-skills" components={{ main: ConsultantSkills, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/consultant-skills/:userId" components={{ main: ConsultantSkillsForm, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/mentorship" components={{ main: Mentorship, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/mentorship/:userId" components={{ main: MentorshipForm, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/pmo" components={{ main: PMO, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/pmo/project/:projectId" components={{ main: PMOProject, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/pmo/project/:projectId/issue-management" components={{ main: PMOIssueManagement, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/pmo/project/:projectId/change-control" components={{ main: PMOChangeControl, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/pmo/project/:projectId/action-item" components={{ main: PMOActionItem, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/pmo/project/:projectId/risk-management" components={{ main: PMORiskManagement, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/pmo/project/:projectId/milestone" components={{ main: PMOMilestone, nav: Navigation, header: Header }} />

        {/* People Section */}
        <Route onEnter={sessionService.checkAuth} path="/people" components={{ main: Users, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/internal-projects" components={{ main: InternalProjects, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/profit-sharing" components={{ main: ProfitSharing, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/stock-options" components={{ main: StockOptions, nav: Navigation, header: Header }} />

        {/* settings */}
        {/* <Route onEnter={sessionService.checkAuth} path="/settings/recruit-stages" components={{ main: SettingsRecruitStages, nav: Navigation, header: Header }} /> */}
        {/* <Route onEnter={sessionService.checkAuth} path="/settings/recruit-sources" components={{ main: SettingsRecruitSources, nav: Navigation, header: Header }} /> */}
        {/* <Route onEnter={sessionService.checkAuth} path="/settings/project-likelihood" components={{ main: SettingsProjectLikelihood, nav: Navigation, header: Header }} /> */}
        <Route onEnter={sessionService.checkAuth} path="/settings/levels" components={{ main: SettingsLevels, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/settings/rates" components={{ main: SettingsRates, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/settings/announcements" components={{ main: SettingsAnnouncements, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/settings/import" components={{ main: Import, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/settings/profit-sharing-points" components={{ main: ProfitSharingPoints, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/settings/profit-sharing-values" components={{ main: ProfitSharingValues, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/settings/stock-value" components={{ main: StockValue, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/settings/users-permissions" components={{ main: UserPermissions, nav: Navigation, header: Header }} />
        <Route onEnter={sessionService.checkAuth} path="/settings/resources" components={{ main: Resources, nav: Navigation, header: Header }} />

        <Route onEnter={sessionService.checkAuth} path="/edit-user" components={{ main: EditUser, nav: Navigation, header: Header }} />

        <Route path="/forgot-password" components={{ main: RecoverPasswordRequest }} />
        <Route path="/users/password-recovery/:token" components={{ main: RecoverPassword }} />
        <Route path="/login" components={{ main: Login }} />

        <Route path="*" components={{ main: Error404 }} />
      </Route>
    </Router>
  </Provider>,
  document.getElementById('root'),
);

// registerServiceWorker();
// unregister()
