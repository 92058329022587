import makeActionCreator from '../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API       from '../utils/config-api'
import * as endpoints from './endpoints'
import { logout } from '../auth/list';


export const PROFIT_SHARING_PENDING = 'PROFIT_SHARING_PENDING'
export const profitSharingPending = makeActionCreator(PROFIT_SHARING_PENDING)

export const PROFIT_SHARING_REJECTED = 'PROFIT_SHARING_REJECTED'
export const profitSharingRejected = makeActionCreator(PROFIT_SHARING_REJECTED)

export const PROFIT_SHARING_SHOW_LIST= 'PROFIT_SHARING_SHOW_LIST'
export const profitSharingShowList = makeActionCreator(PROFIT_SHARING_SHOW_LIST)

export const PROFIT_SHARING_HIDE_LIST = 'PROFIT_SHARING_HIDE_LIST'
export const profitSharingHideList = makeActionCreator(PROFIT_SHARING_HIDE_LIST)

export const PROFIT_SHARING_SHOW_FORM= 'PROFIT_SHARING_SHOW_FORM'
export const profitSharingShowForm = makeActionCreator(PROFIT_SHARING_SHOW_FORM, 'itemId')

export const PROFIT_SHARING_HIDE_FORM = 'PROFIT_SHARING_HIDE_FORM'
export const profitSharingHideForm = makeActionCreator(PROFIT_SHARING_HIDE_FORM)

export const PROFIT_SHARING_SHOW_ITEM = 'PROFIT_SHARING_SHOW_ITEM'
export const profitSharingShowItem = makeActionCreator(PROFIT_SHARING_SHOW_ITEM, 'itemId')


//Show Index

export const PROFIT_SHARING_LIST_FULFILLED = 'PROFIT_SHARING_LIST_FULFILLED'
export const profitSharingListFulfilled = makeActionCreator(PROFIT_SHARING_LIST_FULFILLED, 'data')

export const PROFIT_SHARING_LIST_ERROR = 'PROFIT_SHARING_LIST_ERROR'
export const profitSharingListError = makeActionCreator(PROFIT_SHARING_LIST_ERROR, 'message')

export function profitSharingList(status, year) {
  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(profitSharingPending())
      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}?user_status=${status}&year=${year}`, {
          method  : endpoints.index.method,
          headers : {
              'Authorization' : `Bearer ${currentSession.token}`,
              'Accept': 'application/json'
          }
      })
      .then( API.checkStatus )
      .then( API.parseJSON )
      .then( json => dispatch(profitSharingListFulfilled(json.data)) )
      .catch( err => {
          // Dispatch the error action with error information
          dispatch(profitSharingRejected(err))
      })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));
    })
  }
}
