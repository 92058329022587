import React, { Component } from 'react';
import isMobile from 'ismobilejs';
import _ from 'lodash';
import { sessionService } from 'redux-react-session';
import moment from 'moment';

import '../assets/css/main.css';
import '../assets/bootstrap/css/bootstrap.min.css';
import '../assets/bootstrap/css/AdminLTE.min.css';
import '../assets/bootstrap/skins/_all-skins.min.css';

// import '../assets/jquery/jquery.min.js'
// import '../assets/bootstrap/js/bootstrap.min.js';

const env = process.env.NODE_ENV;

class Main extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsedNav: false,
      openedMobile: false,
      user: null,
    };
  }

  componentWillMount() {
    if (
      this.props.routing.locationBeforeTransitions.pathname.indexOf('login') === -1 &&
      this.props.routing.locationBeforeTransitions.pathname.indexOf('forgot-password') === -1 &&
      this.props.routing.locationBeforeTransitions.pathname.indexOf('users/password-recovery') === -1
    ) {
      sessionService.loadSession().then((sessionData) => {
        if (sessionData.token && moment().unix() > sessionData.expires_at) {
          this.props.logout();
        } else {
          sessionService.loadUser().then((userData) => {
            if (!userData) {
              this.props.logout();
            }
          }).catch((err) => {
            this.props.logout();
          });
        }
      }).catch((err) => {
        this.props.logout();
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.logout && nextProps.auth.logout !== this.props.auth.logout) {
      nextProps.router.push('/login');
    }
    if (!_.isEqual(this.props.routing, nextProps.routing)) {
      if (
        this.props.routing.locationBeforeTransitions.pathname !== nextProps.routing.locationBeforeTransitions.pathname &&
        nextProps.routing.locationBeforeTransitions.pathname.indexOf('login') === -1 &&
        nextProps.routing.locationBeforeTransitions.pathname.indexOf('forgot-password') === -1
      ) {
        sessionService.loadSession().then((sessionData) => {
          if (sessionData.token && moment().unix() > sessionData.expires_at) {
            this.props.logout();
          } else {
            sessionService.loadUser().then((userData) => {
              if (!userData) {
                this.props.logout();
              }
            }).catch((err) => {
              this.props.logout();
            });
          }
        }).catch((err) => {
          this.props.logout();
        });
      }
    }

    if (!nextProps.session.authenticated && this.props.session.authenticated !== nextProps.session.authenticated) {
      this.props.logout();
    }
  }

  getMainClass() {
    let mainClass = 'root-content';

    if (this.state.collapsedNav) {
      mainClass += ' skin-blue sidebar-mini sidebar-collapse';
    } else {
      mainClass += ' skin-blue sidebar-mini';
    }

    if (this.state.openedMobile) {
      mainClass += ' skin-blue sidebar-mini sidebar-open';
    } else {
      mainClass += ' skin-blue sidebar-mini';
    }

    return mainClass;
  }

  collapseNav() {
    const mobile = new isMobile.Class(window.navigator.userAgent);
    const bootstrapSmallDeviceBreakPoint = 768;
    let screenWithIsLessThanBreakpoint = window.innerWidth < bootstrapSmallDeviceBreakPoint;
    if (mobile.any || screenWithIsLessThanBreakpoint) {
      this.setState({ collapsedNav: false });
      this.setState({ openedMobile: !this.state.openedMobile });
    } else {
      this.setState({ collapsedNav: !this.state.collapsedNav });
      this.setState({ openedMobile: false });
    }
  }

  render() {
    const { main, nav, header } = this.props;
    const { user } = this.state;
    const section = this.props.location.pathname.split('/').filter(Boolean);
    let sectionClassname = 'main';

    if (section && section.length > 0) {
      sectionClassname += `-${section[0]}`;
    }

    return (
      <div className={this.getMainClass()}>
        <div className="wrapper">
          {header
            ? React.cloneElement(
              header,
              {
                ...this.props,
                env,
                collapseNav: this.collapseNav.bind(this),
                user,
              },
            )
            : null}

          <main
            className={sectionClassname}
          >
            {nav ? React.cloneElement(nav, { ...this.props, env }) : null}
            {nav ? (
              <div className="content-wrapper">
                {React.cloneElement(main, { ...this.props, env })}
              </div>
            )
              : React.cloneElement(main, { ...this.props, env })}

          </main>
        </div>


      </div>
    );
  }
}

export default Main;
