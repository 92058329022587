import makeActionCreator from '../../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API       from '../../utils/config-api'
import * as endpoints from './endpoints'
import { logout } from '../../auth/list';


export const SETTINGS_ANNOUNCEMENTS_PENDING = 'SETTINGS_ANNOUNCEMENTS_PENDING'
export const settingsAnnouncementsPending = makeActionCreator(SETTINGS_ANNOUNCEMENTS_PENDING)

export const SETTINGS_ANNOUNCEMENTS_REJECTED = 'SETTINGS_ANNOUNCEMENTS_REJECTED'
export const settingsAnnouncementsRejected = makeActionCreator(SETTINGS_ANNOUNCEMENTS_REJECTED)

export const SETTINGS_ANNOUNCEMENTS_SHOW_LIST= 'SETTINGS_ANNOUNCEMENTS_SHOW_LIST'
export const settingsAnnouncementsShowList = makeActionCreator(SETTINGS_ANNOUNCEMENTS_SHOW_LIST)

export const SETTINGS_ANNOUNCEMENTS_HIDE_LIST = 'SETTINGS_ANNOUNCEMENTS_HIDE_LIST'
export const settingsAnnouncementsHideList = makeActionCreator(SETTINGS_ANNOUNCEMENTS_HIDE_LIST)

export const SETTINGS_ANNOUNCEMENTS_SHOW_FORM= 'SETTINGS_ANNOUNCEMENTS_SHOW_FORM'
export const settingsAnnouncementsShowForm = makeActionCreator(SETTINGS_ANNOUNCEMENTS_SHOW_FORM, 'itemId')

export const SETTINGS_ANNOUNCEMENTS_HIDE_FORM = 'SETTINGS_ANNOUNCEMENTS_HIDE_FORM'
export const settingsAnnouncementsHideForm = makeActionCreator(SETTINGS_ANNOUNCEMENTS_HIDE_FORM)

export const SETTINGS_ANNOUNCEMENTS_SHOW_ITEM = 'SETTINGS_ANNOUNCEMENTS_SHOW_ITEM'
export const settingsAnnouncementsShowItem = makeActionCreator(SETTINGS_ANNOUNCEMENTS_SHOW_ITEM, 'itemId')


//Show Index

export const SETTINGS_ANNOUNCEMENTS_LIST_FULFILLED = 'SETTINGS_ANNOUNCEMENTS_LIST_FULFILLED'
export const settingsAnnouncementsListFulfilled = makeActionCreator(SETTINGS_ANNOUNCEMENTS_LIST_FULFILLED, 'data')

export const SETTINGS_ANNOUNCEMENTS_LIST_ERROR = 'SETTINGS_ANNOUNCEMENTS_LIST_ERROR'
export const settingsAnnouncementsListError = makeActionCreator(SETTINGS_ANNOUNCEMENTS_LIST_ERROR, 'message')

export function settingsAnnouncementsList() {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsAnnouncementsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}`, {
              method  : endpoints.index.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
            if (json.status === 'ok') {
              dispatch(settingsAnnouncementsListFulfilled(json.data));
            } else {
              dispatch(settingsAnnouncementsRejected(json));
            }
          })
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsAnnouncementsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}


//Show Item

export const SETTINGS_ANNOUNCEMENTS_ITEM_FULFILLED = 'SETTINGS_ANNOUNCEMENTS_ITEM_FULFILLED'
export const settingsAnnouncementsItemFulfilled = makeActionCreator(SETTINGS_ANNOUNCEMENTS_ITEM_FULFILLED, 'data')

export const SETTINGS_ANNOUNCEMENTS_ITEM_ERROR = 'SETTINGS_ANNOUNCEMENTS_ITEM_ERROR'
export const settingsAnnouncementsItemError = makeActionCreator(SETTINGS_ANNOUNCEMENTS_ITEM_ERROR, 'message')

export function settingsAnnouncementsItem(itemId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsAnnouncementsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getItem.version}${endpoints.getItem.path.replace(":announcement_id", itemId)}`, {
              method  : endpoints.getItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(settingsAnnouncementsItemFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsAnnouncementsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}


//Add Item

export const SETTINGS_ANNOUNCEMENTS_ITEM_ADD_FULFILLED = 'SETTINGS_ANNOUNCEMENTS_ITEM_ADD_FULFILLED'
export const settingsAnnouncementsItemAddFulfilled = makeActionCreator(SETTINGS_ANNOUNCEMENTS_ITEM_ADD_FULFILLED, 'data')

export const SETTINGS_ANNOUNCEMENTS_ITEM_ADD_ERROR = 'SETTINGS_ANNOUNCEMENTS_ITEM_ADD_ERROR'
export const settingsAnnouncementsItemAddError = makeActionCreator(SETTINGS_ANNOUNCEMENTS_ITEM_ADD_ERROR, 'message')

export function settingsAnnouncementsAddItem(data) {
  let formData = new FormData()

  Object.keys(data).map((key)=> {
    formData.append(key, data[key]);
    return key;
  });
  // formData.append("publication_date", data["publication_date"]);
  // formData.append("is_public", data["is_public"]);
  // formData.append("content", data["content"]);


  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsAnnouncementsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addItem.version}${endpoints.addItem.path}`, {
              method  : endpoints.addItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(settingsAnnouncementsItemAddFulfilled(json.data))
              }else{
                dispatch(settingsAnnouncementsItemAddError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsAnnouncementsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}

//Update Item

export const SETTINGS_ANNOUNCEMENTS_ITEM_UPDATE_FULFILLED = 'SETTINGS_ANNOUNCEMENTS_ITEM_UPDATE_FULFILLED'
export const settingsAnnouncementsItemUpdateFulfilled = makeActionCreator(SETTINGS_ANNOUNCEMENTS_ITEM_UPDATE_FULFILLED, 'data')

export const SETTINGS_ANNOUNCEMENTS_ITEM_UPDATE_ERROR = 'SETTINGS_ANNOUNCEMENTS_ITEM_UPDATE_ERROR'
export const settingsAnnouncementsItemUpdateError = makeActionCreator(SETTINGS_ANNOUNCEMENTS_ITEM_UPDATE_ERROR, 'message')

export function settingsAnnouncementsUpdateItem(itemId, data) {
  let formData = new FormData()

  formData.append('_method', 'PUT')
  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });


  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsAnnouncementsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateItem.version}${endpoints.updateItem.path.replace(":announcement_id", itemId)}`, {
              method  : endpoints.updateItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(settingsAnnouncementsItemUpdateFulfilled(json.data))
              }else{
                dispatch(settingsAnnouncementsItemUpdateError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsAnnouncementsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}

//Delete Item

export const SETTINGS_ANNOUNCEMENTS_ITEM_DELETE_FULFILLED = 'SETTINGS_ANNOUNCEMENTS_ITEM_DELETE_FULFILLED'
export const settingsAnnouncementsItemDeleteFulfilled = makeActionCreator(SETTINGS_ANNOUNCEMENTS_ITEM_DELETE_FULFILLED, 'data')

export const SETTINGS_ANNOUNCEMENTS_ITEM_DELETE_ERROR = 'SETTINGS_ANNOUNCEMENTS_ITEM_DELETE_ERROR'
export const settingsAnnouncementsItemDeleteError = makeActionCreator(SETTINGS_ANNOUNCEMENTS_ITEM_DELETE_ERROR, 'message')

export function settingsAnnouncementsDeleteItem(itemId) {
 // console.log(itemId);
  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingsAnnouncementsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteItem.version}${endpoints.deleteItem.path.replace(":announcement_id", itemId)}`, {
              method  : endpoints.deleteItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
            if(json.status === "ok"){
              dispatch(settingsAnnouncementsItemDeleteFulfilled(json.data))
              dispatch(settingsAnnouncementsList())
            }else{
              dispatch(settingsAnnouncementsItemDeleteError(json.message))
            }

          })
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingsAnnouncementsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })





  }
}
