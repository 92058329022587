import React, { Component } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import _ from 'lodash';
import cookie from 'react-cookies';

import PMOProjectsList from './list.jsx';
import PMOProjectsForm from './form.jsx';
import SystemMsg from '../common/system-msg.jsx';
import 'assets/css/pmo.css';
import SmartForm from '../form/base.jsx';

class PMOProjects extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'pmo') {
          permissions = area;
        }
      });
    }

    this.state = {
      permissions,
      status: '9999',
    };
  }

  componentWillMount() {
    const filtersCookie = cookie.load('pmo_projects_filters') || {};

    this.setState(
      {
        status: filtersCookie && filtersCookie.status ? filtersCookie.status : this.state.status,
      },
    );
  }

  componentDidMount() {
    this.props.pmoProjectsList(this.state.status);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'pmo') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  componentWillUnmount() {
    this.props.pmoProjectsHideForm();
    this.props.pmoProjectsShowList();
  }

  getTitle() {
    if (this.props.pmoProjects.showList) {
      return (
        <Row>
          <Col xs={4} className="title-section">
            <h1 className="box-title">
              PMO
            </h1>
            {this.state.permissions.can_create ? (
              <Button bsSize="small" bsStyle="success" onClick={this.props.pmoProjectsShowForm.bind(this, "newItem")}>
                Add New
              </Button>
            ) : null
          }
          </Col>
          <Col xs={8} className="action-section">
            <SmartForm
              schema={[
                {
                  type: 'default',
                  fields: [
                    {
                      type: 'select',
                      name: 'active',
                      label: 'Status:',
                      value: this.state.status,
                      options: [
                        {
                          name: 'All',
                          value: '9999'
                        },
                        {
                          name: 'Active',
                          value: '1'
                        },
                        {
                          name: 'Inactive',
                          value: '0'
                        },
                        {
                          name: 'Archived',
                          value: '2'
                        }
                      ],
                      onChange: this.changeStatus.bind(this)
                    },
                  ],
                },
              ]}
              inline
              buttons={[]}
            />
          </Col>
        </Row>
      );
    }

    if (this.props.pmoProjects.showForm) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.pmoProjectsShowList.bind(this)}>
                <span className="fa fa-arrow-circle-o-left" />
                &nbsp;Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">
                {this.props.pmoProjects.itemId ? 'Edit Project' : 'Add New Project'}
              </h1>
            </Col>
          </Row>
        </div>
      );
    }

    return null;
  }

  changeStatus(value) {
    this.setState({ status: value });
    this.props.pmoProjectsList(value);

    cookie.save('pmo_projects_filters', JSON.stringify({ status: value }), { path: '/' });
  }

  render() {
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className={`pmo-content ${this.props.pmoProjects.showList ? 'minWidth' : ''}`}>
        { this.props.pmoProjects.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        {this.props.pmoProjects.errorList ? <SystemMsg msg={this.props.pmoProjects.errorText } /> : ''}
        <div className="box">
          <div className="box-header with-border">
            {this.getTitle()}
          </div>
          <div className="box-content">
            {this.props.pmoProjects.showList ? <PMOProjectsList permissions={this.state.permissions} listStatus={this.state.status} {...this.props} /> : ''}
            {this.props.pmoProjects.showForm ? <PMOProjectsForm permissions={this.state.permissions} listStatus={this.state.status} {...this.props} /> : ''}
          </div>
        </div>
      </section>
    )
  }
}

export default PMOProjects
