//
// Login:
//

export const index = {
    path   : '/users/:user_id/role_assignments/',
    method : 'GET',
    version: '/v1'
}

export const getItem = {
  path   : '/users/:user_id/role_assignments/:user_role_assignment_id/',
  method : 'GET',
  version: '/v1'
}

export const addItem = {
  path   : '/users/:user_id/role_assignments/',
  method : 'POST',
  version: '/v1'
}

export const updateItem = {
  path   : '/users/:user_id/role_assignments/:user_role_assignment_id/',
  method : 'POST',
  version: '/v1'
}

export const deleteItem = {
  path   : '/users/:user_id/role_assignments/:user_role_assignment_id/',
  method : 'DELETE',
  version: '/v1'
}
