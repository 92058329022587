import React, { Component } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, SearchField } from 'react-bootstrap-table';
import moment from 'moment';
import cookie from 'react-cookies';
import 'assets/css/table.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import 'assets/css/resources.css';
import ppt from '../../assets/img/ic_ppt.svg';
import movie from '../../assets/img/ic_movie.svg';
import dl from '../../assets/img/ic_download.svg';


class RecruitingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort_name: '',
      sort_order: '',
    };

    this.renderButtons = this.renderButtons.bind(this);
    this.dateFormatter = this.dateFormatter.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentWillMount() {
    this.setState(
      {
        sort_name: cookie.load('sort_column_resources') || 'created_at',
        sort_order: cookie.load('sort_order_resources') || 'desc',
      },
    );
  }

  onSortChange(sortName, sortOrder) {
    cookie.save('sort_column_resources', sortName, { path: '/' });
    cookie.save('sort_order_resources', sortOrder, { path: '/' });

    this.setState({
      sort_name: sortName,
      sort_order: sortOrder,
    });
  }

  dateFormatter(cell, row) {
    return moment(cell).format('YYYY-MM-DD');
  }

  sortObjectFunc(a, b, order, sortField, extraData) { // order is desc or asc
    const fieldName = extraData.objectField;

    if (order === 'asc') {
      return a[sortField][fieldName].localeCompare(b[sortField][fieldName]);
    }
    return b[sortField][fieldName].localeCompare(a[sortField][fieldName]);
  }

  onPageChange(page) {
    this.props.getPageList(page);

    this.setState({ currentPage: page });
  }


  renderButtons(cell, row) {
    return (
      <div className="actions">
        <OverlayTrigger
          placement="top"
          overlay={(
            <Tooltip id="tooltip-top">
Download Powerpoint
            </Tooltip>
)}
        >
          <Button
            href={row.resource_1_url}
            download={`${row.name}-resource-1`}
            target="_blank"
            bsStyle="link"
            disabled={!row.resource_1_url}
          >
            <img src={ppt} aria-hidden="true" alt="download powerpoint icon" />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={(
            <Tooltip id="tooltip-top">
Download Video
            </Tooltip>
)}
        >
          <Button
            href={row.resource_2_url}
            download={`${row.name}-resource-2`}
            target="_blank"
            bsStyle="link"
            disabled={!row.resource_2_url}
          >
            <img src={movie} aria-hidden="true" alt="download video icon" />
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={(
            <Tooltip id="tooltip-top">
Download All
            </Tooltip>
)}
        >
          <Button
            href={row.all_resources_url}
            download={`${row.name}-resource-3-all`}
            target="_blank"
            bsStyle="link"
            disabled={!row.all_resources_url}
          >
            <img src={dl} aria-hidden="true" alt="download all icon" />
          </Button>
        </OverlayTrigger>
      </div>
    );
  }

  getPaginationInfo() {
    const {
      type,
    } = this.props;

    const fetchInfo = {
      dataTotalSize: 0,
    };

    const currentData = {
      first_page_url: '',
      from: null,
      last_page: null,
      last_page_url: '',
      per_page: 12,
      prev_page_url: '',
      to: null,
    };

    if (type === 'university' && this.props.settingsUniversityResources && this.props.settingsUniversityResources.pagination) {
      fetchInfo.dataTotalSize = this.props.settingsUniversityResources.pagination.total;

      currentData.first_page_url = this.props.settingsUniversityResources.pagination.first_page_url;
      currentData.from = this.props.settingsUniversityResources.pagination.from;
      currentData.last_page = this.props.settingsUniversityResources.pagination.last_page;
      currentData.last_page_url = this.props.settingsUniversityResources.pagination.last_page_url;
      currentData.per_page = this.props.settingsUniversityResources.pagination.per_page;
      currentData.prev_page_url = this.props.settingsUniversityResources.pagination.prev_page_url;
      currentData.to = this.props.settingsUniversityResources.pagination.to;
    }

    if (type === 'insights' && this.props.settingsInsightsResources && this.props.settingsInsightsResources.pagination) {
      fetchInfo.dataTotalSize = this.props.settingsInsightsResources.pagination.total;

      currentData.first_page_url = this.props.settingsInsightsResources.pagination.first_page_url;
      currentData.from = this.props.settingsInsightsResources.pagination.from;
      currentData.last_page = this.props.settingsInsightsResources.pagination.last_page;
      currentData.last_page_url = this.props.settingsInsightsResources.pagination.last_page_url;
      currentData.per_page = this.props.settingsInsightsResources.pagination.per_page;
      currentData.prev_page_url = this.props.settingsInsightsResources.pagination.prev_page_url;
      currentData.to = this.props.settingsInsightsResources.pagination.to;
    }

    if (type === 'methodologies' && this.props.settingsMethodologiesResources && this.props.settingsMethodologiesResources.pagination) {
      fetchInfo.dataTotalSize = this.props.settingsMethodologiesResources.pagination.total;

      currentData.first_page_url = this.props.settingsMethodologiesResources.pagination.first_page_url;
      currentData.from = this.props.settingsMethodologiesResources.pagination.from;
      currentData.last_page = this.props.settingsMethodologiesResources.pagination.last_page;
      currentData.last_page_url = this.props.settingsMethodologiesResources.pagination.last_page_url;
      currentData.per_page = this.props.settingsMethodologiesResources.pagination.per_page;
      currentData.prev_page_url = this.props.settingsMethodologiesResources.pagination.prev_page_url;
      currentData.to = this.props.settingsMethodologiesResources.pagination.to;
    }

    return {
      fetchInfo,
      currentData,
    };
  }

  remote() {
    const remoteObj = {};
    // Only cell editing, insert and delete row will be handled by remote store
    remoteObj.pagination = true;
    return remoteObj;
  }

  render() {
    const paginationData = this.getPaginationInfo();
    const list = this.props.data;
    const createCustomSearchField = () => (
      <SearchField className="search-control" />
    );
    const options = {
      defaultSortName: this.state.sort_name,
      defaultSortOrder: this.state.sort_order,
      onSortChange: this.onSortChange,
      paginationShowsTotal: true,
      sizePerPage: paginationData.currentData.per_page,
      onPageChange: this.onPageChange,
      page: this.state.currentPage,
      hideSizePerPage: true,
      searchField: createCustomSearchField,
    };

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={list}
          headerStyle={{
            backgroundColor: 'transparent',
            borderBottom: '0',
            paddingLeft: '3em',
          }}
          tableBodyClass="table-spacing"
          trClassName="cell-style"
          search
          options={options}
          headerContainerClass="fixed-header"
          pagination
          remote={this.remote}
          fetchInfo={paginationData.fetchInfo}
          ignoreSinglePage
          bordered={false}
        >
          <TableHeaderColumn
            dataField="name"
            columnTitle
            className="header-padding"
            dataSort
            isKey
            columnClassName="column-data"
          >
            Title
          </TableHeaderColumn>
          { this.props.type === 'university' ? (
            <TableHeaderColumn
              searchable={false}
              dataField="type"
              columnTitle
              className="header-padding"
              dataSort
              filterFormatted
              columnClassName="column-data"
            >
              Type
            </TableHeaderColumn>
          ) : (
            <TableHeaderColumn
              searchable={false}
              dataField="version"
              columnTitle
              className="header-padding"
              dataSort
              filterFormatted
              columnClassName="column-data"
            >
              Version
            </TableHeaderColumn>
          )}

          <TableHeaderColumn
            searchable={false}
            dataField="created_at"
            columnTitle
            className="header-padding"
            dataSort
            dataFormat={this.dateFormatter}
            filterFormatted
            columnClassName="column-data-2"
          >
            Date
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="buttons"
            dataFormat={this.renderButtons}
            dataAlign="right"
            headerAlign="left"
            columnClassName="column-data"
          />
        </BootstrapTable>
      </section>
    );
  }
}

export default RecruitingList;
