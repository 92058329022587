import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

import SmartForm from '../../form/base.jsx';

class ConsultantNetworkingSessionForm extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    if (this.props.consultantNetworkingSessions.itemId) {
      this.props.consultantNetworkingSessionItem(this.props.userId, this.props.consultantNetworkingSessions.itemId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (
        nextProps.consultantNetworkingSessions.addSuccess &&
        nextProps.consultantNetworkingSessions.addSuccess !== this.props.consultantNetworkingSessions.addSuccess
      ) ||
      (
        nextProps.consultantNetworkingSessions.editSuccess &&
        nextProps.consultantNetworkingSessions.editSuccess !== this.props.consultantNetworkingSessions.editSuccess
      )
    ) {
      this.props.consultantNetworkingSessionShowList();
    }
  }

  submit(data) {

    const {
      consultantNetworkingSessionUpdateItem,
      consultantNetworkingSessionAddItem,
      consultantNetworkingSessions,
      listStatus,
      userId
    } = this.props;

    if (consultantNetworkingSessions.itemId) {
      consultantNetworkingSessionUpdateItem(
        userId,
        consultantNetworkingSessions.itemId,
        data,
        listStatus,
      );
    } else {
      consultantNetworkingSessionAddItem(
        userId, data, listStatus,
      );
    }
  }

  render() {
    const { consultantNetworkingSessions } = this.props;


    let itemData;
    if (consultantNetworkingSessions.itemId) {
      itemData = consultantNetworkingSessions.itemData;
    }

    const schema = [{
      type: 'default',
      fields: [
        {
          type: 'text',
          name: 'met_with',
          label: 'Met with:',
          value: itemData && itemData.met_with ? itemData.met_with : '',
        },
        {
          type: 'text',
          name: 'company',
          label: 'Company:',
          value: itemData && itemData.company ? itemData.company : '',
        },
        {
          type: 'text',
          name: 'position',
          label: 'Position:',
          value: itemData && itemData.position ? itemData.position : '',
        },
        {
          type: 'calendar',
          name: 'date',
          label: 'Date:',
          value: itemData && itemData.date ? itemData.date : moment().format('YYYY-MM-DD'),
        },
        {
          type: 'textarea',
          name: 'outcome',
          label: 'Outcome:',
          value: itemData && itemData.outcome ? itemData.outcome : '',
        },
        {
          type: 'text',
          name: 'follow_up',
          label: 'Follow Up:',
          value: itemData && itemData.follow_up ? itemData.follow_up : '',
        },
        {
          type: 'number',
          name: 'expenses',
          label: 'Expenses:',
          value: itemData && itemData.expenses ? itemData.expenses : 0,
        }
      ],
    }];

    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this.submit}
              showError={
                consultantNetworkingSessions.error
                && consultantNetworkingSessions.errorText
              }
              errorMessage={consultantNetworkingSessions.errorText}
              onReset={this.props.consultantNetworkingSessionShowList.bind(this)}
              loading={consultantNetworkingSessions.inProgress}
            />
          </Col>
        </Row>
      </section>
    );
  }
}

export default ConsultantNetworkingSessionForm;
