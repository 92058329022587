const initialState = {
  filterBy: [''],
  inProgress: false,
  error: false,
  errorText: null,
  data: [],
  showList: true,
  showForm: false,
  showItem: null,
  addSuccess: false,
  itemId: null,
  itemData: null,
  editSuccess: false,
  permissionData: null,
};

function SettingsPermissionsReducer(state = initialState, action) {
  const actionName = 'SETTINGS_PERMISSIONS';
  switch (action.type) {
    case `${actionName}_PENDING`:
      return {
        ...state,
        inProgress: true,
        error: false,
        errorText: null,
        addSuccess: false,
        editSuccess: false,
        data: [],
      };
    case `${actionName}_LIST_FULFILLED`:
      return {
        ...state,
        inProgress: false,
        error: false,
        permissionData: action.data,
      };
    case `${actionName}_REJECTED`:
      return {
        ...state,
        inProgress: false,
        error: action.error,
      };
    case `${actionName}_SHOW_LIST`:
      return {
        ...state,
        showList: true,
        showForm: false,
        itemId: null,
        itemData: null,
      };
    case `${actionName}_HIDE_LIST`:
      return {
        ...state,
        permissionData: null,
      };
    case `${actionName}_SHOW_FORM`:
      return {
        ...state,
        showForm: true,
        showList: false,
        itemId: action.itemId !== 'newItem' ? action.itemId : '',
      };
    case `${actionName}_HIDE_FORM`:
      return {
        ...state,
        showForm: false,
      };
    case `${actionName}_ITEM_UPDATE_FULFILLED`:
      return {
        ...state,
        inProgress: false,
        editSuccess: true,
        permissionData: action.data,
      };
    case `${actionName}_ITEM_UPDATE_ERROR`:
      return {
        ...state,
        inProgress: false,
        error: true,
        errorText: action.message,
      };
    default:
      return state;
  }
}

export default SettingsPermissionsReducer;
