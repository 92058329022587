import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';

import SmartForm from '../../form/base.jsx';

class pmoIssueManagementForm extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    const {
      itemData,
    } = this.props.pmoIssueManagement;

    this.state = {
      isClosed: itemData && itemData.is_closed ? !!itemData.is_closed : false,
      closedAt: itemData && itemData.closed_at ? itemData.closed_at : moment(),
    };
  }

  componentWillMount() {
    if (this.props.pmoIssueManagement.itemId) {
      this.props.pmoIssueManagementItem(this.props.projectId, this.props.pmoIssueManagement.itemId);
    }

    this.props.userList();
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.pmoIssueManagement.addSuccess && nextProps.pmoIssueManagement.addSuccess !== this.props.pmoIssueManagement.addSuccess) || (nextProps.pmoIssueManagement.editSuccess && nextProps.pmoIssueManagement.editSuccess !== this.props.pmoIssueManagement.editSuccess)) {
      this.props.pmoIssueManagementShowList();
    }

    if (!_.isEqual(this.props.pmoIssueManagement.itemData, nextProps.pmoIssueManagement.itemData)) {
      const {
        itemData,
      } = nextProps.pmoIssueManagement;

      this.setState({
        isClosed: itemData && itemData.is_closed ? !!itemData.is_closed : false,
        closedAt: itemData && itemData.closed_at ? itemData.closed_at : moment(),
      });
    }
  }

  submit(data) {
    if (this.props.pmoIssueManagement.itemId) {
      this.props.pmoIssueManagementUpdateItem(this.props.projectId, this.props.pmoIssueManagement.itemId, data, this.props.listStatus);
    } else {
      this.props.pmoIssueManagementAddItem(this.props.projectId, data, this.props.listStatus);
    }
  }

  render() {
    let itemData;
    if (this.props.pmoIssueManagement.itemId) {
      itemData = this.props.pmoIssueManagement.itemData;
    }

    const schema = [{
      type: 'default',
      fields: [
        {
          type: 'text',
          name: 'area',
          label: 'Area:',
          value: itemData ? itemData.area : '',
        },
        {
          type: 'textarea',
          name: 'description',
          label: 'Description:',
          value: itemData && itemData.description ? itemData.description : '',
        },
        {
          type: 'calendar',
          name: 'manually_created_at',
          label: 'Created On:',
          value: itemData && itemData.manually_created_at ? itemData.manually_created_at : moment().format('YYYY-MM-DD'),
        },
        {
          type: 'text',
          name: 'created_by',
          label: 'Created By:',
          value: itemData && itemData.created_by ? itemData.created_by : '',
        },
        {
          type: 'text',
          name: 'assigned_to',
          label: 'Assigned To:',
          value: itemData && itemData.assigned_to ? itemData.assigned_to : '',
        },
        {
          type: 'calendar',
          name: 'due_date',
          label: 'Due Date:',
          value: itemData && itemData.due_date ? itemData.due_date : '',
        },
        {
          type: 'radios',
          name: 'is_closed',
          label: 'Closed:',
          value: itemData && itemData.is_closed ? itemData.is_closed.toString() : '0',
          options: [
            { value: '1', text: 'Yes' },
            { value: '0', text: 'No' },
          ],
          onChange: (isClosed) => {
            this.setState({ isClosed: isClosed === '1' });
          },
        },
        this.state.isClosed ? {
          type: 'calendar',
          name: 'closed_at',
          label: 'Close On:',
          value: this.state.closedAt,
          onChange: (date) => {
            this.setState({ closedAt: date });
          },
          maxDate: moment(),
        } : null,
        this.state.isClosed ? {
          type: 'text',
          name: 'closed_by',
          label: 'Closed By:',
          value: itemData && itemData.closed_by ? itemData.closed_by : '',
        } : null,
        {
          type: 'textarea',
          name: 'comments',
          label: 'Comments:',
          value: itemData ? itemData.comments : '',
        },
      ],
    }];

    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this.submit}
              showError={this.props.pmoIssueManagement.error && this.props.pmoIssueManagement.errorText}
              errorMessage={this.props.pmoIssueManagement.errorText}
              onReset={this.props.pmoIssueManagementShowList.bind(this)}
              loading={this.props.pmoIssueManagement.inProgress}
            />
          </Col>
        </Row>
      </section>
    );
  }
}

export default pmoIssueManagementForm;
