import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import _ from 'lodash';
import ResourcesList from './list.jsx';
import SystemMsg from '../common/system-msg.jsx';
import 'assets/css/recruiting.css';

class University extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'university_resources') {
          permissions = area;
        }
      });
    }

    this.state = {
      permissions,
    };
    this.getPageList = this.getPageList.bind(this);
  }

  componentWillMount() {
    this.props.resourcesUniversityList(1);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'university_resources') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  getPageList(page) {
    this.props.resourcesUniversityList(page);
  }

  getTitle() {
    return (
      <Row>
        <Col xs={4} className="title-section">
          <h1>
            Headstorm University
          </h1>
        </Col>
      </Row>
    );
  }

  render() {
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className="recruiting-content minWidth">
          { this.props.settingsUniversityResources.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
          <div className="box-header with-border">
              {this.getTitle()}
          </div>
          <div className="box-content">
            <ResourcesList
              permissions={this.state.permissions}
              data={this.props.settingsUniversityResources.data}
              type="university"
              getPageList={this.getPageList}
              {...this.props}
            />
          </div>
      </section>
    );
  }
}

export default University;
