import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import cookie from 'react-cookies';
import 'assets/css/table.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      itemId: null,
      sortName: '',
      sortOrder: '',
    };

    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
  }

  componentWillMount() {
    this.setState(
      {
        sortName: cookie.load('sort_column_users') || 'name',
        sortOrder: cookie.load('sort_order_users') || 'asc',
      },
    );
  }

  onSortChange(sortName, sortOrder) {
    cookie.save('sort_column_users', sortName, { path: '/' });
    cookie.save('sort_order_users', sortOrder, { path: '/' });
    this.setState({
      sortName,
      sortOrder,
    });
  }

  columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {
    let className = '';

    if (!row.harvest_is_active) {
      className += ' inactive';
    } else if (!row.role) {
      className += ' no-role';
    }

    return className;
  }


  closeDeleteModal() {
    this.setState({ showModal: false, itemId: null });
  }

  _openDeleteModal(itemId) {
    this.setState({ showModal: true, itemId });
  }

  boolFormatter(cell) {
    return cell ? 'Yes' : 'No';
  }

  dateFormatter(cell, row) {
    if (cell) {
      return moment(cell).format('YYYY-MM-DD');
    }

    return '-';
  }

  _activeFormatter(cell, row){
    return cell ? 'Active' : 'Inactive';
  }

  objectFormatter(field, cell, row) {
    if (cell && cell[field]) {
      return cell[field];
    }

    return '';
  }

  sortObjectFunc(a, b, order, sortField, extraData) {   // order is desc or asc
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return (a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'').localeCompare(b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'')
    } else {
      return (b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'').localeCompare(a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'');
    }

  }

  confirmDelete(itemId) {
    this.props.userDeleteItem(itemId);
    this.closeDeleteModal();
  }

  renderButtons(cell, row) {
    return (
      <div className="actions">
        {this.props.permissions.can_update ? (
          <Button bsStyle="success" onClick={this.props.userShowForm.bind(this, row.id)}>
            <i className="fa fa-pencil" aria-hidden="true" />
          </Button>
        ) : null}
      </div>
    );
  }

  renderModal() {
    let name = '';
    const {
      itemId,
      showModal,
    } = this.state;

    if (itemId && this.props.user.data.length > 0) {
      this.props.user.data.forEach((user) => {
        if (user.id === itemId) {
          ({ name } = user);
        }
      });
    }

    return (
      <Modal show={showModal} onHide={this.closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm Delete
            &nbsp;
            {name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete this element?
          </p>
          <p>
            All the infomation related to this Recruit will be deleted.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.confirmDelete.bind(this, this.state.itemId)} bsStyle="danger">
            Delete
          </Button>
          <Button onClick={this.closeDeleteModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const list = this.props.user.data;
    const {
      sortOrder,
      sortName,
    } = this.state;
    const options = {
      expandRowBgColor: 'rgb(242, 255, 163)',
      // afterColumnFilter: this._afterColumnFilter.bind(this),
      onSortChange: this.onSortChange.bind(this),
      defaultSortName: sortName,
      defaultSortOrder: sortOrder,
    };

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={list}
          striped
          hover
          options={options}
          headerContainerClass="fixed-header"
        >
          <TableHeaderColumn
            dataField="name"
            columnTitle
            filter={
              { type: 'TextFilter', delay: 500 }
            }
            isKey
            dataSort
            columnClassName={this.columnClassNameFormat}
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="role"
            columnTitle
            dataSort
            dataFormat={this.objectFormatter.bind(this, 'name')}
            columnClassName={this.columnClassNameFormat}
            filterFormatted
            sortFunc={this.sortObjectFunc}
            sortFuncExtraData={
              { objectField: 'name' }
            }
          >
            Level
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="harvest_is_active"
            dataSort
            dataFormat={this.boolFormatter}
            columnClassName={this.columnClassNameFormat}
            dataAlign="center"
            headerAlign="left"
            width="90px"
          >
            Active?
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="hired_at"
            dataSort
            dataFormat={this.dateFormatter}
            columnClassName={this.columnClassNameFormat}
            dataAlign="center"
            headerAlign="left"
            width="120px"
          >
            Date Added
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="buttons"
            dataFormat={this.renderButtons}
            columnClassName={this.columnClassNameFormat}
            dataAlign="center"
            headerAlign="left"
            width="100px"
            hidden={!this.props.permissions.can_update}
          />
        </BootstrapTable>
        {this.renderModal()}
      </section>
    );
  }
}

export default UsersList;
