import makeActionCreator from '../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API       from '../utils/config-api'
import * as endpoints from './endpoints'
import { logout } from '../auth/list';


export const USER_LEVEL_PENDING = 'USER_LEVEL_PENDING'
export const userLevelPending = makeActionCreator(USER_LEVEL_PENDING)

export const USER_LEVEL_REJECTED = 'USER_LEVEL_REJECTED'
export const userLevelRejected = makeActionCreator(USER_LEVEL_REJECTED)

export const USER_LEVEL_SHOW_LIST= 'USER_LEVEL_SHOW_LIST'
export const userLevelShowList = makeActionCreator(USER_LEVEL_SHOW_LIST)

export const USER_LEVEL_HIDE_LIST = 'USER_LEVEL_HIDE_LIST'
export const userLevelHideList = makeActionCreator(USER_LEVEL_HIDE_LIST)

export const USER_LEVEL_SHOW_FORM= 'USER_LEVEL_SHOW_FORM'
export const userLevelShowForm = makeActionCreator(USER_LEVEL_SHOW_FORM, 'itemId')

export const USER_LEVEL_HIDE_FORM = 'USER_LEVEL_HIDE_FORM'
export const userLevelHideForm = makeActionCreator(USER_LEVEL_HIDE_FORM)

export const USER_LEVEL_SHOW_ITEM = 'USER_LEVEL_SHOW_ITEM'
export const userLevelShowItem = makeActionCreator(USER_LEVEL_SHOW_ITEM, 'itemId')


//Show Index

export const USER_LEVEL_LIST_FULFILLED = 'USER_LEVEL_LIST_FULFILLED'
export const userLevelListFulfilled = makeActionCreator(USER_LEVEL_LIST_FULFILLED, 'data')

export const USER_LEVEL_LIST_ERROR = 'USER_LEVEL_LIST_ERROR'
export const userLevelListError = makeActionCreator(USER_LEVEL_LIST_ERROR, 'message')

export function userLevelList(userId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(userLevelPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path.replace(":user_id", userId)}`, {
              method  : endpoints.index.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(userLevelListFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(userLevelRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}


//Show Item

export const USER_LEVEL_ITEM_FULFILLED = 'USER_LEVEL_ITEM_FULFILLED'
export const userLevelItemFulfilled = makeActionCreator(USER_LEVEL_ITEM_FULFILLED, 'data')

export const USER_LEVEL_ITEM_ERROR = 'USER_LEVEL_ITEM_ERROR'
export const userLevelItemError = makeActionCreator(USER_LEVEL_ITEM_ERROR, 'message')

export function userLevelItem(userId, itemId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(userLevelPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getItem.version}${endpoints.getItem.path.replace(":user_id", userId).replace(":user_role_assignment_id", itemId)}`, {
              method  : endpoints.getItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(userLevelItemFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(userLevelRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}


//Add Item

export const USER_LEVEL_ITEM_ADD_FULFILLED = 'USER_LEVEL_ITEM_ADD_FULFILLED'
export const userLevelItemAddFulfilled = makeActionCreator(USER_LEVEL_ITEM_ADD_FULFILLED, 'data')

export const USER_LEVEL_ITEM_ADD_ERROR = 'USER_LEVEL_ITEM_ADD_ERROR'
export const userLevelItemAddError = makeActionCreator(USER_LEVEL_ITEM_ADD_ERROR, 'message')

export function userLevelAddItem(data) {
  let formData = new FormData()
  let userId = data["user_id"]

  formData.append("start_date", data["start_date"]);
  formData.append("user_role_id", data["user_role_id"]);

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(userLevelPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addItem.version}${endpoints.addItem.path.replace(":user_id", userId)}`, {
              method  : endpoints.addItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(userLevelItemAddFulfilled(json.data))
              }else{
                dispatch(userLevelItemAddError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(userLevelRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}

//Update Item

export const USER_LEVEL_ITEM_UPDATE_FULFILLED = 'USER_LEVEL_ITEM_UPDATE_FULFILLED'
export const userLevelItemUpdateFulfilled = makeActionCreator(USER_LEVEL_ITEM_UPDATE_FULFILLED, 'data')

export const USER_LEVEL_ITEM_UPDATE_ERROR = 'USER_LEVEL_ITEM_UPDATE_ERROR'
export const userLevelItemUpdateError = makeActionCreator(USER_LEVEL_ITEM_UPDATE_ERROR, 'message')

export function userLevelUpdateItem(itemId, data) {
  let formData = new FormData()
  let userId = data["user_id"]
  formData.append('_method', 'PUT')
  formData.append("start_date", data["start_date"]);
  formData.append("user_role_id", data["user_role_id"]);


  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(userLevelPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateItem.version}${endpoints.updateItem.path.replace(":user_id", userId).replace(":user_role_assignment_id", itemId)}`, {
              method  : endpoints.updateItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(userLevelItemUpdateFulfilled(json.data))
              }else{
                dispatch(userLevelItemUpdateError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(userLevelRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));
    })



  }
}

//Delete Item

export const USER_LEVEL_ITEM_DELETE_FULFILLED = 'USER_LEVEL_ITEM_DELETE_FULFILLED'
export const userLevelItemDeleteFulfilled = makeActionCreator(USER_LEVEL_ITEM_DELETE_FULFILLED, 'data')

export const USER_LEVEL_ITEM_DELETE_ERROR = 'USER_LEVEL_ITEM_DELETE_ERROR'
export const userLevelItemDeleteError = makeActionCreator(USER_LEVEL_ITEM_DELETE_ERROR, 'message')

export function userLevelDeleteItem(userId, itemId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(userLevelPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteItem.version}${endpoints.deleteItem.path.replace(":user_id", userId).replace(":user_role_assignment_id", itemId)}`, {
              method  : endpoints.deleteItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
            if(json.status === "ok"){
              dispatch(userLevelItemDeleteFulfilled(json.data))
              dispatch(userLevelList(userId))
            }else{
              dispatch(userLevelItemDeleteError(json.message))
            }

          })
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(userLevelRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })





  }
}
