export const index = {
  path: '/consultant_weekly_locations/',
  method: 'GET',
  version: '/v1',
};

export const updateItem = {
  path: '/consultant_weekly_locations/:user_id/',
  method: 'POST',
  version: '/v1',
};

export const addFavorite = {
  path: '/favorite_user/:favorite_user_id/',
  method: 'POST',
  version: '/v1',
};

export const removeFavorite = {
  path: '/favorite_user/:favorite_user_id/',
  method: 'DELETE',
  version: '/v1',
};
