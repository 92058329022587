import React, {Component} from 'react';
import SettingsProfitSharingValueList from './list.jsx'
import SettingsProfitSharingValueForm from './form.jsx'
import SystemMsg from '../../common/system-msg.jsx'
import { Col, Row, Button} from 'react-bootstrap';
import "assets/css/settings-recruiting-sources.css";
import SmartForm from '../../form/base.jsx'
import moment from 'moment'
import cookie from 'react-cookies'

class SettingsProfitSharingValue extends Component{
  constructor(props){
  	super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'settings') {
          permissions = area;
        }
      });
    }
  	this.state = {
      year: moment(),
      permissions,
    };
  }

  componentWillMount() {
    const filtersCookie = cookie.load('profitSharingValues_filters') || {}
    let year = filtersCookie && filtersCookie.year?moment(filtersCookie.year):this.state.year

    this.setState(
      {
        year: year,
      }
    )

    this.props.settingProfitSharingValuesList(year.format("YYYY"))
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.settingProfitSharingValues.showList && this.props.settingProfitSharingValues.showList !== nextProps.settingProfitSharingValues.showList){
      this.props.settingProfitSharingValuesList(this.state.year.format("YYYY"))
    }
  }


  componentWillUnmount() {
    this.props.settingProfitSharingValuesHideForm()
    this.props.settingProfitSharingValuesShowList()
  }

  render(){
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className={`settings-recruiting-sources-content ${this.props.settingProfitSharingValues.showList ? 'minWidth' : ''}`}>
        { this.props.settingProfitSharingValues.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this._getTitle()}
          </div>
          <div className="box-content">
            {this.props.settingProfitSharingValues.showList?<SettingsProfitSharingValueList currentYear={this.state.year} {...this.props} />:""}
            {this.props.settingProfitSharingValues.showForm?<SettingsProfitSharingValueForm {...this.props} />:""}
          </div>
        </div>



      </section>
    )
  }

  _getTitle(){
    if(this.props.settingProfitSharingValues.showList){
      return (
        <Row>
          <Col xs={4}  className="title-section">
            <h1 className="box-title">Profit Sharing Values</h1>
            {
              //<Button bsStyle="link">No Grouping <span className="fa fa-caret-down"></span></Button>
            }

            <Button bsSize="small" bsStyle="success" onClick={this.props.settingProfitSharingValuesShowForm.bind(this, "newItem")}>Add New</Button>
          </Col>
          <Col xs={8} className="action-section">
            <SmartForm schema={[
                  {
                    type: "default",
                    fields: [
                      {
                        type: "calendar",
                        name: "year",
                        label: "Year:",
                        value: this.state.year,
                        dateFormat: "YYYY",
                        viewMode: "years",
                        maxDate: moment().endOf("year"),
                        onChange: this._changeYear.bind(this)
                      }
                    ]
                  }
                ]

              }
              inline={true}
              buttons={[]} />

          </Col>
        </Row>



      )
    }else if(this.props.settingProfitSharingValues.showForm){
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.settingProfitSharingValuesShowList.bind(this)}><span className="fa fa-arrow-circle-o-left"></span> Back</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">{this.props.settingProfitSharingValues.itemId?"Edit Profits Sharing Value":"Add New Profits Sharing Value"}</h1>
            </Col>
          </Row>
        </div>


      )
    }
  }

  _changeYear(value){
    let year = value.startOf("year")
    let filtersCookie = cookie.load('profitSharingValues_filters') || {}

    filtersCookie = {
      year: value,
    }

    this.setState({year: year})
    cookie.save('profitSharingValues_filters', JSON.stringify(filtersCookie), { path: '/' });
    this.props.settingProfitSharingValuesList(year.format("YYYY"))
  }
}

export default SettingsProfitSharingValue
