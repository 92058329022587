import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import SmartForm from '../../form/base.jsx';

class SettingsResourcesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    // if (this.props.settingsUniversityResources.itemId) {
    //   this.props.settingsUniversityResourcesItem(this.props.settingsUniversityResources.itemId)
    // }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.settingsUniversityResources.addSuccess && nextProps.settingsUniversityResources.addSuccess !== this.props.settingsUniversityResources.addSuccess) || (nextProps.settingsUniversityResources.editSuccess && nextProps.settingsUniversityResources.editSuccess !== this.props.settingsUniversityResources.editSuccess) ||
      (nextProps.settingsInsightsResources.addSuccess && nextProps.settingsInsightsResources.addSuccess !== this.props.settingsInsightsResources.addSuccess) || (nextProps.settingsInsightsResources.editSuccess && nextProps.settingsInsightsResources.editSuccess !== this.props.settingsInsightsResources.editSuccess) ||
      (nextProps.settingsMethodologiesResources.addSuccess && nextProps.settingsMethodologiesResources.addSuccess !== this.props.settingsMethodologiesResources.addSuccess) || (nextProps.settingsMethodologiesResources.editSuccess && nextProps.settingsMethodologiesResources.editSuccess !== this.props.settingsMethodologiesResources.editSuccess)
    ) {
      this.props.settingsResourcesShowList()
    }
  }

  getItemData(itemId) {
    const {
      type,
    } = this.props;

    let itemData = null;
    if (type === 'university') {
      this.props.settingsUniversityResources.data.forEach((item) => {
        if (item.id === itemId) {
          itemData = item;
        }
      });
    }

    if (type === 'insights') {
      this.props.settingsInsightsResources.data.forEach((item) => {
        if (item.id === itemId) {
          itemData = item;
        }
      });
    }

    if (type === 'methodologies') {
      this.props.settingsMethodologiesResources.data.forEach((item) => {
        if (item.id === itemId) {
          itemData = item;
        }
      });
    }

    return itemData;
  }

  addNewAction(type, data) {
    switch (type) {
      case 'university':
        this.props.settingsUniversityResourcesAddItem(data);
        break;
      case 'insights':
        this.props.settingsInsightsResourcesAddItem(data);
        break;
      case 'methodologies':
        this.props.settingsMethodologiesResourcesAddItem(data);
        break;
      default:
    }
  }

  editAction(type, itemId, data) {
    switch (type) {
      case 'university':
        this.props.settingsUniversityResourcesUpdateItem(itemId, data);
        break;
      case 'insights':
        this.props.settingsInsightsResourcesUpdateItem(itemId, data);
        break;
      case 'methodologies':
        this.props.settingsMethodologiesResourcesUpdateItem(itemId, data);
        break;
      default:
    }
  }

  submit(data) {
    const {
      type,
    } = this.props;

    let dataToSend;

    if (type === 'university') {
      dataToSend = {
        name: data.name,
        type: data.type,
      };
    } else {
      dataToSend = {
        name: data.name,
        version: data.version,
      };
    }

    if (
      (data.resource_1[0] && !data.resource_1[0].saved) ||
      data.resource_1[0] === null
    ) {
      dataToSend.resource_1 = data.resource_1[0];
    }

    if (
      (data.resource_2[0] && !data.resource_2[0].saved) ||
      data.resource_2[0] === null
    ) {
      dataToSend.resource_2 = data.resource_2[0];
    }

    if (
      (data.resource_3[0] && !data.resource_3[0].saved) ||
      data.resource_3[0] === null
    ) {
      dataToSend.resource_3 = data.resource_3[0];
    }

    const {
      itemId,
    } = this.props.settingsResources;

    if (itemId) {
      this.editAction(type, itemId, dataToSend);
    } else {
      this.addNewAction(type, dataToSend);
    }
  }

  render() {
    let itemData;
    let resource1;
    let resource2
    let resource3;

    if (this.props.settingsResources.itemId) {
      itemData = this.getItemData(this.props.settingsResources.itemId);

      if (itemData) {
        if (itemData.resource_1) {
          resource1 = { url: itemData.resource_1_url, saved: true };
        } else {
          resource1 = null;
        }

        if (itemData.resource_2) {
          resource2 = { url: itemData.resource_2_url, saved: true };
        } else {
          resource2 = null;
        }

        if (itemData.resource_3) {
          resource3 = { url: itemData.resource_3_url, saved: true };
        } else {
          resource3 = null;
        }
      }
    }

    let showError = false;

    if (
      (this.props.settingsUniversityResources.error && this.props.settingsUniversityResources.errorText)
      || (this.props.settingsInsightsResources.error && this.props.settingsInsightsResources.errorText)
      || (this.props.settingsMethodologiesResources.error && this.props.settingsMethodologiesResources.errorText)
    ) {
      showError = true;
    }

    let showErrorText = false;

    if (this.props.settingsUniversityResources.errorText) {
      showErrorText = this.props.settingsUniversityResources.errorText;
    }

    if (this.props.settingsInsightsResources.errorText) {
      showErrorText = this.props.settingsInsightsResources.errorText;
    }

    if (this.props.settingsMethodologiesResources.errorText) {
      showErrorText = this.props.settingsMethodologiesResources.errorText;
    }


    const schema = [
      {
        type: 'default',
        fields: [
          {
            type: 'text',
            name: 'name',
            label: 'Name:',
            value: itemData ? itemData.name : '',
          },
          this.props.type === 'university' ? {
            type: 'text',
            name: 'type',
            label: 'Type:',
            value: itemData ? itemData.type : '',
          } : {
            type: 'text',
            name: 'version',
            label: 'Version:',
            value: itemData ? itemData.version : '',
          },
          {
            type: 'upload',
            name: 'resource_1',
            label: 'File Resource 1:',
            value: resource1 ? [resource1] : [],
            limitFiles: 1,
            listTitle: 'Selected File',
            edit: !!itemData || false,
            editAllowNewFilesWithoutDeleting: false,
          },
          {
            type: 'upload',
            name: 'resource_2',
            label: 'File Resource 2:',
            value: resource2 ? [resource2] : [],
            limitFiles: 1,
            listTitle: 'Selected File',
            edit: !!itemData || false,
            editAllowNewFilesWithoutDeleting: false,
          },
          {
            type: 'upload',
            name: 'resource_3',
            label: 'File Resource 3:',
            value: resource3 ? [resource3] : [],
            limitFiles: 1,
            listTitle: 'Selected File',
            edit: !!itemData || false,
            editAllowNewFilesWithoutDeleting: false,
          },
        ],
      },
    ];

    return (
      <section className="section-content">
        <Row>
          <Col xs={12}>
            <SmartForm
              schema={schema}
              onSave={this.submit}
              showError={showError}
              errorMessage={showErrorText}
              onReset={this.props.settingsResourcesShowList}
            />
          </Col>
        </Row>
      </section>
    );
  }
}

export default SettingsResourcesForm;
