import React, {Component} from 'react';
import SmartForm from '../../form/base.jsx'
import { Col, Row} from 'react-bootstrap';
import moment from 'moment'

class SettingStockValueForm extends Component{

  componentWillMount() {
    if(this.props.settingStockValue.itemId){
      this.props.settingStockValueItem(this.props.settingStockValue.itemId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if((nextProps.settingStockValue.addSuccess && nextProps.settingStockValue.addSuccess !== this.props.settingStockValue.addSuccess) || (nextProps.settingStockValue.editSuccess && nextProps.settingStockValue.editSuccess !== this.props.settingStockValue.editSuccess)){
      this.props.settingStockValueShowList()
    }
  }

  render(){
    let itemData

    if(this.props.settingStockValue.itemId && this.props.settingStockValue.itemData){
      itemData = this.props.settingStockValue.itemData
    }


    const schema = [
      {
        type: "default",
        fields: [
          {
            type: "calendar",
            name: "date",
            label: "Date:",
            value: itemData?itemData.date:'',
            dateFormat: "YYYY-MM",
            viewMode: "months",
          },
          {
            type: "number",
            name: "price",
            label: "Price:",
            value: itemData?itemData.price:''
          }
        ]
      }
    ]
    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this._submit.bind(this)}
              showError={this.props.settingStockValue.error && this.props.settingStockValue.errorText}
              errorMessage={ this.props.settingStockValue.errorText }
              onReset={this.props.settingStockValueShowList.bind(this)} />
          </Col>
        </Row>


      </section>
    )
  }

  _submit(data){
    data.date = moment(data.date).startOf("month").format("YYYY-MM-DD")

    if(this.props.settingStockValue.itemId){
      this.props.settingStockValueUpdateItem(this.props.settingStockValue.itemId, data)
    }else{
      this.props.settingStockValueAddItem(data)
    }
  }


}

export default SettingStockValueForm
