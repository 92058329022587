import React, { Component } from 'react';

import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import SmartForm from '../../form/base.jsx';

class RatesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    if (this.props.settingsRates.itemId) {
      this.props.settingsRatesItem(this.props.levelId, this.props.settingsRates.itemId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (
        nextProps.settingsRates.addSuccess &&
        nextProps.settingsRates.addSuccess !== this.props.settingsRates.addSuccess
      ) ||
      (
        nextProps.settingsRates.editSuccess &&
        nextProps.settingsRates.editSuccess !== this.props.settingsRates.editSuccess
      )
    ) {
      this.props.settingsRatesShowList();
    }
  }

  submit(data) {
    data.start_date = moment(data.start_date).startOf("month").format("YYYY-MM-DD")

    if (this.props.settingsRates.itemId) {
      this.props.settingsRatesUpdateItem(this.props.settingsRates.itemId, data)
    } else {
      this.props.settingsRatesAddItem(data)
    }
  }

  render() {
    let itemData;
    const levels = [];

    if (this.props.settingsRates.itemId) {
      itemData = this.props.settingsRates.itemData;
    }

    for (let i = 0; i < this.props.settingsLevels.data.length; i += 1) {
      levels.push(
        {
          name: this.props.settingsLevels.data[i].name,
          value: this.props.settingsLevels.data[i].id,
        },
      );
    }

    const schema = [
      {
        type: 'default',
        fields: [
          {
            type: 'select',
            name: 'user_level_id',
            label: 'Level:',
            value: this.props.levelId,
            disabled: true,
            options: levels
          },
          {
            type: 'calendar',
            name: 'start_date',
            label: 'Start Date:',
            value: itemData ? itemData.start_date:'',
            dateFormat: 'YYYY-MM',
            viewMode: 'months',
          },
          {
            type: 'number',
            name: 'hourly_rate',
            label: 'Hourly Rate:',
            value: itemData ? itemData.hourly_rate:''
          },
        ],
      },
    ];
    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this.submit}
              showError={this.props.settingsRates.error && this.props.settingsRates.errorText}
              errorMessage={this.props.settingsRates.errorText}
              onReset={() => this.props.settingsRatesShowList()}
            />
          </Col>
        </Row>
      </section>
    );
  }
}

export default RatesForm;
