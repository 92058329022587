//
// Login:
//

export const index = {
    path   : '/user_roles/:user_role_id/profit_sharings/',
    method : 'GET',
    version: '/v1'
}

export const getItem = {
  path   : '/user_roles/:user_role_id/profit_sharings/:user_role_profit_sharings_id/',
  method : 'GET',
  version: '/v1'
}

export const addItem = {
  path   : '/user_roles/:user_role_id/profit_sharings/',
  method : 'POST',
  version: '/v1'
}

export const updateItem = {
  path   : '/user_roles/:user_role_id/profit_sharings/:user_role_profit_sharings_id/',
  method : 'POST',
  version: '/v1'
}

export const deleteItem = {
  path   : '/user_roles/:user_role_id/profit_sharings/:user_role_profit_sharings_id/',
  method : 'DELETE',
  version: '/v1'
}
