import React, { Component } from 'react';
// import CheckBox from '../form/checkbox.jsx';
import {
  Row,
  Col,
  Button,
  Alert,
} from 'react-bootstrap';

import SystemMsg from '../common/system-msg.jsx';
import Input from '../form/input.jsx';

class ChangePassword extends Component {
  constructor(props, context) {
    super(props, context);
    const {
      token,
    } = this.props.router.params;
    let email = '';
    if (this.props.router && this.props.router.location && this.props.router.location.query) {
      email = this.props.router.location.query.user_email || '';
    }

    this.state = {
      password: '',
      confirmPassword: '',
      checkNewPasswordContent: false,
      token: token || '',
      email,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangePasswordConfirmation = this.onChangePasswordConfirmation.bind(this);
    this.redirectLogin = this.redirectLogin.bind(this);
  }



  onSubmit(e) {
    e.preventDefault();
    const {
      password,
      confirmPassword,
      email,
      token,
    } = this.state;

    const data = {
      password,
      password_confirmation: confirmPassword,
      email,
      token,
    };

    this.props.resetPassword(data);

    this.setState({ password: '', confirmPassword: '', checkNewPasswordContent: false });
  }

  onChangePassword(value) {
    this.setState({ password: value, checkNewPasswordContent: true });
  }

  onChangePasswordConfirmation(value) {
    this.setState({ confirmPassword: value });
  }

  redirectLogin() {
    this.props.router.push('/');
  }

  render() {
    const {
      password,
      confirmPassword,
      checkNewPasswordContent,
      token,
      email,
    } = this.state;

    if (!email || !token) {
      return (
        <div className="hold-transition password-recovery">
          <div className="login-box">
            <div className="login-logo">
              <a href="/">
                CS Hound
              </a>
            </div>

            <div className="login-box-body">
              <p className="login-box-msg">
                Invalid Link. Please check your email or request another link.
              </p>
            </div>
          </div>
        </div>
      );
    }

    const match = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-])(?=.{8,})/.test(password);

    return (
      <div className="hold-transition password-recovery">
        { this.props.auth.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="login-box">
          <div className="login-logo">
            <a href="/">
              CS Hound
            </a>
          </div>

          <div className="login-box-body">
            {this.props.auth.errorPasswordReset && this.props.auth.errorMessagePasswordReset ? (
              <Alert bsStyle="danger">
                {this.props.auth.errorMessagePasswordReset}
              </Alert>
            ) : ''}

            {this.props.auth.errorPasswordReset && this.props.auth.successMessage ?(
              <Alert bsStyle="success">
                {this.props.auth.successMessage}
              </Alert>
            ) : ''}

            { this.props.auth.errorPasswordReset && this.props.auth.successMessage ?
              null : (
                <p className="login-box-msg">
                  Please select your new password
                </p>
              )}

            { this.props.auth.errorPasswordReset && this.props.auth.successMessage ?
              (
                <Button
                  className="btn-flat"
                  type="button"
                  bsClass="btn"
                  bsStyle="primary"
                  block
                  onClick={this.redirectLogin}
                >
                  Login
                </Button>
              )
              : (
                <form onSubmit={this.onSubmit} method="post">
                  <Input
                    schema={{
                      type: 'password',
                      placeholder: 'Password',
                      className: 'has-feedback',
                      value: password,
                      icon: {
                        name: 'lock',
                        className: 'form-control-feedback',
                      },
                      onChange: this.onChangePassword,
                      empty: password === '',
                      errorText: !match && checkNewPasswordContent ?
                        'Password has to be of at least 8 characters long and have at least one uppercase character, one lower case, one numeric value and one symbol.'
                        : '',
                      validationState: !match && checkNewPasswordContent ? 'error' : '',
                    }}
                  />

                  <Input
                    schema={{
                      type: 'password',
                      placeholder: 'Confirm Password',
                      className: 'has-feedback',
                      value: confirmPassword,
                      icon: {
                        name: 'lock',
                        className: 'form-control-feedback',
                      },
                      onChange: this.onChangePasswordConfirmation,
                      empty: confirmPassword === '',
                      validationState: password !== confirmPassword ? 'error' : '',
                      errorText: password !== confirmPassword ? 'Both fields have to match' : '',
                    }}
                  />

                  <Row>

                    <Col xs={6} />

                    <Col xs={6}>
                      <Button
                        className="btn-flat"
                        type="submit"
                        bsClass="btn"
                        bsStyle="primary"
                        block
                        disabled={!match || password !== confirmPassword}
                      >
                        Change Password
                      </Button>
                    </Col>
                  </Row>
                </form>
              )}
          </div>
        </div>
      </div>
    );
  }
}


export default ChangePassword;
