import React, { Component } from 'react';
import _ from 'lodash';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import 'assets/css/table.css';
import LocationEditor from './locationEditor.jsx';

class ConsultantLocationsForm extends Component {
  constructor(props) {
    super(props);
    let userId = null;
    let name = '';
    let monday = '';
    let tuesday = '';
    let wednesday = '';
    let thursday = '';
    let friday = '';

    if (this.props.consultantLocations.item) {
      userId = this.props.consultantLocations.item.id;
      name = this.props.consultantLocations.item.name;

      if (this.props.consultantLocations.data) {
        this.props.consultantLocations.data.user_locations.forEach((item) => {
          if (item.id === userId && item.consultant_weekly_location) {
            monday = item.consultant_weekly_location.monday;
            tuesday = item.consultant_weekly_location.tuesday;
            wednesday = item.consultant_weekly_location.wednesday;
            thursday = item.consultant_weekly_location.thursday;
            friday = item.consultant_weekly_location.friday;
          }
        });
      }
    }
    this.state = {
      userId,
      name,
      dataToEditMonday: monday,
      dataToEditTuesday: tuesday,
      dataToEditWednesday: wednesday,
      dataToEditThursday: thursday,
      dataToEditFriday: friday,
    };

    this.afterSaveCell = this.afterSaveCell.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.nextWeek !== nextProps.nextWeek) {
      nextProps.consultantLocationsList(nextProps.nextWeek);
    }

    if (!_.isEqual(this.props.consultantLocations.data, nextProps.consultantLocations.data)) {
      let monday = '';
      let tuesday = '';
      let wednesday = '';
      let thursday = '';
      let friday = '';

      if (nextProps.consultantLocations.data) {
        nextProps.consultantLocations.data.user_locations.forEach((item) => {
          if (item.id === this.state.userId && item.consultant_weekly_location) {
            monday = item.consultant_weekly_location.monday;
            tuesday = item.consultant_weekly_location.tuesday;
            wednesday = item.consultant_weekly_location.wednesday;
            thursday = item.consultant_weekly_location.thursday;
            friday = item.consultant_weekly_location.friday;
          }
        });
      }

      this.setState({
        dataToEditMonday: monday,
        dataToEditTuesday: tuesday,
        dataToEditWednesday: wednesday,
        dataToEditThursday: thursday,
        dataToEditFriday: friday,
      });
    }
  }

  trClassFormat(row, rowIndex) {
    return rowIndex % 2 === 0 ? 'tr-odd' : 'tr-even';  // return class name.
  }

  objectFormatter(field, cell, row) {
    if (cell) {
      return cell[field] || '';
    }
    return '';
  }

  sortObjectFunc(a, b, order, sortField, extraData) {   // order is desc or asc
    const fieldName = extraData['objectField'];

    if (order === 'asc') {
      return a[sortField][fieldName].localeCompare(b[sortField][fieldName]);
    }

    return b[sortField][fieldName].localeCompare(a[sortField][fieldName]);
  }

  afterSaveCell(row) {
    const {
      userId,
    } = this.state;

    const data = {
      monday: row.dataToEditMonday,
      tuesday: row.dataToEditTuesday,
      wednesday: row.dataToEditWednesday,
      thursday: row.dataToEditThursday,
      friday: row.dataToEditFriday,
    };

    this.props.consultantLocationsUpdateItem(userId, data, this.props.nextWeek);
  }

  render() {
    const list = [this.state];

    const mondayDate = this.props.consultantLocations.data.monday_date;
    const options = {
      expandRowBgColor: 'rgb(242, 255, 163)',
    };

    const cellEdit = {
      mode: 'click',
      afterSaveCell: this.afterSaveCell,
      blurToSave: true,
    };

    const locationEditor = (onUpdate, props) => (<LocationEditor onUpdate={ onUpdate } {...props}/>);

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={list}
          hover
          options={options}
          ref={(table) => { this.table = table; }}
          headerContainerClass="fixed-header"
          cellEdit={cellEdit}
        >
          <TableHeaderColumn
            dataField="name"
            columnTitle
            isKey
          >
            Consultant Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="dataToEditMonday"
            columnTitle
            customEditor={{ getElement: locationEditor }}
          >
            Monday&nbsp;
            <div className="date">
              {mondayDate}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="dataToEditTuesday"
            columnTitle
            customEditor={{ getElement: locationEditor }}
          >
            Tuesday&nbsp;
            <div className="date">
              {moment(mondayDate).add(1, 'days').format('YYYY-MM-DD')}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="dataToEditWednesday"
            columnTitle
            customEditor={{ getElement: locationEditor }}
          >
            Wednesday&nbsp;
            <div className="date">
              {moment(mondayDate).add(2, 'days').format('YYYY-MM-DD')}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="dataToEditThursday"
            columnTitle
            customEditor={{ getElement: locationEditor }}
          >
            Thursday&nbsp;
            <div className="date">
              {moment(mondayDate).add(3, 'days').format('YYYY-MM-DD')}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="dataToEditFriday"
            columnTitle
            customEditor={{ getElement: locationEditor }}
          >
            Friday&nbsp;
            <div className="date">
              {moment(mondayDate).add(4, 'days').format('YYYY-MM-DD')}
            </div>
          </TableHeaderColumn>
        </BootstrapTable>
      </section>
    );
  }
}


export default ConsultantLocationsForm;
