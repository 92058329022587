import React, { Component } from 'react';
import cookie from 'react-cookies'
import _ from 'lodash';
import { Col, Row, Button} from 'react-bootstrap';

import StockOptionsList from './list.jsx';
import StockOptionsForm from './form.jsx';
import SystemMsg from '../common/system-msg.jsx';
import "assets/css/stock-options.css";
import SmartForm from '../form/base.jsx';


class StockOptions extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'stock_options') {
          permissions = area;
        }
      });
    }
    this.state = {
      status: '2',
      permissions,
    };
  }

  componentWillMount() {
    const filtersCookie = cookie.load('stockOptions_filters') || {}

    this.setState(
      {
        status: (filtersCookie && filtersCookie.status) ? filtersCookie.status : this.state.status,
      }
    )
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.stockOptions.showList &&
      this.props.stockOptions.showList !== nextProps.stockOptions.showList
    ) {
      this.props.stockOptionsList(this.state.status)
    }

    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'stock_options') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  componentWillUnmount() {
    this.props.stockOptionsHideForm()
    this.props.stockOptionsShowList()
  }

  getTitle() {
    if (this.props.stockOptions.showList) {
      return (
        <Row>
          <Col xs={4} className="title-section">
            <h1 className="box-title">
              Stock Options
            </h1>
            {
              //<Button bsStyle="link">No Grouping <span className="fa fa-caret-down"></span></Button>
            }
            {this.state.permissions.can_create ? (
              <Button bsSize="small" bsStyle="success" onClick={this.props.stockOptionsShowForm.bind(this, "newItem")}>
                Add New
              </Button>
            ) : null}
          </Col>
          <Col xs={8} className="action-section">
            <SmartForm
              schema={[{
                type: "default",
                fields: [
                  {
                    type: "select",
                    name: "active",
                    label: "User Status:",
                    value: this.state.status,
                    options: [
                      {
                        name: "Both",
                        value: "2"
                      },
                      {
                        name: "Active",
                        value: "1"
                      },
                      {
                        name: "Inactive",
                        value: "0"
                      }
                    ],
                    onChange: this.changeStatus.bind(this)
                  }
                ]
              }]}
              inline={true}
              buttons={[]}
            />
          </Col>
        </Row>
      )
    }

    if (this.props.stockOptions.showForm) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.stockOptionsShowList.bind(this)}><span className="fa fa-arrow-circle-o-left"></span> Back</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">{this.props.stockOptions.itemId?"Edit Stock Options":"Add New Stock Options"}</h1>
            </Col>
          </Row>
        </div>
      )
    }
    return null;
  }

  changeStatus(value) {
    let filtersCookie = cookie.load('stockOptions_filters') || {}

    filtersCookie = {
      status: value
    }

    this.setState({status: value})
    cookie.save('stockOptions_filters', JSON.stringify(filtersCookie), { path: '/' });
  }

  render() {
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }
    return (
      <section className={`stock-options-content ${this.props.stockOptions.showList ? 'minWidth' : ''}`}>
        { this.props.stockOptions.inProgress  ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this.getTitle()}
          </div>
          <div className="box-content">
            {this.props.stockOptions.showList?<StockOptionsList permissions={this.state.permissions} {...this.props} statusFilter={this.state.status}/>:""}
            {this.props.stockOptions.showForm?<StockOptionsForm permissions={this.state.permissions} {...this.props} />:""}
          </div>
        </div>
      </section>
    )
  }
}

export default StockOptions
