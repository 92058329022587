import React, { Component } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import _ from 'lodash';

import ConsultantLocationList from './list.jsx';
import ConsultantLocationForm from './form.jsx';
import SystemMsg from '../common/system-msg.jsx';
import 'assets/css/consultant-location.css';
import SmartForm from '../form/base.jsx';

class ConsultantLocations extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'consultant_locations') {
          permissions = area;
        }
      });
    }
    let myUserData = null;
    if (this.props.consultantLocations.data.length > 0) {
      this.props.consultantLocations.data.forEach((item) => {
        if (item.id === user.id) {
          myUserData = item
        }
      });
    }

    this.state = {
      permissions,
      nextWeek: 0,
      myUserData,
      favorites: '0',
      showOnlyWithMissingLocations: '0',
      locationData: [],
      locationDataToShow: [],
    };

    this.changeMissing = this.changeMissing.bind(this);
    this.changeWeek = this.changeWeek.bind(this);
    this.changeFavorite = this.changeFavorite.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'consultant_locations') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }

    if (nextProps.consultantLocations.data.length > 0 && !_.isEqual(this.props.consultantLocations.data, nextProps.consultantLocations.data)) {
      const { user } = nextProps.session;
      let currentUserData = null;
      let currentEditingUserData = null;

      nextProps.consultantLocations.data.forEach((item) => {
        if (item.id === user.id) {
          currentUserData = item;
        }

        if (nextProps.consultantLocations.showForm && nextProps.consultantLocations.item && item.id === nextProps.consultantLocations.item.id) {
          currentEditingUserData = item;
        }
      });
      if (currentUserData) {
        this.setState({ myUserData: currentUserData });
      }

      if (nextProps.consultantLocations.showForm && nextProps.consultantLocations.item) {
        if (nextProps.consultantLocations.item.id === user.id) {
          nextProps.consultantLocationsShowForm(currentUserData);
        } else {
          nextProps.consultantLocationsShowForm(currentEditingUserData);
        }
      }
    }

    if (nextProps.consultantLocations.data.user_locations !== this.props.consultantLocations.data.user_locations) {
      const locationDataToShow = [];
      nextProps.consultantLocations.data.user_locations.forEach((userData) => {
        if ((this.state.favorites === '1' && userData.is_favorite) || this.state.favorites === '0') {
          locationDataToShow.push(userData);
        }
      });

      this.setState({ locationData: nextProps.consultantLocations.data.user_locations, locationDataToShow });
    }
  }

  componentWillUnmount() {
    this.props.consultantLocationsHideForm();
    this.props.consultantLocationsShowList();
  }

  getTitle() {
    if (this.props.consultantLocations.showList) {
      const {
        myUserData,
        permissions,
        nextWeek,
        favorites,
        showOnlyWithMissingLocations
      } = this.state;

      return (
        <Row>
          <Col xs={4} className="title-section">
            <h1 className="box-title">
              Consultant Location
            </h1>
            {
              myUserData && permissions && permissions.can_update ?
                (
                  <Button bsSize="small" bsStyle="success" onClick={this.props.consultantLocationsShowForm.bind(this, myUserData)}>
                    Edit my location
                  </Button>
                )
                : ''
            }
          </Col>
          <Col xs={8} className="action-section">
            <SmartForm
              schema={[
                {
                  type: 'default',
                  fields: [
                    {
                      type: 'radios',
                      name: 'only_missing',
                      label: 'Only Missing:',
                      value: showOnlyWithMissingLocations,
                      options: [
                        {
                          text: 'Yes',
                          value: '1',
                        },
                        {
                          text: 'No',
                          value: '0',
                        },
                      ],
                      onChange: this.changeMissing,
                      className: 'only_missing',
                    },
                    {
                      type: 'select',
                      name: 'favorites',
                      label: 'Filter: ',
                      value: favorites,
                      options: [
                        {
                          name: 'Show Favorites Only',
                          value: 1,
                        },
                        {
                          name: 'Show All',
                          value: 0,
                        },
                      ],
                      onChange: this.changeFavorite,
                      className: 'favorites',
                    },
                    {
                      type: 'select',
                      name: 'week',
                      label: 'Week: ',
                      value: nextWeek,
                      options: [
                        {
                          name: 'This Week',
                          value: 0,
                        },
                        {
                          name: 'Next Week',
                          value: 1,
                        },
                      ],
                      onChange: this.changeWeek,
                    },
                  ],
                },
              ]
              }
              inline
              buttons={[]}
            />
          </Col>
        </Row>
      );
    }

    if (this.props.consultantLocations.showForm) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.consultantLocationsShowList.bind(this)}>
                <span className="fa fa-arrow-circle-o-left" /> Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={4} className="title-section">
              <h1 className="box-title">
                Edit Consultant Locations
              </h1>
            </Col>
            <Col xs={8} className="action-section">
              <SmartForm
                schema={[
                  {
                    type: 'default',
                    fields: [
                      {
                        type: 'select',
                        name: 'week',
                        label: '',
                        value: this.state.nextWeek,
                        options: [
                          {
                            name: 'This Week',
                            value: 0,
                          },
                          {
                            name: 'Next Week',
                            value: 1,
                          },
                        ],
                        onChange: this.changeWeek,
                      },
                    ],
                  },
                ]
                }
                inline
                buttons={[]}
              />
            </Col>
          </Row>
        </div>
      );
    }

    return null;
  }

  changeMissing(showOnlyWithMissingLocations) {
    let locationDataToShow = [];
    const { locationData } = this.state;

    // Filter only users with missing
    if (showOnlyWithMissingLocations === '1') {
      locationData.forEach((userData) => {
        if (userData.missing_locations_count > 0) {
          locationDataToShow.push(userData);
        }
      });

    // Don't filter
    } else {
      locationDataToShow = locationData;
    }

    // Update state
    this.setState({ showOnlyWithMissingLocations, locationDataToShow });
  }

  changeWeek(nextWeek) {
    this.setState({ nextWeek });
  }

  changeFavorite(favorites) {
    const locationDataToShow = [];
    const { locationData } = this.state;
    locationData.forEach((userData) => {
      if ((favorites === '1' && userData.is_favorite) || favorites === '0') {
        locationDataToShow.push(userData);
      }
    });
    this.setState({ favorites, locationDataToShow });
  }

  render() {
    const {
      showOnlyWithMissingLocations
    } = this.state;
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className={`consultant-locations-content ${this.props.consultantLocations.showList ? 'minWidth' : ''}`}>
        { this.props.consultantLocations.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this.getTitle()}
          </div>
          <div className="box-content">
            {(this.props.consultantLocations.showList)
              ? (
                <ConsultantLocationList
                  permissions={this.state.permissions}
                  nextWeek={parseInt(this.state.nextWeek, 10)}
                  locationData={this.state.locationDataToShow}
                  showOnlyWithMissingLocations={showOnlyWithMissingLocations}
                  {...this.props}
                />
              )
              : ''
            }
            {this.props.consultantLocations.showForm ? <ConsultantLocationForm permissions={this.state.permissions} nextWeek={parseInt(this.state.nextWeek, 10)} {...this.props} /> : ''}
          </div>
        </div>
      </section>
    );
  }
}

export default ConsultantLocations;
