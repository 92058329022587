const initialState = {
  inProgress: false,
  error: false,
  errorText: null,
  showList: true,
  showForm: false,
  showItem: null,
  returnPage: null,
};

function SettingsResourcesReducer(state = initialState, action) {
  const actionName = 'SETTINGS_RESOURCES';
  switch (action.type) {
    case `${actionName}_PENDING`:
      return {
        ...state,
        inProgress: true,
        error: false,
        errorText: null,
        addSuccess: false,
        editSuccess: false,
      };
    case `${actionName}_SHOW_LIST`:
      return {
        ...state,
        showList: true,
        showForm: false,
        itemId: null,
      };
    case `${actionName}_HIDE_LIST`:
      return {
        ...state,
        showList: false,
      };
    case `${actionName}_SHOW_FORM`:
      return {
        ...state,
        showForm: true,
        showList: false,
        itemId: action.itemId !== 'newItem' ? action.itemId : '',
        returnPage: action.returnPage || 1,
      };
    case `${actionName}_HIDE_FORM`:
      return {
        ...state,
        showForm: false,
      };
    case `${actionName}_SHOW_ITEM`:
      return {
        ...state,
        showItem: action.itemId,
      };
    default:
      return state;
  }
}

export default SettingsResourcesReducer;
