import React, {Component} from 'react';
import "assets/css/table.css";
import { Button, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import moment from 'moment'

class UsersLevelList extends Component{
  constructor(props){
  	super(props);
  	this.state = {
      showModal: false,
      itemId: null
    };
  }
  componentWillMount() {
     this.props.userLevelList(this.props.userId)
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.userId && nextProps.userId !== this.props.userId){
      this.props.userLevelList(nextProps.userId)
    }
  }

  render(){
    const list = this.props.userLevel.data

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={list}
          striped={true}
          hover={true}>
            <TableHeaderColumn dataField="user_role" filter={ { type: 'TextFilter', delay: 500 } } isKey={true} dataSort={true} dataFormat={this._objectFormatter.bind(this, "name")} filterFormatted sortFunc={this._sortObjectFunc} sortFuncExtraData={{objectField: "name"}}>Level</TableHeaderColumn>
            <TableHeaderColumn dataField="start_date" dataSort={true} dataFormat={this._dateFormatter}>Start Date</TableHeaderColumn>
            <TableHeaderColumn dataField="buttons" dataFormat={this._renderButtons.bind(this)} dataAlign='center'></TableHeaderColumn>
        </BootstrapTable>

        {this._renderModal()}
      </section>
    )
  }

  _closeDeleteModal() {
    this.setState({ showModal: false, itemId: null });
  }

  _openDeleteModal(itemId) {
    this.setState({ showModal: true, itemId:itemId });
  }


  _dateFormatter(cell, row){
    return moment(cell).format("YYYY-MM-DD")
  }

  _activeFormatter(cell, row){
    return cell?"Active":"Inactive"
  }

  _objectFormatter(field, cell, row){
    if(cell && cell[field]){
      return cell[field]
    }

    return ''

  }

  _renderButtons(cell, row){
    return(
      <div className="actions">
        <Button bsStyle="success" onClick={this.props.userLevelShowForm.bind(this,row.id)}><i className="fa fa-pencil" aria-hidden="true"></i></Button>
        <Button bsStyle="danger" onClick={this._openDeleteModal.bind(this,row.id)}><i className="fa fa-trash" aria-hidden="true"></i></Button>
      </div>
    )
  }

  _sortObjectFunc(a, b, order, sortField, extraData) {   // order is desc or asc
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return a[sortField][fieldName].localeCompare(b[sortField][fieldName])
    } else {
      return b[sortField][fieldName].localeCompare(a[sortField][fieldName]);
    }

  }

  _renderModal(){
    let name = ""
    if(this.state.itemId && this.props.userLevel.data.length > 0){
      for(let i=0; i<this.props.userLevel.data.length;i++){
        if(this.props.userLevel.data[i].id === this.state.itemId){
            name = this.props.userLevel.data[i].name
        }
      }
    }

    return (
      <Modal show={this.state.showModal} onHide={this._closeDeleteModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete {name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this element?</p>
            <p>All the infomation related to this Level will be deleted.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this._confirmDelete.bind(this, this.state.itemId)} bsStyle="danger">Delete</Button>
            <Button onClick={this._closeDeleteModal.bind(this)}>Close</Button>
          </Modal.Footer>
        </Modal>
    )
  }
  _confirmDelete(itemId){
    this.props.userLevelDeleteItem(this.props.userId, itemId)
    this._closeDeleteModal()
  }

}



export default UsersLevelList
