import React, { Component } from 'react';
import 'assets/css/table.css';
import { Button, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import cookie from 'react-cookies';

class SettingsResourcesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      itemId: null,
      sort_name: '',
      sort_order: '',
    };

    this.renderButtons = this.renderButtons.bind(this);
    this.onSortChange = this.onSortChange.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
  }

  componentWillMount() {
    const {
      type,
    } = this.props;
    this.getContent(this.props.type, this.props.currentPage);
    this.setState(
      {
        sort_name: cookie.load(`sort_column_settings_resources_${type}`) || 'id',
        sort_order: cookie.load(`sort_order_settings_resources_${type}`) || 'desc',
      },
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.type !== nextProps.type) {
      this.getContent(nextProps.type, nextProps.currentPage);
    }
  }

  onPageChange(page, sizePerPage) {
    const {
      type,
    } = this.props;

    if (type === 'university') {
      this.props.resourcesUniversityList(page);
    }

    if (type === 'insights') {
      this.props.resourcesInsightsList(page);
    }

    if (type === 'methodologies') {
      this.props.resourcesMethodologiesList(page);
    }

    this.props.changePage(page);
  }

  onSortChange(sortName, sortOrder) {
    const {
      type,
    } = this.props;

    cookie.save(`sort_column_settings_resources_${type}`, sortName, { path: '/' });
    cookie.save(`sort_order_settings_resources_${type}`, sortOrder, { path: '/' });

    this.setState({
      sort_name: sortName,
      sort_order: sortOrder,
    });
  }

  renderButtons(cell, row) {
    return(
      <div className="actions">
        <Button bsStyle="success" onClick={this.props.settingsResourcesShowForm.bind(this, row.id, this.props.currentPage)}><i className="fa fa-pencil" aria-hidden="true"></i></Button>
        <Button bsStyle="danger" onClick={this.openDeleteModal.bind(this, row.id)}><i className="fa fa-trash" aria-hidden="true"></i></Button>
      </div>
    )
  }

  closeDeleteModal() {
    this.setState({ showModal: false, itemId: null });
  }

  openDeleteModal(itemId) {
    this.setState({ showModal: true, itemId });
  }

  renderModal() {
    const {
      type,
    } = this.props;

    const {
      itemId,
      showModal,
    } = this.state;

    let resourceTypName = '';

    if (type === 'university') {
      resourceTypName = 'University';
    }

    if (type === 'insights') {
      resourceTypName = 'Insights';
    }

    if (type === 'methodologies') {
      resourceTypName = 'Methodologies';
    }

    let name = '';
    if (itemId && type === 'university') {
      this.props.settingsUniversityResources.data.forEach((item) => {
        if (item.id === itemId) {
          ({ name } = item);
        }
      });
    }

    if (itemId && type === 'insights') {
      this.props.settingsInsightsResources.data.forEach((item) => {
        if (item.id === itemId) {
          ({ name } = item);
        }
      });
    }

    if (itemId && type === 'methodologies') {
      this.props.settingsMethodologiesResources.data.forEach((item) => {
        if (item.id === itemId) {
          ({ name } = item);
        }
      });
    }

    return (
      <Modal show={showModal} onHide={this.closeDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm Delete &nbsp;
            {resourceTypName}
            &nbsp;Resource '
            {name}
            '
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete this element?
          </p>
          <p>
            All the infomation related to this &nbsp;
            {resourceTypName}
            &nbsp;Resource will be deleted.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.confirmDelete.bind(this, this.state.itemId)} bsStyle="danger">
            Delete
          </Button>
          <Button onClick={this.closeDeleteModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  confirmDelete(itemId) {
    const {
      type,
    } = this.props;

    if (type === 'university') {
      this.props.settingsUniversityResourcesRemoveItem(itemId);
    }

    if (type === 'insights') {
      this.props.settingsInsightsResourcesRemoveItem(itemId);
    }

    if (type === 'methodologies') {
      this.props.settingsMethodologiesResourcesRemoveItem(itemId);
    }

    this.closeDeleteModal();
  }

  getContent(type, currentPage) {
    switch (type) {
      case 'university':
        this.props.resourcesUniversityList(currentPage);
        break;
      case 'insights':
        this.props.resourcesInsightsList(currentPage);
        break;
      case 'methodologies':
        this.props.resourcesMethodologiesList(currentPage);
        break;
      default:
    }
  }

  remote() {
    const remoteObj = {};
    // Only cell editing, insert and delete row will be handled by remote store
    remoteObj.pagination = true;
    return remoteObj;
  }

  getPaginationInfo() {
    const {
      type,
    } = this.props;

    const fetchInfo = {
      dataTotalSize: 0,
    };

    const currentData = {
      first_page_url: '',
      from: null,
      last_page: null,
      last_page_url: '',
      per_page: 12,
      prev_page_url: '',
      to: null,
    };

    if (type === 'university' && this.props.settingsUniversityResources && this.props.settingsUniversityResources.pagination) {
      fetchInfo.dataTotalSize = this.props.settingsUniversityResources.pagination.total;

      currentData.first_page_url = this.props.settingsUniversityResources.pagination.first_page_url;
      currentData.from = this.props.settingsUniversityResources.pagination.from;
      currentData.last_page = this.props.settingsUniversityResources.pagination.last_page;
      currentData.last_page_url = this.props.settingsUniversityResources.pagination.last_page_url;
      currentData.per_page = this.props.settingsUniversityResources.pagination.per_page;
      currentData.prev_page_url = this.props.settingsUniversityResources.pagination.prev_page_url;
      currentData.to = this.props.settingsUniversityResources.pagination.to;
    }

    if (type === 'insights' && this.props.settingsInsightsResources && this.props.settingsInsightsResources.pagination) {
      fetchInfo.dataTotalSize = this.props.settingsInsightsResources.pagination.total;

      currentData.first_page_url = this.props.settingsInsightsResources.pagination.first_page_url;
      currentData.from = this.props.settingsInsightsResources.pagination.from;
      currentData.last_page = this.props.settingsInsightsResources.pagination.last_page;
      currentData.last_page_url = this.props.settingsInsightsResources.pagination.last_page_url;
      currentData.per_page = this.props.settingsInsightsResources.pagination.per_page;
      currentData.prev_page_url = this.props.settingsInsightsResources.pagination.prev_page_url;
      currentData.to = this.props.settingsInsightsResources.pagination.to;
    }

    if (type === 'methodologies' && this.props.settingsMethodologiesResources && this.props.settingsMethodologiesResources.pagination) {
      fetchInfo.dataTotalSize = this.props.settingsMethodologiesResources.pagination.total;

      currentData.first_page_url = this.props.settingsMethodologiesResources.pagination.first_page_url;
      currentData.from = this.props.settingsMethodologiesResources.pagination.from;
      currentData.last_page = this.props.settingsMethodologiesResources.pagination.last_page;
      currentData.last_page_url = this.props.settingsMethodologiesResources.pagination.last_page_url;
      currentData.per_page = this.props.settingsMethodologiesResources.pagination.per_page;
      currentData.prev_page_url = this.props.settingsMethodologiesResources.pagination.prev_page_url;
      currentData.to = this.props.settingsMethodologiesResources.pagination.to;
    }

    return {
      fetchInfo,
      currentData,
    };
  }

  render() {
    const {
      type,
    } = this.props;
    let list = [];
    const {
      currentPage,
    } = this.props;

    const paginationData = this.getPaginationInfo();

    switch (type) {
      case 'university':
        list = this.props.settingsUniversityResources.data;
        break;
      case 'insights':
        list = this.props.settingsInsightsResources.data;
        break;
      case 'methodologies':
        list = this.props.settingsMethodologiesResources.data;
        break;
      default:
        list = [];
    }

    const options = {
      defaultSortName: this.state.sort_name,
      defaultSortOrder: this.state.sort_order,
      onSortChange: this.onSortChange,
      paginationShowsTotal: true,
      sizePerPage: paginationData.currentData.per_page,
      onPageChange: this.onPageChange,
      page: currentPage,
      hideSizePerPage: true,
    };

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={list}
          striped
          hover
          options={options}
          headerContainerClass="fixed-header"
          pagination
          remote={this.remote}
          fetchInfo={paginationData.fetchInfo}
          ignoreSinglePage
        >
          <TableHeaderColumn
            dataField="id"
            className="resource-id"
            columnClassName='resource-id'
            isKey
          >
            ID
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="name"
          >
            Name
          </TableHeaderColumn>
          {
            this.props.type === 'university' ? (
              <TableHeaderColumn
                dataField="type"
              >
                Type
              </TableHeaderColumn>
            ) : (
              <TableHeaderColumn
                dataField="version"
              >
                Version
              </TableHeaderColumn>
            )
          }
          <TableHeaderColumn
            dataField="buttons"
            dataFormat={this.renderButtons}
            dataAlign="center"
          />
        </BootstrapTable>
        {this.renderModal()}
      </section>
    );
  }
}

export default SettingsResourcesList;
