export const index = {
  path: '/mentorship/',
  method: 'GET',
  version: '/v1',
};

export const mentorsCatalog = {
  path: '/mentorship/mentors_catalog/',
  method: 'GET',
  version: '/v1',
};

export const updateItem = {
  path: '/users/update_mentor_user_id/:user_id/',
  method: 'POST',
  version: '/v1',
};
