let client_id = '2';
let client_secret = '';

const env = process.env.NODE_ENV;

switch (env) {
  case 'production':
    client_secret = 'raf254KdPhSYOkDOeTTBENo4PX1TccJta0NpesLs';
    break;
  default:
    client_secret = 'LD3MRYHNIbVpzokfcTtKt8GBRFU6x1UbSE5QkyoM';
}

export { client_id, client_secret };
