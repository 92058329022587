import React, {Component} from 'react';
import "assets/css/table.css";
import { Button, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import Details from './details.jsx'
import cookie from 'react-cookies'

class profitSharingList extends Component{
  constructor(props){
  	super(props);
  	this.state = {
      showModal: false,
      itemId: null,
      total_billable: 0,
      total_available: 0,
      ytd: 0,
      sort_name: "",
      sort_order: ""
    };
  }

  componentWillMount() {
    this.setState(
      {
        sort_name: cookie.load('sort_column_profitSharing') || 'name',
        sort_order: cookie.load('sort_order_profitSharing') || 'asc'
      }
    )
  }

  render(){
    let profitSharing = []
    for (let i=0; i<this.props.profitSharing.data.length; i++) {
      let data = Object.assign({}, this.props.profitSharing.data[i])
      data.role_name = ""
      data.role_target = ""
      if (data.role) {
        data.role_name = data.role.name
        data.role_target = data.role.target
      }
      profitSharing.push(data)
    }

    const options = {
      expandRowBgColor: 'rgb(242, 255, 163)',
      onSortChange: this._onSortChange.bind(this),
      defaultSortName: this.state.sort_name,
      defaultSortOrder: this.state.sort_order
    }

    // Not used the total points right now
    /*
    const total_points = {
      <TableHeaderColumn
        dataField="points"
        filterFormatted
        dataSort={true}
        dataAlign='center'
        headerAlign='left'
        className='col_total_points'
        columnClassName={ 'col_total_points '+this._columnClassNameFormat }
      >
        Points
      </TableHeaderColumn>
    }
    */

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={profitSharing}
          hover={true}
          options={ options }
          expandableRow={ this._isExpandableRow.bind(this) }
          expandComponent={ this._expandComponent.bind(this) }
          autoCollapse={ { sort: true, search: true, filter: true } }
          expandColumnOptions={ {
            expandColumnVisible: true,
            expandColumnComponent: this.expandColumnComponent,
            columnWidth: 50
          } }
          ref={(table) => { this.table = table; }}
          trClassName={this._trClassFormat}
          headerContainerClass='fixed-header'
        >
          <TableHeaderColumn
            dataField="name"
            columnTitle
            isKey={true}
            filter={ { type: 'TextFilter', delay: 500 } }
            dataSort={true}
            columnClassName={ this._columnClassNameFormat }
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="role"
            columnTitle
            dataSort={true}
            columnClassName={ this._columnClassNameFormat }
          >
            Level
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="value_per_point"
            filterFormatted
            dataSort={true}
            dataAlign='right'
            dataFormat={this._moneyFormatter}
            headerAlign='left'
            className='col_total_value_per_point'
            columnClassName={ 'col_total_value_per_point '+this._columnClassNameFormat }
          >
            Value per Point
          </TableHeaderColumn>
{/*          <TableHeaderColumn
            dataField="points_average"
            filterFormatted
            dataSort={true}
            dataAlign='center'
            headerAlign='left'
            className='col_average_points'
            columnClassName={ 'col_average_points '+this._columnClassNameFormat }
          >
            Avg Points
          </TableHeaderColumn>*/}
          <TableHeaderColumn
            dataField="profit_sharing"
            filterFormatted
            dataSort={true}
            dataFormat={this._moneyFormatter}
            dataAlign='right'
            headerAlign='left'
            className='col_total_profit_sharing'
            columnClassName={ 'col_total_profit_sharing '+this._columnClassNameFormat }
          >
            Profit Sharing
          </TableHeaderColumn>
        </BootstrapTable>
        {this._renderModal()}
      </section>
    )
  }

  _onSortChange(sortName, sortOrder){
    cookie.save('sort_column_profitSharing', sortName, { path: '/' });
    cookie.save('sort_order_profitSharing', sortOrder, { path: '/' });
    this.setState({
      sort_name: sortName,
      sort_order: sortOrder
    });
  }

  _columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {
    return !row.is_active ? 'inactive' : '';
  }

  _trClassFormat(row, rowIndex) {
    return rowIndex % 2 === 0 ? "tr-odd" : "tr-even";  // return class name.
  }

  _sortNumberFunc(a, b, order, sortField){
    if (order === 'asc') {
      return parseFloat(a[sortField]) - parseFloat(b[sortField])
    } else {
      return parseFloat(b[sortField]) - parseFloat(a[sortField]);
    }
  }


expandColumnComponent({ isExpandableRow, isExpanded }) {
    let content = '';
    if (isExpandableRow) {
      content = (isExpanded ? '(-)' : '(+)' );
    } else {
      content = ' ';
    }
    return (
      <div> { content } </div>
    );
  }

  _isExpandableRow(row) {
    if (row.months && row.months.length > 0) return true;
    else return false;
  }

  _expandComponent(row) {
    return (
      <Details data={ row } {...this.props} />
    );
  }

  _moneyFormatter(cell, row){
    return Number(cell).toLocaleString('en-US', {style: 'currency',currency: 'USD'})
  }

  _objectPercentajeFormatter(field, cell, row){
    if(cell && cell[field]){
      return Math.round(cell[field]) + "%"
    }

    return ''
  }

  _objectFormatter(field, cell, row){
    if(cell && cell[field]){
      return cell[field]
    }

    return ''
  }

  _objectNumberFormatter(field, cell, row){
    if(cell && cell[field]){
      return Number(cell[field]).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})
    }

    return ''
  }

  _sortObjectFunc(a, b, order, sortField, extraData) {   // order is desc or asc
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return (a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'').localeCompare(b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'')
    } else {
      return (b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'').localeCompare(a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'');
    }

  }

  _sortObjectNumberFunc(a, b, order, sortField, extraData) {   // order is desc or asc
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return (a[sortField] && a[sortField][fieldName]?parseFloat(a[sortField][fieldName]):0) - (b[sortField] && b[sortField][fieldName]?parseFloat(b[sortField][fieldName]):0)
    } else {
      return (b[sortField] && b[sortField][fieldName]?parseFloat(b[sortField][fieldName]):0) - (a[sortField] && a[sortField][fieldName]?parseFloat(a[sortField][fieldName]):0);
    }

  }

  _renderButtons(cell, row){
    return(
      <div className="actions">
        <Button bsStyle="success" onClick={this.props.profitSharingShowForm.bind(this,row.id)}><i className="fa fa-pencil" aria-hidden="true"></i></Button>
        <Button bsStyle="danger" onClick={this._openDeleteModal.bind(this,row.id)}><i className="fa fa-trash" aria-hidden="true"></i></Button>
      </div>
    )
  }

  _closeDeleteModal() {
    this.setState({ showModal: false, itemId: null });
  }

  _openDeleteModal(itemId) {
    this.setState({ showModal: true, itemId:itemId });
  }

  _renderModal(){
    let name = ""
    if(this.state.itemId && this.props.profitSharing.data.length > 0){
      for(let i=0; i<this.props.profitSharing.data.length;i++){
        if(this.props.profitSharing.data[i].id === this.state.itemId){
            name = this.props.profitSharing.data[i].name
        }
      }
    }

    return (
      <Modal show={this.state.showModal} onHide={this._closeDeleteModal.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this element?</p>
          <p>All the infomation related to this Realization will be deleted.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this._confirmDelete.bind(this, this.state.itemId)} bsStyle="danger">Delete</Button>
          <Button onClick={this._closeDeleteModal.bind(this)}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  _confirmDelete(itemId){
    this.props.profitSharingDeleteItem(itemId)
    this._closeDeleteModal()
  }
}

export default profitSharingList
