import makeActionCreator from '../../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API       from '../../utils/config-api'
import * as endpoints from './endpoints'
import { logout } from '../../auth/list';

export const PROFIT_SHARING_POINTS_PENDING = 'PROFIT_SHARING_POINTS_PENDING'
export const settingProfitSharingPointsPending = makeActionCreator(PROFIT_SHARING_POINTS_PENDING)

export const PROFIT_SHARING_POINTS_REJECTED = 'PROFIT_SHARING_POINTS_REJECTED'
export const settingProfitSharingPointsRejected = makeActionCreator(PROFIT_SHARING_POINTS_REJECTED)

export const PROFIT_SHARING_POINTS_SHOW_LIST= 'PROFIT_SHARING_POINTS_SHOW_LIST'
export const settingProfitSharingPointsShowList = makeActionCreator(PROFIT_SHARING_POINTS_SHOW_LIST)

export const PROFIT_SHARING_POINTS_HIDE_LIST = 'PROFIT_SHARING_POINTS_HIDE_LIST'
export const settingProfitSharingPointsHideList = makeActionCreator(PROFIT_SHARING_POINTS_HIDE_LIST)

export const PROFIT_SHARING_POINTS_SHOW_FORM= 'PROFIT_SHARING_POINTS_SHOW_FORM'
export const settingProfitSharingPointsShowForm = makeActionCreator(PROFIT_SHARING_POINTS_SHOW_FORM, 'itemId')

export const PROFIT_SHARING_POINTS_HIDE_FORM = 'PROFIT_SHARING_POINTS_HIDE_FORM'
export const settingProfitSharingPointsHideForm = makeActionCreator(PROFIT_SHARING_POINTS_HIDE_FORM)

export const PROFIT_SHARING_POINTS_SHOW_ITEM = 'PROFIT_SHARING_POINTS_SHOW_ITEM'
export const settingProfitSharingPointsShowItem = makeActionCreator(PROFIT_SHARING_POINTS_SHOW_ITEM, 'itemId')


//Show Index

export const PROFIT_SHARING_POINTS_LIST_FULFILLED = 'PROFIT_SHARING_POINTS_LIST_FULFILLED'
export const settingProfitSharingPointsListFulfilled = makeActionCreator(PROFIT_SHARING_POINTS_LIST_FULFILLED, 'data')

export const PROFIT_SHARING_POINTS_LIST_ERROR = 'PROFIT_SHARING_POINTS_LIST_ERROR'
export const settingProfitSharingPointsListError = makeActionCreator(PROFIT_SHARING_POINTS_LIST_ERROR, 'message')

export function settingProfitSharingPointsList(roleId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingProfitSharingPointsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path.replace(":user_role_id", roleId)}`, {
              method  : endpoints.index.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(settingProfitSharingPointsListFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingProfitSharingPointsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}


//Show Item

export const PROFIT_SHARING_POINTS_ITEM_FULFILLED = 'PROFIT_SHARING_POINTS_ITEM_FULFILLED'
export const settingProfitSharingPointsItemFulfilled = makeActionCreator(PROFIT_SHARING_POINTS_ITEM_FULFILLED, 'data')

export const PROFIT_SHARING_POINTS_ITEM_ERROR = 'PROFIT_SHARING_POINTS_ITEM_ERROR'
export const settingProfitSharingPointsItemError = makeActionCreator(PROFIT_SHARING_POINTS_ITEM_ERROR, 'message')

export function settingProfitSharingPointsItem(roleId, itemId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingProfitSharingPointsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getItem.version}${endpoints.getItem.path.replace(":user_role_id", roleId).replace(":user_role_profit_sharings_id", itemId)}`, {
              method  : endpoints.getItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(settingProfitSharingPointsItemFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingProfitSharingPointsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}


//Add Item

export const PROFIT_SHARING_POINTS_ITEM_ADD_FULFILLED = 'PROFIT_SHARING_POINTS_ITEM_ADD_FULFILLED'
export const settingProfitSharingPointsItemAddFulfilled = makeActionCreator(PROFIT_SHARING_POINTS_ITEM_ADD_FULFILLED, 'data')

export const PROFIT_SHARING_POINTS_ITEM_ADD_ERROR = 'PROFIT_SHARING_POINTS_ITEM_ADD_ERROR'
export const settingProfitSharingPointsItemAddError = makeActionCreator(PROFIT_SHARING_POINTS_ITEM_ADD_ERROR, 'message')

export function settingProfitSharingPointsAddItem(data) {
  let formData = new FormData()
  let roleId = data["user_level_id"]

  formData.append("start_date", data["start_date"]);
  formData.append("points", data["points"]);

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingProfitSharingPointsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addItem.version}${endpoints.addItem.path.replace(":user_role_id", roleId)}`, {
              method  : endpoints.addItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(settingProfitSharingPointsItemAddFulfilled(json.data))
              }else{
                dispatch(settingProfitSharingPointsItemAddError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingProfitSharingPointsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}

//Update Item

export const PROFIT_SHARING_POINTS_ITEM_UPDATE_FULFILLED = 'PROFIT_SHARING_POINTS_ITEM_UPDATE_FULFILLED'
export const settingProfitSharingPointsItemUpdateFulfilled = makeActionCreator(PROFIT_SHARING_POINTS_ITEM_UPDATE_FULFILLED, 'data')

export const PROFIT_SHARING_POINTS_ITEM_UPDATE_ERROR = 'PROFIT_SHARING_POINTS_ITEM_UPDATE_ERROR'
export const settingProfitSharingPointsItemUpdateError = makeActionCreator(PROFIT_SHARING_POINTS_ITEM_UPDATE_ERROR, 'message')

export function settingProfitSharingPointsUpdateItem(itemId, data) {
  let formData = new FormData()
  let roleId = data["user_level_id"]

  formData.append('_method', 'PUT')
  formData.append("start_date", data["start_date"]);
  formData.append("points", data["points"]);


  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingProfitSharingPointsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateItem.version}${endpoints.updateItem.path.replace(":user_role_id", roleId).replace(":user_role_profit_sharings_id", itemId)}`, {
              method  : endpoints.updateItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(settingProfitSharingPointsItemUpdateFulfilled(json.data))
              }else{
                dispatch(settingProfitSharingPointsItemUpdateError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingProfitSharingPointsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}

//Delete Item

export const PROFIT_SHARING_POINTS_ITEM_DELETE_FULFILLED = 'PROFIT_SHARING_POINTS_ITEM_DELETE_FULFILLED'
export const settingProfitSharingPointsItemDeleteFulfilled = makeActionCreator(PROFIT_SHARING_POINTS_ITEM_DELETE_FULFILLED, 'data')

export const PROFIT_SHARING_POINTS_ITEM_DELETE_ERROR = 'PROFIT_SHARING_POINTS_ITEM_DELETE_ERROR'
export const settingProfitSharingPointsItemDeleteError = makeActionCreator(PROFIT_SHARING_POINTS_ITEM_DELETE_ERROR, 'message')

export function settingProfitSharingPointsDeleteItem(roleId, itemId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(settingProfitSharingPointsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteItem.version}${endpoints.deleteItem.path.replace(":user_role_id", roleId).replace(":user_role_profit_sharings_id", itemId)}`, {
              method  : endpoints.deleteItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
            if(json.status === "ok"){
              dispatch(settingProfitSharingPointsItemDeleteFulfilled(json.data))
              dispatch(settingProfitSharingPointsList(roleId))
            }else{
              dispatch(settingProfitSharingPointsItemDeleteError(json.message))
            }

          })
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(settingProfitSharingPointsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })





  }
}
