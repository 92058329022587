import React, { Component } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import _ from 'lodash';

import InternalProjectsList from './list.jsx';
import InternalProjectsForm from './form.jsx';
import SystemMsg from '../common/system-msg.jsx';
import "assets/css/business-development.css";

class InternalProjects extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'internal_projects') {
          permissions = area;
        }
      });
    }

    this.state = {
      permissions,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'internal_projects') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  componentWillUnmount() {
    this.props.internalProjectsHideForm();
    this.props.internalProjectsShowList();
  }

  getTitle() {
    if (this.props.internalProjects.showList) {
      return (
        <Row>
          <Col xs={12} className="title-section">
            <h1 className="box-title">
              Internal Projects
            </h1>
            {
              //<Button bsStyle="link">No Grouping <span className="fa fa-caret-down"></span></Button>
            }
            {this.state.permissions.can_create ? (
              <Button bsSize="small" bsStyle="success" onClick={this.props.internalProjectsShowForm.bind(this, "newItem")}>
                Add New
              </Button>
            ) : null
          }
          </Col>
        </Row>
      )
    }

    if (this.props.internalProjects.showForm) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.internalProjectsShowList.bind(this)}>
                <span className="fa fa-arrow-circle-o-left" /> Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">
                {this.props.internalProjects.itemId ? 'Edit Internal Project' : 'Add New Internal Project'}
              </h1>
            </Col>
          </Row>
        </div>
      )
    }

    return null;
  }

  render() {
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className={`internal-projects-content ${this.props.internalProjects.showList ? 'minWidth' : ''}`}>
        { this.props.internalProjects.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this.getTitle()}
          </div>
          <div className="box-content">
            {this.props.internalProjects.showList ? <InternalProjectsList permissions={this.state.permissions} {...this.props} /> : ''}
            {this.props.internalProjects.showForm ? <InternalProjectsForm permissions={this.state.permissions} {...this.props} /> : ''}
          </div>
        </div>
      </section>
    )
  }
}

export default InternalProjects
