import React, { Component } from 'react';
import EnhancedSwitch from 'react-icheck/lib/EnhancedSwitch';
import { Checkbox } from 'react-icheck';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { sessionService } from 'redux-react-session';

import '../../../assets/icheck/skins/all.css';
import '../../../assets/css/input.css';
import 'assets/css/permissions.css';

EnhancedSwitch.propTypes = {
  ...EnhancedSwitch.propTypes,
  cursor: PropTypes.string,
};

class SettingsPermissionsList extends Component {
  constructor(props) {
    super(props);
    this.defaultPermissions = {
      dashboard: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      business_development: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      recruiting: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      realization: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      staffing: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      utilization: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      invoices: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      internal_projects: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      people: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      profit_sharing: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      stock_options: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      statement: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      university_resources: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      insight_resources: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      methodology_resources: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      settings: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      announcements: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      consultant_locations: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      consultant_networking_sessions: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      consultant_skills: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
      mentorship: {
        can_create: 0,
        can_read: 1,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
/*
      consultant_education_hours: {
        can_create: 1,
        can_read: 1,
        can_update: 1,
        can_delete: 1,
        limit_to_own_user: 1,
      },
//*/
      pmo: {
        can_create: 0,
        can_read: 0,
        can_update: 0,
        can_delete: 0,
        limit_to_own_user: 0,
      },
    };

    this.state = {
      permissionsName: {
        dashboard: {
          name: 'Dashboard',
          availablePermissions: {
            create: 0,
            read: 1,
            update: 0,
            delete: 0,
            limitOwnUser: 0,
          },
        },
        business_development: {
          name: 'Business Development',
          availablePermissions: {
            create: 1,
            read: 1,
            update: 1,
            delete: 1,
            limitOwnUser: 0,
          },
        },
        recruiting: {
          name: 'Recruiting',
          availablePermissions: {
            create: 1,
            read: 1,
            update: 1,
            delete: 1,
            limitOwnUser: 0,
          },
        },
        realization: {
          name: 'Realization',
          availablePermissions: {
            create: 0,
            read: 1,
            update: 0,
            delete: 0,
            limitOwnUser: 0,
          },
        },
        staffing: {
          name: 'Staffing',
          availablePermissions: {
            create: 1,
            read: 1,
            update: 1,
            delete: 1,
            limitOwnUser: 1,
          },
        },
        utilization: {
          name: 'Utilization',
          availablePermissions: {
            create: 0,
            read: 1,
            update: 0,
            delete: 0,
            limitOwnUser: 0,
          },
        },
        invoices: {
          name: 'Invoices',
          availablePermissions: {
            create: 0,
            read: 1,
            update: 0,
            delete: 0,
            limitOwnUser: 0,
          },
        },
        internal_projects: {
          name: 'Internal Projects',
          availablePermissions: {
            create: 1,
            read: 1,
            update: 1,
            delete: 1,
            limitOwnUser: 0,
          },
        },
        people: {
          name: 'People',
          availablePermissions: {
            create: 1,
            read: 1,
            update: 1,
            delete: 1,
            limitOwnUser: 0,
          },
        },
        profit_sharing: {
          name: 'Profit Sharing',
          availablePermissions: {
            create: 1,
            read: 1,
            update: 1,
            delete: 1,
            limitOwnUser: 1,
          },
        },
        stock_options: {
          name: 'Stock Options',
          availablePermissions: {
            create: 1,
            read: 1,
            update: 1,
            delete: 1,
            limitOwnUser: 1,
          },
        },
        statement: {
          name: 'Statement',
          availablePermissions: {
            create: 1,
            read: 1,
            update: 1,
            delete: 1,
            limitOwnUser: 1,
          },
        },
        university_resources: {
          name: 'University Resources',
          availablePermissions: {
            create: 0,
            read: 1,
            update: 0,
            delete: 0,
            limitOwnUser: 0,
          },
        },
        insight_resources: {
          name: 'Insight Resources',
          availablePermissions: {
            create: 0,
            read: 1,
            update: 0,
            delete: 0,
            limitOwnUser: 0,
          },
        },
        methodology_resources: {
          name: 'Methodology Resources',
          availablePermissions: {
            create: 0,
            read: 1,
            update: 0,
            delete: 0,
            limitOwnUser: 0,
          },
        },
        settings: {
          name: 'Settings',
          availablePermissions: {
            create: 1,
            read: 1,
            update: 1,
            delete: 1,
            limitOwnUser: 0,
          },
        },
        announcements: {
          name: 'Announcements',
          availablePermissions: {
            create: 0,
            read: 1,
            update: 0,
            delete: 0,
            limitOwnUser: 0,
          },
        },
        consultant_locations: {
          name: 'Consultant Locations',
          availablePermissions: {
            create: 0,
            read: 1,
            update: 1,
            delete: 0,
            limitOwnUser: 1,
          },
        },
        consultant_networking_sessions: {
          name: 'Consultant Networking',
          availablePermissions: {
            create: 1,
            read: 1,
            update: 1,
            delete: 1,
            limitOwnUser: 1,
          },
        },
        consultant_skills: {
          name: 'Consultant Skills',
          availablePermissions: {
            create: 1,
            read: 1,
            update: 1,
            delete: 1,
            limitOwnUser: 1,
          },
        },
        mentorship: {
          name: 'Mentorship',
          availablePermissions: {
            create: 0,
            read: 1,
            update: 1,
            delete: 0,
            limitOwnUser: 0,
          },
        },
/*
        consultant_education_hours: {
          name: 'Consultant Education Hours',
          availablePermissions: {
            create: 1,
            read: 1,
            update: 1,
            delete: 1,
            limitOwnUser: 1,
          },
        },
//*/
        pmo: {
          name: 'PMO',
          availablePermissions: {
            create: 1,
            read: 1,
            update: 1,
            delete: 1,
            limitOwnUser: 0,
          },
        },
      },
      permissions: Object.assign({}, this.defaultPermissions),
    };
  }

  componentWillMount() {
    this.props.settingsLevelsList();
    this.props.settingsPermissionsList(this.props.levelId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.levelId && nextProps.levelId !== this.props.levelId) {
      this.props.settingsPermissionsList(nextProps.levelId);
      this.setState({ permissions: Object.assign({}, this.defaultPermissions) });
    }

    if (!_.isEqual(
      this.props.settingUserPermission.permissionData,
      nextProps.settingUserPermission.permissionData
    )) {
      if (nextProps.settingUserPermission.permissionData &&
        nextProps.settingUserPermission.permissionData.length > 0) {
        const newPermissions = this.state.permissions;
        const currentUser = Object.assign({}, this.props.session.user);
        nextProps.settingUserPermission.permissionData.forEach((section) => {
          newPermissions[section.area] = {
            can_create: section.can_create,
            can_delete: section.can_delete,
            can_read: section.can_read,
            can_update: section.can_update,
            limit_to_own_user: section.limit_to_own_user,
          };
        });
        this.setState({ permissions: newPermissions });

        if (currentUser.role.id === parseInt(this.props.levelId, 10)) {
          currentUser.role.permissions = nextProps.settingUserPermission.permissionData;
          sessionService.saveUser(currentUser);
        }
      }
    }
  }

  changeAreaActionValue(area, action, value) {
    const {
      permissions,
    } = this.state;
    const {
      levelId,
      settingsPermissionsUpdate,
    } = this.props;
    const currentPermissions = Object.assign({ }, permissions);

    // if (action === 'limit_to_own_user' && value) {
    //   Object.keys(currentPermissions[area]).forEach((actionToReset) => {
    //     if (actionToReset !== 'name') {
    //       currentPermissions[area][actionToReset] = 0;
    //     }
    //   });
    // } else if (action !== 'limit_to_own_user' && value) {
    //   currentPermissions[area].limit_to_own_user = 0;
    // }

    currentPermissions[area][action] = value ? 1 : 0;
    this.setState({ permissions: currentPermissions });

    settingsPermissionsUpdate(levelId, currentPermissions);
  }

  render() {
    const {
      permissions,
      permissionsName,
    } = this.state;
    const {
      settingUserPermission,
      settingsLevels,
    } = this.props;
    const areas = permissions ? Object.keys(permissions) : [];
    const checkDisabled = settingUserPermission.inProgress || settingsLevels.inProgress;
    return (
      <section className="section-list-content">
        <table className="permissionsTable">
          <thead>
            <tr>
              <th>
                Area
              </th>
              <th>
                Create
              </th>
              <th>
                Read
              </th>
              <th>
                Update
              </th>
              <th>
                Delete
              </th>
              <th>
                Only own user
              </th>
            </tr>
          </thead>
          <tbody>
            { areas.map((area, index) => {
              const currentAreaPermissions = permissions[area];

              if (
                !currentAreaPermissions ||
                !permissionsName[area] ||
                !permissionsName[area].availablePermissions
              ) {
                return null;
              }

              return (
                <tr key={`permissions_${area}`} className={(index + 1) % 2 === 0 ? 'tr-even' : 'tr-odd'}>
                  <td className="title">
                    {permissionsName[area].name || area}
                  </td>
                  <td>
                    <Checkbox
                      checkboxClass="icheckbox_square-orange"
                      increaseArea="20%"
                      label=""
                      onChange={(e, checked) => { this.changeAreaActionValue(area, 'can_create', checked); }}
                      checked={!!currentAreaPermissions.can_create}
                      disabled={checkDisabled || !permissionsName[area].availablePermissions.create}
                      cursor="pointer"
                    />
                  </td>
                  <td>
                    <Checkbox
                      checkboxClass="icheckbox_square-orange"
                      increaseArea="20%"
                      label=""
                      onChange={(e, checked) => { this.changeAreaActionValue(area, 'can_read', checked); }}
                      checked={!!currentAreaPermissions.can_read}
                      disabled={checkDisabled || !permissionsName[area].availablePermissions.read}
                      cursor="pointer"
                    />
                  </td>
                  <td>
                    <Checkbox
                      checkboxClass="icheckbox_square-orange"
                      increaseArea="20%"
                      label=""
                      onChange={(e, checked) => { this.changeAreaActionValue(area, 'can_update', checked); }}
                      checked={!!currentAreaPermissions.can_update}
                      disabled={checkDisabled || !permissionsName[area].availablePermissions.update}
                      cursor="pointer"
                    />
                  </td>
                  <td>
                    <Checkbox
                      checkboxClass="icheckbox_square-orange"
                      increaseArea="20%"
                      label=""
                      onChange={(e, checked) => { this.changeAreaActionValue(area, 'can_delete', checked); }}
                      checked={!!currentAreaPermissions.can_delete}
                      disabled={checkDisabled || !permissionsName[area].availablePermissions.delete}
                      cursor="pointer"
                    />
                  </td>
                  <td className="onlyUser">
                    <Checkbox
                      checkboxClass="icheckbox_square-orange"
                      increaseArea="20%"
                      label=""
                      cursor="pointer"
                      onChange={(e, checked) => { this.changeAreaActionValue(area, 'limit_to_own_user', checked); }}
                      checked={!!currentAreaPermissions.limit_to_own_user}
                      disabled={checkDisabled || !permissionsName[area].availablePermissions.limitOwnUser}
                    />
                  </td>
                </tr>
              );
            }) }

          </tbody>
        </table>
      </section>
    );
  }
}


export default SettingsPermissionsList;
