import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'assets/css/table.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

class ConsultantNetworkingList extends Component {
  constructor(props) {
    super(props);
    this.onRowClick = this.onRowClick.bind(this);
  }

  onRowClick(row) {
    const url = '/consultant-networking/'+row.id;
    browserHistory.push(url);
  }

  objectFormatter(field, cell, row) {
    if (cell && cell[field]) {
      return cell[field];
    }

    return '';
  }

  sortObjectFunc(a, b, order, sortField, extraData) {
    const fieldName = extraData["objectField"]
    if (order === 'asc') {
      return (a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'').localeCompare(b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'')
    } else {
      return (b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'').localeCompare(a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'');
    }
  }

  columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {
    let className = '';
    if (!row.harvest_is_active) {
      className += ' inactive';
    } else if (!row.role) {
      className += ' no-role';
    }
    return className;
  }

  render() {
    const { consultantNetworking } = this.props;
    const list = consultantNetworking.data;

    const options = {
      defaultSortName: 'consultant_networking_sessions_count',
      defaultSortOrder: 'desc',
      onRowClick: this.onRowClick,
    }

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={list}
          striped
          hover
          options={options}
          headerContainerClass="fixed-header"
        >
          <TableHeaderColumn
            dataField="name"
            filter={{ type: 'TextFilter', delay: 500 }}
            dataSort
            isKey
            filterFormatted
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="role"
            columnTitle
            dataSort
            dataFormat={this.objectFormatter.bind(this, 'name')}
            columnClassName={this.columnClassNameFormat}
            filterFormatted
            sortFunc={this.sortObjectFunc}
            sortFuncExtraData={
              { objectField: 'name' }
            }
          >
            Level
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="consultant_networking_sessions_count"
            columnTitle
            dataSort
          >
            Sessions
          </TableHeaderColumn>
        </BootstrapTable>
      </section>
    )
  }
}

export default ConsultantNetworkingList;
