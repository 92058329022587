import React, { Component } from 'react';
import {
  Navbar,
  Nav,
  NavDropdown,
  Button,
  NavItem,
} from 'react-bootstrap';
import { withBaseIcon } from 'react-icons-kit';
import { lock } from 'react-icons-kit/fa/lock';

import 'assets/bootstrap/font-awesome/css/font-awesome.min.css';
import 'assets/css/header.css';
import userImageDefault from 'assets/img/default-user-image.png';
import logo from 'assets/img/Headstorm_logo.svg';
import bolt from 'assets/img/bolt_orange.png';

const IconContainer = withBaseIcon({ size: 16, style: { color: '#777' } });

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.clickEditUser = this.clickEditUser.bind(this);
    this.clickLogout = this.clickLogout.bind(this);
  }

  clickLogout() {
    this.props.logout(() => {
      this.props.router.push('/login');
    });
    this.setState({ isOpen: false });
  }

  clickSettingsSection(location) {
    this.props.router.push(location);
  }

  clickEditUser() {
    this.props.router.push('/edit-user');
    this.setState({ isOpen: false });
  }

  shouldShow(areaName) {
    let shouldShow = false;
    let permissions = [];
    const { user } = this.props.session;
    if (user && user.role) {
      permissions = user.role.permissions;
    }

    permissions.forEach((area) => {
      if (area.area === areaName) {
        if (
          area.can_create
          || area.can_delete
          || area.can_read
          || area.can_update
          || area.limit_to_own_user
        ) {
          shouldShow = true;
        }
      }
    });

    return shouldShow;
  }


  render() {
    const { user } = this.props.session;
    const showSettingsMenu = this.shouldShow('settings');

    return (
      <header className="main-header">

        <a href="/" className="logo">
          <span className="logo-mini">
            <img src={bolt} alt="Headstorm" style={{ width: 25, height: 25 }} />
          </span>
          <span className="logo-lg">
            <img src={logo} alt="Headstorm" style={{ width: 182, height: 21 }} />
          </span>
        </a>
        {
          // Header Navbar: style can be found in header.less
        }
        <Navbar fluid>
          <Button bsStyle="link" className="sidebar-toggle" onClick={this.props.collapseNav}>
            <span className="sr-only">
              Toggle navigation
            </span>
          </Button>

          <div className="navbar-custom-menu">
            <Nav>
              {(user && user.role && showSettingsMenu) ?
                (
                  <NavDropdown id="nav-settings" noCaret eventKey={1} title={<span className="fa fa-cog" />} className="settings">
                    <NavItem eventKey="1.4" onClick={this.clickSettingsSection.bind(this, '/settings/levels')}>
                      <span className="fa fa-user-circle" />
                      Levels
                    </NavItem>
                    <NavItem eventKey="1.5" onClick={this.clickSettingsSection.bind(this, '/settings/users-permissions')}>
                      <div style={{ display: 'inline-block', marginRight: 10 }}>
                        <IconContainer icon={lock} />
                      </div>
                      Level Permissions
                    </NavItem>
                    <NavItem eventKey="1.6" onClick={this.clickSettingsSection.bind(this, '/settings/rates')}>
                      <span className="fa fa-money" />
                      Rates
                    </NavItem>
                    <NavItem eventKey="1.8" onClick={this.clickSettingsSection.bind(this, '/settings/profit-sharing-points')}>
                      <span className="fa fa-balance-scale" />
                      Profit Sharing Points
                    </NavItem>
                    <NavItem eventKey="1.8" onClick={this.clickSettingsSection.bind(this, '/settings/profit-sharing-values')}>
                      <span className="fa fa-usd" />
                      Profit Sharing Values
                    </NavItem>
                    <NavItem eventKey="1.9" onClick={this.clickSettingsSection.bind(this, '/settings/stock-value')}>
                      <span className="fa fa-area-chart" />
                      Stock Value
                    </NavItem>
                    <NavItem eventKey="1.10" onClick={this.clickSettingsSection.bind(this, '/settings/import')}>
                      <span className="fa fa-download" />
                      Import Process
                    </NavItem>
                    <NavItem eventKey="1.11" onClick={this.clickSettingsSection.bind(this, '/settings/resources')}>
                      <span className="fa fa-cloud-upload" />
                      Resources
                    </NavItem>
                    <NavItem eventKey="1.12" onClick={this.clickSettingsSection.bind(this, '/settings/announcements')}>
                      <span className="fa fa-bullhorn" />
                      Announcements
                    </NavItem>
                  </NavDropdown>
                ) : ''
              }

              <NavDropdown
                id="nav-user"
                noCaret
                eventKey={2}
                onToggle={isOpen => this.setState({ isOpen })}
                open={this.state.isOpen}
                title={(
                  <div>
                    <img src={user && user.image ? user.image : userImageDefault} className="user-image" alt="User" />
                    <span className="hidden-xs">
                      {user ? user.name : ''}
                    </span>
                  </div>
                )}
                className="user-menu"
                ref={(c) => { this.dropdown = c; }}
              >
                <li className="user-header">
                  <img src={user && user.image ?user.image :userImageDefault} className="img-circle" alt="User" />
                  <p>
                    {user ? user.name : userImageDefault}
                    <small>
                      Level:&nbsp;
                      {user && user.role?user.role.name : ' - '}
                    </small>
                  </p>

                </li>

                <li className="user-footer">
                  <div className="pull-left">
                    <button
                      type="button"
                      className="btn btn-default btn-flat"
                      onClick={this.clickEditUser}
                    >
                      Edit User
                    </button>
                  </div>

                  <div className="pull-right">
                    <button
                      type="button"
                      className="btn btn-default btn-flat"
                      onClick={this.clickLogout}
                    >
                      Sign out
                    </button>
                  </div>
                </li>

              </NavDropdown>
            </Nav>
          </div>
        </Navbar>
      </header>
    );
  }
}

export default Header;
