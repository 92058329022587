import React, {Component} from 'react';
import SmartForm from '../../form/base.jsx'
import { Col, Row} from 'react-bootstrap';

class SettingsImportForm extends Component{

  componentWillMount() {
    if(this.props.settingsImport.itemId){
      this.props.settingsImportItem(this.props.settingsImport.itemId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if((nextProps.settingsImport.addSuccess && nextProps.settingsImport.addSuccess !== this.props.settingsImport.addSuccess) || (nextProps.settingsImport.editSuccess && nextProps.settingsImport.editSuccess !== this.props.settingsImport.editSuccess)){
      this.props.settingsImportShowList()
    }
  }

  render(){
    let itemData

    if(this.props.settingsImport.itemId){
      itemData = this.props.settingsImport.itemData
    }
    const schema = [
      {
        type: "default",
        fields: [
          {
            type: "text",
            name: "ratio",
            label: "Ratio:",
            value: itemData?itemData.ratio:''
          }
        ]
      }
    ]
    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this._submit.bind(this)}
              showError={this.props.settingsImport.error && this.props.settingsImport.errorText}
              errorMessage={ this.props.settingsImport.errorText }
              onReset={this.props.settingsImportShowList.bind(this)} />
          </Col>
        </Row>


      </section>
    )
  }

  _submit(data){
    if(this.props.settingsImport.itemId){
      this.props.settingsImportUpdateItem(this.props.settingsImport.itemId, data)
    }else{
      this.props.settingsImportAddItem(data)
    }
  }


}

export default SettingsImportForm
