import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../../utils/makeActionCreator';
import * as API from '../../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../../auth/list';

export const SETTINGS_RESOURCES_UNIVERSITY_PENDING = 'SETTINGS_RESOURCES_UNIVERSITY_PENDING';
export const settingsUniversityResourcesPending = makeActionCreator(
  SETTINGS_RESOURCES_UNIVERSITY_PENDING,
);

export const SETTINGS_RESOURCES_UNIVERSITY_REJECTED = 'SETTINGS_RESOURCES_UNIVERSITY_REJECTED';
export const settingsUniversityResourcesRejected = makeActionCreator(
  SETTINGS_RESOURCES_UNIVERSITY_REJECTED,
);


export const SETTINGS_RESOURCES_UNIVERSITY_SHOW_ITEM = 'SETTINGS_RESOURCES_UNIVERSITY_SHOW_ITEM';
export const settingsUniversityResourcesShowItem = makeActionCreator(
  SETTINGS_RESOURCES_UNIVERSITY_SHOW_ITEM,
  'itemId',
);

// Show Index

export const SETTINGS_RESOURCES_UNIVERSITY_LIST_FULFILLED = 'SETTINGS_RESOURCES_UNIVERSITY_LIST_FULFILLED';
export const settingsUniversityResourcesListFulfilled = makeActionCreator(SETTINGS_RESOURCES_UNIVERSITY_LIST_FULFILLED, 'data', 'pagination', 'currentPage');

export const SETTINGS_RESOURCES_UNIVERSITY_LIST_ERROR = 'SETTINGS_RESOURCES_UNIVERSITY_LIST_ERROR';
export const settingsUniversityResourcesListError = makeActionCreator(SETTINGS_RESOURCES_UNIVERSITY_LIST_ERROR, 'message');

export function resourcesUniversityList(page = 1) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(settingsUniversityResourcesPending());
      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.UniversityResourcesList.version}${endpoints.UniversityResourcesList.path}?page=${page}`, {
        method: endpoints.UniversityResourcesList.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          const paginationData = {
            first_page_url: json.first_page_url,
            from: json.from,
            last_page: json.last_page,
            last_page_url: json.last_page_url,
            next_page_url: json.next_page_url,
            per_page: json.per_page,
            prev_page_url: json.prev_page_url,
            to: json.to,
            total: json.total,
          };
          dispatch(settingsUniversityResourcesListFulfilled(json.data, paginationData, page));
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(settingsUniversityResourcesRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Add new

export const SETTINGS_RESOURCES_UNIVERSITY_ITEM_ADD_FULFILLED = 'SETTINGS_RESOURCES_UNIVERSITY_ITEM_ADD_FULFILLED';
export const settingsUniversityResourcesAddFulfilled = makeActionCreator(SETTINGS_RESOURCES_UNIVERSITY_ITEM_ADD_FULFILLED, 'data');

export const SETTINGS_RESOURCES_UNIVERSITY_ITEM_ADD_ERROR = 'SETTINGS_RESOURCES_UNIVERSITY_ITEM_ADD_ERROR';
export const settingsUniversityResourcesAddError = makeActionCreator(SETTINGS_RESOURCES_UNIVERSITY_ITEM_ADD_ERROR, 'message');

export function settingsUniversityResourcesAddItem(data) {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('type', data.type);

  if (data.resource_1 || data.resource_1 === null) {
    formData.append('resource_1', data.resource_1 || '');
  }

  if (data.resource_2 || data.resource_2 === null) {
    formData.append('resource_2', data.resource_2 || '');
  }

  if (data.resource_3 || data.resource_3 === null) {
    formData.append('resource_3', data.resource_3 || '');
  }

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(settingsUniversityResourcesPending());
      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.UniversityResourcesSave.version}${endpoints.UniversityResourcesSave.path}`, {
        method: endpoints.UniversityResourcesSave.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
          // 'Content-Type': 'multipart/form-data',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(settingsUniversityResourcesAddFulfilled(json.data));
          } else {
            dispatch(settingsUniversityResourcesAddError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(settingsUniversityResourcesRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Edit Item

export const SETTINGS_RESOURCES_UNIVERSITY_ITEM_UPDATE_FULFILLED = 'SETTINGS_RESOURCES_UNIVERSITY_ITEM_UPDATE_FULFILLED';
export const settingsUniversityResourcesEditFulfilled = makeActionCreator(SETTINGS_RESOURCES_UNIVERSITY_ITEM_UPDATE_FULFILLED, 'data');

export const SETTINGS_RESOURCES_UNIVERSITY_ITEM_UPDATE_ERROR = 'SETTINGS_RESOURCES_UNIVERSITY_ITEM_UPDATE_ERROR';
export const settingsUniversityResourcesEditError = makeActionCreator(SETTINGS_RESOURCES_UNIVERSITY_ITEM_UPDATE_ERROR, 'message');

export function settingsUniversityResourcesUpdateItem(itemId, data) {
  const formData = new FormData();
  formData.append('name', data.name);
  formData.append('type', data.type);

  if (data.resource_1 || data.resource_1 === null) {
    formData.append('resource_1', data.resource_1 || '');
  }

  if (data.resource_2 || data.resource_2 === null) {
    formData.append('resource_2', data.resource_2 || '');
  }

  if (data.resource_3 || data.resource_3 === null) {
    formData.append('resource_3', data.resource_3 || '');
  }

  formData.append('_method', 'PUT');

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(settingsUniversityResourcesPending());
      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.UniversityResourcesEdit.version}${endpoints.UniversityResourcesEdit.path.replace(':university_resource_id', itemId)}`, {
        method: endpoints.UniversityResourcesEdit.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
          // 'Content-Type': 'multipart/form-data',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(settingsUniversityResourcesEditFulfilled(json.data));
          } else {
            dispatch(settingsUniversityResourcesEditError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(settingsUniversityResourcesRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Delete Item

export const SETTINGS_RESOURCES_UNIVERSITY_DELETE_FULFILLED = 'SETTINGS_RESOURCES_UNIVERSITY_DELETE_FULFILLED';
export const settingsUniversityResourcesDeleteFulfilled = makeActionCreator(SETTINGS_RESOURCES_UNIVERSITY_DELETE_FULFILLED, 'data');

export const SETTINGS_RESOURCES_UNIVERSITY_DELETE_ERROR = 'SETTINGS_RESOURCES_UNIVERSITY_DELETE_ERROR';
export const settingsUniversityResourcesDeleteError = makeActionCreator(SETTINGS_RESOURCES_UNIVERSITY_DELETE_ERROR, 'message');

export function settingsUniversityResourcesRemoveItem(itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(settingsUniversityResourcesPending());
      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.UniversityResourcesDelete.version}${endpoints.UniversityResourcesDelete.path.replace(':university_resource_id', itemId)}`, {
        method: endpoints.UniversityResourcesDelete.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          dispatch(settingsUniversityResourcesDeleteFulfilled(json.data));
          dispatch(dispatch(resourcesUniversityList()));
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(settingsUniversityResourcesRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
