import React, {Component} from 'react';
import SmartForm from '../../form/base.jsx'
import { Col, Row} from 'react-bootstrap';
import moment from 'moment'

class ProfitSharingValueForm extends Component{

  componentWillMount() {
    if(this.props.settingProfitSharingValues.itemId){
      this.props.settingProfitSharingValuesItem(this.props.settingProfitSharingValues.itemId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if((nextProps.settingProfitSharingValues.addSuccess && nextProps.settingProfitSharingValues.addSuccess !== this.props.settingProfitSharingValues.addSuccess) || (nextProps.settingProfitSharingValues.editSuccess && nextProps.settingProfitSharingValues.editSuccess !== this.props.settingProfitSharingValues.editSuccess)){
      this.props.settingProfitSharingValuesShowList()
    }
  }

  render(){
    let itemData

    if(this.props.settingProfitSharingValues.itemId && this.props.settingProfitSharingValues.itemData){
      itemData = this.props.settingProfitSharingValues.itemData
    }

    const schema = [
      {
        type: "default",
        fields: [
          {
            type: "calendar",
            name: "date",
            label: "Date:",
            value: itemData?itemData.date:'',
            dateFormat: "YYYY-MM",
            viewMode: "months",
            maxDate: moment().endOf("year").format("YYYY-MM-DD")
          },
          {
            type: "select",
            name: "is_public",
            label: "Is Public?",
            value: itemData?itemData.is_public:'',
            options: [
              { name: "Yes", value: 1},
              { name: "No", value: 0}
            ]
          },
          {
            type: "number",
            name: "value",
            label: "Value ($):",
            value: itemData?itemData.value:''
          }
        ]
      }
    ]
    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this._submit.bind(this)}
              showError={this.props.settingProfitSharingValues.error && this.props.settingProfitSharingValues.errorText}
              errorMessage={ this.props.settingProfitSharingValues.errorText }
              onReset={this.props.settingProfitSharingValuesShowList.bind(this)} />
          </Col>
        </Row>


      </section>
    )
  }

  _submit(data){
    data.date = moment(data.date).startOf("month").format("YYYY-MM-DD")

    if(this.props.settingProfitSharingValues.itemId){
      this.props.settingProfitSharingValuesUpdateItem(this.props.settingProfitSharingValues.itemId, data)
    }else{
      this.props.settingProfitSharingValuesAddItem(data)
    }
  }


}

export default ProfitSharingValueForm
