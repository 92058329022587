export const index = {
  path: '/consultant_skills/',
  method: 'GET',
  version: '/v1',
};

export const show = {
  path: '/consultant/:user_id/skills/',
  method: 'GET',
  version: '/v1',
};

export const update = {
  path: '/consultant/:user_id/skills/',
  method: 'POST',
  version: '/v1',
};
