import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import { Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'assets/css/table.css';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

class MentorshipList extends Component {
  constructor(props) {
    super(props);
    this.renderButtons = this.renderButtons.bind(this);
  }

  objectFormatter(field, cell, row) {
    if (cell && cell[field]) {
      return cell[field];
    }

    return '';
  }

  mentorFormatter(field, cell, row) {
    let mentor = '';
    if (cell && cell['name']) {
      mentor = cell['name'];
      if (cell['role'] && cell['role']['name']) {
        mentor = mentor + ' ( '+cell['role']['name']+' )';
      }
    }

    return mentor;
  }

  sortObjectFunc(a, b, order, sortField, extraData) {
    const fieldName = extraData["objectField"]
    if (order === 'asc') {
      return (a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'').localeCompare(b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'')
    } else {
      return (b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'').localeCompare(a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'');
    }
  }

  columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {
    let className = '';
    if (!row.role) {
      className += ' no-role';
    }
    return className;
  }

  renderButtons(cell, row) {
    // extract can_update permissions
    const { can_update } = this.props.permissions;

    // build destination url
    const url = '/mentorship/'+row.id;
    
    // Show button only when users have access to update
    return (
      <div className="actions">
        {(can_update) ? (
          <Button bsStyle="success" onClick={() => { browserHistory.push(url); }}>
            <i className="fa fa-pencil" aria-hidden="true" />
          </Button>
        ) : null}
      </div>
    );
  }

  render() {
    const { mentorship } = this.props;
    const list = mentorship.data;

    const options = {
      defaultSortName: 'name',
      defaultSortOrder: 'desc',
    }

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={list}
          striped
          hover
          options={options}
          headerContainerClass="fixed-header"
        >
          <TableHeaderColumn
            dataField="name"
            filter={{ type: 'TextFilter', delay: 500 }}
            dataSort
            isKey
            filterFormatted
          >
            Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="role"
            columnTitle
            dataSort
            dataFormat={this.objectFormatter.bind(this, 'name')}
            columnClassName={this.columnClassNameFormat}
            filterFormatted
            sortFunc={this.sortObjectFunc}
            sortFuncExtraData={
              { objectField: 'name' }
            }
          >
            Level
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="mentor"
            columnTitle
            dataSort
            dataFormat={this.mentorFormatter.bind(this, 'name')}
            filterFormatted
            sortFunc={this.sortObjectFunc}
            sortFuncExtraData={
              { objectField: 'name' }
            }
          >
            Mentor
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="buttons"
            dataFormat={this.renderButtons}
            dataAlign="center"
            headerAlign="left"
            className="col_buttons"
            columnClassName="col_buttons"
            width="70px"
            hidden={!!!this.props.permissions.can_update}
          />
        </BootstrapTable>
      </section>
    )
  }
}

export default MentorshipList;
