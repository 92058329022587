import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import {
  Button,
  FormGroup,
  HelpBlock,
  ControlLabel,
} from 'react-bootstrap';
import 'assets/css/dropfile.css'

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [
        ...this.props.schema.value,
      ],
    };
  }

  onDrop(files) {
    const {
      schema,
    } = this.props;
    let newExistingFiles = this.state.files.slice();
    let filesToAdd = files.slice();

    filesToAdd = filesToAdd.filter(Boolean);
    newExistingFiles = newExistingFiles.filter(Boolean);

    // if (
    //   (schema.edit && schema.editAllowNewFilesWithoutDeleting) ||
    //   !schema.edit
    // ) {
    const allFiles = [
      ...newExistingFiles,
      ...filesToAdd,
    ];

    if (schema.limitFiles && schema.limitFiles > 0) {
      if (allFiles.length > schema.limitFiles) {
        this.setState({
          files: allFiles.slice(-3),
        });
      } else {
        this.setState({
          files: allFiles,
        });
      }
    } else {
      this.setState({
        files: allFiles,
      });
    }
    // } else {
    //   newExistingFiles = newExistingFiles.map((file) => {
    //     if (file === null && files[currentUnmodifiedIndexFiles]) {
    //       const newFile = files[currentUnmodifiedIndexFiles];
    //       delete filesToAdd[currentUnmodifiedIndexFiles];
    //       currentUnmodifiedIndexFiles++;
    //       return newFile;
    //     }
    //
    //     return file;
    //   });
    //
    //   this.setState({
    //     files: newExistingFiles,
    //   });
    // }
  }

  deleteFile(index) {
    const currentFiles = this.state.files.slice();

    currentFiles[index] = null;
    this.setState({
      files: currentFiles,
    });
  }

  changeClass(schema) {
    let className = '';

    if(schema.className) {
      className = " " + schema.className;
    }

    if (schema.type === 'hidden') {
      return 'hidden';
    }

    if(schema.disabled) {
      return 'field disabled' + className;
    }

    return 'field' + className;
  }

  getValue() {
    return this.state.files;
  }

  render() {
    const {
      schema,
    } = this.props;

    if (!schema) {
      return null;
    }

    const files = this.state.files.filter(Boolean);

    return (
      <FormGroup
        className={this.changeClass(schema)}
        style={schema.style ? schema.style : null}
        controlId={schema.id ? schema.id : schema.name}
        validationState={schema.validationState || null}
      >
        {schema.label ? (
          <ControlLabel>
            { schema.label }
          </ControlLabel>
        ) : ''}

        {
          (schema.limitFiles && files.length > 0 && files.length < schema.limitFiles) ||
          (!schema.limitFiles) ||
          (files.length === 0) ? (
            <Dropzone
              onDrop={this.onDrop.bind(this)}
              disabled={schema.disabled || false}
              multiple={schema.multiple || schema.multiple === false ? schema.multiple : true}
              className="filedrop"
              activeClassName="active"
            >
              <p className="filedrop-content">
                Try dropping some files here, or click to select files to upload.
              </p>
            </Dropzone>
            ) : null
        }

        {schema.errorText ? <HelpBlock>{schema.errorText}</HelpBlock> : '' }

        {
          files.length > 0 ?
            (
              <aside className="files">
                <p className="files-title">
                  {schema.listTitle || 'Files'}
                </p>
                <ul>
                  {
                    files.map((file, index) => {
                      if ((file && file.name) || (file && file.url)) {
                        const {
                          name,
                          url,
                        } = file

                        let nameToShow = '';
                        let urlToShow = '';
                        let fileName = name;

                        if (file.size) {
                          fileName += ` - ${file.size} bytes`;
                        }

                        if (file.saved && url) {
                          urlToShow = (
                            <a
                              href={url}
                              title={name}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {url}
                            </a>
                          );
                        }

                        if (name && url) {
                          nameToShow = (
                            <span>
                              {fileName}
                              &nbsp;(
                              {url}
                              )
                            </span>
                          );
                        } else if (name) {
                          nameToShow = (
                            <span>
                              {fileName}
                            </span>
                          );
                        } else if (url) {
                          nameToShow = (
                            <span>
                              {urlToShow}
                            </span>
                          );
                        }

                        return (
                          <li key={file.name}>
                            <span style={{ marginRight: 10 }}>
                              {nameToShow}
                            </span>
                            <Button
                              bsSize="xsmall"
                              onClick={() => {
                                this.deleteFile(index);
                              }}
                            >
                                Remove
                            </Button>
                          </li>
                        );
                      }

                      return null;
                    })
                  }
                </ul>
              </aside>
            ) : null
        }
      </FormGroup>
    );
  }
}

export default UploadFile;
