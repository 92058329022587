import React, {Component} from 'react';
import SmartForm from '../../form/base.jsx';
import { Col, Row} from 'react-bootstrap';
import moment from 'moment';

class SettingsAnnouncementsForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    if (this.props.settingsAnnouncements.itemId) {
      this.props.settingsAnnouncementsItem(this.props.settingsAnnouncements.itemId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if((nextProps.settingsAnnouncements.addSuccess && nextProps.settingsAnnouncements.addSuccess !== this.props.settingsAnnouncements.addSuccess) || (nextProps.settingsAnnouncements.editSuccess && nextProps.settingsAnnouncements.editSuccess !== this.props.settingsAnnouncements.editSuccess)) {
      this.props.settingsAnnouncementsShowList();
    }
  }

  submit(data) {
    data.date = moment(data.date).startOf('month').format('YYYY-MM-DD');

    if (this.props.settingsAnnouncements.itemId) {
      this.props.settingsAnnouncementsUpdateItem(this.props.settingsAnnouncements.itemId, data);
    } else {
      this.props.settingsAnnouncementsAddItem(data);
    }
  }

  render() {
    let itemData;

    if (this.props.settingsAnnouncements.itemId) {
      itemData = this.props.settingsAnnouncements.itemData;
    }

    const schema = [
      {
        type: 'default',
        fields: [
          {
            type: 'text',
            name: 'content',
            label: 'Announcement Content:',
            value: itemData ? itemData.content : '',
          },
          {
            type: 'radios',
            name: 'is_public',
            label: 'Is Public?',
            value: itemData ? itemData.is_public.toString() : '1',
            options: [
              {
                text: 'Yes',
                value: '1',
              },
              {
                text: 'No',
                value: '0',
              },
            ],
          },
          {
            type: 'radios',
            name: 'is_highlighted',
            label: 'Highlight:',
            value: itemData ? itemData.is_highlighted.toString() : '1',
            options: [
              {
                text: 'Yes',
                value: '1',
              },
              {
                text: 'No',
                value: '0',
              },
            ],
          },
          {
            type: 'calendar',
            name: 'publication_date',
            label: 'Publication Date:',
            value: itemData ? itemData.publication_date : '',
            dateFormat: 'YYYY-MM-DD',
            viewMode: 'months',
            maxDate: moment().endOf('year').format('YYYY-MM-DD'),
          },
        ],
      },
    ];

    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this.submit}
              showError={this.props.settingsAnnouncements.error && this.props.settingsAnnouncements.errorText}
              errorMessage={ this.props.settingsAnnouncements.errorText }
              onReset={this.props.settingsAnnouncementsShowList}
            />
          </Col>
        </Row>


      </section>
    );
  }
}

export default SettingsAnnouncementsForm;
