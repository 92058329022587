import React, { Component } from "react";
import { Row, Col, Thumbnail } from "react-bootstrap";
import orangeLogo from "assets/img/hs_bolt_logo_orange.svg";
import pluralSightLogo from "assets/img/pluralsight.svg";
import "assets/css/consulting-hub.css";
import insightsPic from "assets/img/insights_bg.jpg";
import methodologyPic from "assets/img/methodology_bg.jpg";
import RightSidebar from "components/common/right-sidebar.jsx";

class ConsultingHub extends Component {
  render() {
    return <div className="dashboard-container">
        <section className="content dashboard-view col-sm-9 sidebar-view">
          <Row className="show-grid">
            <Col sm={12} className="main-content">
              <Row className="show-grid links-row">
                <div className="picture">
                  <div className="bottom-bar">
                    <div className="left">
                      <div className="bold-text">
                        Welcome to Headstorm Consultant Hub
                      </div>
                    </div>
                    <div className="right">Learn More</div>
                  </div>
                </div>
              </Row>
              <Row className="show-grid links-row">
                <Col sm={3}>
                  <Thumbnail src={orangeLogo} alt="Headstorm Logo" href="/university" rel="noopener noreferrer" target="_blank">
                    <br />
                    <h1 align="center">Headstorm <br/> University</h1>
                  </Thumbnail>
                </Col>
                <Col sm={3}>
                  <Thumbnail src={pluralSightLogo} alt="Training Resources" href="https://www.pluralsight.com/" rel="noopener noreferrer" target="_blank">
                    <br />
                    <h1 align="center">Training <br/> Resources</h1>
                  </Thumbnail>
                </Col>
                <Col sm={6} className="col">
                  <a href="/methodologies" rel="noopener noreferrer" target="_blank">
                    <div className="image-container card-height">
                      <img src={methodologyPic} alt="Headstorm Methodology" className="card-picture" />
                      <div className="after">
                        <div className="lower-header border-orange">
                          <h2 className="box-title underline-space">
                            Methodologies & Frameworks
                          </h2>
                        </div>
                      </div>
                    </div>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col sm={6} className="col">
                  <a href="/insights" rel="noopener noreferrer" target="_blank">
                    <div className="image-container card-height">
                      <img src={insightsPic} alt="Headstorm Methodology" className="card-picture" />
                      <div className="after">
                        <div className="lower-header border-orange">
                          <h2 className="box-title underline-space">Insights</h2>
                        </div>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col sm={6} className="col">
                  <div className="box white-box card-height">
                    <div className="box-header border-orange">
                      <h2 className="box-title" style={{width: '100%', paddingBottom:'10px'}}>
                        Recommended Certifications
                      </h2>
                    </div>
                    <ul className="certification-links" style={{paddingLeft:'10px'}}>
                      <li>
                        <a href="https://aws.amazon.com/certification/" rel="noopener noreferrer" target="_blank">
                          AWS Certification
                        </a>
                      </li>
                      <li>
                        <a href="https://www.microsoft.com/en-us/learning/azure-training.aspx" rel="noopener noreferrer" target="_blank">
                          Azure Cloud Certification
                        </a>
                      </li>
                      <li>
                        <a href="https://www.pmi.org/certifications" rel="noopener noreferrer" target="_blank">
                          PMI Certification Course
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <Col sm={3} className="sidebar-outer">
          <RightSidebar {...this.props} />
        </Col>
      </div>;
  }
}

export default ConsultingHub;
