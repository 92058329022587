import React, {Component} from 'react';
import SmartForm from '../../form/base.jsx'
import { Col, Row} from 'react-bootstrap';
import moment from 'moment'

class ProfitSharingPointsForm extends Component{

  componentWillMount() {
    if(this.props.settingProfitSharingPoints.itemId){
      this.props.settingProfitSharingPointsItem(this.props.levelId, this.props.settingProfitSharingPoints.itemId)
    }
  }

  componentWillReceiveProps(nextProps) {
    if((nextProps.settingProfitSharingPoints.addSuccess && nextProps.settingProfitSharingPoints.addSuccess !== this.props.settingProfitSharingPoints.addSuccess) || (nextProps.settingProfitSharingPoints.editSuccess && nextProps.settingProfitSharingPoints.editSuccess !== this.props.settingProfitSharingPoints.editSuccess)){
      this.props.settingProfitSharingPointsShowList()
    }
  }

  render(){
    let itemData, levels = []

    if(this.props.settingProfitSharingPoints.itemId){
      itemData = this.props.settingProfitSharingPoints.itemData
    }

    for(let i = 0; i < this.props.settingsLevels.data.length; i++){
      levels.push(
        {
            name: this.props.settingsLevels.data[i].name,
            value: this.props.settingsLevels.data[i].id
        }
      )
    }

    const schema = [
      {
        type: "default",
        fields: [
          {
            type: "select",
            name: "user_level_id",
            label: "Level:",
            value: this.props.levelId,
            disabled: true,
            options: levels
          },
          {
            type: "calendar",
            name: "start_date",
            label: "Start Date:",
            value: itemData?itemData.start_date:'',
            dateFormat: "YYYY-MM",
            viewMode: "months",
          },
          {
            type: "number",
            name: "points",
            label: "Points:",
            value: itemData?itemData.points:''
          }
        ]
      }
    ]
    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this._submit.bind(this)}
              showError={this.props.settingProfitSharingPoints.error && this.props.settingProfitSharingPoints.errorText}
              errorMessage={ this.props.settingProfitSharingPoints.errorText }
              onReset={this.props.settingProfitSharingPointsShowList.bind(this)} />
          </Col>
        </Row>


      </section>
    )
  }

  _submit(data){
    data.start_date = moment(data.start_date).startOf("month").format("YYYY-MM-DD")

    if(this.props.settingProfitSharingPoints.itemId){
      this.props.settingProfitSharingPointsUpdateItem(this.props.settingProfitSharingPoints.itemId, data)
    }else{
      this.props.settingProfitSharingPointsAddItem(data)
    }
  }


}

export default ProfitSharingPointsForm
