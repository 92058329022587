import makeActionCreator from '../../utils/makeActionCreator';

export const SETTINGS_RESOURCES_PENDING = 'SETTINGS_RESOURCES_PENDING';
export const settingsResourcesPending = makeActionCreator(
  SETTINGS_RESOURCES_PENDING,
);

export const SETTINGS_RESOURCES_REJECTED = 'SETTINGS_RESOURCES_REJECTED';
export const settingsResourcesRejected = makeActionCreator(
  SETTINGS_RESOURCES_REJECTED,
);

export const SETTINGS_RESOURCES_SHOW_LIST = 'SETTINGS_RESOURCES_SHOW_LIST';
export const settingsResourcesShowList = makeActionCreator(
  SETTINGS_RESOURCES_SHOW_LIST,
);

export const SETTINGS_RESOURCES_HIDE_LIST = 'SETTINGS_RESOURCES_HIDE_LIST';
export const settingsResourcesHideList = makeActionCreator(
  SETTINGS_RESOURCES_HIDE_LIST,
);

export const SETTINGS_RESOURCES_SHOW_FORM = 'SETTINGS_RESOURCES_SHOW_FORM';
export const settingsResourcesShowForm = makeActionCreator(
  SETTINGS_RESOURCES_SHOW_FORM,
  'itemId',
  'returnPage',
);

export const SETTINGS_RESOURCES_HIDE_FORM = 'SETTINGS_RESOURCES_HIDE_FORM';
export const settingsResourcesHideForm = makeActionCreator(
  SETTINGS_RESOURCES_HIDE_FORM,
);
