import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { browserHistory } from 'react-router'
import { syncHistoryWithStore, routerReducer } from 'react-router-redux'
import { sessionReducer, sessionService } from 'redux-react-session';
import moment from 'moment';
// Load reducers
import reducers from './reducers';

// Add the reducer to your store on the `router` key
// Also apply our middleware for navigating
const store = createStore(
  combineReducers({
    ...reducers,
    routing: routerReducer,
    session: sessionReducer,
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ ?compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__()
  ):applyMiddleware(thunk)

)

const history = syncHistoryWithStore(browserHistory, store);

const options = {
  refreshOnCheckAuth: true,
  // driver: 'COOKIES',
  redirectPath: '/login',
  validateSession: (session) => {
    if (session.token && moment().unix() <= session.expires_at) {
      return true;
    }

    return false;
  },
};
sessionService.initSessionService(store, options);

export { store, history };
