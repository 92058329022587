import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
// import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import 'assets/css/table.css';

class ConsultantLocationsList extends Component {
  static propTypes = {
    showOnlyWithMissingLocations: PropTypes.string.isRequired,
    nextWeek: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      sortName: 'name',
      sortOrder: 'asc',
    };

    this.onSortChange = this.onSortChange.bind(this);
    this.renderButtons = this.renderButtons.bind(this);
    this.renderFavButton = this.renderFavButton.bind(this);
  }

  componentWillMount() {
    this.props.consultantLocationsList(this.props.nextWeek);
  }

  componentWillReceiveProps(nextProps) {

    // Define sort
    if (nextProps.showOnlyWithMissingLocations === '1') {
      this.setState({
        sortName: 'missing_locations_count',
        sortOrder: 'desc',
      });
    } else {
      this.setState({
        sortName: 'name',
        sortOrder: 'asc',
      });
    }

    // Define Week
    if (this.props.nextWeek !== nextProps.nextWeek) {
      nextProps.consultantLocationsList(!!nextProps.nextWeek);
    }
  }

  onSortChange(sortName, sortOrder) {
    // cookie.save('sort_column_consultant_locations', sortName, { path: '/' });
    // cookie.save('sort_order_consultant_locations', sortOrder, { path: '/' });

    this.setState({
      sortName,
      sortOrder,
    });
  }

  consultantLocationsAddFavorite(row) {
    this.props.consultantLocationsFavoriteAdd(row.id, this.props.nextWeek);
  }

  consultantLocationsRemoveFavorite(row) {
    this.props.consultantLocationsFavoriteRemove(row.id, this.props.nextWeek);
  }

  trClassFormat(row, rowIndex) {
    return rowIndex % 2 === 0 ? 'tr-odd' : 'tr-even';  // return class name.
  }

  objectFormatter(field, cell, row) {
    if (cell) {
      return cell[field] || '';
    }
    return '';
  }

  boolFormatter(cell, row) {
    return cell ? 'Yes' : 'No'
  }

  renderFavButton(cell, row) {
    return (
      <div className="actions">
        <Button bsStyle={row.is_favorite ? 'warning' : 'default'} onClick={row.is_favorite ? this.consultantLocationsRemoveFavorite.bind(this, row) : this.consultantLocationsAddFavorite.bind(this, row)}>
          <i className={row.is_favorite ? 'fa fa-star' : 'fa fa-star-o'} aria-hidden="true" />
        </Button>
      </div>
    );
  }

  renderButtons(cell, row) {
    const { user } = this.props.session;

    const {
      can_update,
      limit_to_own_user,
    } = this.props.permissions;
    return (
      <div className="actions">
        {(can_update && !limit_to_own_user) || (limit_to_own_user && row.id === user.id) ? (
          <Button bsStyle="success" onClick={this.props.consultantLocationsShowForm.bind(this, row)}>
            <i className="fa fa-pencil" aria-hidden="true" />
          </Button>
        ) : null}
      </div>
    );
  }

  render() {
    const list = this.props.locationData;
    const mondayDate = this.props.consultantLocations.data.monday_date;
    const {
      sortOrder,
      sortName,
    } = this.state;

    const options = {
      expandRowBgColor: 'rgb(242, 255, 163)',
      sortName,
      sortOrder,
      onSortChange: this.onSortChange,
    };

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={list}
          hover
          options={options}
          ref={(table) => { this.table = table; }}
          trClassName={this.trClassFormat}
          headerContainerClass="fixed-header"
        >
          <TableHeaderColumn
            dataField="name"
            columnTitle
            filter={
              { type: 'TextFilter', delay: 500 }
            }
            isKey
            dataSort={true}
          >
            Consultant Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="consultant_weekly_location"
            columnTitle
            dataFormat={this.objectFormatter.bind(this, 'monday')}
          >
            Monday&nbsp;
            <div className="date">
              {mondayDate}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="consultant_weekly_location"
            columnTitle
            dataFormat={this.objectFormatter.bind(this, 'tuesday')}
          >
            Tuesday&nbsp;
            <div className="date">
              {moment(mondayDate).add(1, 'days').format('YYYY-MM-DD')}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="consultant_weekly_location"
            columnTitle
            dataFormat={this.objectFormatter.bind(this, 'wednesday')}
          >
            Wednesday&nbsp;
            <div className="date">
              {moment(mondayDate).add(2, 'days').format('YYYY-MM-DD')}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="consultant_weekly_location"
            columnTitle
            dataFormat={this.objectFormatter.bind(this, 'thursday')}
          >
            Thursday&nbsp;
            <div className="date">
              {moment(mondayDate).add(3, 'days').format('YYYY-MM-DD')}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="consultant_weekly_location"
            columnTitle
            dataFormat={this.objectFormatter.bind(this, 'friday')}
          >
            Friday&nbsp;
            <div className="date">
              {moment(mondayDate).add(4, 'days').format('YYYY-MM-DD')}
            </div>
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="is_favorite"
            dataFormat={this.renderFavButton}
            dataAlign="center"
            dataSort={true}
            width="70px"
          >
            Fav
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="buttons"
            dataFormat={this.renderButtons}
            dataAlign="center"
            headerAlign="left"
            className="col_buttons"
            columnClassName="col_buttons"
            width="70px"
            hidden={!!!this.props.permissions.can_update && !!!this.props.permissions.limit_to_own_user}
          />
          <TableHeaderColumn
            dataField="missing_locations_count"
            dataAlign="center"
            headerAlign="left"
            className="col_missing_locations_count"
            columnClassName="col_missing_locations_count"
            width="30px"
            dataSort
            hidden
          />
        </BootstrapTable>
      </section>
    );
  }
}

export default ConsultantLocationsList;
