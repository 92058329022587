import React from 'react';

const Error404 = () => (
  <section className="content">
    <div className="error-page">
      <h2 className="headline text-yellow">
        404
      </h2>

      <div className="error-content">
        <h3 style={{ color: '#FFF' }}>
          <i className="fa fa-warning text-yellow" />
          &nbsp;
          Oops! Page not found.
        </h3>

        <p style={{ color: '#FFF' }}>
          We could not find the page you were looking for.
        </p>

      </div>
    </div>

  </section>
);

export default Error404;
