import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { sessionService } from 'redux-react-session';

import SystemMsg from '../common/system-msg.jsx';
import SmartForm from '../form/base.jsx';
// import moment from 'moment'

class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      newPassword: '',
      confirmNewPassword: '',
    };

    sessionService.loadUser().then((userData) => {
      this.setState({ user: userData });
    });

    this.submit = this.submit.bind(this);
  }

  submit(data) {
    const { user } = this.state;
    this.props.editUser(user.id, data);

    this.setState({ newPassword: '', confirmNewPassword: '' });
  }

  render() {
    const { user } = this.state;
    const match = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-])(?=.{8,})/.test(this.state.newPassword);

    const schema = [
      {
        type: 'default',
        fields: [
          {
            type: 'text',
            name: 'name',
            label: 'Name:',
            value: user ? user.name : '',
            disabled: true,
          },
          {
            type: 'email',
            name: 'email',
            label: 'Email:',
            value: user ? user.email : '',
            disabled: true,
          },
          {
            type: 'text',
            name: 'role',
            label: 'Role:',
            value: user && user.role ? user.role.name : '',
            disabled: true,
          },
          {
            type: 'password',
            name: 'password',
            label: 'Password:',
            errorText: !match && this.state.newPassword ?
              'Password has to be of at least 8 characters long and have at least one uppercase character, one lower case, one numeric value and one symbol.'
              : '',
            validationState: !match && this.state.newPassword ? "error" : "",
            onChange: (value) => { this.setState({ newPassword: value }); },
            value: this.state.newPassword,
            empty: this.state.newPassword === '',
          },
          {
            type: 'password',
            name: 'password_confirm',
            label: 'Confirm Password:',
            validationState: this.state.newPassword!== this.state.confirmNewPassword ? 'error' : '',
            errorText: this.state.newPassword !== this.state.confirmNewPassword ? 'Both fields have to match' : '',
            onChange: (value) => { this.setState({ confirmNewPassword: value }); },
            value: this.state.confirmNewPassword,
            empty: this.state.confirmNewPassword === '',
          },
        ],
      },
    ];

    const buttons = [
      {
        text: 'Save',
        style: 'success',
        type: 'submit',
        disabled: !match || this.state.newPassword !== this.state.confirmNewPassword ,
      },
    ];
    return (
      <section>
        <div className="box">
          { this.props.user.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
          <div className="box-header with-border">
            <h1 className="box-title">
              Edit User
            </h1>
          </div>
          <div className="box-content">
            <section className="section-content">
              <Row>
                <Col xs={12} md={8} lg={6}>
                  <p style={{ color: '#CCC', fontSize: 14, padding: '0 10px' }}>
                    This fields are imported from Harvest. Only password is editable.
                  </p>
                  <SmartForm
                    schema={schema}
                    onSave={this.submit}
                    showError={this.props.user.error && this.props.user.errorText}
                    errorMessage={ this.props.user.errorText }
                    loading={this.props.user.inProgress}
                    buttons={buttons}
                    showSuccess={this.props.user.editSuccess && this.props.user.successText}
                    successMessage={this.props.user.successText}
                  />
                </Col>
              </Row>
            </section>
          </div>
        </div>
      </section>
    )
  }
}

export default EditUser;
