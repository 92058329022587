//
// Login:
//

export const index = {
    path   : '/stock_options/',
    method : 'GET',
    version: '/v1'
}

export const getItem = {
  path   : '/stock_options/:stock_options_id/',
  method : 'GET',
  version: '/v1'
}

export const addItem = {
  path   : '/stock_options/',
  method : 'POST',
  version: '/v1'
}

export const updateItem = {
  path   : '/stock_options/:stock_options_id/',
  method : 'POST',
  version: '/v1'
}

export const deleteItem = {
  path   : '/stock_options/:stock_options_id/',
  method : 'DELETE',
  version: '/v1'
}
