//
// Login:
//

export const index = {
    path   : '/internal_projects/',
    method : 'GET',
    version: '/v1'
}

export const getItem = {
  path   : '/internal_projects/:internal_project_id/',
  method : 'GET',
  version: '/v1'
}

export const addItem = {
  path   : '/internal_projects/',
  method : 'POST',
  version: '/v1'
}

export const updateItem = {
  path   : '/internal_projects/:internal_project_id/',
  method : 'POST',
  version: '/v1'
}

export const deleteItem = {
  path   : '/internal_projects/:internal_project_id/',
  method : 'DELETE',
  version: '/v1'
}
