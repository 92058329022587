import React, { Component } from 'react';
import moment from 'moment';
import cookie from 'react-cookies';
import _ from 'lodash';
import {
  Col, Row, Button, Tabs, Tab
} from 'react-bootstrap';
import RealizationDetailedList from './detailed/list.jsx';
import RealizationSummaryList from './summary.jsx';
import RealizationLast3MonthsList from './last3Months.jsx';
import SystemMsg from '../common/system-msg.jsx';
import 'assets/css/realization.css';
import SmartForm from '../form/base.jsx';

class Realization extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'realization') {
          permissions = area;
        }
      });
    }

    this.state = {
      status: '1',
      billable: '1',
      billableBy: 'both',
      startDate: moment().startOf('month'),
      endDate: moment().startOf('month'),
      summaryStatus: '1',
      summaryBillable: '1',
      summaryBillableBy: 'both',
      last3MonthsStatus: '1',
      last3MonthsBillable: '1',
      last3MonthsBillableBy: 'both',
      permissions,
    };
  }

  componentWillMount() {
    const filtersCookie = cookie.load('realization_filters') || {}
    const filtersRealizationSummaryCookie = cookie.load('realization_summary_filters') || {}
    const filtersRealizationLast3MonthsCookie = cookie.load('realization_last3Months_filters') || {}

    this.setState(
      {
        status: filtersCookie && filtersCookie.status?filtersCookie.status:this.state.status,
        billableBy: filtersCookie && filtersCookie.billableBy?filtersCookie.billableBy:this.state.billableBy,
        startDate: filtersCookie && filtersCookie.startDate?moment(filtersCookie.startDate):this.state.startDate,
        endDate: filtersCookie && filtersCookie.endDate?moment(filtersCookie.endDate):this.state.endDate,

        summaryStatus: filtersRealizationSummaryCookie && filtersRealizationSummaryCookie.status?filtersRealizationSummaryCookie.status:this.state.summaryStatus,
        summaryBillableBy: filtersRealizationSummaryCookie && filtersRealizationSummaryCookie.billableBy?filtersRealizationSummaryCookie.billableBy:this.state.summaryBillableBy,

        last3MonthsStatus: filtersRealizationLast3MonthsCookie && filtersRealizationLast3MonthsCookie.status?filtersRealizationLast3MonthsCookie.status:this.state.last3MonthsStatus,
        last3MonthsBillableBy: filtersRealizationLast3MonthsCookie && filtersRealizationLast3MonthsCookie.billableBy?filtersRealizationLast3MonthsCookie.billableBy:this.state.last3MonthsBillableBy,
      }
    )
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'staffing') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  componentDidMount() {
    this.props.realizationList(
      this.state.status,
      this.state.startDate.format("YYYY-MM-DD"),
      moment(this.state.endDate).endOf("month").format("YYYY-MM-DD"),
      this.state.billable,
      this.state.billableBy,
    );

    this.props.realizationSummaryList(
      this.state.summaryStatus,
      this.state.summaryBillable,
      this.state.summaryBillableBy
    );

    this.props.realizationLast3MonthsList(
      this.state.last3MonthsStatus,
      this.state.last3MonthsBillable,
      this.state.last3MonthsBillableBy
    );
  }

  getTitleDetailedTab() {
    if (this.props.realization.showList) {
      return (
        <Row>
          <Col xs={3}  className="title-section">
            <h1 className="box-title">Realization</h1>
          </Col>
          <Col xs={9} className="action-section">
            <SmartForm
              schema={[
                {
                  type: "default",
                  fields: [
                    {
                      type: "select",
                      name: "billable",
                      label: "Billable:",
                      value: this.state.billable,
                      placeholder:"Billable?",
                      options: [
                        {
                          name: "Both",
                          value: "2"
                        },
                        {
                          name: "Yes",
                          value: "1"
                        },
                        {
                          name: "No",
                          value: "0"
                        }
                      ],
                      onChange: this._changeBillable.bind(this)
                    },{
                      type: "select",
                      name: "billable_by",
                      label: "Billable By:",
                      value: this.state.billableBy,
                      placeholder:"Billable By",
                      options: [
                        {
                          name: "Both",
                          value: "both"
                        },
                        {
                          name: "Tasks",
                          value: "tasks"
                        },
                        {
                          name: "Other",
                          value: "other"
                        }
                      ],
                      onChange: this._changeBillableBy.bind(this)
                    },
                    {
                      type: "select",
                      name: "active",
                      label: "Status:",
                      value: this.state.status,
                      options: [
                        {
                          name: "Both",
                          value: "2"
                        },
                        {
                          name: "Active",
                          value: "1"
                        },
                        {
                          name: "Inactive",
                          value: "0"
                        }
                      ],
                      onChange: this._changeStatus.bind(this)
                    },
                    {
                      type: "calendar",
                      name: "start_date",
                      label: "Start Date:",
                      value: this.state.startDate,
                      dateFormat: "YYYY-MM",
                      viewMode: "months",
                      maxDate: moment(this.state.endDate).endOf("month"),
                      onChange: this._changeStartDate.bind(this)
                    },
                    {
                      type: "calendar",
                      name: "end_date",
                      label: "End Date:",
                      value:  this.state.endDate,
                      dateFormat: "YYYY-MM",
                      viewMode: "months",
                      minDate: this.state.startDate,
                      onChange: this._changeEndDate.bind(this)
                    }
                  ]
                }
              ]

            }
              inline={true}
              buttons={[]}
            />
          </Col>
        </Row>



      )
    } else if(this.props.realization.showForm) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.realizationShowList.bind(this)}><span className="fa fa-arrow-circle-o-left"></span> Back</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">{this.props.realization.itemId?"Edit Realization":"Add New Realization"}</h1>
            </Col>
          </Row>
        </div>
      );
    }
  }

  getTitleSummaryTab() {
    if (this.props.realizationSummary.showList) {
      return (
        <Row>
          <Col xs={4}  className="title-section">
            <h1 className="box-title">Realization</h1>
          </Col>
          <Col xs={8} className="action-section">
            <SmartForm
              schema={[
                {
                  type: "default",
                  fields: [
                    {
                      type: "select",
                      name: "summaryBillable",
                      label: "Billable:",
                      value: this.state.summaryBillable,
                      placeholder:"Billable?",
                      options: [
                        {
                          name: "Both",
                          value: "2"
                        },
                        {
                          name: "Yes",
                          value: "1"
                        },
                        {
                          name: "No",
                          value: "0"
                        }
                      ],
                      onChange: this._changeSummaryBillable.bind(this)
                    },{
                      type: "select",
                      name: "summaryBillableBy",
                      label: "Billable By:",
                      value: this.state.summaryBillableBy,
                      placeholder:"Billable By",
                      options: [
                        {
                          name: "Both",
                          value: "both"
                        },
                        {
                          name: "Tasks",
                          value: "tasks"
                        },
                        {
                          name: "Other",
                          value: "other"
                        }
                      ],
                      onChange: this._changeSummaryBillableBy.bind(this)
                    },
                    {
                      type: "select",
                      name: "summaryStatus",
                      label: "Status:",
                      value: this.state.summaryStatus,
                      options: [
                        {
                          name: "Both",
                          value: "2"
                        },
                        {
                          name: "Active",
                          value: "1"
                        },
                        {
                          name: "Inactive",
                          value: "0"
                        }
                      ],
                      onChange: this._changeSummaryStatus.bind(this)
                    }
                  ]
                }
              ]

            }
              inline={true}
              buttons={[]}
            />
          </Col>
        </Row>
      )
    } else if(this.props.realization.showForm) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.realizationShowList.bind(this)}><span className="fa fa-arrow-circle-o-left"></span> Back</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">{this.props.realization.itemId?"Edit Realization":"Add New Realization"}</h1>
            </Col>
          </Row>
        </div>
      );
    }
  }

  getTitleLast3MonthsTab() {
    if (this.props.realizationLast3Months.showList) {
      return (
        <Row>
          <Col xs={4}  className="title-section">
            <h1 className="box-title">Realization</h1>
          </Col>
          <Col xs={8} className="action-section">
            <SmartForm
              schema={[
                {
                  type: "default",
                  fields: [
                    {
                      type: "select",
                      name: "last3MonthsBillable",
                      label: "Billable:",
                      value: this.state.last3MonthsBillable,
                      placeholder:"Billable?",
                      options: [
                        {
                          name: "Both",
                          value: "2"
                        },
                        {
                          name: "Yes",
                          value: "1"
                        },
                        {
                          name: "No",
                          value: "0"
                        }
                      ],
                      onChange: this._changeLast3MonthsBillable.bind(this)
                    },{
                      type: "select",
                      name: "last3MonthsBillableBy",
                      label: "Billable By:",
                      value: this.state.last3MonthsBillableBy,
                      placeholder:"Billable By",
                      options: [
                        {
                          name: "Both",
                          value: "both"
                        },
                        {
                          name: "Tasks",
                          value: "tasks"
                        },
                        {
                          name: "Other",
                          value: "other"
                        }
                      ],
                      onChange: this._changeLast3MonthsBillableBy.bind(this)
                    },
                    {
                      type: "select",
                      name: "last3MonthsStatus",
                      label: "Status:",
                      value: this.state.last3MonthsStatus,
                      options: [
                        {
                          name: "Both",
                          value: "2"
                        },
                        {
                          name: "Active",
                          value: "1"
                        },
                        {
                          name: "Inactive",
                          value: "0"
                        }
                      ],
                      onChange: this._changeLast3MonthsStatus.bind(this)
                    }
                  ]
                }
              ]

            }
              inline={true}
              buttons={[]}
            />
          </Col>
        </Row>
      )
    } else if(this.props.realization.showForm) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.realizationShowList.bind(this)}><span className="fa fa-arrow-circle-o-left"></span> Back</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">{this.props.realization.itemId?"Edit Realization":"Add New Realization"}</h1>
            </Col>
          </Row>
        </div>
      );
    }
  }

  _changeStatus(value) {
    let filtersCookie = cookie.load('realization_filters') || {}

    filtersCookie = {
      status: value,
      startDate: filtersCookie && filtersCookie.startDate?filtersCookie.startDate:this.state.startDate,
      endDate: filtersCookie && filtersCookie.endDate?filtersCookie.endDate:this.state.endDate,
      billableBy: filtersCookie && filtersCookie.billableBy?filtersCookie.billableBy:this.state.billableBy,
    }

    this.setState({status: value})
    this.props.realizationList(
      value,
      this.state.startDate.format("YYYY-MM-DD"),
      moment(this.state.endDate).endOf("month").format("YYYY-MM-DD"),
      this.state.billable,
      this.state.billableBy
    );

    cookie.save('realization_filters', JSON.stringify(filtersCookie), { path: '/' });
  }

  _changeBillable(value) {
    this.setState({billable: value})
    this.props.realizationList(
      this.state.status,
      this.state.startDate.format("YYYY-MM-DD"),
      moment(this.state.endDate).endOf("month").format("YYYY-MM-DD"),
      value,
      this.state.billableBy
    );
  }

  _changeBillableBy(value) {
    let filtersCookie = cookie.load('realization_filters') || {}

    filtersCookie = {
      status: filtersCookie && filtersCookie.status?filtersCookie.status:this.state.status,
      startDate: filtersCookie && filtersCookie.startDate?filtersCookie.startDate:this.state.startDate,
      endDate: filtersCookie && filtersCookie.endDate?filtersCookie.endDate:this.state.endDate,
      billableBy: value,
    }

    this.setState({billableBy: value})
    this.props.realizationList(
      this.state.status,
      this.state.startDate.format("YYYY-MM-DD"),
      moment(this.state.endDate).endOf("month").format("YYYY-MM-DD"),
      this.state.billable,
      value
    );

    cookie.save('realization_filters', JSON.stringify(filtersCookie), { path: '/' });
  }

  _changeStartDate(value) {
    let startDate = value.startOf("month")
    let filtersCookie = cookie.load('realization_filters') || {}

    filtersCookie = {
      status: filtersCookie && filtersCookie.status?filtersCookie.status:this.state.status,
      startDate: value,
      endDate: filtersCookie && filtersCookie.endDate?filtersCookie.endDate:this.state.endDate,
      billableBy: filtersCookie && filtersCookie.billableBy?filtersCookie.billableBy:this.state.billableBy,
    }

    this.setState({startDate: startDate})
    cookie.save('realization_filters', JSON.stringify(filtersCookie), { path: '/' });
    this.props.realizationList(
      this.state.status,
      startDate.format("YYYY-MM-DD"),
      moment(this.state.endDate).endOf("month").format("YYYY-MM-DD"),
      this.state.billable,
      this.state.billableBy
    );
  }

  _changeEndDate(value) {
    let filtersCookie = cookie.load('realization_filters') || {}

    filtersCookie = {
      status: filtersCookie && filtersCookie.status?filtersCookie.status:this.state.status,
      startDate: filtersCookie && filtersCookie.startDate?filtersCookie.startDate:this.state.startDate,
      endDate: value,
      billableBy: filtersCookie && filtersCookie.billableBy?filtersCookie.billableBy:this.state.billableBy,
    }

    this.setState({endDate: value})
    this.props.realizationList(
      this.state.status,
      this.state.startDate.format("YYYY-MM-DD"),
      moment(value).endOf("month").format("YYYY-MM-DD"),
      this.state.billable,
      this.state.billableBy
    );

    cookie.save('realization_filters', JSON.stringify(filtersCookie), { path: '/' });
  }

  _changeSummaryStatus(value) {
    let filtersRealizationSummaryCookie = cookie.load('realization_summary_filters') || {}

    filtersRealizationSummaryCookie = {
      status: value,
      billableBy: filtersRealizationSummaryCookie && filtersRealizationSummaryCookie.summaryBillableBy?filtersRealizationSummaryCookie.summaryBillableBy:this.state.summaryBillableBy,
    }

    this.setState({summaryStatus: value})
    this.props.realizationSummaryList(
      value,
      this.state.summaryBillable,
      this.state.summaryBillableBy,
    )

    cookie.save('realization_summary_filters', JSON.stringify(filtersRealizationSummaryCookie), { path: '/' });
  }

  _changeSummaryBillable(value) {
    this.setState({summaryBillable: value})
    this.props.realizationSummaryList(
      this.state.summaryStatus,
      value,
      this.state.summaryBillableBy,
    )
  }

  _changeSummaryBillableBy(value) {
    let filtersRealizationSummaryCookie = cookie.load('realization_summary_filters') || {}

    filtersRealizationSummaryCookie = {
      status: filtersRealizationSummaryCookie && filtersRealizationSummaryCookie.summaryStatus?filtersRealizationSummaryCookie.summaryStatus:this.state.summaryStatus,
      billableBy: value,
    }

    this.setState({summaryBillableBy: value})
    this.props.realizationSummaryList(
      this.state.summaryStatus,
      this.state.summaryBillable,
      value
    )

    cookie.save('realization_summary_filters', JSON.stringify(filtersRealizationSummaryCookie), { path: '/' });
  }

  _changeLast3MonthsStatus(value) {
    let filtersRealizationLast3MonthsCookie = cookie.load('realization_last3Months_filters') || {}

    filtersRealizationLast3MonthsCookie = {
      status: value,
      billableBy: filtersRealizationLast3MonthsCookie && filtersRealizationLast3MonthsCookie.last3MonthsBillableBy?filtersRealizationLast3MonthsCookie.last3MonthsBillableBy:this.state.last3MonthsBillableBy,
    }

    this.setState({last3MonthsStatus: value})
    this.props.realizationLast3MonthsList(
      value,
      this.state.last3MonthsBillable,
      this.state.last3MonthsBillableBy,
    )

    cookie.save('realization_last3Months_filters', JSON.stringify(filtersRealizationLast3MonthsCookie), { path: '/' });
  }

  _changeLast3MonthsBillable(value) {
    this.setState({last3MonthsBillable: value})
    this.props.realizationLast3MonthsList(
      this.state.last3MonthsStatus,
      value,
      this.state.last3MonthsBillableBy,
    )
  }

  _changeLast3MonthsBillableBy(value) {
    let filtersRealizationLast3MonthsCookie = cookie.load('realization_last3Months_filters') || {}

    filtersRealizationLast3MonthsCookie = {
      status: filtersRealizationLast3MonthsCookie && filtersRealizationLast3MonthsCookie.last3MonthsStatus?filtersRealizationLast3MonthsCookie.last3MonthsStatus:this.state.last3MonthsStatus,
      billableBy: value,
    }

    this.setState({last3MonthsBillableBy: value})
    this.props.realizationLast3MonthsList(
      this.state.last3MonthsStatus,
      this.state.last3MonthsBillable,
      value
    )

    cookie.save('realization_last3Months_filters', JSON.stringify(filtersRealizationLast3MonthsCookie), { path: '/' });
  }

  render() {
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className={`realization-content ${this.props.realization.showList ? 'minWidth' : ''}`}>
        { this.props.realization.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }

        <div className="box">

          <Tabs defaultActiveKey={1} id="realization-tabs">
            <Tab eventKey={1} title="Detailed by Date">

                <div className="box-header with-border">
                  {this.getTitleDetailedTab()}
                </div>
                <div className="box-content">
                  {this.props.realization.showList?<RealizationDetailedList {...this.props} />:""}
                </div>

            </Tab>
            <Tab eventKey={2} title="Summary">

                <div className="box-header with-border">
                  {this.getTitleSummaryTab()}
                </div>
                <div className="box-content">
                  {this.props.realizationSummary.showList?<RealizationSummaryList {...this.props} />:""}
                </div>

            </Tab>
            <Tab eventKey={3} title="Last 3 Months">

                <div className="box-header with-border">
                  {this.getTitleLast3MonthsTab()}
                </div>
                <div className="box-content">
                  {this.props.realizationLast3Months.showList?<RealizationLast3MonthsList {...this.props} />:""}
                </div>

            </Tab>
          </Tabs>

        </div>
      </section>
    )
  }

}

export default Realization
