import React, { Component } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import _ from 'lodash';

import UsersList from './list.jsx';
import UserLevels from './user-levels/base.jsx';
import SystemMsg from '../common/system-msg.jsx';
import 'assets/css/users.css';
import SmartForm from '../form/base.jsx';

class Users extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'people') {
          permissions = area;
        }
      });
    }

    this.state = {
      status: '1',
      permissions,
    };

    this.changeStatus = this.changeStatus.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'people') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  componentWillUnmount() {
    this.props.userHideForm();
    this.props.userShowList()
  }

  componentDidMount() {
    this.props.userList(this.state.status)
  }

  getTitle() {
    const {
      status,
    } = this.state;

    if (this.props.user.showList) {
      return (
        <Row>
          <Col xs={4} className="title-section">
            <h1 className="box-title">
              People
            </h1>
          </Col>
          <Col xs={8} className="action-section">
            <SmartForm
              schema={[{
                type: 'default',
                fields: [{
                  type: 'select',
                  name: 'active',
                  label: 'Status:',
                  value: status,
                  options: [
                    {
                      name: 'Both',
                      value: '2',
                    },
                    {
                      name: 'Active',
                      value: '1',
                    },
                    {
                      name: 'Inactive',
                      value: '0',
                    },
                  ],
                  onChange: this.changeStatus,
                }],
              }]}
              inline
              buttons={[]}
            />
          </Col>
        </Row>
      );
    }

    if (this.props.user.showForm) {
      let userData = null;

      for (let i = 0; i < this.props.user.data.length; i++) {
        if (this.props.user.data[i].id === this.props.user.itemId) {
          userData = this.props.user.data[i]
        }
      }

      if (this.props.userLevel.showList) {
        return (
          <div>
            <Row>
              <Col xs={12}>
                <Button bsSize="xsmall" onClick={this.props.userShowList.bind(this)}><span className="fa fa-arrow-circle-o-left"></span> Back</Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="title-section">
                <h1 className="box-title">Levels for {userData.name}</h1>
                <Button bsSize="small" bsStyle="success"  className="button-new" onClick={this.props.userLevelShowForm.bind(this, "newItem")} disabled={this.props.userLevel.inProgress }>Add New Level</Button>
              </Col>
            </Row>
          </div>
        );
      }

      if (this.props.userLevel.showForm) {
        return (
          <div>
            <Row>
              <Col xs={12}>
                <Button bsSize="xsmall" onClick={this.props.userLevelShowList.bind(this)}><span className="fa fa-arrow-circle-o-left"></span> Back</Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="title-section">
                <h1 className="box-title">{this.props.userLevel.itemId?"Edit Level":"Add New Level"}</h1>
              </Col>
            </Row>
          </div>
        )
      }
    }

    return null;
  }

  changeStatus(value) {
    this.setState({ status: value });
    this.props.userList(value);
  }

  render() {
    const {
      permissions,
    } = this.state;

    if (
      !permissions
      || (
        !permissions.can_read
        && !permissions.can_create
        && !permissions.can_delete
        && !permissions.can_update
        && !permissions.limit_to_own_user
      )
    ) {
      if (permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className={`users-content ${this.props.user.showList ? 'minWidth' : ''}`}>
        { this.props.user.inProgress || this.props.userLevel.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            { this.getTitle() }
          </div>
          <div className="box-content">
            {this.props.user.showList ? <UsersList permissions={this.state.permissions} {...this.props} /> : ""}
            {this.props.user.showForm ? <UserLevels permissions={this.state.permissions} userId={this.props.user.itemId} {...this.props} />:""}
          </div>
        </div>
      </section>
    );
  }
}

export default Users;
