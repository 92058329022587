export const index = {
  path: '/consultant_networking/',
  method: 'GET',
  version: '/v1',
};

// Consultant Networking Sessions
export const sesssionsIndex = {
  path: '/consultant/:user_id/networking_sessions/',
  method: 'GET',
  version: '/v1',
};

export const addSession = {
  path: '/consultant/:user_id/networking_sessions/',
  method: 'POST',
  version: '/v1',
};

export const getSession = {
  path: '/consultant/:user_id/networking_sessions/:session_id',
  method: 'GET',
  version: '/v1',
};

export const updateSession = {
  path: '/consultant/:user_id/networking_sessions/:session_id',
  method: 'POST',
  version: '/v1',
};

export const deleteSessionItem = {
  path: '/consultant/:user_id/networking_sessions/:session_id',
  method: 'DELETE',
  version: '/v1',
};
