import React, {Component}    from 'react'
import CheckBox      from './checkbox.jsx'
import TextInput     from './input.jsx'
import Select        from './select.jsx'
import TextArea      from './textarea.jsx'
import Calendar      from './calendar.jsx'
import Radios        from './radios.jsx'
import UploadFile from './upload.jsx';
import 'assets/css/form.css'
import { Button, Alert, Form } from 'react-bootstrap';

class SmartForm extends Component {
  constructor(props) {
    super(props);
    this.fields = [];
  }

  componentDidMount() {
    if (this.input) {
      this.input.focus();
    }
  }

  _field(field, index, fieldsetIndex) {
    switch (field.type) {
      case 'select':
        return <Select key={ index } schema={ field }  ref={(input) => { this.fields[fieldsetIndex][index] = input; this._setField(input, field.focus) }} />
      case 'textarea':
        return <TextArea key={ index } schema={ field }  ref={(input) => { this.fields[fieldsetIndex][index] = input; this._setField(input, field.focus) }} />
      case 'calendar':
        return <Calendar key={ index } schema={ field } ref={(field) => {this.fields[fieldsetIndex][index] = field;}}  />
      case 'radios':
        return <Radios key={ index } schema={ field } ref={(field) => {this.fields[fieldsetIndex][index] = field;}}  />
      case 'checkbox':
        return <CheckBox key={ index } schema={ field } ref={(input) => {this.fields[fieldsetIndex][index] = input;}}  />
      case 'upload':
        return <UploadFile key={ index } schema={ field } ref={(field) => {this.fields[fieldsetIndex][index] = field;}}  />
      default:
        return <TextInput key={ index } schema={ field } ref={(input) => { this.fields[fieldsetIndex][index] = input; this._setField(input, field.focus) }} />
    }
  }


  _reset(e) {
      e.preventDefault()
      if ( this.props.onReset ) this.props.onReset()
  }

  _setField(fieldObj, focus){
    if(focus){
      this.input = fieldObj
    }
  }

  _submit(e) {
      e.preventDefault()

      const form = e.target
      const data = {}
      // const data = []

      // store data in an Object
      this.props.schema.map( (s, i) => {
        if(s.fields){
          s.fields.map( (field, index) => {
              Object.assign(data, this._getFieldValue(field, form, index, i, null))
              return field
          })
        }
        return s;
      })


      const id = this.props.id

      if ( id == null ) {
          this.props.onSave(data, form)
      } else {
          this.props.onSave(id, data, form)
      }
  }

  _getFieldValue(field, form, index, fieldsetIndex, fieldsArray) {
    if (!field) {
      return null;
    }

    const data = {}
    const id = field.name
    let {
      fields,
    } = this;

    if (fieldsArray) {
      fields = fieldsArray;
    }

    if (fields && fields[fieldsetIndex] && fields[fieldsetIndex][index]) {
      data[`${id}`] = fields[fieldsetIndex][index].getValue();
    }

    return data;
  }

  _getButtons(buttons) {
    return (
      <fieldset className="buttons">
        {buttons.map((data, index)=>{
          return (
            <Button
              bsStyle={data.style?data.style:'default'}
              bsSize={data.size}
              onClick={data.onClick}
              type={data.type?data.type:"button"}
              disabled={data.disabled}
              key={index}>
              {data.text}
            </Button>
          )
        })}
      </fieldset>
    )
  }

  render() {
    const schema = this.props.schema

    let buttons = [
        {
            'text'      : 'Save',
            'style' : 'success',
            'type'      : 'submit',
            'disabled'  : this.props.loading || this.props.disableSave
        },
        {
            'text'      : 'Cancel',
            'onClick'   : this._reset.bind(this),
            'disabled'  : this.props.loading
        }
    ]

    if(this.props.buttons){
      buttons = this.props.buttons
    }

    return (
        <Form
            onSubmit={ this._submit.bind(this) }
            ref={ this.props.name }
            inline={this.props.inline?true:false}>
            {this.props.showError?<Alert bsStyle="danger">
              {this.props.errorMessage}
            </Alert>:""}
            {this.props.showSuccess?<Alert bsStyle="success">
              {this.props.successMessage}
            </Alert>:""}
            { schema.map( (fieldset, i) => {
              if(fieldset){
                this.fields[i] = []
                return (
                <div key={i} className="form-group fieldset">

                    <fieldset
                        className={ fieldset.type }
                        key={ i }>


                        { fieldset.fields.map( (field, index) => {
                          if(field && field.type){
                            return this._field(field, index, i)
                          }

                          return null

                        }) }
                    </fieldset>
                  </div>
                )
              }
              return null
            }) }

            { this.props.readOnly ? null :
              <div className="form-group fieldset buttons">
                {this._getButtons(buttons)}
              </div> }
        </Form>
    )
  }
}

export default SmartForm;
