import React, { Component } from 'react';
import moment from 'moment';
import 'assets/css/table.css';
import { Table, Row, Col } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

class BusinessDeveplomentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealsCount: 0,
      totalAmount: 0,
      totalWeighted: 0,
    };
  }

  componentWillMount() {
    const { businessDevelopmentList } = this.props;
    businessDevelopmentList();
  }

  trClassFormat = (row, rowIndex) => (rowIndex % 2 === 0 ? 'tr-odd' : 'tr-even');

  moneyFormatter = cell => `$${Number(cell).toLocaleString('en-US')}`;

  dateFormatter = cell => ((cell) ? moment(cell).format('YYYY-MM-DD') : '-');

  percentajeFormatter = cell => `${Math.round((cell || 0))}%`;

  sortNumberFunc = (a, b, order, sortField) => {
    if (order === 'asc') {
      return parseFloat(a[sortField]) - parseFloat(b[sortField]);
    }
    return parseFloat(b[sortField]) - parseFloat(a[sortField]);
  }

  afterColumnFilter = (filterConds, result) => {
    let totalAmount = 0;
    let totalWeighted = 0;

    for (let i = 0; i < result.length; i += 1) {
      totalAmount += parseFloat(result[i].value);
      totalWeighted += parseFloat(result[i].weighted_value);
    }

    const { ...stateCounts } = this.state;
    if (totalAmount !== stateCounts.totalAmount || totalWeighted !== stateCounts.totalWeighted) {
      this.setState({
        dealsCount: result.length,
        totalAmount,
        totalWeighted,
      });
    }
  }

  render() {
    const { ...stateCounts } = this.state;
    const list = this.props.businessDevelopment.data;
    const options = {
      afterColumnFilter: this.afterColumnFilter.bind(this),
      expandRowBgColor: 'rgb(242, 255, 163)',
      defaultSortName: 'stage_name',
      defaultSortOrder: 'desc',
    };

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={list}
          hover
          options={options}
          ref={(table) => { this.table = table; }}
          trClassName={this.trClassFormat}
          headerContainerClass="fixed-header"
        >
          <TableHeaderColumn
            dataField="org_name"
            columnTitle
            filter={{ type: 'TextFilter', delay: 500 }}
            dataSort
            className="col_client"
            columnClassName="col_client"
          >
            Client
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="title"
            columnTitle
            filter={{ type: 'TextFilter', delay: 500 }}
            dataSort
            isKey
            className="col_project"
            columnClassName="col_project"
          >
            Project
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="owner_name"
            columnTitle
            filter={{ type: 'TextFilter', delay: 500 }}
            dataSort
            className="col_officer_in_charge"
            columnClassName="col_officer_in_charge"
          >
            OIC
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="stage_name"
            filter={{ type: 'TextFilter', delay: 500 }}
            dataSort
            headerAlign="left"
            className="col_bd_stage"
            columnClassName="col_bd_stage"
          >
            Stage
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="stage_deal_probability"
            filter={{ type: 'TextFilter', delay: 500 }}
            dataSort
            dataFormat={this.percentajeFormatter}
            dataAlign="center"
            headerAlign="left"
            className="col_bd_probability"
            columnClassName="col_bd_probability"
          >
            Likely
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="value"
            dataSort
            dataFormat={this.moneyFormatter}
            sortFunc={this.sortNumberFunc}
            dataAlign="right"
            headerAlign="left"
            className="col_amount"
            columnClassName="col_amount"
          >
            Amount
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="weighted_value"
            dataSort
            dataFormat={this.moneyFormatter}
            sortFunc={this.sortNumberFunc}
            dataAlign="right"
            headerAlign="left"
            className="col_weighted_amount"
            columnClassName="col_weighted_amount"
          >
            Weighted
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="expected_close_date"
            dataSort
            dataFormat={this.dateFormatter}
            dataAlign="center"
            headerAlign="left"
            className="col_close_date"
            columnClassName="col_close_date"
          >
            Expected
            <br />
            Close Date
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="next_activity_note"
            columnTitle
            dataSort
            dataAlign="center"
            headerAlign="left"
          >
            Next Act
            <br />
            Notes
          </TableHeaderColumn>
        </BootstrapTable>

        <div className="totals">
          <Row>
            <Col xs={12} md={5} mdOffset={7}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Deals</th>
                    <th>Total Amount:</th>
                    <th>Total Weighted Amount:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{Number(stateCounts.dealsCount)}</td>
                    <td>{`$${Number(stateCounts.totalAmount).toLocaleString('en-US')}`}</td>
                    <td>{`$${Number(stateCounts.totalWeighted).toLocaleString('en-US')}`}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}


export default BusinessDeveplomentList;
