import React, { Component } from 'react';
import 'assets/css/sidebar.css';
import userImageDefault from '../../assets/img/default-user-image.png';

class Navigation extends Component {
  menuOperationsElements = [
    {
      text: 'Home',
      location: '/',
      icon: 'home',
    },
    {
      text: 'Dashboard',
      location: '/dashboard',
      icon: 'dashboard',
      shouldShow: () => this.shouldShow('dashboard'),
    },
    {
      text: 'Consulting Hub',
      location: '/consulting-hub',
      icon: 'database',
    },
    {
      text: 'Business Development',
      location: '/business-development',
      icon: 'handshake-o',
      shouldShow: () => this.shouldShow('business_development'),
    },
    {
      text: 'Recruiting',
      location: '/recruiting',
      icon: 'users',
      shouldShow: () => this.shouldShow('recruiting'),
    },
    {
      text: 'Realization',
      location: '/realization',
      icon: 'thermometer-three-quarters',
      shouldShow: () => this.shouldShow('realization'),
    },
    {
      text: 'Staffing',
      location: '/staffing',
      icon: 'calendar-check-o',
      shouldShow: () => this.shouldShow('staffing'),
    },
    {
      text: 'Utilization',
      location: '/utilization',
      icon: 'clock-o',
      shouldShow: () => this.shouldShow('utilization'),
    },
    {
      text: 'Invoices',
      location: '/invoices',
      icon: 'file-text',
      shouldShow: () => this.shouldShow('invoices'),
    },
    {
      text: 'Internal Projects',
      location: '/internal-projects',
      icon: 'puzzle-piece',
      shouldShow: () => this.shouldShow('internal_projects'),
    },
    {
      text: 'Consultant Locations',
      location: '/consultant-locations',
      icon: 'map-marker',
      shouldShow: () => this.shouldShow('consultant_locations'),
    },
    {
      text: 'Consultant Networking',
      location: '/consultant-networking/',
      icon: 'magnet',
      shouldShow: () => this.shouldShow('consultant_networking_sessions'),
    },
    {
      text: 'Consultant Skills',
      location: '/consultant-skills/',
      icon: 'tasks',
      shouldShow: () => this.shouldShow('consultant_skills'),
    },
    {
      text: 'Mentorship',
      location: '/mentorship/',
      icon: 'graduation-cap',
      shouldShow: () => this.shouldShow('mentorship'),
    },
  ]

  menuPeopleElements = [
    {
      text: 'People',
      location: '/people',
      icon: 'user',
      shouldShow: () => this.shouldShow('people'),
    },
    {
      text: 'Profit Sharing',
      location: '/profit-sharing',
      icon: 'pie-chart',
      shouldShow: () => this.shouldShow('profit_sharing'),
    },
    {
      text: 'Stock Options',
      location: '/stock-options',
      icon: 'line-chart',
      shouldShow: () => this.shouldShow('stock_options'),
    },
  ]

  menuMoreElements = [
    {
      text: 'Time/Expenses',
      location: 'https://headstorm.harvestapp.com/',
      icon: 'clock-o',
      target: '_blank',
      rel: 'noopener noreferrer',
      redirect: true,
    },
    {
      text: 'Performance Review',
      location: 'https://onitamago.headstorm.com',
      icon: 'check',
      target: '_blank',
      rel: 'noopener noreferrer',
      redirect: true,
    },
    {
      text: 'Calendar',
      location: 'https://outlook.office.com/owa/?path=/calendar/view/Week',
      icon: 'calendar',
      target: '_blank',
      rel: 'noopener noreferrer',
      redirect: true,
    },
    {
      text: 'PMO',
      location: '/pmo',
      icon: 'briefcase',
      shouldShow: () => this.shouldShow('pmo'),
    },
  ];

  constructor(props) {
    super(props);
    this.shouldShow = this.shouldShow.bind(this);

    this.state = {
      latestImport: [],
    }
  }

  componentWillMount() {
    // Get Last import information
    this.props.getBackgroundProcessLastRun();
  }

  componentWillReceiveProps(nextProps) {
    // Set Last inmport information
    if (nextProps.backgroundProcess && nextProps.backgroundProcess.lastRunData) {
      this.setState({ latestImport: nextProps.backgroundProcess.lastRunData });
    }
  }

  shouldShow(areaName) {
    let shouldShow = false;
    let permissions = [];
    const { user } = this.props.session;
    if (user && user.role) {
      permissions = user.role.permissions;
    }

    permissions.forEach((area) => {
      if (area.area === areaName) {
        if (
          area.can_create
          || area.can_delete
          || area.can_read
          || area.can_update
          || area.limit_to_own_user
        ) {
          shouldShow = true;
        }
      }
    });

    return shouldShow;
  }

  clickNav(element, e) {
    e.preventDefault();
    this.props.router.push(element);
  }

  render() {
    const { user } = this.props.session;
    const shouldShowPeopleHeader =
      this.shouldShow('stock_options')
      || this.shouldShow('profit_sharing')
      || this.shouldShow('people');

    const { latestImport } = this.state;

    return (
      <aside className="main-sidebar">
        <section className="sidebar">
          <div className="user-panel">
            <div className="pull-left image">
              <img src={user && user.image ? user.image : userImageDefault} className="img-circle" alt="User" />
            </div>
            <div className="pull-left info">
              <p>
                {user ? user.name : ''}
              </p>
            </div>
          </div>
          {
            // <form action="#" method="get" className="sidebar-form">
            //   <div className="input-group">
            //     <input type="text" name="q" className="form-control" placeholder="Search..." />
            //     <span className="input-group-btn">
            //           <button type="submit" name="search" id="search-btn" className="btn btn-flat"><i className="fa fa-search"></i>
            //           </button>
            //         </span>
            //   </div>
            // </form>
          }
          <ul className="sidebar-menu" data-widget="tree">
            <li className="header">
              Operations
            </li>
            {this.menuOperationsElements.map((element, index) => {
              let shouldShow = true;
              if (element.shouldShow) {
                shouldShow = element.shouldShow();
              }

              let current = false;
              let content = '';

              if (element.location === this.props.location.pathname) {
                current = true;
              }
              if (element.submenu) {
                content = <div />;
              } else if (shouldShow) {
                content = (
                  <li key={index} className={current ? 'current' : ''}>
                    <a
                      href={element.location}
                      onClick={this.clickNav.bind(this, element.location)}
                    >
                      {element.icon ? <i className={`fa fa-${element.icon}`} /> : ''}
                      &nbsp;
                      <span>
                        {element.text}
                      </span>
                    </a>
                  </li>
                );
              }
              return content;
            })}

            {shouldShowPeopleHeader ? (
              <li className="header">
                People
              </li>
            ) : ''}

            {
              this.menuPeopleElements.map((element, index) => {
                let shouldShow = true;
                if (element.shouldShow) {
                  shouldShow = element.shouldShow();
                }

                let current = false;

                if (element.location === this.props.location.pathname) {
                  current = true;
                }

                let content = '';

                if (element.submenu) {
                  content = <div />;
                } else if (shouldShow) {
                  content = (
                    <li key={index} className={current ? 'current' : ''}>
                      <a
                        href={element.location}
                        onClick={this.clickNav.bind(this, element.location)}
                      >
                        {element.icon ? <i className={`fa fa-${element.icon}`} /> : ''}
                        &nbsp;
                        <span>
                          {element.text}
                        </span>
                      </a>
                    </li>
                  );
                }
                return content;
              })
          }

            <li className="header">
              More
            </li>
            {this.menuMoreElements.map((element, index) => {
              if (element.redirect) {
                return (
                  <li key={index}>
                    <a href={element.location} target={element.target}>
                      {element.icon ? <i className={`fa fa-${element.icon}`} /> : ''}
                      &nbsp;
                      <span>
                        {element.text}
                      </span>
                    </a>
                  </li>
                );
              }

              let shouldShow = true;
              if (element.shouldShow) {
                shouldShow = element.shouldShow();
              }

              let current = false;

              if (element.location === this.props.location.pathname) {
                current = true;
              }

              if (shouldShow) {
                return (
                  <li key={index} className={current ? 'current' : ''}>
                    <a
                      href={element.location}
                      onClick={this.clickNav.bind(this, element.location)}
                    >
                      {element.icon ? <i className={`fa fa-${element.icon}`} /> : ''}
                      &nbsp;
                      <span>
                        {element.text}
                      </span>
                    </a>
                  </li>
                );
              }

              return null;
            })}

            <li className="header">
              Latest Import
            </li>
            { latestImport.map((element, index) => {
              return (
                <li key={index} className="nav-info"><span>{ element }</span></li>
              )
            })}

            {
            // <li className="active treeview">
            //   <a href="#">
            //     <i className="fa fa-dashboard"></i> <span>Dashboard</span>
            //     <span className="pull-right-container">
            //       <i className="fa fa-angle-left pull-right"></i>
            //     </span>
            //   </a>
            //   <ul className="treeview-menu">
            //     <li className="active"><a href="index.html"><i className="fa fa-circle-o"></i> Dashboard v1</a></li>
            //     <li><a href="index2.html"><i className="fa fa-circle-o"></i> Dashboard v2</a></li>
            //   </ul>
            // </li>
            // <li>
            //   <a href="pages/widgets.html">
            //     <i className="fa fa-th"></i> <span>Widgets</span>
            //     <span className="pull-right-container">
            //       <small className="label pull-right bg-green">new</small>
            //     </span>
            //   </a>
            // </li>
            //
            //
            // <li>
            //   <a href="pages/calendar.html">
            //     <i className="fa fa-calendar"></i> <span>Calendar</span>
            //     <span className="pull-right-container">
            //       <small className="label pull-right bg-red">3</small>
            //       <small className="label pull-right bg-blue">17</small>
            //     </span>
            //   </a>
            // </li>
            //
            // <li className="treeview">
            //   <a href="#">
            //     <i className="fa fa-share"></i> <span>Multilevel</span>
            //     <span className="pull-right-container">
            //       <i className="fa fa-angle-left pull-right"></i>
            //     </span>
            //   </a>
            //   <ul className="treeview-menu">
            //     <li><a href="#"><i className="fa fa-circle-o"></i> Level One</a></li>
            //     <li className="treeview">
            //       <a href="#"><i className="fa fa-circle-o"></i> Level One
            //         <span className="pull-right-container">
            //           <i className="fa fa-angle-left pull-right"></i>
            //         </span>
            //       </a>
            //       <ul className="treeview-menu">
            //         <li><a href="#"><i className="fa fa-circle-o"></i> Level Two</a></li>
            //         <li className="treeview">
            //           <a href="#"><i className="fa fa-circle-o"></i> Level Two
            //             <span className="pull-right-container">
            //               <i className="fa fa-angle-left pull-right"></i>
            //             </span>
            //           </a>
            //           <ul className="treeview-menu">
            //             <li><a href="#"><i className="fa fa-circle-o"></i> Level Three</a></li>
            //             <li><a href="#"><i className="fa fa-circle-o"></i> Level Three</a></li>
            //           </ul>
            //         </li>
            //       </ul>
            //     </li>
            //     <li><a href="#"><i className="fa fa-circle-o"></i> Level One</a></li>
            //   </ul>
            // </li>
            // <li><a href="https://adminlte.io/docs"><i className="fa fa-book"></i> <span>Documentation</span></a></li>
            }
          </ul>
        </section>
      </aside>
    );
  }
}

export default Navigation;
