import React, { Component } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import SettingsResourcesList from './list.jsx';
import SettingsResourcesForm from './form.jsx';
import SystemMsg from '../../common/system-msg.jsx';
import Select from '../../form/select.jsx';
// import 'assets/css/rates.css';

class SettingsResources extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'settings') {
          permissions = area;
        }
      });
    }
    this.state = {
      permissions,
      type: null,
      currentPage: this.props.settingsResources.returnPage || 1,
    };

    this.setType = this.setType.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  componentWillUnmount() {
    this.props.recruitsHideForm();
  }

  getTitle() {
    const {
      type,
    } = this.state;
    let loading = false;

    if (
      this.props.settingsUniversityResources.inProgress ||
      this.props.settingsInsightsResources.inProgress ||
      this.props.settingsMethodologiesResources.inProgress
    ) {
      loading = true;
    }

    if (this.props.settingsResources.showList) {
      return (
        <Row>
          <Col xs={4} sm={4} md={3} lg={2} className="title-section">
            <h1 className="box-title">
              Resources
            </h1>
          </Col>
          <Col xs={8} sm={8} md={9} lg={10}>
            <Col xs={9} sm={6} md={4}>
              <Select schema={{
                value: type,
                name: 'type',
                label: '',
                disabled: loading,
                placeholder: 'Select a type to continue',
                options: [
                  {
                    name: 'Headstorm University',
                    value: 'university',
                  },
                  {
                    name: 'Insights',
                    value: 'insights',
                  },
                  {
                    name: 'Methodologies & Frameworks',
                    value: 'methodologies',
                  },
                ],
                onChange: this.setType,
                searchable: true,
              }}
              />
            </Col>
            <Col
              xs={3}
              sm={6}
              md={8}
              style={{
                paddingTop: 22,
              }}
            >
              {
                type ? (
                  <Button
                    bsSize="small"
                    bsStyle="success"
                    onClick={this.props.settingsResourcesShowForm.bind(this, 'newItem', this.state.currentPage)}
                    disabled={loading}
                  >
                    Add New
                  </Button>
                ) : null
              }
            </Col>
          </Col>
        </Row>
      );
    }

    if (this.props.settingsResources.showForm) {
      let typeName;
      switch (type) {
        case 'university':
          typeName = 'University';
          break;
        case 'insights':
          typeName = 'Insights';
          break;
        case 'methodologies':
          typeName = 'Methodologies';
          break;
        default:
          typeName = '';
      }
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.settingsResourcesShowList.bind(this)}>
                <span className="fa fa-arrow-circle-o-left" />
                &nbsp;Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">
                {this.props.settingsResources.itemId ? `Edit ${typeName} Resource` : `Add New ${typeName} Resource`}
              </h1>
            </Col>
          </Row>
        </div>
      );
    }

    return null;
  }

  setType(type) {
    this.setState({ type, currentPage: 1 });
  }

  changePage(page) {
    this.setState({ currentPage: page });
  }

  render() {
    const {
      type,
    } = this.state;

    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    let loading = false;
    if (
      this.props.settingsUniversityResources.inProgress ||
      this.props.settingsInsightsResources.inProgress ||
      this.props.settingsMethodologiesResources.inProgress
    ) {
      loading = true;
    }

    return (
      <section className="rates-content">
        { loading ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this.getTitle()}
          </div>
          <div className="box-content">
            {this.props.settingsResources.showList && type ?
              <SettingsResourcesList
                type={type}
                changePage={this.changePage}
                currentPage={this.state.currentPage}
                {...this.props}
              />
              : null}
            {this.props.settingsResources.showForm && type ?
              <SettingsResourcesForm type={type} {...this.props} />
              : null}
          </div>
        </div>
      </section>
    );
  }
}

export default SettingsResources;
