import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';

export const CONSULTANT_NETWORKING_SESSION_PENDING = 'CONSULTANT_NETWORKING_SESSION_PENDING';
export const consultantNetworkingSessionPending = makeActionCreator(CONSULTANT_NETWORKING_SESSION_PENDING);

export const CONSULTANT_NETWORKING_SESSION_REJECTED = 'CONSULTANT_NETWORKING_SESSION_REJECTED';
export const consultantNetworkingSessionRejected = makeActionCreator(CONSULTANT_NETWORKING_SESSION_REJECTED);

export const CONSULTANT_NETWORKING_SESSION_SHOW_LIST = 'CONSULTANT_NETWORKING_SESSION_SHOW_LIST';
export const consultantNetworkingSessionShowList = makeActionCreator(CONSULTANT_NETWORKING_SESSION_SHOW_LIST);

export const CONSULTANT_NETWORKING_SESSION_HIDE_LIST = 'CONSULTANT_NETWORKING_SESSION_HIDE_LIST';
export const consultantNetworkingSessionHideList = makeActionCreator(CONSULTANT_NETWORKING_SESSION_HIDE_LIST);

export const CONSULTANT_NETWORKING_SESSION_SHOW_FORM = 'CONSULTANT_NETWORKING_SESSION_SHOW_FORM';
export const consultantNetworkingSessionShowForm = makeActionCreator(CONSULTANT_NETWORKING_SESSION_SHOW_FORM, 'itemId');

export const CONSULTANT_NETWORKING_SESSION_HIDE_FORM = 'CONSULTANT_NETWORKING_SESSION_HIDE_FORM';
export const consultantNetworkingSessionHideForm = makeActionCreator(CONSULTANT_NETWORKING_SESSION_HIDE_FORM);

export const CONSULTANT_NETWORKING_SESSION_SHOW_ITEM = 'CONSULTANT_NETWORKING_SESSION_SHOW_ITEM';
export const consultantNetworkingSessionShowItem = makeActionCreator(CONSULTANT_NETWORKING_SESSION_SHOW_ITEM, 'itemId');


// Show Index
export const CONSULTANT_NETWORKING_SESSION_LIST_FULFILLED = 'CONSULTANT_NETWORKING_SESSION_LIST_FULFILLED';
export const consultantNetworkingSessionListFulfilled = makeActionCreator(CONSULTANT_NETWORKING_SESSION_LIST_FULFILLED, 'data');

export const CONSULTANT_NETWORKING_SESSION_LIST_ERROR = 'CONSULTANT_NETWORKING_SESSION_LIST_ERROR';
export const consultantNetworkingSessionListError = makeActionCreator(CONSULTANT_NETWORKING_SESSION_LIST_ERROR, 'message');

export function consultantNetworkingSessionList(userId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(consultantNetworkingSessionPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.sesssionsIndex.version}${endpoints.sesssionsIndex.path.replace(':user_id', userId)}`, {
        method: endpoints.sesssionsIndex.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(consultantNetworkingSessionListFulfilled(json.data));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(consultantNetworkingSessionRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Show Item
export const CONSULTANT_NETWORKING_SESSION_ITEM_FULFILLED = 'CONSULTANT_NETWORKING_SESSION_ITEM_FULFILLED';
export const consultantNetworkingSessionItemFulfilled = makeActionCreator(CONSULTANT_NETWORKING_SESSION_ITEM_FULFILLED, 'data');

export const CONSULTANT_NETWORKING_SESSION_ITEM_ERROR = 'CONSULTANT_NETWORKING_SESSION_ITEM_ERROR';
export const consultantNetworkingSessionItemError = makeActionCreator(CONSULTANT_NETWORKING_SESSION_ITEM_ERROR, 'message');

export function consultantNetworkingSessionItem(userId, itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(consultantNetworkingSessionPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getSession.version}${endpoints.getSession.path.replace(':user_id', userId).replace(':session_id', itemId)}`, {
        method: endpoints.getSession.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(consultantNetworkingSessionItemFulfilled(json.data));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(consultantNetworkingSessionRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Add Item
export const CONSULTANT_NETWORKING_SESSION_ITEM_ADD_FULFILLED = 'CONSULTANT_NETWORKING_SESSION_ITEM_ADD_FULFILLED';
export const consultantNetworkingSessionItemAddFulfilled = makeActionCreator(CONSULTANT_NETWORKING_SESSION_ITEM_ADD_FULFILLED, 'data');

export const CONSULTANT_NETWORKING_SESSION_ITEM_ADD_ERROR = 'CONSULTANT_NETWORKING_SESSION_ITEM_ADD_ERROR';
export const consultantNetworkingSessionItemAddError = makeActionCreator(CONSULTANT_NETWORKING_SESSION_ITEM_ADD_ERROR, 'message');

export function consultantNetworkingSessionAddItem(userId, data, status) {
  const formData = new FormData();

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(consultantNetworkingSessionPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addSession.version}${endpoints.addSession.path.replace(':user_id', userId)}`, {
        method: endpoints.addSession.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(consultantNetworkingSessionItemAddFulfilled(json.data));
            dispatch(consultantNetworkingSessionList(userId, status));
          } else {
            dispatch(consultantNetworkingSessionItemAddError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(consultantNetworkingSessionRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Update Item
export const CONSULTANT_NETWORKING_SESSION_ITEM_UPDATE_FULFILLED = 'CONSULTANT_NETWORKING_SESSION_ITEM_UPDATE_FULFILLED';
export const consultantNetworkingSessionItemUpdateFulfilled = makeActionCreator(CONSULTANT_NETWORKING_SESSION_ITEM_UPDATE_FULFILLED, 'data');

export const CONSULTANT_NETWORKING_SESSION_ITEM_UPDATE_ERROR = 'CONSULTANT_NETWORKING_SESSION_ITEM_UPDATE_ERROR';
export const consultantNetworkingSessionItemUpdateError = makeActionCreator(CONSULTANT_NETWORKING_SESSION_ITEM_UPDATE_ERROR, 'message');

export function consultantNetworkingSessionUpdateItem(userId, itemId, data, status) {
  const formData = new FormData();
  formData.append('_method', 'PUT');

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(consultantNetworkingSessionPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateSession.version}${endpoints.updateSession.path.replace(':user_id', userId).replace(':session_id', itemId)}`, {
        method: endpoints.updateSession.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(consultantNetworkingSessionItemUpdateFulfilled(json.data));
            dispatch(consultantNetworkingSessionList(userId, status));
          } else {
            dispatch(consultantNetworkingSessionItemUpdateError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(consultantNetworkingSessionRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Delete Item
export const CONSULTANT_NETWORKING_SESSION_ITEM_DELETE_FULFILLED = 'CONSULTANT_NETWORKING_SESSION_ITEM_DELETE_FULFILLED';
export const consultantNetworkingSessionItemDeleteFulfilled = makeActionCreator(CONSULTANT_NETWORKING_SESSION_ITEM_DELETE_FULFILLED, 'data');

export const CONSULTANT_NETWORKING_SESSION_ITEM_DELETE_ERROR = 'CONSULTANT_NETWORKING_SESSION_ITEM_DELETE_ERROR';
export const consultantNetworkingSessionItemDeleteError = makeActionCreator(CONSULTANT_NETWORKING_SESSION_ITEM_DELETE_ERROR, 'message');

export function consultantNetworkingSessionDeleteItem(userId, itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(consultantNetworkingSessionPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteSessionItem.version}${endpoints.deleteSessionItem.path.replace(':user_id', userId).replace(':session_id', itemId)}`, {
        method: endpoints.deleteSessionItem.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(consultantNetworkingSessionItemDeleteFulfilled(json.data));
            dispatch(consultantNetworkingSessionList(userId));
          } else {
            dispatch(consultantNetworkingSessionItemDeleteError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(consultantNetworkingSessionRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
