import React, {Component} from 'react';
import SettingProfitSharingPointsList from './list.jsx'
import SettingProfitSharingPointsForm from './form.jsx'
import SystemMsg from '../../common/system-msg.jsx'
import { Col, Row, Button} from 'react-bootstrap';
import "assets/css/rates.css";
import Select from '../../form/select.jsx'

class SettingProfitSharingPoints extends Component{
  constructor(props){
  	super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'settings') {
          permissions = area;
        }
      });
    }
  	this.state = {
      levelId: null,
      permissions
    };
  }

  componentWillMount() {
      this.props.settingsLevelsList()
  }

  componentWillUnmount() {
    this.props.settingProfitSharingPointsHideForm()
    this.props.settingProfitSharingPointsShowList()
  }

  render() {
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className="rates-content">
        { this.props.settingProfitSharingPoints.inProgress || this.props.settingsLevels.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this._getTitle()}
          </div>
          <div className="box-content">
            {this.props.settingProfitSharingPoints.showList && this.state.levelId ?<SettingProfitSharingPointsList levelId={this.state.levelId} {...this.props} />:""}
            {this.props.settingProfitSharingPoints.showForm?<SettingProfitSharingPointsForm levelId={this.state.levelId} {...this.props} />:""}
          </div>
        </div>



      </section>
    )
  }

  _getTitle(){
    let levels = []

    for(let i = 0; i < this.props.settingsLevels.data.length; i++){
      levels.push(
        {
            name: this.props.settingsLevels.data[i].name,
            value: this.props.settingsLevels.data[i].id
        }
      )
    }
    if(this.props.settingProfitSharingPoints.showList){
      return (
        <Row>
          <Col xs={12} sm={4} md={3} lg={3} className="title-section">
            <h1 className="box-title">Profit Sharing Points</h1>
          </Col>
          <Col  xs={12} sm={6} md={4} lg={3}>
            <Select schema={{
                value: this.state.levelId,
                name: "level",
                label: "",
                disabled: this.props.settingProfitSharingPoints.inProgress || this.props.settingsLevels.inProgress,
                placeholder: "Select a Level to continue",
                options: levels,
                onChange: this._setLevel.bind(this),
                searchable: true
              }} />
          </Col>
          <Col xs={6} sm={3} md={2} lg={2}>
              {(this.state.levelId)?<Button bsSize="small" bsStyle="success"  className="button-new" onClick={this.props.settingProfitSharingPointsShowForm.bind(this, "newItem")} disabled={this.props.settingProfitSharingPoints.inProgress || this.props.settingsLevels.inProgress}>Add New Profit Sharing Point</Button>:""}
          </Col>
        </Row>



      )
    }else if(this.props.settingProfitSharingPoints.showForm){
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.settingProfitSharingPointsShowList.bind(this)}><span className="fa fa-arrow-circle-o-left"></span> Back</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">{ this.props.settingProfitSharingPoints.itemId?"Edit Profit Sharing Point":"Add New Profit Sharing Point" }</h1>
            </Col>
          </Row>
        </div>


      )
    }
  }

  _setLevel(level){
    this.setState({"levelId": level})
  }
}

export default SettingProfitSharingPoints
