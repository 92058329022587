import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as authListActionCreators from '../actions/auth/list';
import * as businessDevelopmentListActionCreators from '../actions/business-development/list';
import * as RecruitsListActionCreators from '../actions/recruits/list';
import * as RealizationListActionCreators from '../actions/realization/detailedList';
import * as RealizationSummaryListActionCreators from '../actions/realization/summaryList';
import * as RealizationLast3MonthsActionCreators from '../actions/realization/last3Months';
import * as UtilizationListActionCreators from '../actions/utilization/list';
import * as InvoicesIssuedListActionCreators from '../actions/invoices-issued/list';
import * as SettingsRecruitStagesListActionCreators from '../actions/settings/recruiting-stages/list';
import * as SettingsRecruitSourcesListActionCreators from '../actions/settings/recruiting-sources/list';
import * as UserListActionCreators from '../actions/user/list';
import * as LevelsListActionCreators from '../actions/settings/levels/list';
import * as RatesListActionCreators from '../actions/settings/rates/list';
import * as AnnouncementsListActionCreators from '../actions/settings/announcements/list';
import * as UserLevelListActionCreators from '../actions/user-level/list';
import * as StaffingListActionCreators from '../actions/staffing/list';
import * as ProjectsListActionCreators from '../actions/projects/list';
import * as ImportListActionCreators from '../actions/settings/import/list';
import * as InternalProjectsListActionCreators from '../actions/internal-projects/list';
import * as SettingsProfitSharingPointsListActionCreators from '../actions/settings/profit-sharing-points/list';
import * as SettingsProfitSharingValuesListActionCreators from '../actions/settings/profit-sharing-values/list';
import * as ProfitSharingListActionCreators from '../actions/profit-sharing/list';
import * as StockOptionsListActionCreators from '../actions/stock-options/list';
import * as StockValueListActionCreators from '../actions/settings/stock-value/list';
import * as UserPermissionActionCreators from '../actions/settings/user-permissions/list';
import * as ResourcesActionCreators from '../actions/settings/resources/list';
import * as UniversityResourcesActionCreators from '../actions/settings/resources/university';
import * as InsightsResourcesActionCreators from '../actions/settings/resources/insights';
import * as MethodologiesResourcesActionCreators from '../actions/settings/resources/methodologies';
import * as ConsultantLocationActionCreators from '../actions/consultant-locations/list';
import * as ConsultantNetworkingActionCreators from '../actions/consultant-networking/list';
import * as ConsultantNetworkingSessionsActionCreators from '../actions/consultant-networking/sessions';
import * as ConsultantSkillsActionCreators from '../actions/consultant-skills/consultantSkills';
import * as MentorshipActionCreators from '../actions/mentorship/mentorship';
import * as DashboardActionCreators from '../actions/dashboard/list';
import * as PMOProjectsActionCreators from '../actions/pmo/projects';
import * as PMOIssueManagementActionCreators from '../actions/pmo/issueManagement';
import * as PMORiskManagementActionCreators from '../actions/pmo/riskManagement';
import * as PMOChangeControlActionCreators from '../actions/pmo/changeControl';
import * as PMOActionItemsActionCreators from '../actions/pmo/actionItems';
import * as PMOMilestoneActionCreators from '../actions/pmo/milestones';
import * as BackgroundProcessActionCreators from '../actions/background-process/backgroundProcess';

import Main from './main.jsx';

function mapStateToProps(state) {
  // console.log('########## STATE ##########');
  // console.log(state);
  // console.log('########## - ##########');
  return {
    auth: state.authReducer,
    dashboard: state.DashboardReducer,
    businessDevelopment: state.BusinessDevelopmentReducer,
    recruits: state.RecruitsReducer,
    realization: state.RealizationReducer,
    realizationSummary: state.RealizationSummaryReducer,
    realizationLast3Months: state.RealizationLast3MonthsReducer,
    utilization: state.UtilizationReducer,
    invoicesIssued: state.InvoicesIssuedReducer,
    settingsRecruitingStages: state.SettingsRecruitingStagesReducer,
    user: state.UserReducer,
    settingsRecruitingSources: state.SettingsRecruitingSourcesReducer,
    settingsLevels: state.SettingsLevelsReducer,
    settingsRates: state.SettingsRatesReducer,
    settingsAnnouncements: state.SettingsAnnouncementsReducer,
    userLevel: state.UserLevelReducer,
    staffing: state.StaffingReducer,
    projects: state.ProjectsReducer,
    internalProjects: state.InternalProjectsReducer,
    settingsImport: state.ImportReducer,
    settingProfitSharingPoints: state.SettingsProfitSharingPointsReducer,
    settingProfitSharingValues: state.SettingsProfitSharingValuesReducer,
    profitSharing: state.ProfitSharingReducer,
    stockOptions: state.StockOptionsReducer,
    consultantLocations: state.ConsultantLocationsReducer,
    consultantNetworking: state.ConsultantNetworkingReducer,
    consultantNetworkingSessions: state.ConsultantNetworkingSessionsReducer,
    consultantSkills: state.ConsultantSkillsReducer,
    mentorship: state.MentorshipReducer,
    settingStockValue: state.StockValueReducer,
    settingUserPermission: state.UserPermissionReducer,
    settingsResources: state.SettingsResourcesReducer,
    settingsUniversityResources: state.SettingsUniversityResourcesReducer,
    settingsInsightsResources: state.SettingsInsightsResourcesReducer,
    settingsMethodologiesResources: state.SettingsMethodologiesResourcesReducer,
    pmoProjects: state.pmoProjectsReducer,
    pmoIssueManagement: state.pmoIssueManagementReducer,
    pmoRiskManagement: state.pmoRiskManagementReducer,
    pmoChangeControl: state.pmoChangeControlReducer,
    pmoActionItems: state.pmoActionItemsReducer,
    pmoMilestone: state.pmoMilestoneReducer,
    backgroundProcess: state.BackgroundProcessReducer,
    session: state.session,
    routing: state.routing,
  };
}

function mapDispachToProps(dispatch) {
  return bindActionCreators(Object.assign(
    {},
    authListActionCreators,
    businessDevelopmentListActionCreators,
    RecruitsListActionCreators,
    RealizationListActionCreators,
    RealizationSummaryListActionCreators,
    RealizationLast3MonthsActionCreators,
    UtilizationListActionCreators,
    InvoicesIssuedListActionCreators,
    SettingsRecruitStagesListActionCreators,
    UserListActionCreators,
    SettingsRecruitSourcesListActionCreators,
    LevelsListActionCreators,
    RatesListActionCreators,
    AnnouncementsListActionCreators,
    DashboardActionCreators,
    UserLevelListActionCreators,
    StaffingListActionCreators,
    ProjectsListActionCreators,
    ImportListActionCreators,
    InternalProjectsListActionCreators,
    SettingsProfitSharingPointsListActionCreators,
    SettingsProfitSharingValuesListActionCreators,
    ProfitSharingListActionCreators,
    StockOptionsListActionCreators,
    StockValueListActionCreators,
    UserPermissionActionCreators,
    ResourcesActionCreators,
    UniversityResourcesActionCreators,
    InsightsResourcesActionCreators,
    MethodologiesResourcesActionCreators,
    ConsultantLocationActionCreators,
    ConsultantNetworkingActionCreators,
    ConsultantNetworkingSessionsActionCreators,
    ConsultantSkillsActionCreators,
    MentorshipActionCreators,
    PMOProjectsActionCreators,
    PMOIssueManagementActionCreators,
    PMORiskManagementActionCreators,
    PMOChangeControlActionCreators,
    PMOActionItemsActionCreators,
    PMOMilestoneActionCreators,
    BackgroundProcessActionCreators,
  ),
  dispatch);
}

const App = connect(mapStateToProps, mapDispachToProps)(Main);

export default App;
