import makeActionCreator from '../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API       from '../utils/config-api'
import * as endpoints from './_endpoints'
import { logout } from '../auth/list';

// General Status
export const BACKGROUND_PROCESS_PENDING = 'BACKGROUND_PROCESS_PENDING'
export const backgroundProcessPending = makeActionCreator(BACKGROUND_PROCESS_PENDING)

export const BACKGROUND_PROCESS_ERROR = 'BACKGROUND_PROCESS_ERROR'
export const backgroundProcessError = makeActionCreator(BACKGROUND_PROCESS_ERROR)


// Last Run
export const BACKGROUND_PROCESS_LAST_RUN_FULFILLED = 'BACKGROUND_PROCESS_LAST_RUN_FULFILLED'
export const backgroundProcessLastRunFulfilled = makeActionCreator(BACKGROUND_PROCESS_LAST_RUN_FULFILLED, 'data')

export function getBackgroundProcessLastRun(start_date, end_date) {
  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(backgroundProcessPending())
        return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.lastRun.version}${endpoints.lastRun.path}`, {
            method  : endpoints.lastRun.method,
            headers : {
              'Authorization' : `Bearer ${currentSession.token}`,
              'Accept': 'application/json'
            }
        })
        .then( API.checkStatus )
        .then( API.parseJSON )
        .then( json => dispatch(backgroundProcessLastRunFulfilled(json.data)))
        .catch( err => {
          // Dispatch the error action with error information
          dispatch(backgroundProcessError(err))
        })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));
    })
  }
}
