import React, {Component} from 'react';
import SmartForm from '../form/base.jsx'
import { Col, Row} from 'react-bootstrap';
import moment from 'moment'

class BusinessDeveplomentForm extends Component{

  componentWillMount() {

    if(this.props.businessDevelopment.itemId){
      this.props.businessDevelopmentItem(this.props.businessDevelopment.itemId)
    }

    this.props.userList()

    this.props.settingsProjectLikelihoodList()
  }

  componentWillReceiveProps(nextProps) {
    if((nextProps.businessDevelopment.addSuccess && nextProps.businessDevelopment.addSuccess !== this.props.businessDevelopment.addSuccess) || (nextProps.businessDevelopment.editSuccess && nextProps.businessDevelopment.editSuccess !== this.props.businessDevelopment.editSuccess)){
      this.props.businessDevelopmentShowList()
    }
  }

  render(){
    let itemData, users = [], projectLikelihood =[]

    if(this.props.user.data){
      for(let i=0; i<this.props.user.data.length; i++){

        if(this.props.user.data[i].role && this.props.user.data[i].role.capabilities && this.props.user.data[i].role.capabilities.can_be_officer_in_charge){
          users.push({
            name: this.props.user.data[i].name,
            value: this.props.user.data[i].id
          })
        }

      }
    }

    if(this.props.settingsProjectLikelihood.data){
      for(let i=0; i<this.props.settingsProjectLikelihood.data.length; i++){
        projectLikelihood.push({
          name: this.props.settingsProjectLikelihood.data[i].ratio,
          value: this.props.settingsProjectLikelihood.data[i].id
        })
      }
    }

    if(this.props.businessDevelopment.itemId){
      itemData = this.props.businessDevelopment.itemData
    }
    const schema = [
      {
        type: "default",
        fields: [
          {
            type: "text",
            name: "client",
            label: "Client:",
            value: itemData?itemData.client:''
          },
          {
            type: "text",
            name: "project",
            label: "Project:",
            value: itemData?itemData.project:''
          },
          {
            type: "select",
            name: "officer_in_charge_id",
            label: "OIC:",
            value: itemData && itemData.officer_in_charge?itemData.officer_in_charge.id:'',
            options: users,
            disabled: this.props.user.inProgress
          },
          {
            type: "select",
            name: "business_development_likelihood_id",
            label: "Likely:",
            value: itemData && itemData.business_development_likelihood_id?itemData.business_development_likelihood.id:'',
            options: projectLikelihood,
            disabled: this.props.settingsProjectLikelihoodList.inProgress
          },
          {
            type: "radios",
            name: "is_proposed",
            label: "Proposed?",
            value: itemData?itemData.is_proposed.toString():'',
            options: [
              {
                text: "Yes",
                value: "1"
              },
              {
                text: "No",
                value: "0"
              }
            ]
          },
          {
            type: "radios",
            name: "is_accepted",
            label: "Accepted?",
            value: itemData?itemData.is_accepted.toString():'',
            options: [
              {
                text: "Yes",
                value: "1"
              },
              {
                text: "No",
                value: "0"
              }
            ]
          },
          {
            type: "number",
            name: "amount",
            label: "Amount:",
            value: itemData?itemData.amount:''

          },
          {
            type: "calendar",
            name: "start_date",
            label: "Start Date:",
            value: itemData?moment(itemData.start_date):''

          },
          {
            type: "textarea",
            name: "note",
            label: "Notes:",
            value: itemData?itemData.note:''

          }
        ]
      }
    ]
    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this._submit.bind(this)}
              showError={this.props.businessDevelopment.error && this.props.businessDevelopment.errorText}
              errorMessage={ this.props.businessDevelopment.errorText }
              onReset={this.props.businessDevelopmentShowList.bind(this)}
              loading={this.props.businessDevelopment.inProgress } />
          </Col>
        </Row>
      </section>
    )
  }

  _submit(data){
    if(this.props.businessDevelopment.itemId){
      this.props.businessDevelopmentUpdateItem(this.props.businessDevelopment.itemId, data)
    }else{
      this.props.businessDevelopmentAddItem(data)
    }
  }


}

export default BusinessDeveplomentForm
