const initialState = {
  filterBy: [''],
  inProgress: false,
  error: false,
  errorText: null,
  data: [],
  showList: true,
  showForm: false,
  showItem: null,
  addSuccess: false,
  itemId: null,
  itemData: null,
  editSuccess: false,
};

let filters;

function PMOIssueManagementReducer(state = initialState, action) {
  const actionName = 'PMO_ISSUE_MANAGEMENT';
  switch (action.type) {
    case `${actionName}_PENDING`:
      return {
        ...state,
        inProgress: true,
        error: false,
        errorText: null,
        addSuccess: false,
        editSuccess: false,
      };
    case `${actionName}_LIST_FULFILLED`:
      return {
        ...state,
        inProgress: false,
        error: false,
        data: action.data,
      };
    case `${actionName}_REJECTED`:
      return {
        ...state,
        inProgress: false,
        error: action.error,
      };
    case `${actionName}_LIST_FILTER_BY`:
      filters = state.filterBy;
      filters[action.index] = action.value;
      return {
        ...state,
        filterBy: filters,
      };
    case `${actionName}_SHOW_LIST`:
      return {
        ...state,
        showList: true,
        showForm: false,
        itemId: null,
        itemData: null,
      };
    case `${actionName}_HIDE_LIST`:
      return {
        ...state,
        showList: false,
      };
    case `${actionName}_SHOW_FORM`:
      return {
        ...state,
        showForm: true,
        showList: false,
        itemId: action.itemId !== 'newItem' ? action.itemId : '',
      };
    case `${actionName}_HIDE_FORM`:
      return {
        ...state,
        showForm: false,
      };
    case `${actionName}_SHOW_ITEM`:
      return {
        ...state,
        showItem: action.itemId,
      };
    case `${actionName}_ITEM_ADD_FULFILLED`:
      return {
        ...state,
        inProgress: false,
        addSuccess: true,
      };
    case `${actionName}_ITEM_ADD_ERROR`:
      return {
        ...state,
        inProgress: false,
        error: true,
        errorText: action.message,
      };
    case `${actionName}_ITEM_UPDATE_FULFILLED`:
      return {
        ...state,
        inProgress: false,
        editSuccess: true,
      };
    case `${actionName}_ITEM_UPDATE_ERROR`:
      return {
        ...state,
        inProgress: false,
        error: true,
        errorText: action.message,
      };
    case `${actionName}_ITEM_FULFILLED`:
      return {
        ...state,
        inProgress: false,
        error: false,
        itemData: action.data,
      };
    default:
      return state;
  }
}

export default PMOIssueManagementReducer;
