import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';

const possibleValues = [
  { text: 'Office', value: 'Office' },
  { text: 'Client', value: 'Client' },
  { text: 'Home', value: 'Home' },
  { text: 'PTO', value: 'PTO' },
  // { text: 'Other', value: 'other' },
];

class LocationEditor extends Component {
  constructor(props) {
    super(props);

    this.updateData = this.updateData.bind(this);
    this.state = {
      name: props.defaultValue,
    };
  }

  componentDidMount() {
    this.input.focus();
  }


  updateData() {
    const {
      name,
    } = this.state;

    this.props.onUpdate(name);
  }

  render() {
    const {
      name,
    } = this.state;

    return (
      <div>

        <Autocomplete
          ref={(el) => { this.input = el; }}
          getItemValue={item => item.value}
          items={possibleValues}
          renderItem={(item, isHighlighted) => {
            let className = '';

            if (isHighlighted) {
              className = 'highlihted';
            }

            if (name === item.value) {
              className += ' selected';
            }

            return (
              <li className={className} key={item.value}>
                <button type="button">{item.text}</button>
              </li>
            );
          }}
          value={name}
          onChange={(e) => { this.setState({ name: e.target.value }); }}
          onSelect={(value) => { this.setState({ name: value }); }}
          inputProps={{
            onBlur: this.updateData.bind(this),
            className: 'form-control',
          }}
          renderMenu={(items, value, style) => {
            const menuStyle = {
              position: 'fixed',
              overflow: 'auto',
              maxHeight: '50%',
            };

            return (
              <ul className="dropdown-menu" style={{ ...style, ...menuStyle }}>
                {items}
              </ul>
            );
          }}
          wrapperProps={{
            className: 'location-select open',
          }}
          menuStyle={{}}
          wrapperStyle={{}}
        />

      </div>
    );
  }
}

export default LocationEditor;
