export const index = {
  path: '/pmo_projects/',
  method: 'GET',
  version: '/v1',
};

export const getItem = {
  path: '/pmo_projects/:pmo_project_id/',
  method: 'GET',
  version: '/v1',
};

export const addItem = {
  path: '/pmo_projects/',
  method: 'POST',
  version: '/v1',
};

export const updateItem = {
  path: '/pmo_projects/:pmo_project_id/',
  method: 'POST',
  version: '/v1',
};

export const deleteItem = {
  path: '/pmo_projects/:pmo_project_id/',
  method: 'DELETE',
  version: '/v1',
};

// Issue Management

export const issuesIndex = {
  path: '/pmo_projects/:pmo_project_id/issues/',
  method: 'GET',
  version: '/v1',
};

export const getIssue = {
  path: '/pmo_projects/:pmo_project_id/issues/:issue_id',
  method: 'GET',
  version: '/v1',
};

export const addIssue = {
  path: '/pmo_projects/:pmo_project_id/issues/',
  method: 'POST',
  version: '/v1',
};

export const updateIssue = {
  path: '/pmo_projects/:pmo_project_id/issues/:issue_id',
  method: 'POST',
  version: '/v1',
};

export const deleteIssue = {
  path: '/pmo_projects/:pmo_project_id/issues/:issue_id',
  method: 'DELETE',
  version: '/v1',
};

// Risk Management

export const riskIndex = {
  path: '/pmo_projects/:pmo_project_id/risks/',
  method: 'GET',
  version: '/v1',
};

export const getRisk = {
  path: '/pmo_projects/:pmo_project_id/risks/:risk_id',
  method: 'GET',
  version: '/v1',
};

export const addRisk = {
  path: '/pmo_projects/:pmo_project_id/risks/',
  method: 'POST',
  version: '/v1',
};

export const updateRisk = {
  path: '/pmo_projects/:pmo_project_id/risks/:risk_id',
  method: 'POST',
  version: '/v1',
};

export const deleteRisk = {
  path: '/pmo_projects/:pmo_project_id/risks/:risk_id',
  method: 'DELETE',
  version: '/v1',
};

// Change Control

export const changesIndex = {
  path: '/pmo_projects/:pmo_project_id/changes/',
  method: 'GET',
  version: '/v1',
};

export const getChange = {
  path: '/pmo_projects/:pmo_project_id/changes/:change_id',
  method: 'GET',
  version: '/v1',
};

export const addChange = {
  path: '/pmo_projects/:pmo_project_id/changes/',
  method: 'POST',
  version: '/v1',
};

export const updateChange = {
  path: '/pmo_projects/:pmo_project_id/changes/:change_id',
  method: 'POST',
  version: '/v1',
};

export const deleteChange = {
  path: '/pmo_projects/:pmo_project_id/changes/:change_id',
  method: 'DELETE',
  version: '/v1',
};

// Action Item

export const actionItemsIndex = {
  path: '/pmo_projects/:pmo_project_id/action_items/',
  method: 'GET',
  version: '/v1',
};

export const getActionItem = {
  path: '/pmo_projects/:pmo_project_id/action_items/:action_item_id',
  method: 'GET',
  version: '/v1',
};

export const addActionItem = {
  path: '/pmo_projects/:pmo_project_id/action_items/',
  method: 'POST',
  version: '/v1',
};

export const updateActionItem = {
  path: '/pmo_projects/:pmo_project_id/action_items/:action_item_id',
  method: 'POST',
  version: '/v1',
};

export const deleteActionItem = {
  path: '/pmo_projects/:pmo_project_id/action_items/:action_item_id',
  method: 'DELETE',
  version: '/v1',
};

// Milestone

export const milestonesIndex = {
  path: '/pmo_projects/:pmo_project_id/milestones/',
  method: 'GET',
  version: '/v1',
};

export const getMilestone = {
  path: '/pmo_projects/:pmo_project_id/milestones/:milestone_id',
  method: 'GET',
  version: '/v1',
};

export const addMilestone = {
  path: '/pmo_projects/:pmo_project_id/milestones/',
  method: 'POST',
  version: '/v1',
};

export const updateMilestone = {
  path: '/pmo_projects/:pmo_project_id/milestones/:milestone_id',
  method: 'POST',
  version: '/v1',
};

export const deleteMilestone = {
  path: '/pmo_projects/:pmo_project_id/milestones/:milestone_id',
  method: 'DELETE',
  version: '/v1',
};


// export project
export const exportPPTX = {
  path: '/pmo_projects/:pmo_project_id/export_pptx',
  method: 'GET',
  version: '/v1',
};
