import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';


export const CONSULTANT_LOCATIONS_PENDING = 'CONSULTANT_LOCATIONS_PENDING';
export const consultantLocationsPending = makeActionCreator(CONSULTANT_LOCATIONS_PENDING);

export const CONSULTANT_LOCATIONS_REJECTED = 'CONSULTANT_LOCATIONS_REJECTED';
export const consultantLocationsRejected = makeActionCreator(CONSULTANT_LOCATIONS_REJECTED);

export const CONSULTANT_LOCATIONS_SHOW_LIST = 'CONSULTANT_LOCATIONS_SHOW_LIST';
export const consultantLocationsShowList = makeActionCreator(CONSULTANT_LOCATIONS_SHOW_LIST);

export const CONSULTANT_LOCATIONS_HIDE_LIST = 'CONSULTANT_LOCATIONS_HIDE_LIST';
export const consultantLocationsHideList = makeActionCreator(CONSULTANT_LOCATIONS_HIDE_LIST);

export const CONSULTANT_LOCATIONS_SHOW_FORM = 'CONSULTANT_LOCATIONS_SHOW_FORM';
export const consultantLocationsShowForm = makeActionCreator(CONSULTANT_LOCATIONS_SHOW_FORM, 'item');

export const CONSULTANT_LOCATIONS_HIDE_FORM = 'CONSULTANT_LOCATIONS_HIDE_FORM';
export const consultantLocationsHideForm = makeActionCreator(CONSULTANT_LOCATIONS_HIDE_FORM);

export const CONSULTANT_LOCATIONS_SHOW_ITEM = 'CONSULTANT_LOCATIONS_SHOW_ITEM';
export const consultantLocationsShowItem = makeActionCreator(CONSULTANT_LOCATIONS_SHOW_ITEM, 'itemId');


// Show Index

export const CONSULTANT_LOCATIONS_LIST_FULFILLED = 'CONSULTANT_LOCATIONS_LIST_FULFILLED';
export const consultantLocationsListFulfilled = makeActionCreator(CONSULTANT_LOCATIONS_LIST_FULFILLED, 'data');

export const CONSULTANT_LOCATIONS_LIST_ERROR = 'CONSULTANT_LOCATIONS_LIST_ERROR';
export const consultantLocationsListError = makeActionCreator(CONSULTANT_LOCATIONS_LIST_ERROR, 'message');

export function consultantLocationsList(nextWeek = false) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(consultantLocationsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}${nextWeek ? 'next_week' : 'this_week'}`, {
        method: endpoints.index.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          dispatch(consultantLocationsListFulfilled(json.data));
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(consultantLocationsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Update Item

export const CONSULTANT_LOCATIONS_ITEM_UPDATE_FULFILLED = 'CONSULTANT_LOCATIONS_ITEM_UPDATE_FULFILLED';
export const consultantLocationsItemUpdateFulfilled = makeActionCreator(CONSULTANT_LOCATIONS_ITEM_UPDATE_FULFILLED, 'data');

export const CONSULTANT_LOCATIONS_ITEM_UPDATE_ERROR = 'CONSULTANT_LOCATIONS_ITEM_UPDATE_ERROR';
export const consultantLocationsItemUpdateError = makeActionCreator(CONSULTANT_LOCATIONS_ITEM_UPDATE_ERROR, 'message');

export function consultantLocationsUpdateItem(itemId, data, nextWeek) {
  const formData = new FormData();
  formData.append('_method', 'PUT');

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });


  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(consultantLocationsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateItem.version}${endpoints.updateItem.path.replace(':user_id', itemId)}${nextWeek ? 'next_week' : 'this_week'}`, {
        method: endpoints.updateItem.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(consultantLocationsItemUpdateFulfilled(json.data));
          } else {
            dispatch(consultantLocationsItemUpdateError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(consultantLocationsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Add Favorites

export const CONSULTANT_LOCATIONS_FAVORITE_ADD_FULFILLED = 'CONSULTANT_LOCATIONS_FAVORITE_ADD_FULFILLED';
export const consultantLocationsFavoriteAddFulfilled = makeActionCreator(CONSULTANT_LOCATIONS_FAVORITE_ADD_FULFILLED, 'data');

export const CONSULTANT_LOCATIONS_FAVORITE_ADD_ERROR = 'CONSULTANT_LOCATIONS_FAVORITE_ADD_ERROR';
export const consultantLocationsFavoriteAddError = makeActionCreator(CONSULTANT_LOCATIONS_FAVORITE_ADD_ERROR, 'message');

export function consultantLocationsFavoriteAdd(itemId, nextWeek) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(consultantLocationsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addFavorite.version}${endpoints.addFavorite.path.replace(':favorite_user_id', itemId)}`, {
        method: endpoints.addFavorite.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(consultantLocationsFavoriteAddFulfilled(json.data));
            dispatch(consultantLocationsList(nextWeek));
          } else {
            dispatch(consultantLocationsFavoriteAddError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(consultantLocationsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Remove Favorites

export const CONSULTANT_LOCATIONS_FAVORITE_REMOVE_FULFILLED = 'CONSULTANT_LOCATIONS_FAVORITE_REMOVE_FULFILLED';
export const consultantLocationsFavoriteRemoveFulfilled = makeActionCreator(CONSULTANT_LOCATIONS_FAVORITE_REMOVE_FULFILLED, 'data');

export const CONSULTANT_LOCATIONS_FAVORITE_REMOVE_ERROR = 'CONSULTANT_LOCATIONS_FAVORITE_REMOVE_ERROR';
export const consultantLocationsFavoriteRemoveError = makeActionCreator(CONSULTANT_LOCATIONS_FAVORITE_REMOVE_ERROR, 'message');

export function consultantLocationsFavoriteRemove(itemId, nextWeek) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(consultantLocationsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.removeFavorite.version}${endpoints.removeFavorite.path.replace(':favorite_user_id', itemId)}`, {
        method: endpoints.removeFavorite.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(consultantLocationsFavoriteRemoveFulfilled(json.data));
            dispatch(consultantLocationsList(nextWeek));
          } else {
            dispatch(consultantLocationsFavoriteRemoveError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(consultantLocationsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
