//
// Realization:
//

export const detailedIndex = {
  path: '/projects/realization/',
  method: 'GET',
  version: '/v1',
};

export const summary = {
  path: '/projects/realization_summary/',
  method: 'GET',
  version: '/v1',
};

export const last3Months = {
  path: '/projects/realization_last_3_months/',
  method: 'GET',
  version: '/v1',
};
