import React    from 'react'

import Datetime from 'react-datetime'
import moment from 'moment'
import * as helpers  from '../utils/helpers.js'
import 'assets/react-datetime/react-datetime.css'
import 'assets/css/input.css'

class Calendar extends React.Component {
  constructor(props) {
    super(props)
    this.state = ({
      minDate: this.props.schema.minDate ? moment(this.props.schema.minDate) : null,
      maxDate: this.props.schema.maxDate ? moment(this.props.schema.maxDate) : null,
      disabled: this.props.schema.disabled ? true:(this.props.schema.disabled !== false && this.props.schema.value == null?true:false),
      value: (this.props.schema.value && moment(this.props.schema.value).isValid()) || (this.props.schema.startDate && moment(this.props.schema.startDate).isValid()) ? moment(this.props.schema.value) : null,
    })
  }

  componentWillReceiveProps(nextProps) {
    if ( !helpers._isEquivalent(nextProps.schema, this.props.schema) ) {
        this.setState({ value : nextProps.schema.value ? moment(nextProps.schema.value) : null, disabled  : nextProps.schema.disabled || nextProps.schema.value == null, minDate : nextProps.schema.minDate ? moment(nextProps.schema.minDate) : null, maxDate : nextProps.schema.maxDate ? moment(nextProps.schema.maxDate) : null, })
    }
  }

  render() {
    const {
      schema,
    } = this.props;

    let fieldReadOnly = true;

    if (schema.fieldReadOnly || schema.fieldReadOnly === false) {
      fieldReadOnly = schema.fieldReadOnly;
    }

    if (schema.isButton) {
      return (
        <div data-tip={schema.tooltip ? schema.tooltip : ''} className="refresh-btn">
          <button
            type="button"
            disabled={schema.isButtonDisabled}
            onClick={schema.onClick}
          >
            <span className="fa fa-refresh" />
          </button>
        </div>
      );
    }
    return (
      <div
          className={this._getClassName(schema) } style={this._getStyle(schema)}  data-tip={schema.tooltip?schema.tooltip:''}>

          <label
              htmlFor={ schema.name }
          >
              { schema.label }
          </label>
          <div className="field-calendar">
              <Datetime
                  dateFormat={schema.dateFormat?schema.dateFormat:"YYYY-MM-DD"}
                  timeFormat={schema.timeSelect?"HH:mm:ss":false}
                  value={ this.state.value }
                  onChange={ this._handleChange.bind(this) }
                  disabled={ schema.disabled }
                  inputProps={{id: schema.id, name: schema.id, required: schema.isRequired, autoComplete:'off' , readOnly: fieldReadOnly, disabled: this.state.disabled, placeholder: "Select a date", style:{backgroundColor: this.state.disabled?"#eee":"#FFF"}}}
                  isValidDate={ this._validDay.bind(this) }
                  name={ schema.name }
                  closeOnSelect={true}
                  viewMode={schema.viewMode?schema.viewMode:"days"}/>
                <div className="icon">
                    <span className="fa fa-calendar"></span>
                </div>

          </div>
      </div>
    )
  }

  _validDay(currentDate, selectedDate) {
    let validDate = moment(selectedDate).isValid();

    if(validDate && (this.state.minDate || this.state.maxDate)) {
      if(this.state.minDate ){
        validDate &= currentDate.isSameOrAfter(moment(this.state.minDate), 'day')
      }

      if(this.state.maxDate ){
        validDate &= currentDate.isSameOrBefore(moment(this.state.maxDate), 'day')
      }
    }

    return validDate;
  }

  _handleChange(date) {
      this.setState({ value : date })

      if(this.props.schema.onChange){
        this.props.schema.onChange(date)
      }
  }

  _getClassName(schema) {
    let className = "form-group field calendar-field"

      if(schema.border) {
        className += " border"
      }

      if(this.state.disabled) {
        className += " disabled"
      }

      if(schema.className) {
          className += ` ${schema.className}`
      }

      return className
  }

  _getStyle(schema) {
    let style = schema.style?schema.style:{}

    if(schema.border){
      style.borderLeftColor = schema.border
    }

    return style
  }

  _onClickDisableCheck(e) {
    this.setState({ disabled : !e.target.checked})
  }

  getValue() {
    let format = this.props.schema.dateFormat?this.props.schema.dateFormat:"YYYY-MM-DD"

    return this.state.value ? moment(this.state.value).format(format) : '';
  }

}


export default Calendar;
