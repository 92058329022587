import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../../utils/makeActionCreator';
import * as API from '../../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../../auth/list';


export const SETTINGS_PERMISSIONS_PENDING = 'SETTINGS_PERMISSIONS_PENDING';
export const settingsPermissionsPending = makeActionCreator(SETTINGS_PERMISSIONS_PENDING);

export const SETTINGS_PERMISSIONS_REJECTED = 'SETTINGS_PERMISSIONS_REJECTED';
export const settingsPermissionsRejected = makeActionCreator(SETTINGS_PERMISSIONS_REJECTED);

export const SETTINGS_PERMISSIONS_SHOW_LIST = 'SETTINGS_PERMISSIONS_SHOW_LIST';
export const settingsPermissionsShowList = makeActionCreator(SETTINGS_PERMISSIONS_SHOW_LIST);

export const SETTINGS_PERMISSIONS_HIDE_LIST = 'SETTINGS_PERMISSIONS_HIDE_LIST';
export const settingsPermissionsHideList = makeActionCreator(SETTINGS_PERMISSIONS_HIDE_LIST);


// Show Index

export const SETTINGS_PERMISSIONS_LIST_FULFILLED = 'SETTINGS_PERMISSIONS_LIST_FULFILLED';
export const settingsPermissionsListFulfilled = makeActionCreator(SETTINGS_PERMISSIONS_LIST_FULFILLED, 'data');

export const SETTINGS_PERMISSIONS_LIST_ERROR = 'SETTINGS_PERMISSIONS_LIST_ERROR';
export const settingsPermissionsListError = makeActionCreator(SETTINGS_PERMISSIONS_LIST_ERROR, 'message');

export function settingsPermissionsList(roleId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(settingsPermissionsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getPermissions.version}${endpoints.getPermissions.path.replace(':user_role_id', roleId)}`, {
        method: endpoints.getPermissions.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then(json => dispatch(settingsPermissionsListFulfilled(json.data)))
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(settingsPermissionsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Edit Permissions
export const SETTINGS_PERMISSIONS_ITEM_UPDATE_FULFILLED = 'SETTINGS_PERMISSIONS_ITEM_UPDATE_FULFILLED';
export const settingsPermissionsItemUpdateFulfilled = makeActionCreator(SETTINGS_PERMISSIONS_ITEM_UPDATE_FULFILLED, 'data');

export const SETTINGS_PERMISSIONS_ITEM_UPDATE_ERROR = 'SETTINGS_PERMISSIONS_ITEM_UPDATE_ERROR';
export const settingsPermissionsItemUpdateError = makeActionCreator(SETTINGS_PERMISSIONS_ITEM_UPDATE_ERROR, 'message');

export function settingsPermissionsUpdate(roleId, data) {
  const formData = new FormData();

  formData.append('_method', 'PUT');
  formData.append('permissions', data);

  Object.keys(data).forEach((section) => {
    formData.append(`permissions[${section}][can_create]`, data[section].can_create);
    formData.append(`permissions[${section}][can_delete]`, data[section].can_delete);
    formData.append(`permissions[${section}][can_read]`, data[section].can_read);
    formData.append(`permissions[${section}][can_update]`, data[section].can_update);
    formData.append(`permissions[${section}][limit_to_own_user]`, data[section].limit_to_own_user);
  });


  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(settingsPermissionsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updatePermissions.version}${endpoints.updatePermissions.path.replace(':user_role_id', roleId)}`, {
        method: endpoints.updatePermissions.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(settingsPermissionsItemUpdateFulfilled(json.data));
          } else {
            dispatch(settingsPermissionsItemUpdateError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(settingsPermissionsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
