import React, { Component } from 'react';
import moment from 'moment';
import cookie from 'react-cookies';
import { Col, Row, Button } from 'react-bootstrap';
import _ from 'lodash';

import ProfitSharingList from './list.jsx';
import SystemMsg from '../common/system-msg.jsx';
import "assets/css/profit-sharing.css";
import SmartForm from '../form/base.jsx';

class ProfitSharing extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'profit_sharing') {
          permissions = area;
        }
      });
    }
    this.state = {
      status: '2',
      year: moment().startOf('month'),
      permissions,
    };
  }

  componentWillMount() {
    const filtersCookie = cookie.load('profitSharing_filters') || {}

    this.setState(
      {
        status: filtersCookie && filtersCookie.status?filtersCookie.status:this.state.status,
        year: filtersCookie && filtersCookie.year?moment(filtersCookie.year):this.state.year,
      }
    )
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'profit_sharing') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  componentWillUnmount() {
    this.props.profitSharingShowList()
  }

  componentDidMount() {
    this.props.profitSharingList(this.state.status, this.state.year.format("YYYY"))
  }

  getTitle() {
    if (this.props.profitSharing.showList) {
      return (
        <Row>
          <Col xs={4} className="title-section">
            <h1 className="box-title">Profit Sharing</h1>
            {
              //<Button bsStyle="link">No Grouping <span className="fa fa-caret-down"></span></Button>
              //<Button bsSize="small" bsStyle="success" onClick={this.props.realizationShowForm.bind(this, "newItem")}>Add New</Button>
            }
          </Col>
          <Col xs={8} className="action-section">
            <SmartForm
              schema={[{
                type: "default",
                fields: [
                  {
                    type: "select",
                    name: "active",
                    label: "Status:",
                    value: this.state.status,
                    options: [
                      {
                        name: "Both",
                        value: "2"
                      },
                      {
                        name: "Active",
                        value: "1"
                      },
                      {
                        name: "Inactive",
                        value: "0"
                      }
                    ],
                    onChange: this.changeStatus.bind(this)
                  },
                  {
                    type: "calendar",
                    name: "year",
                    label: "Year:",
                    value: this.state.year,
                    dateFormat: "YYYY",
                    viewMode: "years",
                    maxDate: moment().endOf("year"),
                    onChange: this.changeYear.bind(this)
                  }
                ]
              }]}
              inline={true}
              buttons={[]}
            />
          </Col>
        </Row>
      )
    }

    if (this.props.profitSharing.showForm) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.profitSharingShowList.bind(this)}><span className="fa fa-arrow-circle-o-left"></span> Back</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">{this.props.profitSharing.itemId?"Edit ProfitSharing":"Add New ProfitSharing"}</h1>
            </Col>
          </Row>
        </div>
      )
    }

    return null;
  }

  changeYear(value) {
    let year = value.startOf("year")
    let filtersCookie = cookie.load('profitSharing_filters') || {}

    filtersCookie = {
      status: filtersCookie && filtersCookie.status?filtersCookie.status:this.state.status,
      year: value,
    }

    this.setState({year: year})
    cookie.save('profitSharing_filters', JSON.stringify(filtersCookie), { path: '/' });
    this.props.profitSharingList(this.state.status, year.format("YYYY"))
  }

  changeStatus(value) {
    let filtersCookie = cookie.load('profitSharing_filters') || {}

    filtersCookie = {
      status: value,
      year: filtersCookie && filtersCookie.year?filtersCookie.year:this.state.year,
    }

    this.setState({status: value})
    cookie.save('profitSharing_filters', JSON.stringify(filtersCookie), { path: '/' });
    this.props.profitSharingList(value, moment(this.state.year).format("YYYY"))
  }

  render() {
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }
    return (
      <section className={`profit-sharing-content ${this.props.profitSharing.showList ? 'minWidth' : ''}`}>
        { this.props.profitSharing.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this.getTitle()}
          </div>
          <div className="box-content">
            {this.props.profitSharing.showList?<ProfitSharingList {...this.props} />:""}
          </div>
        </div>
      </section>
    )
  }
}

export default ProfitSharing
