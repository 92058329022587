import React, { Component } from 'react';
import cookie from 'react-cookies';
import { Col, Row, Button } from 'react-bootstrap';
import _ from 'lodash';
import RecruitingList from './list.jsx';
import RecruitingForm from './form.jsx';
import SystemMsg from '../common/system-msg.jsx';
import 'assets/css/recruiting.css';
import SmartForm from '../form/base.jsx';

class Recruiting extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'recruiting') {
          permissions = area;
        }
      });
    }

    this.state = {
      status: '1',
      permissions,
    };
  }

  componentWillMount() {
    const filtersCookie = cookie.load('recruiting_filters') || {};

    this.setState(
      {
        status: filtersCookie && filtersCookie.status ? filtersCookie.status : this.state.status,
      },
    );
  }

  componentDidMount() {
    this.props.recruitsList(this.state.status);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.recruits.showList &&
      this.props.recruits.showList !== nextProps.recruits.showList
    ) {
      this.props.recruitsList(this.state.status);
    }

    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'recruiting') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  componentWillUnmount() {
    this.props.recruitsHideForm();
    this.props.recruitsShowList(this.state.status);
  }

  getTitle() {
    if (this.props.recruits.showList) {
      return (
        <Row>
          <Col xs={4} className="title-section">
            <h1 className="box-title">
              Recruiting
            </h1>
          </Col>
          <Col xs={8} className="action-section">
            <SmartForm
              schema={[
                {
                  type: 'default',
                  fields: [
                    {
                      type: 'select',
                      name: 'active',
                      label: 'Status:',
                      value: this.state.status,
                      options: [
                        {
                          name: 'All',
                          value: '9'
                        },
                        {
                          name: 'Active',
                          value: '1'
                        },
                        {
                          name: 'Hired',
                          value: '2'
                        },
                        {
                          name: 'Rejected',
                          value: '0'
                        }
                      ],
                      onChange: this._changeStatus.bind(this)
                    },
                  ]
                }
              ]
              }
              inline
              buttons={[]}
            />
          </Col>
        </Row>


      )
    } else if (this.props.recruits.showForm) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.recruitsShowList.bind(this)}><span className="fa fa-arrow-circle-o-left"></span> Back</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">{this.props.recruits.itemId?"Edit Recruit":"Add New Recruit"}</h1>
            </Col>
          </Row>
        </div>
      )
    }

    return null;
  }

  _changeStatus(value) {
    this.setState({status: value})
    this.props.recruitsList(value)

    cookie.save('recruiting_filters', JSON.stringify({status: value}), { path: '/' });
  }

  render() {
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className={`recruiting-content ${this.props.recruits.showList ? 'minWidth' : ''}`}>
        <div className="box">
          { this.props.recruits.inProgress || this.props.settingsLevels.inProgress || this.props.settingsRecruitingSources.inProgress || this.props.settingsRecruitingStages.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
          <div className="box-header with-border">
              {this.getTitle()}
          </div>
          <div className="box-content">
            {this.props.recruits.showList?<RecruitingList permissions={this.state.permissions} {...this.props} statusFilter={this.state.status} />:""}
            {this.props.recruits.showForm?<RecruitingForm permissions={this.state.permissions} {...this.props} />:""}
          </div>
        </div>
      </section>
    )
  }
}

export default Recruiting
