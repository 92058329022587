const initialState = {
    inProgress: true,
    error: false,
    errorText: null,
    lastRunData: [],
  };
  
  function BackgroundProcessReducer(state = initialState, action) {
    const actionName = 'BACKGROUND_PROCESS';
    switch (action.type) {
      case `${actionName}_PENDING`:
        return {
          ...state,
          inProgress: true,
          error: false,
          errorText: null,
        };
      case `${actionName}_ERROR`:
        return {
          ...state,
          inProgress: false,
          error: action.error,
          errorText: action.message,
        };
      case `${actionName}_LAST_RUN_FULFILLED`:
        return {
          ...state,
          inProgress: false,
          error: false,
          lastRunData: action.data,
        };
      default:
        return state;
    }
  }
  
  export default BackgroundProcessReducer;
  