import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import { Button, Modal } from 'react-bootstrap';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      itemId: null,
    };
  }

  moneyFormatter(cell, row) {
    return "$" + Number(cell).toLocaleString('en-US', {minimumFractionDigits: 2})
  }

  numberFormatter(cell, row) {
    return Number(cell).toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0})
  }

  closeDeleteModal() {
    this.setState({ showModal: false, itemId: null });
  }

  openDeleteModal(itemId) {
    this.setState({ showModal: true, itemId });
  }

  confirmDelete(itemId) {
    this.props.stockOptionsDeleteItem(itemId, this.props.statusFilter);
    this.closeDeleteModal();
  }

  renderModal() {
    if (!this.props.permissions.can_delete) {
      return null;
    }

    let name = '';
    if (this.state.itemId && this.props.stockOptions.data.length > 0) {
      for (let i = 0; i < this.props.stockOptions.data.length; i += 1) {
        if (this.props.stockOptions.data[i].id === this.state.itemId) {
          name = this.props.stockOptions.data[i].name;
        }
      }
    }

    return (
      <Modal show={this.state.showModal} onHide={this.closeDeleteModal.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm Delete {name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete this element?
          </p>
          <p>
            All the infomation related to this Stock Option will be deleted.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.confirmDelete.bind(this, this.state.itemId)} bsStyle="danger">
            Delete
          </Button>
          <Button onClick={this.closeDeleteModal.bind(this)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }

  renderButtons(cell, row) {
    return (
      <div className="actions">
        {this.props.permissions.can_create ? (
          <Button bsStyle="success" onClick={this.props.stockOptionsShowForm.bind(this,row.id)}>
            <i className="fa fa-pencil" aria-hidden="true" />
          </Button>
        ) : null }
        {this.props.permissions.can_delete ? (
          <Button bsStyle="danger" onClick={this.openDeleteModal.bind(this,row.id)}>
              <i className="fa fa-trash" aria-hidden="true" />
          </Button>
        ) : null}
      </div>
    )
  }

  render() {
    if (this.props.data && this.props.data.entries) {
      return (
        <div>
          <BootstrapTable data={ this.props.data.entries }>
            <TableHeaderColumn dataField="grant_date" isKey={ true }>Grant Date</TableHeaderColumn>
            <TableHeaderColumn dataField="grant_amount" dataAlign="right" headerAlign="left" dataFormat={this.numberFormatter.bind(this)}>Grant Amount</TableHeaderColumn>
            <TableHeaderColumn dataField="exercise_price" dataFormat={this.moneyFormatter} dataAlign="right" headerAlign="left">Exercise Price</TableHeaderColumn>
            <TableHeaderColumn dataField="current_price" dataFormat={this.moneyFormatter} dataAlign="right" headerAlign="left">Current Price</TableHeaderColumn>
            <TableHeaderColumn dataField="value" dataFormat={this.moneyFormatter} dataAlign="right" headerAlign="left">Value</TableHeaderColumn>
            <TableHeaderColumn dataField="vested" dataAlign="right" headerAlign="left" dataFormat={this.numberFormatter.bind(this)}>Vested Options</TableHeaderColumn>
            <TableHeaderColumn dataField="vested_value" dataAlign="right" headerAlign="left" dataFormat={this.moneyFormatter.bind(this)}>Vested Value</TableHeaderColumn>
            <TableHeaderColumn dataField="buttons" dataFormat={this.renderButtons.bind(this)} dataAlign="center" headerAlign="left" hidden={!this.props.permissions.can_update && !this.props.permissions.can_delete} />
          </BootstrapTable>

          {this.renderModal()}
        </div>
      );
    } else {
      return (<p>No Data</p>);
    }
  }
}

export default Details
