//
// Projects:
//

export const index = {
  path   : '/projects/',
  method : 'GET',
  version: '/v1'
}

export const getItem = {
  path   : '/projects/:projects_id/',
  method : 'GET',
  version: '/v1'
}
