import React, { Component } from 'react';
import "assets/css/table.css";
import { Button, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import cookie from 'react-cookies';
import moment from 'moment';

class SettingsAnnouncementsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      itemId: null,
      sortName: '',
      sortOrder: '',
    };
    this.renderButtons = this.renderButtons.bind(this);
  }

  componentWillMount() {
    this.props.settingsAnnouncementsList();

    this.setState(
      {
        sortName: cookie.load('sort_column_settings_announcements') || 'content',
        sortOrder: cookie.load('sort_order_settings_announcements') || 'asc',
      },
    );
  }

  onSortChange(sortName, sortOrder) {
    cookie.save('sort_column_settings_announcements', sortName, { path: '/' });
    cookie.save('sort_order_settings_announcements', sortOrder, { path: '/' });

    this.setState({
      sort_name: sortName,
      sort_order: sortOrder
    });
  }




  closeDeleteModal() {
    this.setState({ showModal: false, itemId: null });
  }

  openDeleteModal(itemId) {
    this.setState({ showModal: true, itemId });
  }

  confirmDelete(itemId) {
    this.props.settingsAnnouncementsDeleteItem(itemId);
    this.closeDeleteModal();
  }

  dateFormatter(cell) {
    return moment(cell).format('YYYY-MM-DD');
  }

  boolFormatter(cell) {
    return cell ? 'Yes' : 'No';
  }

  renderButtons(cell, row) {
    return (
      <div className="actions">
        <Button bsStyle="success" onClick={this.props.settingsAnnouncementsShowForm.bind(this,row.id)}><i className="fa fa-pencil" aria-hidden="true"></i></Button>
        <Button bsStyle="danger" onClick={this.openDeleteModal.bind(this,row.id)}><i className="fa fa-trash" aria-hidden="true"></i></Button>
      </div>
    );
  }

  renderModal() {
    let description = '';
    if (this.state.itemId && this.props.settingsAnnouncements.data.length > 0) {
      for (let i = 0; i < this.props.settingsAnnouncements.data.length; i++) {
        if (this.props.settingsAnnouncements.data[i].id === this.state.itemId) {
            description = this.props.settingsAnnouncements.data[i].description;
        }
      }
    }

    return (
      <Modal show={this.state.showModal} onHide={this.closeDeleteModal.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm Delete {description}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this element?</p>
          <p>All the infomation related to this Announcement will be deleted.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.confirmDelete.bind(this, this.state.itemId)} bsStyle="danger">Delete</Button>
          <Button onClick={this.closeDeleteModal.bind(this)}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    const list = this.props.settingsAnnouncements.data;

    const options = {
      defaultSortName: this.state.sortName,
      defaultSortOrder: this.state.sortOrder,
      onSortChange: this.onSortChange.bind(this),
    };

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={list}
          striped
          hover
          options={options}
          ref={(table) => { this.table = table; }}
          headerContainerClass="fixed-header"
        >
          <TableHeaderColumn
            dataField="content"
            isKey
            filter={{ type: 'TextFilter', delay: 500 }}
            dataSort={false}
          >
            Announcement
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="publication_date"
            filter={{ type: 'TextFilter', delay: 500 }}
            dataSort
            dataFormat={this.dateFormatter}
          >
            Publication Date
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="is_public"
            dataSort
            dataFormat={this.boolFormatter}
          >
            Public
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="is_highlighted"
            dataSort
            dataFormat={this.boolFormatter}
          >
            Highlighted
          </TableHeaderColumn>
          <TableHeaderColumn dataField="buttons" dataFormat={this.renderButtons} dataAlign="center" />
        </BootstrapTable>
        {this.renderModal()}
      </section>
    );
  }
}

export default SettingsAnnouncementsList;
