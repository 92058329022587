const initialState = {
  inProgress      : false,
  error           : false,
  errorText       : null,
  data            : [],
  mentorsCatalog     : [],
};

function MentorshipReducer(state = initialState, action) {
  const actionName = 'MENTORSHIP';
  switch (action.type) {
    case `${actionName}_PENDING`:
      return {
        ...state,
        inProgress: true,
        error: false,
        errorText: null,
      };
    case `${actionName}_FULFILLED`:
      return {
        ...state,
        inProgress: false,
        error: false,
        data: action.data,
      };
    case `${actionName}_REJECTED`:
      return {
        ...state,
        inProgress: false,
        error: action.error,
      };
    case `${actionName}_ERROR`:
      return {
        ...state,
        inProgress: false,
        error: true,
        errorText: action.message,
      };
    case `${actionName}_MENTORS_CATALOG_FULFILLED`:
      return {
        ...state,
        inProgress: false,
        error: false,
        mentorsCatalog: action.data,
      };
    case `${actionName}_MENTORS_CATALOG_ERROR`:
      return {
        ...state,
        inProgress: false,
        error: true,
        errorText: action.message,
      };
    default:
      return state;
  }
}

export default MentorshipReducer;