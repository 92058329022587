//
// Login:
//

export const index = {
  path: '/users/',
  method: 'GET',
  version: '/v1',
};

export const getItem = {
  path: '/users/:users_id/',
  method: 'GET',
  version: '/v1',
};

export const addItem = {
  path: '/users/',
  method: 'POST',
  version: '/v1',
};

export const updateItem = {
  path: '/users/:users_id/',
  method: 'POST',
  version: '/v1',
};

export const deleteItem = {
  path: '/users/:users_id/',
  method: 'DELETE',
  version: '/v1',
};

export const editPassword = {
  path: '/users/update_password/:users_id/',
  method: 'POST',
  version: '/v1',
};

export const editUserStaffingDate = {
  path: '/users/update_staffing_end_date/:user_id/',
  method: 'POST',
  version: '/v1',
};

export const editUserStaffingAvailabilityNote = {
  path: '/users/update_staffing_availability_note/:user_id/',
  method: 'POST',
  version: '/v1',
};

export const editUserMentor = {
  path: '/users/update_mentor_user_id/:user_id/',
  method: 'POST',
  version: '/v1',
};
