import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import moment from 'moment';
import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';

export const AUTH_PENDING = 'AUTH_PENDING';
export const sendAuthPending = makeActionCreator(AUTH_PENDING, 'data');

export const AUTH_REJECTED = 'AUTH_REJECTED';
export const sendAuthRejected = makeActionCreator(AUTH_REJECTED, 'message');

// login
export const AUTH_LOGIN_FULFILLED = 'AUTH_LOGIN_FULFILLED';
export const sendLoginfulfilled = makeActionCreator(AUTH_LOGIN_FULFILLED, 'data');

export const AUTH_LOGIN_ERROR = 'AUTH_LOGIN_ERROR';
export const sendLoginError = makeActionCreator(AUTH_LOGIN_ERROR, 'message');

export function login(data) {
  const configAuth = {
    scope: '*',
    grant_type: 'password',
  };

  const formData = new FormData();
  formData.append('client_id', API.config.client_id);
  formData.append('client_secret', API.config.client_secret);
  formData.append('scope', configAuth.scope);
  formData.append('grant_type', configAuth.grant_type);
  formData.append('username', data.email);
  formData.append('password', data.password);

  return (dispatch) => {
    dispatch(sendAuthPending());

    return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.login.version}${endpoints.login.path}`, {
      method: endpoints.login.method,
      body: formData,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(API.checkStatus)
      .then(API.parseJSON)
      .then((json) => {
        if (json.status === 'ok') {
          dispatch(sendLoginfulfilled(json.user));
          sessionService.saveSession({
            token: json.access_token,
            expires_in: json.expires_in,
            expires_at: moment().unix() + parseInt(json.expires_in, 10) - 20,
          })
            .then(() => {
              sessionService.saveUser(json.user);
            });
        } else {
          dispatch(sendLoginError(json.message));
        }
      })
      .catch((err) => {
        // Dispatch the error action with error information
        dispatch(sendAuthRejected(err));
      });
  };
}

//
// Logout:
//
export const AUTH_LOGOUT_PENDING = 'AUTH_LOGOUT_PENDING';
export const AUTH_LOGOUT_FULFILLED = 'AUTH_LOGOUT_FULFILLED';

export const logoutPending = makeActionCreator(AUTH_LOGOUT_PENDING);
export const logoutFulFilled = makeActionCreator(AUTH_LOGOUT_FULFILLED);

export function logout(callback = null) {
  return (dispatch) => {
    sessionService.deleteSession()
      .then(() => {
        sessionService.deleteUser();
        dispatch(logoutFulFilled());
        if (callback) {
          callback();
        }
      });
  };
}

// Update Password

export const AUTH_UPDATE_PASSWORD_FULFILLED = 'AUTH_UPDATE_PASSWORD_FULFILLED';
export const updatePasswordfulfilled = makeActionCreator(AUTH_UPDATE_PASSWORD_FULFILLED, 'data');

export const AUTH_UPDATE_PASSWORD_ERROR = 'AUTH_UPDATE_PASSWORD_ERROR';
export const updatePasswordError = makeActionCreator(AUTH_UPDATE_PASSWORD_ERROR, 'message');

export function updatePassword(userId, data) {
  const formData = new FormData();
  formData.append('password', data.password);
  formData.append('password_confirmation', data.passwordConfirmation);
  formData.append('_method', 'PUT');

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(sendAuthPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updatePassword.version}${endpoints.updatePassword.path.replace(':user_id', userId)}`, {
        method: endpoints.updatePassword.method,
        body: formData,
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${currentSession.token}`,
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(updatePasswordfulfilled(json.data));
          } else {
            dispatch(updatePasswordError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(sendAuthRejected(err));
        });
    });
  };
}

// Reset Password
export const RECOVER_PASSWORD_CHANGE_FULFILLED = 'RECOVER_PASSWORD_CHANGE_FULFILLED';
export const updatePasswordResetFulfilled = makeActionCreator(RECOVER_PASSWORD_CHANGE_FULFILLED, 'message');

export const SEND_RECOVER_PASSWORD_ERROR = 'SEND_RECOVER_PASSWORD_ERROR';
export const updatePasswordResetError = makeActionCreator(SEND_RECOVER_PASSWORD_ERROR, 'message');

export const REQUEST_RECOVER_PASSWORD_FULFILLED = 'REQUEST_RECOVER_PASSWORD_FULFILLED';
export const passwordResetRequestFulfilled = makeActionCreator(REQUEST_RECOVER_PASSWORD_FULFILLED, 'data');

export const AUTH_PASSWORD_RESET_ERROR = 'AUTH_PASSWORD_RESET_ERROR';
export const passwordResetRequestError = makeActionCreator(AUTH_PASSWORD_RESET_ERROR, 'message');

export function resetPasswordRequest(email) {
  const formData = new FormData();
  formData.append('email', email);

  return (dispatch) => {
    dispatch(sendAuthPending());

    return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.resetPasswordRequest.version}${endpoints.resetPasswordRequest.path}`, {
      method: endpoints.resetPasswordRequest.method,
      body: formData,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(API.checkStatus)
      .then(API.parseJSON)
      .then((json) => {
        if (json.status === 'ok') {
          dispatch(passwordResetRequestFulfilled(json.data));
        } else {
          dispatch(passwordResetRequestError(json.message));
        }
      })
      .catch((err) => {
        // Dispatch the error action with error information
        dispatch(sendAuthRejected(err));
      });
  };
}

export function resetPassword(data) {
  const formData = new FormData();
  formData.append('email', data.email);
  formData.append('password', data.password);
  formData.append('password_confirmation', data.password_confirmation);
  formData.append('token', data.token);

  return (dispatch) => {
    dispatch(sendAuthPending());

    return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.resetPassword.version}${endpoints.resetPassword.path}`, {
      method: endpoints.resetPassword.method,
      body: formData,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(API.parseJSON)
      .then((json) => {
        if (json.status === 'ok') {
          dispatch(updatePasswordResetFulfilled(json.message));
        } else {
          dispatch(updatePasswordResetError(json.message));
        }
      })
      .catch((err) => {
        // Dispatch the error action with error information
        dispatch(sendAuthRejected(err));
      });
  };
}
