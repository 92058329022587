import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import _ from 'lodash';
import { browserHistory } from 'react-router';

import whiteLogo from 'assets/img/headstorm_bolt_white.svg';
import iconStatements from 'assets/img/statements.svg';
import iconProfitSharing from 'assets/img/profit_sharing.svg';
import iconStockOptions from 'assets/img/stock_options.svg';
import iconClientProjects from 'assets/img/client_projects.svg';
import iconRepository from 'assets/img/repository.svg';
import iconInternalProjects from 'assets/img/internal_projects.svg';
import iconPeople from 'assets/img/ic_people.svg';
import iconSchedule from 'assets/img/ic_schedule.svg';
import iconAtlas from 'assets/img/headstorm-atlas.svg';
import 'assets/css/home.css';
import RightSidebar from '../common/right-sidebar.jsx';

export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissionsPMO = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'pmo') {
          permissionsPMO = area;
        }
      });
    }
    this.state = {
      permissionsPMO,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissionsPMO = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'pmo') {
            permissionsPMO = area;
          }
        });
      }

      this.setState({ permissionsPMO });
    }
  }

  render() {
    const {
      permissionsPMO,
    } = this.state;

    return (
      <div className="dashboard-container">
        <section className="content home-view col-sm-9 sidebar-view">
          <Row className="show-grid">
            <Col sm={12}>
              <Row className="show-grid links-row">
                <Col sm={9} className="col">
                  <div className="box white-box">
                    <div className="box-header border-orange">
                      <h3 className="box-title">
                        My Headstorm
                      </h3>
                    </div>
                    <div className="circular-links-container row">
                      <Col xs={6} sm={3} className="circular_link">
                        <a href="https://www.paycheckrecords.com/login.jsp" target="_blank" rel="noopener noreferrer">
                          <img src={iconStatements} alt="Statements" />
                          <span>
                            Statements
                          </span>
                        </a>
                      </Col>
                      <Col xs={6} sm={3} className="circular_link">
                        <a href="/profit-sharing">
                          <img src={iconProfitSharing} alt="Profit Sharing" />
                          <span>
                            Profit Sharing
                          </span>
                        </a>
                      </Col>
                      <Col xs={6} sm={3} className="circular_link">
                        <a href="/stock-options">
                          <img src={iconStockOptions} alt="Stock Options" />
                          <span>
                            Stock Options
                          </span>
                        </a>
                      </Col>
                      <Col xs={6} sm={3} className="circular_link">
                        <a href="https://headstorm.atlassian.net/wiki/spaces/HR/pages/870486/Introduction" rel="noopener noreferrer" target="_blank">
                          <img src={iconAtlas} alt="Insurance" />
                          <span>
                            Employee Handbook
                          </span>
                        </a>
                      </Col>
                    </div>
                  </div>
                </Col>
                <Col sm={3} className="col">
                  <div className="box box-solid color-box black-box flex-column">
                    <a href="/consulting-hub">
                      <img
                        src={whiteLogo}
                        className="white-logo"
                        alt="Headstorm Hub"
                      />
                      <span>
                        Headstorm Hub
                      </span>
                    </a>
                  </div>
                </Col>
              </Row>
              <Row className="show-grid links-row">
                <Col sm={3} className="col">
                  <div className="box box-solid color-box orange-box flex-column">
                    <a
                      href="https://headstorm.harvestapp.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={iconSchedule}
                        className="white-logo"
                        alt="Headstorm Hub"
                      />
                      <span>
                        Time / Expenses
                      </span>
                    </a>
                  </div>
                </Col>
                <Col sm={3} className="col">
                  <div className="box box-solid color-box lightblue-box flex-column">
                    <a href="/staffing">
                      <img
                        src={iconPeople}
                        className="white-logo"
                        alt="Headstorm Hub"
                      />
                      <span className="title-staff">
                        Staffing
                      </span>
                    </a>
                  </div>
                </Col>
                <Col sm={6} className="col">
                  <div className="box white-box">
                    <div className="box-header border-orange">
                      <h3 className="box-title">
                        Project Management
                      </h3>
                    </div>
                    <div className="circular-links-container row">
                      <Col xs={6} sm={4} className="circular_link">
                        <a
                          href="https://headstorm.atlassian.net/projects"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={iconClientProjects} alt="Client Projects" />
                          <span>
                            Client Projects
                          </span>
                        </a>
                      </Col>
                      <Col xs={6} sm={4} className="circular_link">
                        <a
                          href="https://headstorm.atlassian.net/wiki/spaces/KB/pages/870678539/Project+Overviews"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={iconRepository} alt="Project Repository" />
                          <span>
                            Project Repository
                          </span>
                        </a>
                      </Col>
                      <Col xs={6} sm={4} className="circular_link">
                        <a href="/internal-projects">
                          <img
                            src={iconInternalProjects}
                            alt="Internal Projects"
                          />
                          <span>
                            Internal Projects
                          </span>
                        </a>
                      </Col>
                    </div>
                  </div>
                </Col>
              </Row>
              { (
                permissionsPMO
                && (
                  permissionsPMO.can_read
                  || permissionsPMO.can_create
                  || permissionsPMO.can_delete
                  || permissionsPMO.can_update
                  || permissionsPMO.limit_to_own_user
                )
              ) ? (
                <Row className="show-grid links-row">
                  <Col sm={6} className="col">
                    <div className="box box-solid color-box purple-box flex-column pmo">
                      <button onClick={() => { browserHistory.push('/pmo'); }} type="button">
                        <i
                          className="fa fa-briefcase"
                          aria-hidden="true"
                        />
                        <span>
                          PMO
                        </span>
                      </button>
                    </div>
                  </Col>
                </Row>
                ) : null }
            </Col>
          </Row>
        </section>
        <Col sm={3} className="sidebar-outer">
          <RightSidebar {...this.props} />
        </Col>
      </div>
    );
  }
}
