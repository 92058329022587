import React, {Component}    from 'react'

import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import 'assets/css/system-msg.css'

class SystemMsg extends Component {

    constructor(props) {
        super(props)
        this.state = ({ visible : true })
    }

    componentDidMount() {
        this._isMounted = true
        if (this.props.delay){
          this._setTimer()
        }
    }

    componentWillUnmount() {
        this._isMounted = false
    }

    componentWillReceiveProps(nextProps) {
        if (!this._isMounted) return

        if (nextProps.children !== this.props.children) {
            this._setTimer()
            this.setState({ visible : true })
        }

    }

    _setTimer() {
        if (!this._isMounted) return

        //
        // clear any existing timer
        //
        if(this._timer !== null){
          clearTimeout(this._timer)
        }

        //
        // hide after 'delay' milliseconds
        //
        this._timer = setTimeout( function () {
          if(this._isMounted){
            this.setState({ visible : false })
          }
          this._timer = null
        }.bind(this), 5000)

    }

    render() {

        return (
            <ReactCSSTransitionGroup
                transitionName="transition-system-msg"
                transitionAppear={ true }
                transitionAppearTimeout={ 500 }
                transitionLeaveTimeout={ 500 }
                transitionEnterTimeout={ 500 }>
                { this.state.visible ?
                    <div
                        className="system-msg">
                        <p>
                            { this.props.msg }
                            { this.props.hasSpinner ? <span className="system-msg-spinner"></span> : '' }
                        </p>
                    </div> : '' }
            </ReactCSSTransitionGroup>
        )
    }

}

export default SystemMsg
