import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';
import makeActionCreator from '../utils/makeActionCreator';

import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';

export const USER_PENDING = 'USER_PENDING';
export const userPending = makeActionCreator(USER_PENDING);

export const USER_REJECTED = 'USER_REJECTED';
export const userRejected = makeActionCreator(USER_REJECTED);

export const USER_SHOW_LIST = 'USER_SHOW_LIST';
export const userShowList = makeActionCreator(USER_SHOW_LIST);

export const USER_HIDE_LIST = 'USER_HIDE_LIST';
export const userHideList = makeActionCreator(USER_HIDE_LIST);

export const USER_SHOW_FORM = 'USER_SHOW_FORM';
export const userShowForm = makeActionCreator(USER_SHOW_FORM, 'itemId');

export const USER_HIDE_FORM = 'USER_HIDE_FORM';
export const userHideForm = makeActionCreator(USER_HIDE_FORM);

export const USER_SHOW_ITEM = 'USER_SHOW_ITEM';
export const userShowItem = makeActionCreator(USER_SHOW_ITEM, 'itemId');


// Show Index
export const USER_LIST_FULFILLED = 'USER_LIST_FULFILLED';
export const userListFulfilled = makeActionCreator(USER_LIST_FULFILLED, 'data');

export const USER_LIST_ERROR = 'USER_LIST_ERROR';
export const userListError = makeActionCreator(USER_LIST_ERROR, 'message');

export function userList(status = 2) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(userPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}?status=${status}`, {
        method: endpoints.index.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then(json => dispatch(userListFulfilled(json.data)))
        .catch((err) => {
          // console.log(err);
          // Dispatch the error action with error information
          dispatch(userRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Show Item
export const USER_ITEM_FULFILLED = 'USER_ITEM_FULFILLED';
export const userItemFulfilled = makeActionCreator(USER_ITEM_FULFILLED, 'data');

export const USER_ITEM_ERROR = 'USER_ITEM_ERROR';
export const userItemError = makeActionCreator(USER_ITEM_ERROR, 'message');

export function userItem(itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(userPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getItem.version}${endpoints.getItem.path.replace(':users_id', itemId)}`, {
        method: endpoints.getItem.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then(json => dispatch(userItemFulfilled(json.data)))
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(userRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Add Item
export const USER_ITEM_ADD_FULFILLED = 'USER_ITEM_ADD_FULFILLED';
export const userItemAddFulfilled = makeActionCreator(USER_ITEM_ADD_FULFILLED, 'data');

export const USER_ITEM_ADD_ERROR = 'USER_ITEM_ADD_ERROR';
export const userItemAddError = makeActionCreator(USER_ITEM_ADD_ERROR, 'message');

export function userAddItem(data) {
  const formData = new FormData();

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(userPending());
      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addItem.version}${endpoints.addItem.path}`, {
        method: endpoints.addItem.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(userItemAddFulfilled(json.data));
          } else {
            dispatch(userItemAddError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(userRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Comoon User Update Status handling
export const USER_ITEM_UPDATE_FULFILLED = 'USER_ITEM_UPDATE_FULFILLED';
export const userItemUpdateFulfilled = makeActionCreator(USER_ITEM_UPDATE_FULFILLED, 'data');

export const USER_ITEM_UPDATE_ERROR = 'USER_ITEM_UPDATE_ERROR';
export const userItemUpdateError = makeActionCreator(USER_ITEM_UPDATE_ERROR, 'message');

// Update Item
export function userUpdateItem(itemId, data) {
  const formData = new FormData();
  formData.append('_method', 'PUT');

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });


  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(userPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateItem.version}${endpoints.updateItem.path.replace(':users_id', itemId)}`, {
        method: endpoints.updateItem.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(userItemUpdateFulfilled(json.data));
          } else {
            dispatch(userItemUpdateError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(userRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Delete Item

export const USER_ITEM_DELETE_FULFILLED = 'USER_ITEM_DELETE_FULFILLED';
export const userItemDeleteFulfilled = makeActionCreator(USER_ITEM_DELETE_FULFILLED, 'data');

export const USER_ITEM_DELETE_ERROR = 'USER_ITEM_DELETE_ERROR';
export const userItemDeleteError = makeActionCreator(USER_ITEM_DELETE_ERROR, 'message');

export function userDeleteItem(itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(userPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteItem.version}${endpoints.deleteItem.path.replace(':users_id', itemId)}`, {
        method: endpoints.deleteItem.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(userItemDeleteFulfilled(json.data));
            dispatch(userList());
          } else {
            dispatch(userItemDeleteError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(userRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Comoon User Update Status handling
export const USER_ITEM_EDIT_FULFILLED = 'USER_ITEM_EDIT_FULFILLED';
export const userItemEditFulfilled = makeActionCreator(USER_ITEM_EDIT_FULFILLED, 'message');

export const USER_ITEM_EDIT_ERROR = 'USER_ITEM_EDIT_ERROR';
export const userItemEditError = makeActionCreator(USER_ITEM_EDIT_ERROR, 'message');

// Update User Password
export function editUser(userId, data) {
  const formData = new FormData();
  formData.append('_method', 'PUT');
  formData.append('password', data.password);
  formData.append('password_confirmation', data.password_confirm);

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(userPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.editPassword.version}${endpoints.editPassword.path.replace(':users_id', userId)}`, {
        method: endpoints.editPassword.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(userItemUpdateFulfilled());
          } else {
            // Endpoint Error with Message
            dispatch(userItemUpdateError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(userRejected(err));
        });
    })
      .catch(() => {
        sessionService.deleteSession();
        sessionService.deleteUser();
        browserHistory.push('/login');
      });
  };
}

// Update User Staffing End Date
export function editUserStaffingDate(userId, date) {
  const formData = new FormData();
  formData.append('_method', 'PUT');
  formData.append('staffing_end_date', date);

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(userPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.editUserStaffingDate.version}${endpoints.editUserStaffingDate.path.replace(':user_id', userId)}`, {
        method: endpoints.editUserStaffingDate.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(userList());
          } else {
            // Endpoint Error with Message
            dispatch(userItemUpdateError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch General Error
          dispatch(userRejected(err));
        });
    })
      .catch(() => {
        sessionService.deleteSession();
        sessionService.deleteUser();
        browserHistory.push('/login');
      });
  };
}

// Update User Staffing Availability Note
export function putUpdateUserStaffingAvailabilityNote(userId, note) {
  const formData = new FormData();
  formData.append('_method', 'PUT');
  formData.append('staffingAvailabilityNote', note);

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(userPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.editUserStaffingAvailabilityNote.version}${endpoints.editUserStaffingAvailabilityNote.path.replace(':user_id', userId)}`, {
        method: endpoints.editUserStaffingAvailabilityNote.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(userItemEditFulfilled());
          } else {
            // Endpoint Error with Message
            dispatch(userItemEditError(json.message));
          }
        })
        .catch((err) => {
          // General Error
          dispatch(userRejected(err));
        });
    }).catch(() => {
      sessionService.deleteSession();
      sessionService.deleteUser();
      browserHistory.push('/login');
    });
  };
}

// Update User Mentor Function
export function editUserMentor(userId, mentorUserId) {
  const formData = new FormData();
  formData.append('_method', 'PUT');
  formData.append('mentor_user_id', mentorUserId);

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(userPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.editUserMentor.version}${endpoints.editUserMentor.path.replace(':user_id', userId)}`, {
        method: endpoints.editUserMentor.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(userItemUpdateFulfilled());
          } else {
            dispatch(userItemUpdateError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(userRejected(err));
        });
    })
      .catch(() => {
        sessionService.deleteSession();
        sessionService.deleteUser();
        browserHistory.push('/login');
      });
  };
}