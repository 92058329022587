import React, {Component} from 'react';
import "assets/css/table.css";
import { Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import cookie from 'react-cookies'

class SettingsImportList extends Component{
  constructor(props){
  	super(props);
  	this.state = {
      showModal: false,
      itemId: null,
      sort_name: "",
      sort_order: ""
    };
  }
  componentWillMount() {
     this.props.settingsImportList()

     this.setState(
       {
         sort_name: cookie.load('sort_column_settings_import_process') || 'ratio',
         sort_order: cookie.load('sort_order_settings_import_process') || 'asc'
       }
     )
  }

  render(){
    const list = this.props.settingsImport.data

    const options = {
      defaultSortName: this.state.sort_name,
      defaultSortOrder: this.state.sort_order,
      onSortChange: this._onSortChange.bind(this),
    }

    return (
      <section className="section-list-content">
        <BootstrapTable
            data={list}
            striped={true}
            hover={true}
            options={options}
            ref={(table) => { this.table = table; }}
            headerContainerClass='fixed-header'>
            <TableHeaderColumn dataField="ratio" isKey={true}  filter={ { type: 'TextFilter', delay: 500 } } dataSort={true} dataFormat={this._percentajeFormatter}>Ratio</TableHeaderColumn>
            <TableHeaderColumn dataField="buttons" dataFormat={this._renderButtons.bind(this)} dataAlign='center'></TableHeaderColumn>
        </BootstrapTable>


        {this._renderModal()}
      </section>
    )
  }

  _onSortChange(sortName, sortOrder){

    cookie.save('sort_column_settings_import_process', sortName, { path: '/' });
    cookie.save('sort_order_settings_import_process', sortOrder, { path: '/' });

    this.setState({
      sort_name: sortName,
      sort_order: sortOrder
    });

  }


  _renderButtons(cell, row){
    return(
      <div className="actions">
        <Button bsStyle="success" onClick={this.props.settingsImportShowForm.bind(this,row.id)}><i className="fa fa-pencil" aria-hidden="true"></i></Button>
        <Button bsStyle="danger" onClick={this._openDeleteModal.bind(this,row.id)}><i className="fa fa-trash" aria-hidden="true"></i></Button>
      </div>
    )
  }

  _percentajeFormatter(cell, row){
    return Number(cell).toLocaleString('en-US') + "%"
  }



}



export default SettingsImportList
