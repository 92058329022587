import React, { Component } from 'react';
import HSPortalLogo from 'assets/img/HS_portal_logo.svg';
// import CheckBox from '../form/checkbox.jsx';
import {
  Row,
  Col,
  Button,
  Alert,
} from 'react-bootstrap';

import SystemMsg from '../common/system-msg.jsx';
import Input from '../form/input.jsx';

class RecoverPassword extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      email: '',
      checkEmailContent: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChangeField = this.onChangeField.bind(this);
    this.returnLogin = this.returnLogin.bind(this);
  }

  onChangeField(value) {
    this.setState({ email: value, checkEmailContent: true });
  }

  onSubmit(e) {
    e.preventDefault()
    const { email } = this.state;
    this.props.resetPasswordRequest(email);
    this.setState({ email: '', checkEmailContent: false });
  }

  returnLogin() {
    this.props.router.push('/login');
  }

  render() {
    const { email, checkEmailContent } = this.state;

    const match = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);

    return (
      <div className="hold-transition login-page">
        { this.props.auth.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="login-box">
          <div className="login-logo">
            <a href="/">
              <img alt="Headstorm MTM" src={HSPortalLogo}/>
            </a>
          </div>

          <div className="login-box-body">
            {this.props.auth.successMessage?(
              <Alert bsStyle="success">
                {this.props.auth.successMessage}
              </Alert>
            ) : ''}
            <p className="login-box-msg">
              Enter your email to recover your password
            </p>

            <form onSubmit={this.onSubmit} method="post">
              <Input
                schema={{
                  type: 'email',
                  placeholder: 'Email',
                  className: 'has-feedback',
                  value: email,
                  icon: {
                    name: 'envelope',
                    className: 'form-control-feedback',
                  },
                  onChange: this.onChangeField,
                  empty: !email,
                  validationState: checkEmailContent && !match ? 'error' : '',
                  errorText: checkEmailContent && !match ? 'Please enter a valid email' : '',
                }}
              />
              <Row>
                <Col xs={6} style={{ float: 'none' }} />
                <Button bsStyle="link" onClick={this.returnLogin}>
                  Return to Login
                </Button>
                <Col xs={6} style={{ float: 'right' }}>
                  <Button
                    className="btn-hs-orange"
                    type="submit"
                    bsClass="btn"
                    block
                    disabled={!email || !match}
                  >
                    Recover Password
                  </Button>
                </Col>
              </Row>
            </form>


          </div>
        </div>
      </div>
    );
  }
}


export default  RecoverPassword;
