import React, {Component} from 'react';
import moment from 'moment'
import "assets/css/table.css";
import { Button, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import cookie from 'react-cookies'

class InternalProjectsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      itemId: null,
      sort_name: '',
      sort_order: '',
    };
  }

  componentWillMount() {
    this.props.internalProjectsList();

    this.setState(
      {
        sort_name: cookie.load('sort_column_internal_projects') || 'project',
        sort_order: cookie.load('sort_order_internal_projects') || 'asc'
      }
    )
  }

  _onSortChange(sortName, sortOrder) {

    cookie.save('sort_column_internal_projects', sortName, { path: '/' });
    cookie.save('sort_order_internal_projects', sortOrder, { path: '/' });

    this.setState({
      sort_name: sortName,
      sort_order: sortOrder
    });

  }

  trClassFormat(row, rowIndex) {
    return rowIndex % 2 === 0 ? "tr-odd" : "tr-even";  // return class name.
  }

  moneyFormatter(cell, row){
    return "$" + Number(cell).toLocaleString('en-US')
  }

  dateFormatter(cell, row){
    return moment(cell).format("YYYY-MM-DD")
  }

  boolFormatter(cell, row){
    return cell?"Yes":"No"
  }

  percentajeFormatter(cell, row){
    return cell + "%"
  }

  objectFormatter(field, cell, row){
    return cell[field]
  }

  objectPercentajeFormatter(field, cell, row){
    return cell[field] + "%"
  }

  sortObjectFunc(a, b, order, sortField, extraData) {   // order is desc or asc
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return a[sortField][fieldName].localeCompare(b[sortField][fieldName])
    } else {
      return b[sortField][fieldName].localeCompare(a[sortField][fieldName]);
    }

  }

  sortNumberObjectFunc(a, b, order, sortField, extraData) {
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return parseFloat(a[sortField][fieldName]) - parseFloat(b[sortField][fieldName])
    } else {
      return parseFloat(b[sortField][fieldName]) - parseFloat(a[sortField][fieldName]);
    }
  }

  sortNumberFunc(a, b, order, sortField) {
    if (order === 'asc') {
      return parseFloat(a[sortField]) - parseFloat(b[sortField])
    } else {
      return parseFloat(b[sortField]) - parseFloat(a[sortField]);
    }
  }



  closeDeleteModal() {
    this.setState({ showModal: false, itemId: null });
  }

  openDeleteModal(itemId) {
    this.setState({ showModal: true, itemId:itemId });
  }

  confirmDelete(itemId) {
    this.props.internalProjectsDeleteItem(itemId)
    this.closeDeleteModal()
  }

  renderButtons(cell, row) {
    return (
      <div className="actions">
        {this.props.permissions.can_update ? <Button bsStyle="success" onClick={this.props.internalProjectsShowForm.bind(this,row.id)}><i className="fa fa-pencil" aria-hidden="true"></i></Button> : null}
        {this.props.permissions.can_delete ? <Button bsStyle="danger" onClick={this.openDeleteModal.bind(this,row.id)}><i className="fa fa-trash" aria-hidden="true"></i></Button> : null}
      </div>
    )
  }

  renderModal() {
    let name = '';
    if (this.state.itemId && this.props.internalProjects.data.length > 0) {
      for (let i=0; i<this.props.internalProjects.data.length;i++) {
        if(this.props.internalProjects.data[i].id === this.state.itemId){
            name = this.props.internalProjects.data[i].name
        }
      }
    }

    return (
      <Modal show={this.state.showModal} onHide={this.closeDeleteModal.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete {name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this element?</p>
            <p>All the infomation related to this Internal Project will be deleted.</p>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.confirmDelete.bind(this, this.state.itemId)} bsStyle="danger">Delete</Button>
            <Button onClick={this.closeDeleteModal.bind(this)}>Close</Button>
          </Modal.Footer>
        </Modal>
    )
  }

  render() {
    const list = this.props.internalProjects.data

    const options = {
      expandRowBgColor: 'rgb(242, 255, 163)',
      defaultSortName: this.state.sort_name,
      defaultSortOrder: this.state.sort_order,
      onSortChange: this._onSortChange.bind(this),
    }

    return (
      <section className="section-list-content">
        <BootstrapTable
          data={list}
          hover={true}
          options={ options }
          ref={(table) => { this.table = table; }}
          trClassName={this.trClassFormat}
          headerContainerClass="fixed-header"
        >
          <TableHeaderColumn
            dataField="project"
            columnTitle
            filter={ { type: 'TextFilter', delay: 500 } }
            isKey={true}
            dataSort={true}
          >
            Project Name
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="officer_in_charge"
            columnTitle
            filter={ { type: 'TextFilter', delay: 500 } }
            dataSort={true}
            dataFormat={this.objectFormatter.bind(this, "name")}
            filterFormatted sortFunc={this.sortObjectFunc}
            sortFuncExtraData={{objectField: "name"}}
          >
            OIC
          </TableHeaderColumn>
          <TableHeaderColumn dataField="note" columnTitle>Notes</TableHeaderColumn>
          <TableHeaderColumn
            dataField="buttons"
            dataFormat={this.renderButtons.bind(this)}
            dataAlign='center'
            headerAlign='left'
            className='col_buttons'
            columnClassName='col_buttons'
            width="125px"
            hidden={!this.props.permissions.can_update && !this.props.permissions.can_delete}
          />
        </BootstrapTable>


        {this.renderModal()}
      </section>
    )
  }
}


export default InternalProjectsList;
