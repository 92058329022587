import React, { Component } from 'react';
import moment from 'moment';
import { Button, Modal } from 'react-bootstrap';

import 'assets/css/table.css';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';

class ConsultantNetworkingSessionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      itemId: null,
      sortName: '',
      sortOrder: '',
    };

    this.renderButtons = this.renderButtons.bind(this);
  }

  onSortChange(sortName, sortOrder) {
    this.setState({
      sortName,
      sortOrder,
    });
  }

  dateFormatter(cell, row){
    return cell ? moment(cell).format("YYYY-MM-DD") : ''
  }

  trClassFormat(row, rowIndex) {
    return rowIndex % 2 === 0 ? "tr-odd" : "tr-even";  // return class name.
  }

  closeDeleteModal() {
    this.setState({
      showModal: false,
      itemId: null,
    });
  }

  openDeleteModal(itemId) {
    this.setState({ showModal: true, itemId });
  }

  confirmDelete(itemId) {
    this.props.consultantNetworkingSessionDeleteItem(this.props.userId, itemId);
    this.closeDeleteModal();
  }

  renderButtons(cell, row) {
    const { permissions, session, editEnabled } = this.props;

    return (
      <div className="actions">
        { /* If not limited to own user, sessions can be edited by other users */ }
        {(editEnabled) ? (
          <Button
            bsStyle="success"
            onClick={(e) => {
              e.stopPropagation();
              this.props.consultantNetworkingSessionShowForm(row.id);
            }}
          >
            <i className="fa fa-pencil" aria-hidden="true" />
          </Button>
        ) : null}

        { /* General Rule: only owners can delete their own sessions */ }
        {(permissions.can_delete && row.user_id === session.user.id) ? (
          <Button
            bsStyle="danger"
            onClick={(e) => {
              e.stopPropagation();
              this.openDeleteModal(row.id);
            }}
          >
            <i className="fa fa-trash" aria-hidden="true" />
          </Button>
        ) : null}
      </div>
    );
  }

  renderModal() {
    let name = '';
    if (this.state.itemId && this.props.pmoActionItems.data.length > 0) {
      for (let i=0; i<this.props.pmoActionItems.data.length;i++) {
        if(this.props.pmoActionItems.data[i].id === this.state.itemId){
          name = this.props.pmoActionItems.data[i].name
        }
      }
    }

    return (
      <Modal show={this.state.showModal} onHide={this.closeDeleteModal.bind(this)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete this element?
          </p>
          <p>
            All the infomation related to this Action Item will be deleted.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.confirmDelete.bind(this, this.state.itemId)} bsStyle="danger">Delete</Button>
          <Button onClick={this.closeDeleteModal.bind(this)}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }

  render() {
    const { consultantNetworkingSessions, permissions, editEnabled, deleteEnabled } = this.props;
    const list = consultantNetworkingSessions.data;

    const {
      sortName,
      sortOrder,
    } = this.state;

    const options = {
      expandRowBgColor: 'rgb(242, 255, 163)',
      defaultSortName: sortName,
      defaultSortOrder: sortOrder,
      onSortChange: this.onSortChange.bind(this),
    };

    return (
      <section className="section-list-content actionItems">
        <BootstrapTable
          data={list}
          hover
          options={options}
          ref={(table) => { this.table = table; }}
          trClassName={this.trClassFormat}
          headerContainerClass="fixed-header"
        >
          <TableHeaderColumn
            columnTitle
            dataField="met_with"
            width="125px"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter' }}
            dataSort
            isKey
          >
            Met With
          </TableHeaderColumn>
          <TableHeaderColumn
            columnTitle
            dataField="company"
            width="125px"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter' }}
            dataSort
          >
            Company
          </TableHeaderColumn>
          <TableHeaderColumn
            columnTitle
            dataField="position"
            width="125px"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter' }}
            dataSort
          >
            Position
          </TableHeaderColumn>
          <TableHeaderColumn
            columnTitle
            dataField="date"
            width="95px"
            tdStyle={{ whiteSpace: 'normal' }}
            dataSort
            filter={{ type: 'TextFilter' }}
            sortFunc={this.sortDateFunc}
            dataFormat={this.dateFormatter}
          >
            Date
          </TableHeaderColumn>
          <TableHeaderColumn
            columnTitle
            dataField="outcome"
            width="160px"
            tdStyle={{ whiteSpace: 'normal' }}
          >
            Outcome
          </TableHeaderColumn>
          <TableHeaderColumn
            columnTitle
            dataField="follow_up"
            width="160px"
            tdStyle={{ whiteSpace: 'normal' }}
          >
            Follow Up
          </TableHeaderColumn>
          <TableHeaderColumn
            columnTitle
            dataField="expenses"
            width="150px"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{type: 'NumberFilter'}}
          >
            Expenses
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="buttons"
            dataFormat={this.renderButtons}
            dataAlign="center"
            headerAlign="left"
            className="col_buttons"
            columnClassName="col_buttons"
            width="95px"
            hidden={!permissions || (!editEnabled && !deleteEnabled)}
          />
        </BootstrapTable>
        {this.renderModal()}
      </section>
    );
  }
}


export default ConsultantNetworkingSessionList;
