import React, { Component } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import moment from 'moment';
import cookie from 'react-cookies';
import _ from 'lodash';

import UtilizationList from './list.jsx';
import SystemMsg from '../common/system-msg.jsx';
import "assets/css/utilization.css";
import SmartForm from '../form/base.jsx';

class Utilization extends Component {
  constructor(props){
  	super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'utilization') {
          permissions = area;
        }
      });
    }

  	this.state = {
      status: "1",
      startDate: moment().startOf("month"),
      endDate: moment().startOf("month"),
      permissions,
    };
  }

  componentWillMount() {
    const filtersCookie = cookie.load('utilization_filters') || {}

    this.setState(
      {
        startDate: filtersCookie && filtersCookie.startDate?moment(filtersCookie.startDate):this.state.startDate,
        endDate: filtersCookie && filtersCookie.endDate?moment(filtersCookie.endDate):this.state.endDate,
      }
    )
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'utilization') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  componentWillUnmount() {
    this.props.utilizationShowList()
  }

  componentDidMount(){
    this.props.utilizationList(this.state.status, this.state.startDate.format("YYYY-MM-DD"), this.state.endDate.endOf("month").format("YYYY-MM-DD"))
  }

  render() {
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className={`utilization-content ${this.props.utilization.showList ? 'minWidth' : ''}`}>
        { this.props.utilization.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this._getTitle()}
          </div>
          <div className="box-content">
            {this.props.utilization.showList ? <UtilizationList {...this.props} /> : ""}

          </div>
        </div>
      </section>
    )
  }

  _getTitle(){
    if (this.props.utilization.showList) {
      return (
        <Row>
          <Col xs={4}  className="title-section">
            <h1 className="box-title">Utilization</h1>
          </Col>
          <Col xs={8} className="action-section">
            <SmartForm schema={[
                  {
                    type: "default",
                    fields: [
                      {
                        type: "select",
                        name: "active",
                        label: "Status:",
                        value: this.state.status,
                        options: [
                          {
                            name: "Both",
                            value: "2"
                          },
                          {
                            name: "Active",
                            value: "1"
                          },
                          {
                            name: "Inactive",
                            value: "0"
                          }
                        ],
                        onChange: this._changeStatus.bind(this)
                      },
                      {
                        type: "calendar",
                        name: "start_date",
                        label: "Start Date:",
                        value: this.state.startDate,
                        dateFormat: "YYYY-MM",
                        viewMode: "months",
                        maxDate: moment(this.state.endDate).endOf("month"),
                        onChange: this._changeStartDate.bind(this)
                      },
                      {
                        type: "calendar",
                        name: "end_date",
                        label: "End Date:",
                        value:  this.state.endDate,
                        dateFormat: "YYYY-MM",
                        viewMode: "months",
                        minDate: this.state.startDate,
                        onChange: this._changeEndDate.bind(this)
                      }
                    ]
                  }
                ]

              }
              inline={true}
              buttons={[]} />
          </Col>
        </Row>
      )
    } else if (this.props.utilization.showForm){
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.utilizationShowList.bind(this)}><span className="fa fa-arrow-circle-o-left"></span> Back</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <h1 className="box-title">{this.props.utilization.itemId?"Edit Utilization":"Add New Utilization"}</h1>
            </Col>
          </Row>
        </div>
      )
    }
  }

  _changeStartDate(value){
    let startDate = value.startOf("month")
    let filtersCookie = cookie.load('utilization_filters') || {}

    filtersCookie = {
      startDate: value,
      endDate: filtersCookie && filtersCookie.endDate?filtersCookie.endDate:this.state.endDate,
      billable: filtersCookie && filtersCookie.billable?filtersCookie.billable:this.state.billable,
    }

    this.setState({startDate: startDate})
    cookie.save('utilization_filters', JSON.stringify(filtersCookie), { path: '/' });
    this.props.utilizationList(this.state.status, startDate.format("YYYY-MM-DD"), moment(this.state.endDate).endOf("month").format("YYYY-MM-DD"))
  }

  _changeEndDate(value){
    let filtersCookie = cookie.load('utilization_filters') || {}

    filtersCookie = {
      startDate: filtersCookie && filtersCookie.startDate?filtersCookie.startDate:this.state.startDate,
      endDate: value,
      billable: filtersCookie && filtersCookie.billable?filtersCookie.billable:this.state.billable,
    }

    this.setState({endDate: value})
    cookie.save('utilization_filters', JSON.stringify(filtersCookie), { path: '/' });
    this.props.utilizationList(this.state.status, this.state.startDate.format("YYYY-MM-DD"), moment(value).endOf("month").format("YYYY-MM-DD"))
  }

  _changeStatus(value){
    this.setState({status: value})
    this.props.utilizationList(value, moment(this.state.startDate).format("YYYY-MM-DD"), moment(this.state.endDate).endOf("month").format("YYYY-MM-DD"))
  }
}

export default Utilization
