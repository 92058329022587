import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';

export const PMO_ACTION_ITEMS_PENDING = 'PMO_ACTION_ITEMS_PENDING';
export const pmoActionItemsPending = makeActionCreator(PMO_ACTION_ITEMS_PENDING);

export const PMO_ACTION_ITEMS_REJECTED = 'PMO_ACTION_ITEMS_REJECTED';
export const pmoActionItemsRejected = makeActionCreator(PMO_ACTION_ITEMS_REJECTED);

export const PMO_ACTION_ITEMS_SHOW_LIST = 'PMO_ACTION_ITEMS_SHOW_LIST';
export const pmoActionItemsShowList = makeActionCreator(PMO_ACTION_ITEMS_SHOW_LIST);

export const PMO_ACTION_ITEMS_HIDE_LIST = 'PMO_ACTION_ITEMS_HIDE_LIST';
export const pmoActionItemsHideList = makeActionCreator(PMO_ACTION_ITEMS_HIDE_LIST);

export const PMO_ACTION_ITEMS_SHOW_FORM = 'PMO_ACTION_ITEMS_SHOW_FORM';
export const pmoActionItemsShowForm = makeActionCreator(PMO_ACTION_ITEMS_SHOW_FORM, 'itemId');

export const PMO_ACTION_ITEMS_HIDE_FORM = 'PMO_ACTION_ITEMS_HIDE_FORM';
export const pmoActionItemsHideForm = makeActionCreator(PMO_ACTION_ITEMS_HIDE_FORM);

export const PMO_ACTION_ITEMS_SHOW_ITEM = 'PMO_ACTION_ITEMS_SHOW_ITEM';
export const pmoActionItemsShowItem = makeActionCreator(PMO_ACTION_ITEMS_SHOW_ITEM, 'itemId');


// Show Index

export const PMO_ACTION_ITEMS_LIST_FULFILLED = 'PMO_ACTION_ITEMS_LIST_FULFILLED';
export const pmoActionItemsListFulfilled = makeActionCreator(PMO_ACTION_ITEMS_LIST_FULFILLED, 'data');

export const PMO_ACTION_ITEMS_LIST_ERROR = 'PMO_ACTION_ITEMS_LIST_ERROR';
export const pmoActionItemsListError = makeActionCreator(PMO_ACTION_ITEMS_LIST_ERROR, 'message');

export function pmoActionItemsList(projectId, status = 9999) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoActionItemsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.actionItemsIndex.version}${endpoints.actionItemsIndex.path.replace(':pmo_project_id', projectId)}?is_completed=${status}`, {
        method: endpoints.actionItemsIndex.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoActionItemsListFulfilled(json.data));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoActionItemsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Show Item

export const PMO_ACTION_ITEMS_ITEM_FULFILLED = 'PMO_ACTION_ITEMS_ITEM_FULFILLED';
export const pmoActionItemsItemFulfilled = makeActionCreator(PMO_ACTION_ITEMS_ITEM_FULFILLED, 'data');

export const PMO_ACTION_ITEMS_ITEM_ERROR = 'PMO_ACTION_ITEMS_ITEM_ERROR';
export const pmoActionItemsItemError = makeActionCreator(PMO_ACTION_ITEMS_ITEM_ERROR, 'message');

export function pmoActionItemsItem(projectId, itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoActionItemsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getActionItem.version}${endpoints.getActionItem.path.replace(':pmo_project_id', projectId).replace(':action_item_id', itemId)}`, {
        method: endpoints.getActionItem.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoActionItemsItemFulfilled(json.data));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoActionItemsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Add Item
export const PMO_ACTION_ITEMS_ITEM_ADD_FULFILLED = 'PMO_ACTION_ITEMS_ITEM_ADD_FULFILLED';
export const pmoActionItemsItemAddFulfilled = makeActionCreator(PMO_ACTION_ITEMS_ITEM_ADD_FULFILLED, 'data');

export const PMO_ACTION_ITEMS_ITEM_ADD_ERROR = 'PMO_ACTION_ITEMS_ITEM_ADD_ERROR';
export const pmoActionItemsItemAddError = makeActionCreator(PMO_ACTION_ITEMS_ITEM_ADD_ERROR, 'message');

export function pmoActionItemsAddItem(projectId, data, status) {
  const formData = new FormData();

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoActionItemsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addActionItem.version}${endpoints.addActionItem.path.replace(':pmo_project_id', projectId)}`, {
        method: endpoints.addActionItem.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoActionItemsItemAddFulfilled(json.data));
            dispatch(pmoActionItemsList(projectId, status));
          } else {
            dispatch(pmoActionItemsItemAddError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoActionItemsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Update Item

export const PMO_ACTION_ITEMS_ITEM_UPDATE_FULFILLED = 'PMO_ACTION_ITEMS_ITEM_UPDATE_FULFILLED';
export const pmoActionItemsItemUpdateFulfilled = makeActionCreator(PMO_ACTION_ITEMS_ITEM_UPDATE_FULFILLED, 'data');

export const PMO_ACTION_ITEMS_ITEM_UPDATE_ERROR = 'PMO_ACTION_ITEMS_ITEM_UPDATE_ERROR';
export const pmoActionItemsItemUpdateError = makeActionCreator(PMO_ACTION_ITEMS_ITEM_UPDATE_ERROR, 'message');

export function pmoActionItemsUpdateItem(projectId, itemId, data, status) {
  const formData = new FormData();
  formData.append('_method', 'PUT');

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoActionItemsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateActionItem.version}${endpoints.updateActionItem.path.replace(':pmo_project_id', projectId).replace(':action_item_id', itemId)}`, {
        method: endpoints.updateActionItem.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoActionItemsItemUpdateFulfilled(json.data));
            dispatch(pmoActionItemsList(projectId, status));
          } else {
            dispatch(pmoActionItemsItemUpdateError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoActionItemsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Delete Item

export const PMO_ACTION_ITEMS_ITEM_DELETE_FULFILLED = 'PMO_ACTION_ITEMS_ITEM_DELETE_FULFILLED';
export const pmoActionItemsItemDeleteFulfilled = makeActionCreator(PMO_ACTION_ITEMS_ITEM_DELETE_FULFILLED, 'data');

export const PMO_ACTION_ITEMS_ITEM_DELETE_ERROR = 'PMO_ACTION_ITEMS_ITEM_DELETE_ERROR';
export const pmoActionItemsItemDeleteError = makeActionCreator(PMO_ACTION_ITEMS_ITEM_DELETE_ERROR, 'message');

export function pmoActionItemsDeleteItem(projectId, itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoActionItemsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteActionItem.version}${endpoints.deleteActionItem.path.replace(':pmo_project_id', projectId).replace(':action_item_id', itemId)}`, {
        method: endpoints.deleteActionItem.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoActionItemsItemDeleteFulfilled(json.data));
            dispatch(pmoActionItemsList(projectId));
          } else {
            dispatch(pmoActionItemsItemDeleteError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoActionItemsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
