import React, {Component} from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import moment from 'moment'

class Details extends Component {
  constructor(props) {
    super(props);
    this.forecastColumnClassName = this.forecastColumnClassName.bind(this);
  }

  render() {
    let week_1 = moment().startOf("isoWeek")
    let week_2 = moment(week_1).add(1, "week")
    let week_3 = moment(week_2).add(1, "week")
    let week_4 = moment(week_3).add(1, "week")
    let week_5 = moment(week_4).add(1, "week")
    let week_6 = moment(week_5).add(1, "week")
    let week_7 = moment(week_6).add(1, "week")
    let week_8 = moment(week_7).add(1, "week")

    if (this.props.data && this.props.data.projects) {
      return (
        <BootstrapTable data={ this.props.data.projects }>
          <TableHeaderColumn
            dataField='project' isKey={ true }
            columnTitle
            filterFormatted
            dataSort={true}
            filter={ { type: 'TextFilter', delay: 500 } }
            dataFormat={this._objectFormatter.bind(this, "name", "")}
            >Project</TableHeaderColumn>
          <TableHeaderColumn
            dataField='project'
            columnTitle
            filterFormatted
            dataSort={true}
            filter={ { type: 'TextFilter', delay: 500 } }
            dataFormat={this._objectFormatter.bind(this, "client", "name")}
            sortFunc={this._sortObjectFunc}
            sortFuncExtraData={{objectField: "client", secondLevelObjectField: "name"}}
          >
            Client
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="forecast"
            filterFormatted
            dataFormat={this.totalProjectUtilization.bind(this, 0, "utilization")}
            headerAlign='left'
            dataAlign='center'
            className='col_availability'
            columnClassName={ this.forecastColumnClassName }
          >
            {week_1.format("YYYY-MM-DD")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="forecast"
            filterFormatted
            dataFormat={this.totalProjectUtilization.bind(this, 1, "utilization")}
            headerAlign='left'
            dataAlign='center'
            className='col_availability'
            columnClassName={ this.forecastColumnClassName }
          >{week_2.format("YYYY-MM-DD")}</TableHeaderColumn>
          <TableHeaderColumn
            dataField="forecast"
            filterFormatted
            dataFormat={this.totalProjectUtilization.bind(this, 2, "utilization")}
            headerAlign='left'
            dataAlign='center'
            className='col_availability'
            columnClassName={ this.forecastColumnClassName }
          >
            {week_3.format("YYYY-MM-DD")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="forecast"
            filterFormatted
            dataFormat={this.totalProjectUtilization.bind(this, 3, "utilization")}
            headerAlign='left'
            dataAlign='center'
            className='col_availability'
            columnClassName={ this.forecastColumnClassName }
          >
            {week_4.format("YYYY-MM-DD")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="forecast"
            filterFormatted
            dataFormat={this.totalProjectUtilization.bind(this, 4, "utilization")}
            headerAlign='left'
            dataAlign='center'
            className='col_availability'
            columnClassName={ this.forecastColumnClassName }
          >
            {week_5.format("YYYY-MM-DD")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="forecast"
            filterFormatted
            dataFormat={this.totalProjectUtilization.bind(this, 5, "utilization")}
            headerAlign='left'
            dataAlign='center'
            className='col_availability'
            columnClassName={ this.forecastColumnClassName }
          >
            {week_6.format("YYYY-MM-DD")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="forecast"
            filterFormatted
            dataFormat={this.totalProjectUtilization.bind(this, 6, "utilization")}
            headerAlign='left'
            dataAlign='center'
            className='col_availability'
            columnClassName={ this.forecastColumnClassName }
          >
            {week_7.format("YYYY-MM-DD")}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="forecast"
            filterFormatted
            dataFormat={this.totalProjectUtilization.bind(this, 7, "utilization")}
            headerAlign='left'
            dataAlign='center'
            className='col_availability'
            columnClassName={ this.forecastColumnClassName }
          >
            {week_8.format("YYYY-MM-DD")}
          </TableHeaderColumn>
        </BootstrapTable>);
    } else {
      return (<p>No Data</p>);
    }
  }

  forecastColumnClassName(fieldValue, row, rowIdx, colIdx) {
    let className = 'col_availability';
    if (colIdx - 2 >= 0) {
      const data = fieldValue[colIdx - 2];
      if (!data.utilization && data.utilization !== 0) {
        className += ' empty';
      }
    }

    return className;
  }

  _percentajeFormatter(cell, row){
    return cell + "%"
  }

  _objectFormatter(field, field2, cell, row){

    if (field2) {
      if(cell && cell[field] && cell[field][field2]){
        return cell[field][field2]
      }
    } else {
      if (cell && cell[field]) {
        return cell[field]
      }
    }


    return ''

  }

  _objectFormatterPosField(pos, field, cell, row){
    if((cell && cell[pos] && cell[pos][field]) || (cell && cell[pos] && cell[pos][field] === 0)){
      return cell[pos][field]
    }

    return ''

  }

  totalProjectUtilization(pos, field, cell, row){
    let data = this._objectFormatterPosField(pos, field, cell, row);

    if (data || data === 0) {
      return `${data} %`;
    }

    return 'N/A';
  }

  _sortObjectFunc(a, b, order, sortField, extraData) {   // order is desc or asc


    if(extraData["secondLevelObjectField"]){
      let fieldName = extraData["objectField"]
      let fieldSecondLevelFieldName = extraData["secondLevelObjectField"]

      if (order === 'asc') {
        return (a[sortField] && a[sortField][fieldName] && a[sortField][fieldName][fieldSecondLevelFieldName]?a[sortField][fieldName][fieldSecondLevelFieldName]:'').localeCompare(b[sortField] && b[sortField][fieldName]  && b[sortField][fieldName][fieldSecondLevelFieldName]?b[sortField][fieldName][fieldSecondLevelFieldName]:'')
      } else {
        return (b[sortField] && b[sortField][fieldName] && b[sortField][fieldName][fieldSecondLevelFieldName]?b[sortField][fieldName][fieldSecondLevelFieldName]:'').localeCompare(a[sortField] && a[sortField][fieldName] && a[sortField][fieldName][fieldSecondLevelFieldName]?a[sortField][fieldName][fieldSecondLevelFieldName]:'');
      }
    }else{
      let fieldName = extraData["objectField"]

      if (order === 'asc') {
        return (a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'').localeCompare(b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'')
      } else {
        return (b[sortField] && b[sortField][fieldName]?b[sortField][fieldName]:'').localeCompare(a[sortField] && a[sortField][fieldName]?a[sortField][fieldName]:'');
      }
    }



  }
}

export default Details
