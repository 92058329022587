//
// Login:
//

export const getPermissions = {
  path: '/user_roles/:user_role_id/permissions/',
  method: 'GET',
  version: '/v1',
};

export const updatePermissions = {
  path: '/user_roles/:user_role_id/permissions/all',
  method: 'POST',
  version: '/v1',
};
