import React, { Component } from 'react';
import _ from 'lodash';
import SystemMsg from '../common/system-msg.jsx';
import 'assets/css/mentorship.css';
import MentorshipList from './list.jsx';

class Mentorship extends Component {
  constructor(props) {
    super(props);
    const { session } = this.props;
    const { user } = session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'mentorship') {
          permissions = area;
        }
      });
    }

    this.state = {
      permissions
    };
  }

  componentDidMount() {
    this.props.getMentorshipData();
  }

  componentWillReceiveProps(nextProps) {
    // Session + Permissions
    const { session } = this.props;
    if (!_.isEqual(session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'mentorship') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  render() {
    const { permissions } = this.state;
    const { mentorship } = this.props;

    if (
      !permissions
      || (
        !permissions.can_read
        && !permissions.can_create
        && !permissions.can_delete
        && !permissions.can_update
        && !permissions.limit_to_own_user
      )
    ) {
      if (permissions) {
        const { router } = this.props;
        router.push('/404');
      }
      return null;
    }

    // Loading
    if (mentorship.inProgress === true) {
      return (
        <section className="cn-sessions-container">
          <SystemMsg msg="Loading" hasSpinner />
        </section>
      );
    }

    // Data
    return (
      <section>
        <div className="box">
          <div className="box-header with-border">
            <h1>
              Mentorship
            </h1>
          </div>
          <div className="box-content">
            <MentorshipList
              permissions={permissions}
              {...this.props}
            />
          </div>
        </div>
      </section>
    );
  }
}

export default Mentorship;
