import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import SettingsRatesList from './list.jsx';
import SystemMsg from '../../common/system-msg.jsx';
import 'assets/css/rates.css';
import Select from '../../form/select.jsx';

class SettingsPermissions extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'settings') {
          permissions = area;
        }
      });
    }
    this.state = {
      levelId: null,
      permissions,
    };

    this.setLevel = this.setLevel.bind(this);
  }

  componentWillMount() {
    this.props.settingsLevelsList();
  }

  componentWillUnmount() {
    this.props.settingsPermissionsHideList();
  }

  setLevel(level) {
    this.setState({ levelId: level });
  }

  getTitle() {
    const levels = [];
    const {
      levelId,
    } = this.state;

    if (this.props.settingsLevels && this.props.settingsLevels.data) {
      for (let i = 0; i < this.props.settingsLevels.data.length; i += 1) {
        levels.push(
          {
            name: this.props.settingsLevels.data[i].name,
            value: this.props.settingsLevels.data[i].id,
          },
        );
      }
    }


    if (this.props.settingUserPermission.showList) {
      return (
        <Row>
          <Col xs={4} sm={4} md={3} lg={2} className="title-section">
            <h1 className="box-title">
              User Permissions
            </h1>
          </Col>
          <Col xs={9} sm={6} md={4} lg={3}>
            <Select schema={{
              value: levelId,
              name: 'level',
              label: '',
              disabled: this.props.settingUserPermission.inProgress || this.props.settingsLevels.inProgress,
              placeholder: 'Select a Level to continue',
              options: levels,
              onChange: this.setLevel,
              searchable: true
            }}
            />
          </Col>
        </Row>
      );
    }

    return null;
  }

  render() {
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className="rates-content">
        { this.props.settingUserPermission.inProgress || this.props.settingsLevels.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this.getTitle()}
          </div>
          <div className="box-content">
            {this.props.settingUserPermission.showList && this.state.levelId ?
              <SettingsRatesList levelId={this.state.levelId} {...this.props} />
              : null}
          </div>
        </div>

      </section>
    );
  }
}

export default SettingsPermissions;
