import React    from 'react'
import { FormGroup, HelpBlock, ControlLabel, FormControl } from 'react-bootstrap';

class TextInput extends React.Component {

  constructor(props) {
    super(props)
    this.focus = this.focus.bind(this);
    this.state = ({ value: (this.props.schema && this.props.schema.value) && (this.props.schema.value || this.props.schema.value === 0)  ?this.props.schema.value:"" })
  }

  componentWillReceiveProps(nexProps) {
    if ( (nexProps.schema && (nexProps.schema.value || nexProps.schema.value === 0) && nexProps.schema.value !== this.props.schema.value) || (nexProps.schema.empty === true)) {
      this.setState({ value: nexProps.schema.value || nexProps.schema.value === 0?nexProps.schema.value:"" })
    }
  }

  onChange(schema, e) {
    this.setState({ value: e.target.value });

    if (schema.onChange) {
      schema.onChange(e.target.value);
    }
  }

  getValue() {
    return this.state.value;
  }

  focus() {
    this.textInput.focus();
  }

  changeClass(schema) {
    let className = '';

    if(schema.className){
      className = " "+schema.className;
    }

    if(schema.type === 'hidden'){
      return 'hidden';
    }else if(schema.disabled){
      return "field disabled" + className;
    }

    return "field" + className;
  }


  render() {
    const {
      schema,
    } = this.props;

    if (!schema) {
      return null;
    }

    return (
      <FormGroup
        className={this.changeClass(schema)}
        style={schema.style ? schema.style : null}
        controlId={schema.id ? schema.id : schema.name}
        validationState={schema.validationState || null}
      >
        {schema.label ? (
          <ControlLabel>
            { schema.label }
          </ControlLabel>
        ) : ''}
        <FormControl
          type={schema.type}
          name={schema.name}
          placeholder={schema.placeholder}
          ref={(input) => { this.textInput = input; }}
          value={this.state.value}
          onChange={this.onChange.bind(this, schema)}
          readOnly={schema.readOnly}
          autoComplete={schema.autocomplete ? schema.autocomplete : ''}
          max={schema.maxValue}
          min={schema.minValue}
          maxLength={schema.maxlength}
          disabled={schema.disabled}
          onBlur={schema.onblur ? schema.onblur : null}
          required={schema.isRequired ? 'required' : null}
          onKeyUp={schema.onKeyUp ? schema.onKeyUp : null}
        />
        {schema.icon && schema.icon.name ? <span className={`glyphicon glyphicon-${schema.icon.name} ${schema.icon.className?schema.icon.className:''}`}></span>: '' }
        {schema.representedBy ? <span className="represented-by">Days</span> : ''  }
        {schema.errorText ? <HelpBlock>{schema.errorText}</HelpBlock>: ''  }
      </FormGroup>
    );
  }
}


export default TextInput;
