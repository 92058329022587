const initialState = {
  inProgress: false,
  error: null,
  data: {},
  isAuthenticated: false,
  errorMessage: null,
  errorMessagePasswordReset: null,
  logout: false,
  errorPasswordUpdate: false,
  errorMessagePasswordUpdate: null,
  passwordChanged: false,
};

function authReducer(state = initialState, action) {
  const actionAuth = 'AUTH';

  switch (action.type) {
    case `${actionAuth}_PENDING`:
      return {
        ...state,
        inProgress: true,
        error: false,
        errorMessage: null,
        successMessage: null,
        errorMessagePasswordReset: null,
        errorPasswordReset: false,
        errorPasswordUpdate: false,
        errorMessagePasswordUpdate: null,
        passwordChanged: false,
      };
    case `${actionAuth}_REJECTED`:
      return {
        ...state,
        inProgress: false,
        error: action.error,
        successMessage: null,
        errorMessagePasswordReset: null,
        errorPasswordReset: false,
      };
    case `${actionAuth}_LOGIN_FULFILLED`:
      return {
        ...state,
        inProgress: false,
        data: action.data,
        isAuthenticated: true,
        error: false,
        errorMessage: null,
        errorMessagePasswordReset: null,
        errorPasswordReset: false,
        logout: false,
      };
    case `${actionAuth}_LOGIN_ERROR`:
      return {
        ...state,
        inProgress: false,
        isAuthenticated: false,
        error: true,
        errorMessage: action.message,
        errorMessagePasswordReset: null,
        errorPasswordReset: false,
      };
    case `${actionAuth}_LOGOUT_PENDING`:
      return {
        ...state,
        inProgress: true,
        error: false,
        errorMessage: null,
        errorMessagePasswordReset: null,
        errorPasswordReset: false,
      };
    case `${actionAuth}_LOGOUT_FULFILLED`:
      return {
        ...state,
        inProgress: false,
        isAuthenticated: false,
        errorMessagePasswordReset: null,
        errorPasswordReset: false,
        logout: true,
      };
    case 'USER_AGREEMENT_FULFILLED':
      return {
        ...state,
        inProgress: false,
      };
    case 'REQUEST_RECOVER_PASSWORD_FULFILLED':
      return {
        ...state,
        inProgress: false,
        error: false,
        errorMessage: null,
        isAuthenticated: false,
        successMessage: 'Message successfully sent, check your email.',
        errorMessagePasswordReset: null,
        errorPasswordReset: false,
      };
    case 'SEND_RECOVER_PASSWORD_ERROR':
      return {
        ...state,
        inProgress: false,
        isAuthenticated: false,
        error: false,
        errorPasswordReset: true,
        errorMessage: null,
        errorMessagePasswordReset: action.message,
      };
    case 'RECOVER_PASSWORD_CHANGE_FULFILLED':
      return {
        ...state,
        inProgress: false,
        isAuthenticated: false,
        successMessage: action.message,
        error: false,
        errorPasswordReset: true,
        errorMessage: null,
        errorMessagePasswordReset: null,
      };
    case 'AUTH_UPDATE_PASSWORD_FULFILLED':
      return {
        ...state,
        inProgress: false,
        data: action.data,
        isAuthenticated: true,
        passwordChanged: true,
        error: false,
        errorMessage: null,
        errorMessagePasswordReset: null,
        errorPasswordReset: false,
        logout: false,
      };
    case 'AUTH_UPDATE_PASSWORD_RESET':
      return {
        ...state,
        passwordChanged: false,
      };
    case 'AUTH_UPDATE_PASSWORD_ERROR':
      return {
        ...state,
        inProgress: false,
        errorPasswordUpdate: true,
        errorMessagePasswordUpdate: action.message,
      };
    default:
      return state;
  }
}

export default authReducer;
