import makeActionCreator from '../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API       from '../utils/config-api'
import * as endpoints from './endpoints'
import { logout } from '../auth/list';

export const RECRUITS_PENDING = 'RECRUITS_PENDING'
export const recruitsPending = makeActionCreator(RECRUITS_PENDING)

export const RECRUITS_REJECTED = 'RECRUITS_REJECTED'
export const recruitsRejected = makeActionCreator(RECRUITS_REJECTED)

export const RECRUITS_SHOW_LIST= 'RECRUITS_SHOW_LIST'
export const recruitsShowList = makeActionCreator(RECRUITS_SHOW_LIST)

export const RECRUITS_HIDE_LIST = 'RECRUITS_HIDE_LIST'
export const recruitsHideList = makeActionCreator(RECRUITS_HIDE_LIST)

export const RECRUITS_SHOW_FORM= 'RECRUITS_SHOW_FORM'
export const recruitsShowForm = makeActionCreator(RECRUITS_SHOW_FORM, 'itemId')

export const RECRUITS_HIDE_FORM = 'RECRUITS_HIDE_FORM'
export const recruitsHideForm = makeActionCreator(RECRUITS_HIDE_FORM)

export const RECRUITS_SHOW_ITEM = 'RECRUITS_SHOW_ITEM'
export const recruitsShowItem = makeActionCreator(RECRUITS_SHOW_ITEM, 'itemId')


//Show Index

export const RECRUITS_LIST_FULFILLED = 'RECRUITS_LIST_FULFILLED'
export const recruitsListFulfilled = makeActionCreator(RECRUITS_LIST_FULFILLED, 'data')

export const RECRUITS_LIST_ERROR = 'RECRUITS_LIST_ERROR'
export const recruitsListError = makeActionCreator(RECRUITS_LIST_ERROR, 'message')

export function recruitsList(status) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(recruitsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}?status=${status}`, {
              method  : endpoints.index.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(recruitsListFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(recruitsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}


//Show Item

export const RECRUITS_ITEM_FULFILLED = 'RECRUITS_ITEM_FULFILLED'
export const recruitsItemFulfilled = makeActionCreator(RECRUITS_ITEM_FULFILLED, 'data')

export const RECRUITS_ITEM_ERROR = 'RECRUITS_ITEM_ERROR'
export const recruitsItemError = makeActionCreator(RECRUITS_ITEM_ERROR, 'message')

export function recruitsItem(itemId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(recruitsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getItem.version}${endpoints.getItem.path.replace(":recruit_id", itemId)}`, {
              method  : endpoints.getItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(recruitsItemFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(recruitsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })




  }
}


//Add Item

export const RECRUITS_ITEM_ADD_FULFILLED = 'RECRUITS_ITEM_ADD_FULFILLED'
export const recruitsItemAddFulfilled = makeActionCreator(RECRUITS_ITEM_ADD_FULFILLED, 'data')

export const RECRUITS_ITEM_ADD_ERROR = 'RECRUITS_ITEM_ADD_ERROR'
export const recruitsItemAddError = makeActionCreator(RECRUITS_ITEM_ADD_ERROR, 'message')

export function recruitsAddItem(data) {
  let formData = new FormData()

  Object.keys(data).map((key)=> {
    formData.append(key, data[key]);
    return key;
  });

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(recruitsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addItem.version}${endpoints.addItem.path}`, {
              method  : endpoints.addItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(recruitsItemAddFulfilled(json.data))
              }else{
                dispatch(recruitsItemAddError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(recruitsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}

//Update Item

export const RECRUITS_ITEM_UPDATE_FULFILLED = 'RECRUITS_ITEM_UPDATE_FULFILLED'
export const recruitsItemUpdateFulfilled = makeActionCreator(RECRUITS_ITEM_UPDATE_FULFILLED, 'data')

export const RECRUITS_ITEM_UPDATE_ERROR = 'RECRUITS_ITEM_UPDATE_ERROR'
export const recruitsItemUpdateError = makeActionCreator(RECRUITS_ITEM_UPDATE_ERROR, 'message')

export function recruitsUpdateItem(itemId, data) {
  let formData = new FormData()
  formData.append('_method', 'PUT')

  Object.keys(data).map((key)=> {
    formData.append(key, data[key]);
    return key;
  });


  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(recruitsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateItem.version}${endpoints.updateItem.path.replace(":recruit_id", itemId)}`, {
              method  : endpoints.updateItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(recruitsItemUpdateFulfilled(json.data))
              }else{
                dispatch(recruitsItemUpdateError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(recruitsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}

//Delete Item

export const RECRUITS_ITEM_DELETE_FULFILLED = 'RECRUITS_ITEM_DELETE_FULFILLED'
export const recruitsItemDeleteFulfilled = makeActionCreator(RECRUITS_ITEM_DELETE_FULFILLED, 'data')

export const RECRUITS_ITEM_DELETE_ERROR = 'RECRUITS_ITEM_DELETE_ERROR'
export const recruitsItemDeleteError = makeActionCreator(RECRUITS_ITEM_DELETE_ERROR, 'message')

export function recruitsDeleteItem(itemId, status) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(recruitsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteItem.version}${endpoints.deleteItem.path.replace(":recruit_id", itemId)}`, {
              method  : endpoints.deleteItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
            if(json.status === "ok"){
              dispatch(recruitsItemDeleteFulfilled(json.data))
              dispatch(recruitsList(status))
            }else{
              dispatch(recruitsItemDeleteError(json.message))
            }

          })
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(recruitsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })





  }
}
