import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';

import SmartForm from '../../form/base.jsx';

class LevelsForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentWillMount() {
    if (this.props.settingsLevels.itemId) {
      this.props.settingsLevelsItem(this.props.settingsLevels.itemId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.settingsLevels.addSuccess && nextProps.settingsLevels.addSuccess !== this.props.settingsLevels.addSuccess) ||
      (nextProps.settingsLevels.editSuccess && nextProps.settingsLevels.editSuccess !== this.props.settingsLevels.editSuccess)
    ) {
      this.props.settingsLevelsShowList();
    }
  }

  submit(data) {
    if (this.props.settingsLevels.itemId) {
      this.props.settingsLevelsUpdateItem(this.props.settingsLevels.itemId, data);
    } else {
      this.props.settingsLevelsAddItem(data);
    }
  }

  render() {
    let itemData;

    if (this.props.settingsLevels.itemId) {
      itemData = this.props.settingsLevels.itemData;
    }

    const schema = [
      {
        type: 'default',
        fields: [
          {
            type: 'text',
            name: 'name',
            label: 'Name:',
            value: itemData ? itemData.name : '',
          },
          {
            type: 'number',
            name: 'target',
            label: 'Target:',
            value: itemData ? itemData.target : '',
          },
          {
            type: 'radios',
            name: 'include_in_staffing',
            label: 'Include in Staffing:',
            value: itemData ? itemData.include_in_staffing.toString() : '1',
            options: [
              {
                text: 'Yes',
                value: '1',
              },
              {
                text: 'No',
                value: '0',
              },
            ],
          },
          {
            type: 'radios',
            name: 'include_in_utilization',
            label: 'Include in Utilization:',
            value: itemData ? itemData.include_in_utilization.toString() : '1',
            options: [
              {
                text: 'Yes',
                value: '1',
              },
              {
                text: 'No',
                value: '0',
              },
            ],
          },
          {
            type: 'radios',
            name: 'include_in_consultant_location',
            label: 'Include in C. Location:',
            value: itemData ? itemData.include_in_consultant_location.toString() : '1',
            options: [
              {
                text: 'Yes',
                value: '1',
              },
              {
                text: 'No',
                value: '0',
              },
            ],
          },
          {
            type: 'radios',
            name: 'include_in_consultant_networking',
            label: 'Include in C. Networking:',
            value: itemData ? itemData.include_in_consultant_networking.toString() : '1',
            options: [
              {
                text: 'Yes',
                value: '1',
              },
              {
                text: 'No',
                value: '0',
              },
            ],
          },
          {
            type: 'radios',
            name: 'include_in_consultant_skills',
            label: 'Include in C. Skills:',
            value: itemData ? itemData.include_in_consultant_skills.toString() : '1',
            options: [
              {
                text: 'Yes',
                value: '1',
              },
              {
                text: 'No',
                value: '0',
              },
            ],
          },
          {
            type: 'radios',
            name: 'include_in_mentorship',
            label: 'Include in Mentorship:',
            value: itemData ? itemData.include_in_mentorship.toString() : '1',
            options: [
              {
                text: 'Yes',
                value: '1',
              },
              {
                text: 'No',
                value: '0',
              },
            ],
          },
          {
            type: 'radios',
            name: 'can_mentor',
            label: 'Can Mentor:',
            value: itemData ? itemData.can_mentor.toString() : '1',
            options: [
              {
                text: 'Yes',
                value: '1',
              },
              {
                text: 'No',
                value: '0',
              },
            ],
          }
        ],
      },
    ];

    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this.submit}
              showError={this.props.settingsLevels.error && this.props.settingsLevels.errorText}
              errorMessage={this.props.settingsLevels.errorText}
              onReset={this.props.settingsLevelsShowList}
            />
          </Col>
        </Row>
      </section>
    );
  }
}

export default LevelsForm;
