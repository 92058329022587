import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';

import SmartForm from '../../form/base.jsx';

class MilestoneForm extends Component {
  constructor(props) {
    super(props);

    const {
      itemData,
    } = this.props.pmoMilestone;

    this.submit = this.submit.bind(this);
    this.state = {
      isCompleted: itemData ? itemData.is_completed.toString() : '0',
      completedAt: itemData && itemData.completed_at ? itemData.completed_at : moment(),
    };
  }

  componentWillMount() {
    if (this.props.pmoMilestone.itemId) {
      this.props.pmoMilestones(this.props.projectId, this.props.pmoMilestone.itemId);
    }

    this.props.userList();
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.pmoMilestone.addSuccess && nextProps.pmoMilestone.addSuccess !== this.props.pmoMilestone.addSuccess) || (nextProps.pmoMilestone.editSuccess && nextProps.pmoMilestone.editSuccess !== this.props.pmoMilestone.editSuccess)) {
      this.props.pmoMilestoneShowList();
    }

    if (!_.isEqual(this.props.pmoMilestone.itemData, nextProps.pmoMilestone.itemData)) {
      const {
        itemData,
      } = nextProps.pmoMilestone;

      this.setState({
        isCompleted: itemData ? itemData.is_completed.toString() : '0',
        completedAt: itemData && itemData.completed_at ? itemData.completed_at : moment(),
      });
    }
  }

  submit(data) {
    if (this.props.pmoMilestone.itemId) {
      this.props.pmoMilestoneUpdateItem(this.props.projectId, this.props.pmoMilestone.itemId, data, this.props.listStatus);
    } else {
      this.props.pmoMilestoneAddItem(this.props.projectId, data, this.props.listStatus);
    }
  }

  render() {
    let itemData;
    if (this.props.pmoMilestone.itemId) {
      itemData = this.props.pmoMilestone.itemData;
    }

    const schema = [{
      type: 'default',
      fields: [
        {
          type: 'textarea',
          name: 'description',
          label: 'Description:',
          value: itemData && itemData.description ? itemData.description : '',
        },
        {
          type: 'calendar',
          name: 'due_date',
          label: 'Due Date:',
          value: itemData && itemData.due_date ? itemData.due_date : '',
        },
        {
          type: 'radios',
          name: 'is_completed',
          label: 'Completed:',
          value: this.state.isCompleted,
          options: [
            { value: '1', text: 'Yes' },
            { value: '0', text: 'No' },
          ],
          onChange: (isCompleted) => {
            if (isCompleted) {
              this.setState({ isCompleted: isCompleted.toString(), completedAt: moment().format('YYYY-MM-DD') });
            } else {
              this.setState({ isCompleted: isCompleted.toString(), completedAt: '' });
            }
          },
        },
        this.state.isCompleted === '1' ? {
          type: 'calendar',
          name: 'completed_at',
          label: 'Completed On:',
          value: this.state.completedAt,
          onChange: (date) => {
            this.setState({ completedAt: date });
          },
          maxDate: moment(),
        } : null,
        {
          type: 'textarea',
          name: 'comments',
          label: 'Comments:',
          value: itemData ? itemData.comments : '',
        },
      ],
    }];

    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this.submit}
              showError={this.props.pmoMilestone.error && this.props.pmoMilestone.errorText}
              errorMessage={this.props.pmoMilestone.errorText}
              onReset={this.props.pmoMilestoneShowList.bind(this)}
              loading={this.props.pmoMilestone.inProgress}
            />
          </Col>
        </Row>
      </section>
    );
  }
}

export default MilestoneForm;
