import React, { Component } from 'react';
import moment from 'moment'
import 'assets/css/table.css';
import { Table, Row, Col } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css';
import cookie from 'react-cookies';

class InvoicesIssuedList extends Component{
  constructor(props){
  	super(props);
  	this.state = {
      itemId: null,
      total: 0,
      sort_name: "",
      sort_order: ""
    };
  }

  componentWillMount() {
    this.setState(
      {
        sort_name: cookie.load('sort_column_invoices') || 'number',
        sort_order: cookie.load('sort_order_invoices') || 'asc'
      }
    )
  }

  render(){
    const list = this.props.invoicesIssued.data
    const options = {
      afterColumnFilter: this._afterColumnFilter.bind(this),
      expandRowBgColor: 'rgb(242, 255, 163)',
      defaultSortName: this.state.sort_name,
      defaultSortOrder: this.state.sort_order,
      onSortChange: this._onSortChange.bind(this),
    }

    return (
      <section className="section-list-content">
        <BootstrapTable
            data={list}
            striped={true}
            hover={true}
            options={options}
            autoCollapse={ { sort: true, search: true, filter: true } }
            ref={(table) => { this.table = table; }}
            headerContainerClass='fixed-header'>
            <TableHeaderColumn
              dataField="number"
              isKey={true}
              dataSort={true}
              width="80px"
              className='col_number'
              columnClassName={this._columnClassNameFormat}
            >
              No.
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="issue_date"
              dataSort={true}
              dataFormat={this._dateFormatter}
              dataAlign='center'
              headerAlign='left'
              width="105px"
              className='col_issue_date'
              columnClassName={this._columnClassNameFormat}
            >
              Issue Date
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="due_date"
              dataSort={true}
              dataFormat={this._dateFormatter}
              dataAlign='center'
              headerAlign='left'
              width="105px"
              className='col_due_date'
              columnClassName={this._columnClassNameFormat}
            >
              Due Date
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="client"
              columnTitle
              filter={ { type: 'TextFilter', delay: 500 } }
              dataSort={true}
              dataFormat={this._objectFormatter.bind(this, "name")}
              filterFormatted
              sortFunc={this._sortObjectFunc}
              sortFuncExtraData={{objectField: "name"}}
              columnClassName={this._columnClassNameFormat}
            >
              Client
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="project"
              columnTitle
              filter={ { type: 'TextFilter', delay: 500 } }
              dataSort={true}
              dataFormat={this._objectFormatter.bind(this, "name")}
              filterFormatted
              sortFunc={this._sortObjectFunc}
              sortFuncExtraData={{objectField: "name"}}
              columnClassName={this._columnClassNameFormat}
            >
              Project
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="amount"
              filter={ { type: 'NumberFilter', delay: 500 } }
              dataFormat={this._moneyFormatter}
              dataSort={true}
              sortFunc={this._sortNumberFunc}
              dataAlign='right'
              headerAlign='left'
              width="165px"
              className='col_amount'
              columnClassName={this._columnClassNameFormat}
            >
              Amount
            </TableHeaderColumn>
        </BootstrapTable>
        <div className="totals">
          <Row>
            <Col xs={12} md={5} mdOffset={7}>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Total:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{Number(this.state.total).toLocaleString('en-US', {style: 'currency',currency: 'USD'})}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <Row>
          <h4>Status</h4>
          <p><span className="invoice-is-late status-hint">Late</span></p>
        </Row>
        </div>
      </section>
    )
  }

  _columnClassNameFormat(fieldValue, row, rowIdx, colIdx) {
    if (row.is_late) {
      return 'invoice-is-late';
    } else if (row.is_open)
      return 'invoice-is-open';
  }

  _onSortChange(sortName, sortOrder){

    cookie.save('sort_column_invoices', sortName, { path: '/' });
    cookie.save('sort_order_invoices', sortOrder, { path: '/' });

    this.setState({
      sort_name: sortName,
      sort_order: sortOrder
    });

  }

  _moneyFormatter(cell, row){
    return Number(cell).toLocaleString('en-US', {style: 'currency',currency: 'USD'})
  }

  _dateFormatter(cell, row){
    return moment(cell).format("YYYY-MM-DD")
  }

  _objectFormatter(field, cell, row){
    if (cell && cell[field]) {
      return cell[field]
    }

    return ''
  }

  _sortObjectFunc(a, b, order, sortField, extraData) {   // order is desc or asc
    const fieldName = extraData["objectField"]

    if (order === 'asc') {
      return a[sortField][fieldName].localeCompare(b[sortField][fieldName])
    } else {
      return b[sortField][fieldName].localeCompare(a[sortField][fieldName]);
    }

  }

  _sortNumberFunc(a, b, order, sortField){
    if (order === 'asc') {
      return parseFloat(a[sortField]) - parseFloat(b[sortField])
    } else {
      return parseFloat(b[sortField]) - parseFloat(a[sortField]);
    }
  }

  _afterColumnFilter(filterConds, result) {
    let total = 0

    for (let i = 0; i < result.length; i++) {
      total += parseFloat(result[i].amount)
    }

    if(total !== this.state.total){
        this.setState({total: total})
    }
  }
}

export default InvoicesIssuedList
