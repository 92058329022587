import makeActionCreator from '../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API       from '../utils/config-api'
import * as endpoints from './endpoints'
import { logout } from '../auth/list';

export const INVOICES_ISSUED_PENDING = 'INVOICES_ISSUED_PENDING'
export const invoicesIssuedPending = makeActionCreator(INVOICES_ISSUED_PENDING)

export const INVOICES_ISSUED_REJECTED = 'INVOICES_ISSUED_REJECTED'
export const invoicesIssuedRejected = makeActionCreator(INVOICES_ISSUED_REJECTED)

export const INVOICES_ISSUED_SHOW_LIST= 'INVOICES_ISSUED_SHOW_LIST'
export const invoicesIssuedShowList = makeActionCreator(INVOICES_ISSUED_SHOW_LIST)

export const INVOICES_ISSUED_HIDE_LIST = 'INVOICES_ISSUED_HIDE_LIST'
export const invoicesIssuedHideList = makeActionCreator(INVOICES_ISSUED_HIDE_LIST)

export const INVOICES_ISSUED_SHOW_FORM= 'INVOICES_ISSUED_SHOW_FORM'
export const invoicesIssuedShowForm = makeActionCreator(INVOICES_ISSUED_SHOW_FORM, 'itemId')

export const INVOICES_ISSUED_HIDE_FORM = 'INVOICES_ISSUED_HIDE_FORM'
export const invoicesIssuedHideForm = makeActionCreator(INVOICES_ISSUED_HIDE_FORM)

export const INVOICES_ISSUED_SHOW_ITEM = 'INVOICES_ISSUED_SHOW_ITEM'
export const invoicesIssuedShowItem = makeActionCreator(INVOICES_ISSUED_SHOW_ITEM, 'itemId')


//Show Index

export const INVOICES_ISSUED_LIST_FULFILLED = 'INVOICES_ISSUED_LIST_FULFILLED'
export const invoicesIssuedListFulfilled = makeActionCreator(INVOICES_ISSUED_LIST_FULFILLED, 'data')

export const INVOICES_ISSUED_LIST_ERROR = 'INVOICES_ISSUED_LIST_ERROR'
export const invoicesIssuedListError = makeActionCreator(INVOICES_ISSUED_LIST_ERROR, 'message')

export function invoicesIssuedList(start_date, end_date) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(invoicesIssuedPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}?start_date=${start_date}&end_date=${end_date}`, {
              method  : endpoints.index.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(invoicesIssuedListFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(invoicesIssuedRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}
