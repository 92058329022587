import React, { Component } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import _ from 'lodash';
import cookie from 'react-cookies';
import { browserHistory } from 'react-router';

import PMOIssueManagementList from './list.jsx';
import PMOIssueManagementForm from './form.jsx';
import SystemMsg from '../../common/system-msg.jsx';
import 'assets/css/pmo.css';
import SmartForm from '../../form/base.jsx';

class PMOIssueManagement extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'pmo') {
          permissions = area;
        }
      });
    }

    this.state = {
      permissions,
      status: '9999',
      projectId: this.props.params && this.props.params.projectId ? this.props.params.projectId : '',
      projectData: null,
    };
  }

  componentWillMount() {
    if (this.state.projectId) {
      this.props.pmoProjectsItem(this.state.projectId);
    }

    const filtersCookie = cookie.load('pmo_issue_management_filters') || {};

    this.setState(
      {
        status: filtersCookie && filtersCookie.status ? filtersCookie.status : this.state.status,
      },
    );
  }

  componentDidMount() {
    this.props.pmoIssueManagementList(this.state.projectId, this.state.status);
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'pmo') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }

    if (!_.isEqual(this.props.pmoProjects.itemData, nextProps.pmoProjects.itemData) || !_.isEqual(nextProps.pmoProjects.itemData, this.state.projectData)) {
      this.setState({
        projectData: nextProps.pmoProjects.itemData,
      });
    }
  }

  componentWillUnmount() {
    this.props.pmoIssueManagementHideForm();
    this.props.pmoIssueManagementShowList();
  }

  getTitle() {
    if (!this.state.projectData) {
      return null;
    }

    if (this.props.pmoIssueManagement.showList) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={() => { browserHistory.push(`/pmo/project/${this.state.projectId}`); }}>
                <span className="fa fa-arrow-circle-o-left" />
                &nbsp;Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={8} className="title-section">
              <div className="title-box">
                <h1 className="box-title">
                  Issue Management
                </h1>
                <h2>
                  {this.state.projectData.project}
                </h2>
              </div>
              <Button bsSize="small" bsStyle="success" onClick={this.props.pmoIssueManagementShowForm.bind(this, "newItem")}>
                Add Issue
              </Button>
            </Col>
            <Col xs={4} className="action-section">
              <SmartForm
                schema={[
                  {
                    type: 'default',
                    fields: [
                      {
                        type: 'select',
                        name: 'active',
                        label: 'Status:',
                        value: this.state.status,
                        options: [
                          {
                            name: 'All',
                            value: '9999',
                          },
                          {
                            name: 'Open',
                            value: '0',
                          },
                          {
                            name: 'Closed',
                            value: '1',
                          },
                        ],
                        onChange: this.changeStatus.bind(this),
                      },
                    ],
                  },
                ]}
                inline
                buttons={[]}
              />
            </Col>
          </Row>
        </div>
      );
    }

    if (this.props.pmoIssueManagement.showForm) {
      return (
        <div>
          <Row>
            <Col xs={12}>
              <Button bsSize="xsmall" onClick={this.props.pmoIssueManagementShowList.bind(this)}>
                <span className="fa fa-arrow-circle-o-left" />
                &nbsp;Back
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="title-section">
              <div className="title-box">
                <h1 className="box-title">
                  {this.props.pmoIssueManagement.itemId ? 'Edit Issue' : 'Add New Issue'}
                </h1>
                <h2>
                  {this.state.projectData.project}
                </h2>
              </div>
            </Col>
          </Row>
        </div>
      );
    }

    return null;
  }

  changeStatus(value) {
    this.setState({ status: value });
    this.props.pmoIssueManagementList(this.state.projectId, value);

    cookie.save('pmo_issue_management_filters', JSON.stringify({ status: value }), { path: '/' });
  }

  render() {
    const {
      permissions,
      projectId,
    } = this.state;

    if (
      !projectId
      || !permissions
      || (
        !permissions.can_read
        && !permissions.can_create
        && !permissions.can_delete
        && !permissions.can_update
        && !permissions.limit_to_own_user
      )
    ) {
      if (permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className={`pmo-content ${this.props.pmoIssueManagement.showList ? 'minWidth' : ''}`}>
        { this.props.pmoIssueManagement.inProgress || this.props.pmoProjects.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this.getTitle()}
          </div>
          <div className="box-content">
            {this.props.pmoIssueManagement.showList ? <PMOIssueManagementList permissions={this.state.permissions} listStatus={this.state.status} projectId={this.state.projectId} {...this.props} /> : ''}
            {this.props.pmoIssueManagement.showForm ? <PMOIssueManagementForm permissions={this.state.permissions} listStatus={this.state.status} projectId={this.state.projectId} {...this.props} /> : ''}
          </div>
        </div>
      </section>
    );
  }
}

export default PMOIssueManagement;
