import React, {Component} from 'react';
import SmartForm from '../form/base.jsx'
import { Col, Row} from 'react-bootstrap';
//import moment from 'moment'

class StockOptionsForm extends Component{

  componentWillMount() {

    if(this.props.stockOptions.itemId){
      this.props.stockOptionsItem(this.props.stockOptions.itemId)
    }

    this.props.userList()
  }

  componentWillReceiveProps(nextProps) {
    if((nextProps.stockOptions.addSuccess && nextProps.stockOptions.addSuccess !== this.props.stockOptions.addSuccess) || (nextProps.stockOptions.editSuccess && nextProps.stockOptions.editSuccess !== this.props.stockOptions.editSuccess)){
      this.props.stockOptionsShowList()
    }
  }

  render(){
    let itemData, users = []

    if(this.props.user.data){
      for(let i=0; i<this.props.user.data.length; i++){


          users.push({
            name: this.props.user.data[i].name,
            value: this.props.user.data[i].id
          })

      }
    }

    if(this.props.stockOptions.itemId){
      itemData = this.props.stockOptions.itemData
    }

    const schema = [
      {
        type: "default",
        fields: [
          {
            type: "select",
            name: "user_id",
            label: "Name:",
            value: itemData?itemData.user_id:'',
            options: users,
            placeholder: "Select User"
          },
          {
            type: "calendar",
            name: "grant_date",
            label: "Grant Date:",
            value: itemData?itemData.grant_date:'',
            dateFormat: "YYYY-MM-DD",
            //viewMode: "months",

          },
          {
            type: "number",
            name: "grant_amount",
            label: "Grant Amount:",
            value: itemData?itemData.grant_amount:'',
          },
          {
            type: "number",
            name: "exercise_price",
            label: "Exercise Price:",
            value: itemData?itemData.exercise_price:'',
          },
          {
            type: "number",
            name: "grant_percent_year_0",
            label: "Grant Percent Year 0 (%):",
            value: itemData?itemData.grant_percent_year_0:'',
            maxValue: 100,
            minValue: 0
          },
          {
            type: "number",
            name: "grant_percent_year_1",
            label: "Grant Percent Year 1 (%):",
            value: itemData?itemData.grant_percent_year_1:'',
            maxValue: 100,
            minValue: 0
          },
          {
            type: "number",
            name: "grant_percent_year_2",
            label: "Grant Percent Year 2 (%):",
            value: itemData?itemData.grant_percent_year_2:'',
            maxValue: 100,
            minValue: 0
          },
          {
            type: "number",
            name: "grant_percent_year_3",
            label: "Grant Percent Year 3 (%):",
            value: itemData?itemData.grant_percent_year_3:'',
            maxValue: 100,
            minValue: 0
          },
          {
            type: "number",
            name: "grant_percent_year_4",
            label: "Grant Percent Year 4 (%):",
            value: itemData?itemData.grant_percent_year_4:'',
            maxValue: 100,
            minValue: 0
          },
          {
            type: "number",
            name: "grant_percent_year_5",
            label: "Grant Percent Year 5 (%):",
            value: itemData?itemData.grant_percent_year_5:'',
            maxValue: 100,
            minValue: 0
          },
        ]
      }
    ]
    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this._submit.bind(this)}
              showError={this.props.stockOptions.error && this.props.stockOptions.errorText}
              errorMessage={ this.props.stockOptions.errorText }
              onReset={this.props.stockOptionsShowList.bind(this)}
              loading={this.props.stockOptions.inProgress } />
          </Col>
        </Row>


      </section>
    )
  }

  _submit(data){
    // data.grant_date = moment(data.grant_date).startOf("month").format("YYYY-MM-DD")

    if(this.props.stockOptions.itemId){
      this.props.stockOptionsUpdateItem(this.props.stockOptions.itemId, data)
    }else{
      this.props.stockOptionsAddItem(data)
    }
  }


}

export default StockOptionsForm
