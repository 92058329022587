import React, { Component } from "react";
import Announcements from "../announcements/base.jsx";


class RightSidebar extends Component {
  render() {
    return (
      <div className="box box-solid right-pane">
        <Announcements {...this.props}/>
      </div>
    );
  }
}

export default RightSidebar;
