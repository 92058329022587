import makeActionCreator from '../utils/makeActionCreator'
import fetch from 'isomorphic-fetch'
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router'

import * as API       from '../utils/config-api'
import * as endpoints from './endpoints'
import { logout } from '../auth/list';


export const STAFFING_PENDING = 'STAFFING_PENDING'
export const staffingPending = makeActionCreator(STAFFING_PENDING)

export const STAFFING_REJECTED = 'STAFFING_REJECTED'
export const staffingRejected = makeActionCreator(STAFFING_REJECTED)

export const STAFFING_SHOW_LIST= 'STAFFING_SHOW_LIST'
export const staffingShowList = makeActionCreator(STAFFING_SHOW_LIST)

export const STAFFING_HIDE_LIST = 'STAFFING_HIDE_LIST'
export const staffingHideList = makeActionCreator(STAFFING_HIDE_LIST)

export const STAFFING_SHOW_INDEX_AVAILABILITY= 'STAFFING_SHOW_INDEX_AVAILABILITY'
export const staffingShowIndexAvailability = makeActionCreator(STAFFING_SHOW_INDEX_AVAILABILITY)

export const STAFFING_HIDE_INDEX_AVAILABILITY = 'STAFFING_HIDE_INDEX_AVAILABILITY'
export const staffingHideIndexAvailability = makeActionCreator(STAFFING_HIDE_INDEX_AVAILABILITY)

export const STAFFING_SHOW_FORM= 'STAFFING_SHOW_FORM'
export const staffingShowForm = makeActionCreator(STAFFING_SHOW_FORM, 'itemId')

export const STAFFING_HIDE_FORM = 'STAFFING_HIDE_FORM'
export const staffingHideForm = makeActionCreator(STAFFING_HIDE_FORM)

export const STAFFING_SHOW_ITEM = 'STAFFING_SHOW_ITEM'
export const staffingShowItem = makeActionCreator(STAFFING_SHOW_ITEM, 'itemId')


// GET Index All

export const STAFFING_LIST_FULFILLED = 'STAFFING_LIST_FULFILLED'
export const staffingListFulfilled = makeActionCreator(STAFFING_LIST_FULFILLED, 'data')

export const STAFFING_LIST_ERROR = 'STAFFING_LIST_ERROR'
export const staffingListError = makeActionCreator(STAFFING_LIST_ERROR, 'message')

export function staffingList() {
  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(staffingPending())
        return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.indexAll.version}${endpoints.indexAll.path}`, {
          method  : endpoints.indexAll.method,
          headers : {
            'Authorization' : `Bearer ${currentSession.token}`,
            'Accept': 'application/json'
          }
        })
        .then( API.checkStatus )
        .then( API.parseJSON )
        .then( json => dispatch(staffingListFulfilled(json.data)) )
        .catch( err => {
          // Dispatch the error action with error information
          dispatch(staffingRejected(err))
        })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));
    })
  }
}

// GET Index Availability
export const STAFFING_INDEX_AVAILABILITY_FULFILLED = 'STAFFING_INDEX_AVAILABILITY_FULFILLED'
export const staffingIndexAvailabilityFulfilled = makeActionCreator(STAFFING_INDEX_AVAILABILITY_FULFILLED, 'data')

export function staffingGetIndexAvailability(withTargetReached = 0) {
  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(staffingPending())
        return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.indexAvailability.version}${endpoints.indexAvailability.path}?withTargetReached=${withTargetReached}`, {
          method  : endpoints.indexAvailability.method,
          headers : {
            'Authorization' : `Bearer ${currentSession.token}`,
            'Accept': 'application/json'
          }
        })
        .then( API.checkStatus )
        .then( API.parseJSON )
        .then( json => dispatch(staffingIndexAvailabilityFulfilled(json.data)) )
        .catch( err => {
          // Dispatch the error action with error information
          dispatch(staffingRejected(err))
        })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));
    })
  }
}


//Add Item

export const STAFFING_ITEM_ADD_FULFILLED = 'STAFFING_ITEM_ADD_FULFILLED'
export const staffingItemAddFulfilled = makeActionCreator(STAFFING_ITEM_ADD_FULFILLED, 'data')

export const STAFFING_ITEM_ADD_ERROR = 'STAFFING_ITEM_ADD_ERROR'
export const staffingItemAddError = makeActionCreator(STAFFING_ITEM_ADD_ERROR, 'message')

export function staffingAddItem(data) {
  const formData = new FormData()
  const project_id = data['project_id']
  const user_id = data['user_id']

  formData.append('week1', data['week1']);
  formData.append('week2', data['week2']);
  formData.append('week3', data['week3']);
  formData.append('week4', data['week4']);
  formData.append('week5', data['week5']);
  formData.append('week6', data['week6']);
  formData.append('week7', data['week7']);
  formData.append('week8', data['week8']);

  if (data['repeat_until_date']) {
    formData.append('repeat_until_date', data['repeat_until_date']);
    formData.append('repeat_until_value', data['repeat_until_value']);
  }

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(staffingPending())
        return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addItem.version}${endpoints.addItem.path.replace(":user_id", user_id).replace(":project_id", project_id)}`, {
          method  : endpoints.addItem.method,
          headers : {
            'Authorization' : `Bearer ${currentSession.token}`,
            'Accept': 'application/json'
          },
          body: formData
        })
        .then( API.checkStatus )
        .then( API.parseJSON )
        .then( json => {
          if(json.status === "ok"){
            dispatch(staffingItemAddFulfilled(json.data))
          }else{
            dispatch(staffingItemAddError(json.message))
          }
        } )
        .catch( err => {
          // Dispatch the error action with error information
          dispatch(staffingRejected(err))
        })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));
    })
  }
}

//Update Item

export const STAFFING_ITEM_UPDATE_FULFILLED = 'STAFFING_ITEM_UPDATE_FULFILLED';
export const staffingItemUpdateFulfilled = makeActionCreator(STAFFING_ITEM_UPDATE_FULFILLED, 'data');

export const STAFFING_ITEM_UPDATE_ERROR = 'STAFFING_ITEM_UPDATE_ERROR';
export const staffingItemUpdateError = makeActionCreator(STAFFING_ITEM_UPDATE_ERROR, 'message');

export function staffingUpdateItem(project_id, user_id, data) {
  let formData = new FormData();
  formData.append('_method', 'PUT')
  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });


  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(staffingPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateItem.version}${endpoints.updateItem.path.replace(":user_id", user_id).replace(":project_id", project_id)}`, {
              method  : endpoints.updateItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              },
              body: formData
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
              if(json.status === "ok"){
                dispatch(staffingItemUpdateFulfilled(json.data))
              }else{
                dispatch(staffingItemUpdateError(json.message))
              }
          } )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(staffingRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}

//Delete Item

export const STAFFING_ITEM_DELETE_FULFILLED = 'STAFFING_ITEM_DELETE_FULFILLED'
export const staffingItemDeleteFulfilled = makeActionCreator(STAFFING_ITEM_DELETE_FULFILLED, 'data')

export const STAFFING_ITEM_DELETE_ERROR = 'STAFFING_ITEM_DELETE_ERROR'
export const staffingItemDeleteError = makeActionCreator(STAFFING_ITEM_DELETE_ERROR, 'message')

export function staffingDeleteItem(itemId, userId) {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(staffingPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteItem.version}${endpoints.deleteItem.path.replace(":project_id", itemId).replace(":user_id", userId)}`, {
              method  : endpoints.deleteItem.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => {
            if(json.status === "ok"){
              dispatch(staffingItemDeleteFulfilled(json.data))
              dispatch(staffingList())
            }else{
              dispatch(staffingItemDeleteError(json.message))
            }

          })
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(staffingRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })





  }
}
