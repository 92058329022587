import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';

export const PMO_PROJECT_PENDING = 'PMO_PROJECT_PENDING';
export const pmoProjectsPending = makeActionCreator(PMO_PROJECT_PENDING);

export const PMO_PROJECT_REJECTED = 'PMO_PROJECT_REJECTED';
export const pmoProjectsRejected = makeActionCreator(PMO_PROJECT_REJECTED, 'error');

export const PMO_PROJECT_SHOW_LIST = 'PMO_PROJECT_SHOW_LIST';
export const pmoProjectsShowList = makeActionCreator(PMO_PROJECT_SHOW_LIST);

export const PMO_PROJECT_HIDE_LIST = 'PMO_PROJECT_HIDE_LIST';
export const pmoProjectsHideList = makeActionCreator(PMO_PROJECT_HIDE_LIST);

export const PMO_PROJECT_SHOW_FORM = 'PMO_PROJECT_SHOW_FORM';
export const pmoProjectsShowForm = makeActionCreator(PMO_PROJECT_SHOW_FORM, 'itemId');

export const PMO_PROJECT_HIDE_FORM = 'PMO_PROJECT_HIDE_FORM';
export const pmoProjectsHideForm = makeActionCreator(PMO_PROJECT_HIDE_FORM);

export const PMO_PROJECT_SHOW_ITEM = 'PMO_PROJECT_SHOW_ITEM';
export const pmoProjectsShowItem = makeActionCreator(PMO_PROJECT_SHOW_ITEM, 'itemId');


// Show Index

export const PMO_PROJECT_LIST_FULFILLED = 'PMO_PROJECT_LIST_FULFILLED';
export const pmoProjectsListFulfilled = makeActionCreator(PMO_PROJECT_LIST_FULFILLED, 'data');

export const PMO_PROJECT_LIST_ERROR = 'PMO_PROJECT_LIST_ERROR';
export const pmoProjectsListError = makeActionCreator(PMO_PROJECT_LIST_ERROR, 'message');

export function pmoProjectsList(status = 9999) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoProjectsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}?status=${status}`, {
        method: endpoints.index.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoProjectsListFulfilled(json.data));
          } else {
            dispatch(pmoProjectsListError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoProjectsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Show Item

export const PMO_PROJECT_ITEM_FULFILLED = 'PMO_PROJECT_ITEM_FULFILLED';
export const pmoProjectsItemFulfilled = makeActionCreator(PMO_PROJECT_ITEM_FULFILLED, 'data');

export const PMO_PROJECT_ITEM_ERROR = 'PMO_PROJECT_ITEM_ERROR';
export const pmoProjectsItemError = makeActionCreator(PMO_PROJECT_ITEM_ERROR, 'message');

export function pmoProjectsItem(itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoProjectsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getItem.version}${endpoints.getItem.path.replace(':pmo_project_id', itemId)}`, {
        method: endpoints.getItem.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoProjectsItemFulfilled(json.data));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoProjectsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}


// Add Item
export const PMO_PROJECT_ITEM_ADD_FULFILLED = 'PMO_PROJECT_ITEM_ADD_FULFILLED';
export const pmoProjectsItemAddFulfilled = makeActionCreator(PMO_PROJECT_ITEM_ADD_FULFILLED, 'data');

export const PMO_PROJECT_ITEM_ADD_ERROR = 'PMO_PROJECT_ITEM_ADD_ERROR';
export const pmoProjectsItemAddError = makeActionCreator(PMO_PROJECT_ITEM_ADD_ERROR, 'message');

export function pmoProjectsAddItem(data, status) {
  const formData = new FormData();

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoProjectsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addItem.version}${endpoints.addItem.path}`, {
        method: endpoints.addItem.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoProjectsItemAddFulfilled(json.data));
            dispatch(pmoProjectsList(status));
          } else {
            dispatch(pmoProjectsItemAddError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoProjectsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Update Item

export const PMO_PROJECT_ITEM_UPDATE_FULFILLED = 'PMO_PROJECT_ITEM_UPDATE_FULFILLED';
export const pmoProjectsItemUpdateFulfilled = makeActionCreator(PMO_PROJECT_ITEM_UPDATE_FULFILLED, 'data');

export const PMO_PROJECT_ITEM_UPDATE_ERROR = 'PMO_PROJECT_ITEM_UPDATE_ERROR';
export const pmoProjectsItemUpdateError = makeActionCreator(PMO_PROJECT_ITEM_UPDATE_ERROR, 'message');

export function pmoProjectsUpdateItem(itemId, data, status) {
  const formData = new FormData();
  formData.append('_method', 'PUT');

  Object.keys(data).map((key) => {
    formData.append(key, data[key]);
    return key;
  });

  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoProjectsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateItem.version}${endpoints.updateItem.path.replace(':pmo_project_id', itemId)}`, {
        method: endpoints.updateItem.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
        body: formData,
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoProjectsItemUpdateFulfilled(json.data));
            dispatch(pmoProjectsList(status));
          } else {
            dispatch(pmoProjectsItemUpdateError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoProjectsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Delete Item

export const PMO_PROJECT_ITEM_DELETE_FULFILLED = 'PMO_PROJECT_ITEM_DELETE_FULFILLED'
export const pmoProjectsItemDeleteFulfilled = makeActionCreator(PMO_PROJECT_ITEM_DELETE_FULFILLED, 'data')

export const PMO_PROJECT_ITEM_DELETE_ERROR = 'PMO_PROJECT_ITEM_DELETE_ERROR'
export const pmoProjectsItemDeleteError = makeActionCreator(PMO_PROJECT_ITEM_DELETE_ERROR, 'message')

export function pmoProjectsDeleteItem(itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoProjectsPending());

      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteItem.version}${endpoints.deleteItem.path.replace(":pmo_project_id", itemId)}`, {
        method: endpoints.deleteItem.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        .then(API.parseJSON)
        .then((json) => {
          if (json.status === 'ok') {
            dispatch(pmoProjectsItemDeleteFulfilled(json.data));
            dispatch(pmoProjectsList());
          } else {
            dispatch(pmoProjectsItemDeleteError(json.message));
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoProjectsRejected(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}

// Export project

export const PMO_PROJECT_EXPORT_PPTX_FULFILLED = 'PMO_PROJECT_EXPORT_PPTX_FULFILLED';
export const pmoProjectsExportPPTXFulfilled = makeActionCreator(PMO_PROJECT_EXPORT_PPTX_FULFILLED, 'data');

export const PMO_PROJECT_EXPORT_PPTX_ERROR = 'PMO_PROJECT_EXPORT_PPTX_ERROR';
export const pmoProjectsExportPPTXError = makeActionCreator(PMO_PROJECT_EXPORT_PPTX_ERROR, 'data');

export function pmoProjectsExportPPTX(itemId) {
  return (dispatch) => {
    sessionService.loadSession().then((currentSession) => {
      dispatch(pmoProjectsPending());
      
      return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.exportPPTX.version}${endpoints.exportPPTX.path.replace(':pmo_project_id', itemId)}`, {
        method: endpoints.exportPPTX.method,
        headers: {
          Authorization: `Bearer ${currentSession.token}`,
          // Accept: 'application/json',
        },
      })
        .then(API.checkStatus)
        
        // .then((json) => {
        //   if (json.status === 'ok') {
        //     dispatch(pmoProjectsExportPPTXFulfilled(json.data));
        //     // dispatch(pmoProjectsList());
          // } else {
          //   dispatch(pmoProjectsExportPPTXError(json));
          //   // dispatch(pmoProjectsItemDeleteError(json.message));
          // }
        // })
        .then(resp => resp.blob())
        .then((blob) => {
          if (blob) {
            dispatch(pmoProjectsExportPPTXFulfilled(blob));
          } else {
            dispatch(pmoProjectsExportPPTXError());
          }
        })
        .catch((err) => {
          // Dispatch the error action with error information
          dispatch(pmoProjectsRejected(err));
          dispatch(pmoProjectsExportPPTXError(err));
        });
    })
      .catch(() => {
        dispatch(logout(() => browserHistory.push('/login')));
      });
  };
}
