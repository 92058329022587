export const indexAll = {
    path   : '/staffing/all',
    method : 'GET',
    version: '/v1'
}

export const indexAvailability = {
  path   : '/staffing/availability',
  method : 'GET',
  version: '/v1'
}

export const addItem = {
  path   : '/staffing/:user_id/projects/:project_id/',
  method : 'POST',
  version: '/v1'
}

export const updateItem = {
  path   : '/staffing/:user_id/projects/:project_id/',
  method : 'POST',
  version: '/v1'
}

export const deleteItem = {
  path   : '/staffing/:user_id/projects/:project_id/',
  method : 'DELETE',
  version: '/v1'
}
