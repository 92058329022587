import fetch from 'isomorphic-fetch';
import { sessionService } from 'redux-react-session';
import { browserHistory } from 'react-router';

import makeActionCreator from '../utils/makeActionCreator';
import * as API from '../utils/config-api';
import * as endpoints from './endpoints';
import { logout } from '../auth/list';

export const INTERNAL_PROJECTS_PENDING = 'INTERNAL_PROJECTS_PENDING'
export const internalProjectsPending = makeActionCreator(INTERNAL_PROJECTS_PENDING)

export const INTERNAL_PROJECTS_REJECTED = 'INTERNAL_PROJECTS_REJECTED'
export const internalProjectsRejected = makeActionCreator(INTERNAL_PROJECTS_REJECTED)

export const INTERNAL_PROJECTS_SHOW_LIST= 'INTERNAL_PROJECTS_SHOW_LIST'
export const internalProjectsShowList = makeActionCreator(INTERNAL_PROJECTS_SHOW_LIST)

export const INTERNAL_PROJECTS_HIDE_LIST = 'INTERNAL_PROJECTS_HIDE_LIST'
export const internalProjectsHideList = makeActionCreator(INTERNAL_PROJECTS_HIDE_LIST)

export const INTERNAL_PROJECTS_SHOW_FORM= 'INTERNAL_PROJECTS_SHOW_FORM'
export const internalProjectsShowForm = makeActionCreator(INTERNAL_PROJECTS_SHOW_FORM, 'itemId')

export const INTERNAL_PROJECTS_HIDE_FORM = 'INTERNAL_PROJECTS_HIDE_FORM'
export const internalProjectsHideForm = makeActionCreator(INTERNAL_PROJECTS_HIDE_FORM)

export const INTERNAL_PROJECTS_SHOW_ITEM = 'INTERNAL_PROJECTS_SHOW_ITEM'
export const internalProjectsShowItem = makeActionCreator(INTERNAL_PROJECTS_SHOW_ITEM, 'itemId')


// Show Index

export const INTERNAL_PROJECTS_LIST_FULFILLED = 'INTERNAL_PROJECTS_LIST_FULFILLED'
export const internalProjectsListFulfilled = makeActionCreator(INTERNAL_PROJECTS_LIST_FULFILLED, 'data')

export const INTERNAL_PROJECTS_LIST_ERROR = 'INTERNAL_PROJECTS_LIST_ERROR'
export const internalProjectsListError = makeActionCreator(INTERNAL_PROJECTS_LIST_ERROR, 'message')

export function internalProjectsList() {

  return dispatch => {
    sessionService.loadSession().then(currentSession => {
      dispatch(internalProjectsPending())

          return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.index.version}${endpoints.index.path}`, {
              method  : endpoints.index.method,
              headers : {
                  'Authorization' : `Bearer ${currentSession.token}`,
                  'Accept': 'application/json'
              }
          })
          .then( API.checkStatus )
          .then( API.parseJSON )
          .then( json => dispatch(internalProjectsListFulfilled(json.data)) )
          .catch( err => {
              // Dispatch the error action with error information
              dispatch(internalProjectsRejected(err))
          })
    })
    .catch(err => {
      dispatch(logout(() => browserHistory.push('/login')));

    })



  }
}


//Show Item

export const INTERNAL_PROJECTS_ITEM_FULFILLED = 'INTERNAL_PROJECTS_ITEM_FULFILLED'
export const internalProjectsItemFulfilled = makeActionCreator(INTERNAL_PROJECTS_ITEM_FULFILLED, 'data')

export const INTERNAL_PROJECTS_ITEM_ERROR = 'INTERNAL_PROJECTS_ITEM_ERROR'
export const internalProjectsItemError = makeActionCreator(INTERNAL_PROJECTS_ITEM_ERROR, 'message')

export function internalProjectsItem(itemId) {

  return dispatch => {
      sessionService.loadSession().then(currentSession => {
        dispatch(internalProjectsPending())

            return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.getItem.version}${endpoints.getItem.path.replace(":internal_project_id", itemId)}`, {
                method  : endpoints.getItem.method,
                headers : {
                    'Authorization' : `Bearer ${currentSession.token}`,
                    'Accept': 'application/json'
                }
            })
            .then( API.checkStatus )
            .then( API.parseJSON )
            .then( json => dispatch(internalProjectsItemFulfilled(json.data)) )
            .catch( err => {
                // Dispatch the error action with error information
                dispatch(internalProjectsRejected(err))
            })
      })
      .catch(err => {
        dispatch(logout(() => browserHistory.push('/login')));

      })





  }
}


//Add Item

export const INTERNAL_PROJECTS_ITEM_ADD_FULFILLED = 'INTERNAL_PROJECTS_ITEM_ADD_FULFILLED'
export const internalProjectsItemAddFulfilled = makeActionCreator(INTERNAL_PROJECTS_ITEM_ADD_FULFILLED, 'data')

export const INTERNAL_PROJECTS_ITEM_ADD_ERROR = 'INTERNAL_PROJECTS_ITEM_ADD_ERROR'
export const internalProjectsItemAddError = makeActionCreator(INTERNAL_PROJECTS_ITEM_ADD_ERROR, 'message')

export function internalProjectsAddItem(data) {
  let formData = new FormData()

  Object.keys(data).map((key)=> {
    formData.append(key, data[key]);
    return key;
  });

  return dispatch => {
      sessionService.loadSession().then(currentSession => {
        dispatch(internalProjectsPending())

            return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.addItem.version}${endpoints.addItem.path}`, {
                method  : endpoints.addItem.method,
                headers : {
                    'Authorization' : `Bearer ${currentSession.token}`,
                    'Accept': 'application/json'
                },
                body: formData
            })
            .then( API.checkStatus )
            .then( API.parseJSON )
            .then( json => {
                if(json.status === "ok"){
                  dispatch(internalProjectsItemAddFulfilled(json.data))
                }else{
                  dispatch(internalProjectsItemAddError(json.message))
                }
            } )
            .catch( err => {
                // Dispatch the error action with error information
                dispatch(internalProjectsRejected(err))
            })
      })
      .catch(err => {
        dispatch(logout(() => browserHistory.push('/login')));

      })



  }
}

//Update Item

export const INTERNAL_PROJECTS_ITEM_UPDATE_FULFILLED = 'INTERNAL_PROJECTS_ITEM_UPDATE_FULFILLED'
export const internalProjectsItemUpdateFulfilled = makeActionCreator(INTERNAL_PROJECTS_ITEM_UPDATE_FULFILLED, 'data')

export const INTERNAL_PROJECTS_ITEM_UPDATE_ERROR = 'INTERNAL_PROJECTS_ITEM_UPDATE_ERROR'
export const internalProjectsItemUpdateError = makeActionCreator(INTERNAL_PROJECTS_ITEM_UPDATE_ERROR, 'message')

export function internalProjectsUpdateItem(itemId, data) {
  let formData = new FormData()
  formData.append('_method', 'PUT')

  Object.keys(data).map((key)=> {
    formData.append(key, data[key]);
    return key;
  });


  return dispatch => {
      sessionService.loadSession().then(currentSession => {
        dispatch(internalProjectsPending())

            return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.updateItem.version}${endpoints.updateItem.path.replace(":internal_project_id", itemId)}`, {
                method  : endpoints.updateItem.method,
                headers : {
                    'Authorization' : `Bearer ${currentSession.token}`,
                    'Accept': 'application/json'
                },
                body: formData
            })
            .then( API.checkStatus )
            .then( API.parseJSON )
            .then( json => {
                if(json.status === "ok"){
                  dispatch(internalProjectsItemUpdateFulfilled(json.data))
                }else{
                  dispatch(internalProjectsItemUpdateError(json.message))
                }
            } )
            .catch( err => {
                // Dispatch the error action with error information
                dispatch(internalProjectsRejected(err))
            })
      })
      .catch(err => {
        dispatch(logout(() => browserHistory.push('/login')));

      })



  }
}

//Delete Item

export const INTERNAL_PROJECTS_ITEM_DELETE_FULFILLED = 'INTERNAL_PROJECTS_ITEM_DELETE_FULFILLED'
export const internalProjectsItemDeleteFulfilled = makeActionCreator(INTERNAL_PROJECTS_ITEM_DELETE_FULFILLED, 'data')

export const INTERNAL_PROJECTS_ITEM_DELETE_ERROR = 'INTERNAL_PROJECTS_ITEM_DELETE_ERROR'
export const internalProjectsItemDeleteError = makeActionCreator(INTERNAL_PROJECTS_ITEM_DELETE_ERROR, 'message')

export function internalProjectsDeleteItem(itemId) {

  return dispatch => {
      sessionService.loadSession().then(currentSession => {
        dispatch(internalProjectsPending())

            return fetch(`${API.config.domain}${API.config.apiPreUrl}${endpoints.deleteItem.version}${endpoints.deleteItem.path.replace(":internal_project_id", itemId)}`, {
                method  : endpoints.deleteItem.method,
                headers : {
                    'Authorization' : `Bearer ${currentSession.token}`,
                    'Accept': 'application/json'
                }
            })
            .then( API.checkStatus )
            .then( API.parseJSON )
            .then( json => {
              if(json.status === "ok"){
                dispatch(internalProjectsItemDeleteFulfilled(json.data))
                dispatch(internalProjectsList())
              }else{
                dispatch(internalProjectsItemDeleteError(json.message))
              }

            })
            .catch( err => {
                // Dispatch the error action with error information
                dispatch(internalProjectsRejected(err))
            })
      })
      .catch(err => {
        dispatch(logout(() => browserHistory.push('/login')));

      })
  }
}
