import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import _ from 'lodash';

import SmartForm from '../../form/base.jsx';

class PMOChangeControlForm extends Component {
  constructor(props) {
    super(props);
    const {
      itemData,
    } = this.props.pmoChangeControl;

    this.submit = this.submit.bind(this);
    this.state = {
      isApproved: itemData ? itemData.is_approved.toString() : '0',
      approvedAt: itemData && itemData.approved_at ? itemData.approved_at : moment(),
      approvedBy: itemData && itemData.approved_by ? itemData.approved_by : '',
    };
  }

  componentWillMount() {
    if (this.props.pmoChangeControl.itemId) {
      this.props.pmoChangeControlItem(this.props.projectId, this.props.pmoChangeControl.itemId);
    }

    this.props.userList();
  }

  componentWillReceiveProps(nextProps) {
    if ((nextProps.pmoChangeControl.addSuccess && nextProps.pmoChangeControl.addSuccess !== this.props.pmoChangeControl.addSuccess) || (nextProps.pmoChangeControl.editSuccess && nextProps.pmoChangeControl.editSuccess !== this.props.pmoChangeControl.editSuccess)) {
      this.props.pmoChangeControlShowList();
    }

    if (!_.isEqual(this.props.pmoChangeControl.itemData, nextProps.pmoChangeControl.itemData)) {
      const {
        itemData,
      } = nextProps.pmoChangeControl;

      this.setState({
        isApproved: itemData ? itemData.is_approved.toString() : '0',
        approvedAt: itemData && itemData.approved_at ? itemData.approved_at : moment(),
        approvedBy: itemData && itemData.approved_by ? itemData.approved_by : '',
      });
    }
  }

  submit(data) {
    if (this.props.pmoChangeControl.itemId) {
      this.props.pmoChangeControlUpdateItem(this.props.projectId, this.props.pmoChangeControl.itemId, data, this.props.listStatus);
    } else {
      this.props.pmoChangeControlAddItem(this.props.projectId, data, this.props.listStatus);
    }
  }

  render() {
    let itemData;
    if (this.props.pmoChangeControl.itemId) {
      itemData = this.props.pmoChangeControl.itemData;
    }

    const schema = [{
      type: 'default',
      fields: [
        {
          type: 'text',
          name: 'area',
          label: 'Area:',
          value: itemData ? itemData.area : '',
        },
        {
          type: 'textarea',
          name: 'description',
          label: 'Description:',
          value: itemData && itemData.description ? itemData.description : '',
        },
        {
          type: 'calendar',
          name: 'manually_created_at',
          label: 'Created On:',
          value: itemData && itemData.manually_created_at ? itemData.manually_created_at : moment().format('YYYY-MM-DD'),
        },
        {
          type: 'text',
          name: 'created_by',
          label: 'Created By:',
          value: itemData && itemData.created_by ? itemData.created_by : '',
        },
        {
          type: 'text',
          name: 'assigned_to',
          label: 'Assigned To:',
          value: itemData && itemData.assigned_to ? itemData.assigned_to : '',
        },
        {
          type: 'calendar',
          name: 'due_date',
          label: 'Due Date:',
          value: itemData && itemData.due_date ? itemData.due_date : '',
        },
        {
          type: 'radios',
          name: 'is_approved',
          label: 'Approved:',
          value: this.state.isApproved,
          options: [
            { value: '1', text: 'Yes' },
            { value: '0', text: 'No' },
          ],
          onChange: (isApproved) => {
            this.setState({ isApproved: isApproved.toString() });
          },
        },
        this.state.isApproved === '1' ? {
          type: 'calendar',
          name: 'approved_at',
          label: 'Approved On:',
          value: this.state.approvedAt,
          onChange: (date) => {
            this.setState({ approvedAt: date });
          },
        } : null,
        this.state.isApproved === '1' ? {
          type: 'text',
          name: 'approved_by',
          label: 'Approved By:',
          value: this.state.approvedBy,
          onChange: (name) => {
            this.setState({ approvedBy: name });
          },
        } : null,
        {
          type: 'textarea',
          name: 'comments',
          label: 'Comments:',
          value: itemData ? itemData.comments : '',
        },
      ],
    }];

    return (
      <section className="section-content">
        <Row>
          <Col xs={12} md={8} lg={6}>
            <SmartForm
              schema={schema}
              onSave={this.submit}
              showError={this.props.pmoChangeControl.error && this.props.pmoChangeControl.errorText}
              errorMessage={this.props.pmoChangeControl.errorText}
              onReset={this.props.pmoChangeControlShowList.bind(this)}
              loading={this.props.pmoChangeControl.inProgress}
            />
          </Col>
        </Row>
      </section>
    );
  }
}

export default PMOChangeControlForm;
