import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import cookie from 'react-cookies';
import _ from 'lodash';

import InvoicesIssuedList from './list.jsx';
import SystemMsg from '../common/system-msg.jsx';
import 'assets/css/invoices-issued.css';
import SmartForm from '../form/base.jsx';

class Invoices extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'invoices') {
          permissions = area;
        }
      });
    }

    this.state = {
      startDate: moment().startOf('month'),
      endDate: moment().startOf('month'),
      permissions,
    };
  }

  componentWillMount() {
    const filtersCookie = cookie.load('invoices_filters') || {}

    this.setState(
      {
        status: filtersCookie && filtersCookie.status?filtersCookie.status:this.state.status,
        startDate: filtersCookie && filtersCookie.startDate?moment(filtersCookie.startDate):this.state.startDate,
        endDate: filtersCookie && filtersCookie.endDate?moment(filtersCookie.endDate):this.state.endDate,
      }
    )
  }

  componentDidMount() {
    this.props.invoicesIssuedList(this.state.startDate.format("YYYY-MM-DD"), moment(this.state.endDate).endOf("month").format("YYYY-MM-DD"))
  }

  componentWillReceiveProps(nextProps) {
    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'invoices') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  componentWillUnmount() {
    this.props.invoicesIssuedHideForm()
    this.props.invoicesIssuedShowList()
  }

  render() {
    if (
      !this.state.permissions
      || (
        !this.state.permissions.can_read
        && !this.state.permissions.can_create
        && !this.state.permissions.can_delete
        && !this.state.permissions.can_update
        && !this.state.permissions.limit_to_own_user
      )
    ) {
      if (this.state.permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    return (
      <section className={`invoices-issued-content ${this.props.invoicesIssued.showList ? 'minWidth' : ''}`}>
        { this.props.invoicesIssued.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="box">
          <div className="box-header with-border">
            {this._getTitle()}
          </div>
          <div className="box-content">
            {this.props.invoicesIssued.showList?<InvoicesIssuedList {...this.props} />:""}
          </div>
        </div>
      </section>
    )
  }

  _getTitle(){
      return (
        <Row>
          <Col xs={4}  className="title-section">
            <h1 className="box-title">Invoices Issued</h1>
          </Col>
          <Col xs={8} className="action-section">
                <SmartForm schema={[
                      {
                        type: "default",
                        fields: [
                          {
                            type: "calendar",
                            name: "start_date",
                            label: "Start Date:",
                            value: this.state.startDate,
                            dateFormat: "YYYY-MM",
                            viewMode: "months",
                            maxDate: moment(this.state.endDate).endOf("month"),
                            onChange: this._changeStartDate.bind(this)
                          },
                          {
                            type: "calendar",
                            name: "end_date",
                            label: "End Date:",
                            value:  this.state.endDate,
                            dateFormat: "YYYY-MM",
                            viewMode: "months",
                            minDate: this.state.startDate,
                            onChange: this._changeEndDate.bind(this)
                          }
                        ]
                      }
                    ]

                  }
                  inline={true}
                  buttons={[]} />
          </Col>
        </Row>
      )
  }

  _changeStartDate(value){
    let startDate = value.startOf("month")
    let filtersCookie = cookie.load('invoices_filters') || {}

    filtersCookie = {
      status: filtersCookie && filtersCookie.status?filtersCookie.status:this.state.status,
      startDate: value,
      endDate: filtersCookie && filtersCookie.endDate?filtersCookie.endDate:this.state.endDate,
      billable: filtersCookie && filtersCookie.billable?filtersCookie.billable:this.state.billable,
    }

    this.setState({startDate: startDate})
    cookie.save('invoices_filters', JSON.stringify(filtersCookie), { path: '/' });
    this.props.invoicesIssuedList(startDate.format("YYYY-MM-DD"), moment(this.state.endDate).endOf("month").format("YYYY-MM-DD"))
  }

  _changeEndDate(value){
    let filtersCookie = cookie.load('invoices_filters') || {}

    filtersCookie = {
      status: filtersCookie && filtersCookie.status?filtersCookie.status:this.state.status,
      startDate: filtersCookie && filtersCookie.startDate?filtersCookie.startDate:this.state.startDate,
      endDate: value,
      billable: filtersCookie && filtersCookie.billable?filtersCookie.billable:this.state.billable,
    }

    this.setState({endDate: value})
    cookie.save('invoices_filters', JSON.stringify(filtersCookie), { path: '/' });
    this.props.invoicesIssuedList(this.state.startDate.format("YYYY-MM-DD"), moment(value).endOf("month").format("YYYY-MM-DD"))
  }

}

export default Invoices
