import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
// import ChartistGraph from 'react-chartist';
import _ from 'lodash';

import SystemMsg from '../common/system-msg.jsx';
import Knob from './knob';
import 'assets/css/dashboard.css';
import 'chartist/dist/chartist.min.css';
import 'chartist/dist/chartist.min.js';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    const { user } = this.props.session;
    let permissions = null;

    if (user && user.role && user.role.permissions) {
      user.role.permissions.forEach((area) => {
        if (area.area === 'dashboard') {
          permissions = area;
        }
      });
    }
    this.state = {
      permissions,
      realizationContainerSize: {
        width: 0,
        height: 0,
      },
      utilizationContainerSize: {
        width: 0,
        height: 0,
      },
      dashboardData: {},
      dashboardLoaded: false,
    };

    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount() {
    this.props.getDashboard();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.dashboard.data && !_.isEqual(this.props.dashboard.data, nextProps.dashboard.data)) ||
      (nextProps.dashboard.data && !_.isEqual(this.state.dashboardData, nextProps.dashboard.data))
    ) {
      this.setState({
        dashboardData: nextProps.dashboard.data,
      });
    }

    if (!_.isEqual(this.props.session, nextProps.session)) {
      const { user } = nextProps.session;
      let permissions = null;

      if (user && user.role && user.role.permissions) {
        user.role.permissions.forEach((area) => {
          if (area.area === 'dashboard') {
            permissions = area;
          }
        });
      }

      this.setState({ permissions });
    }
  }

  componentDidUpdate() {
    if (this.sectionContainer && !this.state.dashboardLoaded) {
      this.setState({
        dashboardLoaded: true,
        realizationContainerSize: {
          width: this.realizationContainer.offsetWidth,
          height: this.realizationContainer.offsetHeight,
        },
        utilizationContainerSize: {
          width: this.utilizationContainer.offsetWidth,
          height: this.utilizationContainer.offsetHeight,
        },
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize() {
    if (this.sectionContainer) {
      this.setState({
        realizationContainerSize: {
          width: this.realizationContainer.offsetWidth,
          height: this.realizationContainer.offsetHeight,
        },
        utilizationContainerSize: {
          width: this.utilizationContainer.offsetWidth,
          height: this.utilizationContainer.offsetHeight,
        },
      });
    }
  }

  render() {
    const {
      dashboardData,
      permissions,
      realizationContainerSize,
      utilizationContainerSize,
    } = this.state;

    if (
      !permissions
      || (
        !permissions.can_read
        && !permissions.can_create
        && !permissions.can_delete
        && !permissions.can_update
        && !permissions.limit_to_own_user
      )
    ) {
      if (permissions) {
        this.props.router.push('/404');
      }
      return null;
    }

    if (_.isEmpty(dashboardData)) {
      return (
        <section className="dashboard-container">
          {this.props.dashboard.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        </section>
      );
    }

    const realizationContainerSizeWidth = realizationContainerSize.width * 0.25;
    const utilizationContainerSizeWidth = utilizationContainerSize.width * 0.25;

    return (
      <section className="dashboard-container" ref={(c) => { this.sectionContainer = c; }}>
        {this.props.dashboard.inProgress ? <SystemMsg msg="Loading" hasSpinner /> : '' }
        <div className="content">
          <Row className="row-eq-height">
            {
              dashboardData.realization ? (
                <Col md={5} xs={12}>
                  <div className="small-box" ref={(c) => { this.realizationContainer = c; }}>
                    <div className="inner">
                      <h2>
                        Realization
                      </h2>
                      <Row>
                        <Col xs={4}>
                          <Knob
                            value={parseFloat(dashboardData.realization.year_to_date)}
                            height={realizationContainerSizeWidth}
                            width={realizationContainerSizeWidth}
                            displayInput={false}
                            className="knob"
                            displayCustom={() => (
                              <div
                                className="knob-percentage"
                              >
                                {`${dashboardData.realization.year_to_date}%`}
                              </div>
                            )}
                            fgColor="#EC7A52"
                            readOnly
                            disableMouseWheel
                          />
                          <div className="secondary-title">
                            YTD
                          </div>
                        </Col>
                        <Col xs={4}>
                          <Knob
                            value={parseFloat(dashboardData.realization.last_quarter)}
                            height={realizationContainerSizeWidth}
                            width={realizationContainerSizeWidth}
                            displayInput={false}
                            className="knob"
                            displayCustom={() => (
                              <div
                                className="knob-percentage"
                              >
                                {`${dashboardData.realization.last_quarter}%`}
                              </div>
                            )}
                            fgColor="#EC7A52"
                            readOnly
                            disableMouseWheel
                          />
                          <div className="secondary-title">
                            Last Quarter
                          </div>
                        </Col>
                        <Col xs={4}>
                          <Knob
                            value={parseFloat(dashboardData.realization.last_month)}
                            height={realizationContainerSizeWidth}
                            width={realizationContainerSizeWidth}
                            displayInput={false}
                            className="knob"
                            displayCustom={() => (
                              <div
                                className="knob-percentage"
                              >
                                {`${parseFloat(dashboardData.realization.last_month)}%`}
                              </div>
                            )}
                            fgColor="#EC7A52"
                            readOnly
                            disableMouseWheel
                          />
                          <div className="secondary-title">
                            Last Month
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <a href="/realization" className="small-box-footer">
                      More info&nbsp;
                      <i className="fa fa-arrow-circle-right" />
                    </a>
                  </div>
                </Col>
              ) : null
            }

            {
              dashboardData.utilization ? (
                <Col md={5} xs={12}>
                  <div className="small-box" ref={(c) => { this.utilizationContainer = c; }}>
                    <div className="inner">
                      <h2>
                        Utilization
                      </h2>
                      <Row>
                        <Col xs={4}>
                          <Knob
                            value={parseFloat(dashboardData.utilization.year_to_date)}
                            height={utilizationContainerSizeWidth}
                            width={utilizationContainerSizeWidth}
                            displayInput={false}
                            className="knob"
                            displayCustom={() => (
                              <div
                                className="knob-percentage"
                              >
                                {`${dashboardData.utilization.year_to_date}%`}
                              </div>
                            )}
                            fgColor="#EC7A52"
                            readOnly
                            disableMouseWheel
                          />
                          <div className="secondary-title">
                            YTD
                          </div>
                        </Col>
                        <Col xs={4}>
                          <Knob
                            value={parseFloat(dashboardData.utilization.last_quarter)}
                            height={utilizationContainerSizeWidth}
                            width={utilizationContainerSizeWidth}
                            displayInput={false}
                            className="knob"
                            displayCustom={() => (
                              <div
                                className="knob-percentage"
                              >
                                {`${dashboardData.utilization.last_quarter}%`}
                              </div>
                            )}
                            fgColor="#EC7A52"
                            readOnly
                            disableMouseWheel
                          />
                          <div className="secondary-title">
                            Last Quarter
                          </div>
                        </Col>
                        <Col xs={4}>
                          <Knob
                            value={parseFloat(dashboardData.utilization.last_month)}
                            height={utilizationContainerSizeWidth}
                            width={utilizationContainerSizeWidth}
                            displayInput={false}
                            className="knob"
                            displayCustom={() => (
                              <div
                                className="knob-percentage"
                              >
                                {`${dashboardData.utilization.last_month}%`}
                              </div>
                            )}
                            fgColor="#EC7A52"
                            readOnly
                            disableMouseWheel
                          />
                          <div className="secondary-title">
                            Last Month
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <a href="/utilization" className="small-box-footer">
                      More info&nbsp;
                      <i className="fa fa-arrow-circle-right" />
                    </a>
                  </div>
                </Col>
              ) : null
            }

            {
              dashboardData.staffing ? (
                <Col md={2} xs={12}>
                  <div className="small-box" ref={(c) => { this.staffingContainer = c; }}>
                    <div className="inner">
                      <h2>
                        Staffing
                      </h2>
                      <Knob
                        value={parseFloat(dashboardData.staffing.next_8_weeks_average)}
                        height={utilizationContainerSizeWidth}
                        width={utilizationContainerSizeWidth}
                        displayInput={false}
                        className="knob"
                        displayCustom={() => (
                          <div
                            className="knob-percentage"
                          >
                            {`${dashboardData.staffing.next_8_weeks_average}%`}
                          </div>
                        )}
                        fgColor="#EC7A52"
                        readOnly
                        disableMouseWheel
                      />
                      <div className="secondary-title">
                        8-Week Forward
                      </div>
                    </div>
                    <a href="/staffing" className="small-box-footer">
                      More info&nbsp;
                      <i className="fa fa-arrow-circle-right" />
                    </a>
                  </div>
                </Col>
              ) : null
            }

            {
              dashboardData.business_development ? (
                <Col md={4} xs={12}>
                  <div className="small-box">
                    <div className="inner">
                      <h2>
                        Business Development
                      </h2>
                      <div className="big-number">
                        {`$ ${Number(dashboardData.business_development.total_wighted_amount).toLocaleString('en-US')}`}
                      </div>
                      <div className="secondary-title">
                        Total Weighted Amount
                      </div>
                    </div>
                    <a href="/business-development" className="small-box-footer">
                      More info&nbsp;
                      <i className="fa fa-arrow-circle-right" />
                    </a>
                  </div>
                </Col>
              ) : null
            }

            {
              dashboardData.stock_options ? (
                <Col md={4} xs={12}>
                  <div className="small-box">
                    <div className="inner">
                      <h2>
                        Stock Options
                      </h2>
                      <div className="big-number">
                        {Number(dashboardData.stock_options.options_granted).toLocaleString('en-US')}
                      </div>
                      <div className="secondary-title">
                        Options Granted to Active Users
                      </div>
                    </div>
                    <a href="/stock-options" className="small-box-footer">
                      More info&nbsp;
                      <i className="fa fa-arrow-circle-right" />
                    </a>
                  </div>
                </Col>
              ) : null
            }

            {
              dashboardData.profit_sharing ? (
                <Col md={4} xs={12}>
                  <div className="small-box">
                    <div className="inner">
                      <h2>
                        Profit Sharing
                      </h2>
                      <div className="big-number">
                        {`$ ${Number(dashboardData.profit_sharing.year_to_date).toLocaleString('en-US')}`}
                      </div>
                      <div className="secondary-title">
                        YTD Allocation
                      </div>
                    </div>
                    <a href="/profit-sharing" className="small-box-footer">
                      More info&nbsp;
                      <i className="fa fa-arrow-circle-right" />
                    </a>
                  </div>
                </Col>
              ) : null
            }

            {
              dashboardData.recruiting ? (
                <Col md={4} xs={12}>
                  <div className="small-box">
                    <div className="inner">
                      <h2>
                        Recruiting
                      </h2>
                      <div className="big-number">
                        {dashboardData.recruiting.stage_3_and_more}
                      </div>
                      <div className="secondary-title">
                        Recruiting on Stage 3 and After
                      </div>
                    </div>
                    <a href="/recruiting" className="small-box-footer">
                      More info&nbsp;
                      <i className="fa fa-arrow-circle-right" />
                    </a>
                  </div>
                </Col>
              ) : null
            }

            {
              dashboardData.invoicing ? (
                <Col md={8} xs={12}>
                  <div className="small-box">
                    <div className="inner">
                      <h2>
                        Invoicing
                      </h2>
                      <Row>
                        <Col md={3} xs={12}>
                          <div className="big-number">
                            {`$ ${Number(dashboardData.invoicing.current_month).toLocaleString('en-US')}`}
                          </div>
                          <div className="secondary-title">
                            Current Month
                          </div>
                        </Col>
                        <Col md={3} xs={12} className="margin-top">
                          <div className="big-number">
                            {`$ ${Number(dashboardData.invoicing.last_30_days).toLocaleString('en-US')}`}
                          </div>
                          <div className="secondary-title">
                            30 Days
                          </div>
                        </Col>
                        <Col md={3} xs={12} className="margin-top">
                          <div className="big-number">
                            {`$ ${Number(dashboardData.invoicing.last_60_days).toLocaleString('en-US')}`}
                          </div>
                          <div className="secondary-title">
                            60 Days
                          </div>
                        </Col>
                        <Col md={3} xs={12} className="margin-top">
                          <div className="big-number">
                            {`$ ${Number(dashboardData.invoicing.last_90_days).toLocaleString('en-US')}`}
                          </div>
                          <div className="secondary-title">
                            90 Days
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <a href="/invoices-issued" className="small-box-footer">
                      More info&nbsp;
                      <i className="fa fa-arrow-circle-right" />
                    </a>
                  </div>
                </Col>
              ) : null
            }
          </Row>
        </div>
      </section>
    );
  }
}

export default Dashboard;
